import { useRecoilState } from 'recoil';
import { modalState } from '@frontastic-engbers/lib/state/modal/atoms';
import { Modal } from '../modal';
import { AnimatePresence } from 'framer-motion';

export const ModalWrap = () => {
  const [modal, setModal] = useRecoilState(modalState);
  return (
    <>
      <AnimatePresence>
        {modal && (
          <Modal
            hasOkayButton={modal.hasOkayButton}
            id={modal.id}
            content={modal.content}
            title={modal.title}
            onClose={modal.onClose}
            hasCloseButton={modal.hasCloseButton}
            canCloseByBackdropClick={modal.canCloseByBackdropClick}
            isLarge={modal.isLarge}
            isMedium={modal.isMedium}
          />
        )}
      </AnimatePresence>
    </>
  );
};
