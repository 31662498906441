import React, { useCallback, useState } from "react";
import { useAccount } from "@frontastic-engbers/lib";
import { Block, Button, InputText, PasswordCheckList } from "@engbers/components";
import { useToastNotificationsActions } from "@frontastic-engbers/lib/state/notification/actions";
import { useFormat } from "@frontastic-engbers/helpers/hooks/useFormat";
import { PasswordCheckListHelper } from "@frontastic-engbers/helpers/passwordCheckListHelper";
import styles from "./my-data.module.scss";

type ChangePasswordProps = {
    currentEmail: string;
    myDataAccessDataTitle: string;
};
type ChangePasswordFormState = {
    currentPassword: string;
    newPassword: string;
    newPasswordConfirm: string;
};

const validate = (data: ChangePasswordFormState) => {
    return {
        currentPassword: data.currentPassword !== "",
        newPassword: data.newPassword !== "",
        newPasswordConfirm: data.newPassword === data.newPasswordConfirm,
    };
};

export const MyDataChangePassword: React.FC<ChangePasswordProps> = ({
    currentEmail,
    myDataAccessDataTitle,
}) => {
    const { changePassword } = useAccount();
    const { pushNotification } = useToastNotificationsActions();
    const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });
    const { formatMessage: formatSuccessMessage } = useFormat({ name: 'success' })
    const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' })
    const { formatMessage } = useFormat({ name: 'common' });
    const [errors, setErrors] = useState({
        currentPassword: true,
        newPassword: true,
        newPasswordConfirm: true,
    });
    const [oldPasswordErrors, setOldPasswordErrors ] = useState({
      valid: false,
      message: '',
    });
    const [newPasswordErrors, setNewPasswordErrors] = useState<{
        valid: boolean;
        message: string;
    } | null>(null);
    const [isPassInputFocused, setIsPassInputFocused] =
        useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<ChangePasswordFormState>({
        currentPassword: "",
        newPassword: "",
        newPasswordConfirm: "",
    });

    const resetPasswordForm = useCallback(() => {
        setData({
            currentPassword: "",
            newPassword: "",
            newPasswordConfirm: "",
        });
    }, []);

    const handleChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setData((prev) => ({
                ...prev,
                [event.target.name]: event.target.value,
            }));
        },
        [setData]
    );

    const handleSubmit = useCallback(async (e) => {
        e.preventDefault();
        const err = validate(data);
        validateNewPassword();
        setErrors(err);

        if (
            Object.values(err).some((error) => !error) ||
            !newPasswordErrors ||
            !newPasswordErrors.valid
        ) {
            return;
        }

        setLoading(true);
        try {
            const response = await changePassword(
                data.currentPassword,
                data.newPassword
            );
            if (response.confirmed) {
                pushNotification(
                    formatSuccessMessage({ id: 'updatedData' }),
                    "error"
                );

                resetPasswordForm();
            } else {
              setOldPasswordErrors({
                valid: true,
                message: formatAccountMessage({ id: 'password.enter' })
              })
            }
        } catch (e) {
            console.error(e);
        }

        setLoading(false);
    }, [setLoading, setErrors, changePassword, data]);

    const validateNewPassword = (e?: React.ChangeEvent<HTMLInputElement>) => {
        const ruleDefinitions: any = PasswordCheckListHelper.ruleDefinitions(
            e ? e.target.value : data.newPassword,
            6
        );
        const rulesKeys: string[] = Object.keys(ruleDefinitions).filter(
            (key) => !ruleDefinitions[key].valid
        );

        rulesKeys.forEach((key) => {
            if (!ruleDefinitions[key].valid) {
                setErrors({
                    ...errors,
                    newPassword: ruleDefinitions[key].valid,
                });
                setNewPasswordErrors(ruleDefinitions[key]);
            }
        });

        if (!rulesKeys.length) {
            setErrors({ ...errors, newPassword: true });
            setNewPasswordErrors({ valid: true, message: "" });
        }
    };

    if (!data) {
        return null;
    }

    return (
        <form className={styles.form}>
            <Block marginTop={8} marginBottom={4}>
                <h3>{myDataAccessDataTitle}</h3>
            </Block>
            <div className={styles.emailText}>{currentEmail}</div>
            <Block marginTop={4}>
                <Block marginBottom={4}>
                    <InputText
                        id="currentPassword"
                        name="currentPassword"
                        type="password"
                        placeholder={formatAccountMessage({ id: 'password' })}
                        required
                        value={data.currentPassword}
                        onChange={handleChange}
                        onBlur={() =>
                            setErrors({
                                ...errors,
                                currentPassword: data.currentPassword !== "",
                            })
                        }
                        errorMessage={
                          (!errors.currentPassword && formatAccountMessage({ id: 'password.enter' })) ||
                          (oldPasswordErrors.valid && oldPasswordErrors.message)
                        }
                    />
                </Block>
                <Block marginBottom={4}>
                    <Block>
                        <InputText
                            id="newPassword"
                            name="newPassword"
                            type="password"
                            placeholder={formatAccountMessage({ id: 'password.new' })}
                            required
                            onChange={(e) => {
                                handleChange(e);
                                validateNewPassword(e);
                            }}
                            value={data.newPassword}
                            onFocus={() => setIsPassInputFocused(true)}
                            onBlur={(e) => {
                                setIsPassInputFocused(false);
                                validateNewPassword(e);
                            }}
                            errorMessage={
                                newPasswordErrors && !newPasswordErrors.valid
                                    ? newPasswordErrors.message
                                    : undefined
                            }
                        />
                    </Block>
                    {isPassInputFocused ? (
                        <Block marginTop={2}>
                            <PasswordCheckList
                                rules={[
                                    "minLength",
                                    "lowercase",
                                    "capital",
                                    "number",
                                    "specialChar",
                                ]}
                                minLength={6}
                                value={data.newPassword}
                            />
                        </Block>
                    ) : null}
                </Block>
                <Block marginBottom={4}>
                    <InputText
                        id="newPasswordConfirm"
                        name="newPasswordConfirm"
                        type="password"
                        placeholder={formatAccountMessage({ id: 'password.repeatNewShort' })}
                        required
                        value={data.newPasswordConfirm}
                        onChange={handleChange}
                        onBlur={() =>
                            setErrors({
                                ...errors,
                                newPasswordConfirm:
                                    data.newPassword ===
                                    data.newPasswordConfirm,
                            })
                        }
                        errorMessage={
                            !errors.newPasswordConfirm
                                ? formatErrorMessage({ id: 'password.noMatch' })
                                : undefined
                        }
                    />
                </Block>
                <Block
                    customStyle={{ marginLeft: "auto" }}
                    maxWidth="fit-content"
                >
                    <Button
                        buttonType="submit"
                        size="large"
                        label={formatMessage({ id: 'save.bold' })}
                        isLoading={loading}
                        customStyle={{
                            paddingLeft: "30px",
                            paddingRight: "30px",
                        }}
                        onClick={handleSubmit}
                    />
                </Block>
            </Block>
        </form>
    );
};
