import { EngbersCardBalance } from '@engbers/components/online-shops/engbers-card-balance';

export const EngbersCardBalanceTastic = ({ data }) => {
  return (
    <EngbersCardBalance
      headline={data.headline}
      textSize={data.textSize}
      description={data.description}
      ctaText={data.ctaText}
      image={data.image}
      balanceSuccess={data.balanceSuccess}
      balanceError={data.balanceError}
    />
  );
};
