import React, { useEffect, useState } from 'react';
import Script from 'next/script';
import { Order } from '@frontastic-engbers/types/cart/Order';

interface ISovendus {
  order: Order;
}

interface CustomWindow extends Window {
  sovIframes?: any[];
  sovConsumer?: any[];
}

declare const window: CustomWindow;

export const Sovendus: React.FC<ISovendus> = ({ order }) => {
  const [renderSovScript, setRenderSovScript] = useState(false);

  useEffect(() => {
    if (order) {
      const sovIframeData = {
        trafficSourceNumber: '4858',
        trafficMediumNumber: '1',
        timestamp: `${Math.floor(new Date(order.createdAt).getTime() / 1000)}`,
        orderId: `${order.orderId}`,
        orderValue: `${((order.sum.centAmount - order.shippingInfo.shippingRate.price.centAmount) / 100) / (order.taxed.taxPortions[0].rate + 1)}`,
        orderCurrency: `${order.sum.currencyCode}`,
        usedCouponCode: `${order.discountCodes.map((code) => code.code).join(';')}`,
        iframeContainerId: 'sovendus-container-1',
      };

      const sovConsumerData = {
        consumerSalutation: `${order.billingAddress.salutation === 'MR' ? 'Mr.' : 'Mr.s'}`,
        consumerFirstName: `${order.billingAddress.firstName}`,
        consumerLastName: `${order.billingAddress.lastName}`,
        consumerEmail: `${order.email}`,
        consumerCountry: `${order.billingAddress.country}`,
        consumerZipcode: `${order.billingAddress.postalCode}`,
      };

      window.sovIframes = window.sovIframes || [];
      window.sovIframes.push(sovIframeData);

      window.sovConsumer = window.sovConsumer || [];
      window.sovConsumer.push(sovConsumerData);

      setRenderSovScript(true);
    }
  }, [order]);

  if (!order) {
    return null;
  }

  const sovDomain = window.location.protocol + '\x2F\x2F' + 'api.sovendus.com';
  const sovJsFile = sovDomain + '\x2Fsovabo\x2Fcommon\x2Fjs\x2FflexibleIframe.js';

  return (
    <div>
      <div id="sovendus-container-1" className="mt-2"></div>
      {renderSovScript && <Script src={sovJsFile} />}
    </div>
  )
};
