import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Block, Button, IconCustom, InputCheckbox, InputRadioGroup, Markdown, Typography } from '@engbers/components';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { InputText } from '@engbers/components/shopmacher-ui/input-text';
import { Textarea } from '@engbers/components/textarea';
import { useAccount } from '@frontastic-engbers/lib';
import { sendContactMessage } from '@frontastic-engbers/lib/actions/service';
import { useToastNotificationsActions } from '@frontastic-engbers/lib/state/notification/actions';
import { useMediaQuery } from 'react-responsive';
import { EmailValidatorHelper } from '@frontastic-engbers/helpers/emailValidatorHelper';
import { getReferenceTarget, Reference } from '@frontastic-engbers/helpers/reference';
import ReCAPTCHA from 'react-google-recaptcha';
import styles from './contact-form.module.scss';
import { useRouter } from 'next/router';

export interface IContactForm {
  headline: string;
  headlineSize: number;
  isEmilio: boolean;
  reCaptchaKey: string;
  redirectSite: Reference;
  labels: any;
}

type FormData = {
  Salutation: string;
  FirstName: string;
  LastName: string;
  ZipCode: string;
  City: string;
  CustNo: string;
  Email: string;
  Phone: string;
  About: string;
  Message: string;
  AcceptTos: boolean;
  IsEmilio: boolean;
};

type ValidationErrors = {
  generalError?: string;
  firstName: boolean;
  lastName: boolean;
  zipCode: boolean;
  city: boolean;
  email: boolean;
  about: boolean;
  message: boolean;
  acceptTos: boolean;
  acceptReCaptcha: boolean;
};

export const ContactForm: React.FC<IContactForm> = ({
  headline,
  headlineSize,
  isEmilio,
  reCaptchaKey,
  redirectSite,
  labels,
}) => {
  const router = useRouter();
  const { account } = useAccount();
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });
  const { formatMessage: formatSuccessMessage } = useFormat({ name: 'success' });
  const { formatMessage } = useFormat({ name: 'common' });
  const { pushNotification } = useToastNotificationsActions();

  const [errors, setErrors] = useState<Partial<ValidationErrors>>({});
  const [emailErrors, setEmailErrors] = useState<string | null>(null);
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const [initialSend, setInitialSend] = useState(false);
  const [isMinWidthMedium, setIsMinWidthMedium] = useState<boolean>(false);
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const recaptchaRef = useRef();

  const [formData, setFormData] = useState<FormData>({
    Salutation: '',
    FirstName: '',
    LastName: '',
    ZipCode: '',
    City: '',
    CustNo: account?.cardId ?? '',
    Email: '',
    Phone: '',
    About: '',
    Message: '',
    AcceptTos: false,
    IsEmilio: isEmilio,
  });

  const mailMessage = formatErrorMessage({
    id: 'email.wrong',
    defaultMessage: 'E-Mail Adresse format ist invalid.',
  });
  const mailErrorMessage = formatAccountMessage({
    id: 'email.enter.again',
    defaultMessage: 'Please enter your e-mail address.',
  });

  useEffect(() => {
    if (isMobile !== isMinWidthMedium) {
      setIsMinWidthMedium(isMobile);
    }
  }, [isMobile]);

  useEffect(() => {
    if (account) {
      setFormData((prevData) => {
        return {
          ...prevData,
          CustNo: account.cardId,
        };
      });
    }
  }, [account?.cardId]);

  const resetFormData = useCallback(() => {
    setFormData(() => {
      return {
        Salutation: '',
        FirstName: '',
        LastName: '',
        ZipCode: '',
        City: '',
        CustNo: account?.cardId ?? '',
        Email: '',
        Phone: '',
        About: '',
        Message: '',
        AcceptTos: false,
        IsEmilio: isEmilio,
      };
    });
  }, [account?.cardId]);

  const validateEmail = async (): Promise<boolean> => {
    const ruleDefinitions: string | null = await EmailValidatorHelper.ruleDefinitions(formData.Email);

    if (!ruleDefinitions) {
      setEmailErrors(null);
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: true,
      }));

      return true;
    }

    setEmailErrors(ruleDefinitions);
    setErrors((prevErrors) => ({
      ...prevErrors,
      email: false,
    }));

    return false;
  };

  const validate = () => {
    const validationErrors: ValidationErrors = {
      firstName: formData.FirstName !== '',
      lastName: formData.LastName !== '',
      zipCode: formData.ZipCode !== '',
      city: formData.City !== '',
      email: formData.Email !== '',
      about: formData.About !== '',
      message: formData.Message !== '',
      acceptTos: formData.AcceptTos,
      acceptReCaptcha: (recaptchaRef.current as any)?.getValue() !== '',
    };

    setErrors(validationErrors);

    return !Object.values(validationErrors).includes(false);
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.checked,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      acceptTos: e.target.checked,
    }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setInitialSend(true);

    const validEmail = await validateEmail();

    if (!validEmail || !validate()) {
      return;
    }

    setLoading(true);
    const response = await sendContactMessage({ formData: formData });

    if (!response.success) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        generalError: formatErrorMessage({
          id: 'wentWrong',
          defaultMessage: 'Sorry. Something went wrong..',
        }),
      }));
      pushNotification(formatErrorMessage({ id: 'failedMsgSend' }), 'error');
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        generalError: null,
      }));
      resetFormData();
      setSuccess(formatSuccessMessage({ id: 'contact.sent' }));
      pushNotification(formatSuccessMessage({ id: 'SendMsg' }), 'success');

      router.push(getReferenceTarget(redirectSite));
    }

    setLoading(false);
  };

  if (isMinWidthMedium) {
    return (
      <>
        <Markdown className={styles.headline} text={headline} textSize={headlineSize} />
        <form onSubmit={handleSubmit}>
          <Block marginBottom={2}>
            {errors.generalError && (
              <Typography color="danger" size="base">
                {errors.generalError}
              </Typography>
            )}
            {success && (
              <Typography color="success" size="base">
                {success}
              </Typography>
            )}
          </Block>
          <Block marginBottom={8}>
            <Block marginBottom={4}>
              <InputRadioGroup
                onChange={(id) => {
                  setFormData((prev) => ({
                    ...prev,
                    Salutation: id as any,
                  }));
                }}
                options={[
                  {
                    label: `${labels.radioMister ?? 'MR'}`,
                    id: `${labels.radioMister ?? 'MR'}`,
                  },
                  {
                    label: `${labels.radioMs ?? 'MS'}`,
                    id: `${labels.radioMs ?? 'MS'}`,
                  },
                ]}
                activeId={formData.Salutation}
              />
            </Block>
            <Block marginBottom={4}>
              <label className="sr-only" htmlFor="firstName">
                {labels.labelFirstName ??
                  formatMessage({
                    id: 'firstName',
                    defaultMessage: 'Forename',
                  })}
              </label>
              <InputText
                style={'grey'}
                id="firstName"
                errorMessage={!errors.firstName && initialSend && formatErrorMessage({ id: 'fillInput' })}
                name="FirstName"
                type="text"
                onChange={handleChange}
                onBlur={() =>
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    firstName: formData.FirstName !== '',
                  }))
                }
                placeholder={
                  labels.labelFirstName ??
                  formatMessage({
                    id: 'firstName',
                    defaultMessage: 'Forename',
                  })
                }
                required
                value={formData.FirstName}
              />
            </Block>
            <Block marginBottom={4}>
              <label className="sr-only" htmlFor="lastName">
                {labels.labelLastName ??
                  formatMessage({
                    id: 'lastName',
                    defaultMessage: 'Surname',
                  })}
              </label>
              <InputText
                style={'grey'}
                errorMessage={!errors.lastName && initialSend && formatErrorMessage({ id: 'fillInput' })}
                id="lastName"
                name="LastName"
                type="text"
                onChange={handleChange}
                onBlur={() =>
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    lastName: formData.LastName !== '',
                  }))
                }
                placeholder={
                  labels.labelLastName ??
                  formatMessage({
                    id: 'lastName',
                    defaultMessage: 'Surname',
                  })
                }
                required
                value={formData.LastName}
              />
            </Block>
            <Block marginBottom={4}>
              <label className="sr-only" htmlFor="zipCode">
                {labels.zipCode ??
                  formatMessage({
                    id: 'zipCode',
                    defaultMessage: 'zipCode',
                  })}
              </label>
              <InputText
                style={'grey'}
                id="zipCode"
                name="ZipCode"
                type="number"
                autoComplete="zipCode"
                required
                value={formData.ZipCode}
                onChange={handleChange}
                onBlur={() =>
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    zipCode: formData.ZipCode !== '',
                  }))
                }
                placeholder={
                  labels.zipCode ??
                  formatMessage({
                    id: 'zipCode',
                    defaultMessage: 'zipCode',
                  })
                }
                errorMessage={
                  !errors.zipCode && initialSend
                    ? formatMessage({ id: 'zipCode' }) + formatErrorMessage({ id: 'mandatory.error' })
                    : undefined
                }
              />
            </Block>
            <Block marginBottom={4}>
              <label className="sr-only" htmlFor="city">
                {labels.zipCode ??
                  formatMessage({
                    id: 'place',
                    defaultMessage: 'place',
                  })}
              </label>
              <InputText
                style={'grey'}
                id="city"
                name="City"
                type="text"
                autoComplete="city"
                required
                value={formData.City}
                onChange={handleChange}
                onBlur={() =>
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    city: formData.City !== '',
                  }))
                }
                placeholder={
                  labels.city ??
                  formatMessage({
                    id: 'place',
                    defaultMessage: 'place',
                  })
                }
                errorMessage={
                  !errors.city && initialSend
                    ? formatMessage({ id: 'place' }) + formatErrorMessage({ id: 'mandatory.error' })
                    : undefined
                }
              />
            </Block>
            <Block marginBottom={4}>
              <label className="sr-only" htmlFor="custNo">
                {labels.labelEngbersCard ??
                  formatMessage({
                    id: 'custNo',
                    defaultMessage: 'Customer No.',
                  })}
              </label>
              <InputText
                style={'grey'}
                id="custNo"
                name="CustNo"
                type="number"
                onChange={handleChange}
                placeholder={
                  labels.labelEngbersCard ??
                  formatMessage({
                    id: 'custNo',
                    defaultMessage: 'Customer No.',
                  })
                }
                value={`${formData.CustNo}`}
              />
            </Block>
            <Block marginBottom={4}>
              <label className="sr-only" htmlFor="email">
                {labels.labelEmail ??
                  formatMessage({
                    id: 'email',
                    defaultMessage: 'Email',
                  })}
              </label>
              <InputText
                style={'grey'}
                id="email"
                errorMessage={!emailErrors ? undefined : emailErrors === 'empty' ? mailErrorMessage : mailMessage}
                name="Email"
                type="email"
                onChange={handleChange}
                onBlur={validateEmail}
                placeholder={
                  labels.labelEmail ??
                  formatMessage({
                    id: 'email',
                    defaultMessage: 'Email',
                  })
                }
                required
                value={formData.Email}
              />
            </Block>
            <Block marginBottom={4} className={styles.phoneWrap}>
              <label className="sr-only" htmlFor="phone">
                {labels.labelphone ??
                  formatMessage({
                    id: 'phone',
                    defaultMessage: 'phone',
                  })}
              </label>
              <InputText
                style={'grey'}
                id="phone"
                name="Phone"
                type="number"
                onChange={handleChange}
                placeholder={
                  labels.labelphone ??
                  formatMessage({
                    id: 'phone',
                    defaultMessage: 'phone',
                  })
                }
                value={formData.Phone}
              />
            </Block>
            <Block marginBottom={4}>
              <label className="sr-only" htmlFor="about">
                {labels.labelAbout ??
                  formatMessage({
                    id: 'message',
                    defaultMessage: 'message',
                  })}
              </label>
              <InputText
                style={'grey'}
                id="about"
                name="About"
                type="text"
                required
                errorMessage={!errors.about && initialSend && formatErrorMessage({ id: 'fillInput' })}
                onChange={handleChange}
                onBlur={() =>
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    about: formData.About !== '',
                  }))
                }
                placeholder={
                  labels.labelAbout ??
                  formatMessage({
                    id: 'message',
                    defaultMessage: 'message',
                  })
                }
                value={formData.About}
              />
            </Block>
            <Block marginBottom={4}>
              <label className="sr-only" htmlFor="message">
                {labels.labelMessage ??
                  formatMessage({
                    id: 'message',
                    defaultMessage: 'Your enquiry',
                  })}
              </label>
              <Textarea
                style={'grey'}
                id="message"
                name="Message"
                errorMessage={!errors.message && initialSend && formatErrorMessage({ id: 'fillInput' })}
                onBlur={() =>
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    message: formData.Message !== '',
                  }))
                }
                onChange={handleChange}
                placeholder={`${
                  labels.labelMessage ??
                  formatMessage({
                    id: 'message',
                    defaultMessage: 'Your enquiry',
                  })
                } *`}
                rows={5}
                required
                value={formData.Message}
              />
            </Block>
            <Block marginBottom={4}>
              <label className="sr-only">
                {labels.labelRecaptcha ??
                  formatMessage({
                    id: 'reCaptchaSecurityCode',
                    defaultMessage: 'accept ReCaptcha',
                  })}
              </label>
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={reCaptchaKey}
                onChange={(v) =>
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    acceptReCaptcha: v !== '',
                  }))
                }
              />
              <span className={styles.errorMsg}>
                {!errors.acceptReCaptcha &&
                  initialSend &&
                  formatErrorMessage({
                    id: 'fillInput',
                    defaultMessage: 'fillInput',
                  })}
              </span>
            </Block>
            <Block marginBottom={4}>
              <InputCheckbox
                id="accept_tos"
                name="AcceptTos"
                label={
                  <span className={styles.acceptTosWrap}>
                    <span className={errors.acceptTos === false ? styles.errorMsgColor : ''}>
                      <Markdown linkToNewTab text={labels.textTos} className={styles.markdownGuidelines} />
                    </span>
                    <span className="flex w-min items-center">
                      {errors.acceptTos === false ? (
                        <IconCustom width={18} icon="Close" className={styles.errorMsgColor} />
                      ) : (
                        ''
                      )}
                    </span>
                  </span>
                }
                onChange={handleCheckboxChange}
                errorMessage={
                  !errors.acceptTos && initialSend ? formatErrorMessage({ id: 'acceptedTerms' }) : undefined
                }
                hasHoverEffect
              />
            </Block>
            <Button
              onClick={handleSubmit}
              isLoading={loading}
              size="large"
              label={
                labels.labelMobileBtnSubmit ??
                formatMessage({
                  id: 'submit',
                  defaultMessage: 'Submit',
                })
              }
            />
          </Block>
        </form>
      </>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <Block
        marginBottom={4}
        customStyle={{
          width: '100%',
          display: 'inline-flex',
        }}
      >
        <label className="w-1/4 text-sm" htmlFor="firstName">
          {formatMessage({
            id: 'salutation',
            defaultMessage: 'salutation',
          })}
        </label>
        <span className={styles.wrapperRadioBtn}>
          <label>
            <input
              className={styles.radioInputMister}
              type="radio"
              id="salutationMr"
              value={labels.radioMister}
              name="Salutation"
              onChange={handleChange}
            />
            <span>{labels.radioMister}</span>
          </label>
          <label>
            <input
              className={styles.radioInputMs}
              type="radio"
              id="salutationMs"
              value={labels.radioMs}
              name="Salutation"
              onChange={handleChange}
            />
            <span>{labels.radioMs}</span>
          </label>
        </span>
      </Block>
      <Block
        marginBottom={4}
        customStyle={{
          width: '100%',
          display: 'inline-flex',
        }}
      >
        <label className="w-1/4 text-sm" htmlFor="firstName">
          {labels.labelFirstName
            ? `${labels.labelFirstName} *`
            : formatMessage({
              id: 'firstName',
              defaultMessage: 'Forename',
            })}
        </label>
        <InputText
          style={'white'}
          name="FirstName"
          type="text"
          autoComplete="given-name"
          required
          value={formData.FirstName}
          onChange={handleChange}
          onBlur={() =>
            setErrors((prevErrors) => ({
              ...prevErrors,
              firstName: formData.FirstName !== '',
            }))
          }
          errorMessage={
            !errors.firstName && initialSend
              ? formatMessage({ id: 'firstName' }) + formatErrorMessage({ id: 'mandatory.error' })
              : undefined
          }
          wrapperCustomStyle={{ width: '75%' }}
          inputCustomStyle={{
            height: '28px',
            padding: '0 8px',
          }}
        />
      </Block>
      <Block
        marginBottom={4}
        customStyle={{
          width: '100%',
          display: 'inline-flex',
        }}
      >
        <label className="w-1/4 text-sm" htmlFor="lastName">
          {labels.labelLastName
            ? `${labels.labelLastName} *`
            : formatMessage({
              id: 'lastName',
              defaultMessage: 'Surname',
            })}
        </label>
        <InputText
          style={'white'}
          id="last_name"
          name="LastName"
          type="text"
          autoComplete="family-name"
          required
          value={formData.LastName}
          onChange={handleChange}
          onBlur={() =>
            setErrors((prevErrors) => ({
              ...prevErrors,
              lastName: formData.LastName !== '',
            }))
          }
          errorMessage={
            !errors.lastName && initialSend
              ? formatMessage({ id: 'lastName' }) + formatErrorMessage({ id: 'mandatory.error' })
              : undefined
          }
          wrapperCustomStyle={{ width: '75%' }}
          inputCustomStyle={{
            height: '28px',
            padding: '0 8px',
          }}
        />
      </Block>
      <Block
        marginBottom={4}
        customStyle={{
          width: '100%',
          display: 'inline-flex',
        }}
      >
        <label className="w-1/4 text-sm" htmlFor="address_zip">
          {labels.labelZip && labels.labelCity
            ? `${labels.labelZip}, ${labels.labelCity} *`
            : `${formatMessage({
              id: 'zipCodeShort',
              defaultMessage: 'zipcode',
            })}, ${formatMessage({
              id: 'place',
              defaultMessage: 'place',
            })}`}
        </label>
        <div className="inline-flex w-3/4">
          <Block maxWidth="30%" customStyle={{ width: '100%' }}>
            <InputText
              style={'white'}
              id="zipCode"
              name="ZipCode"
              type="number"
              autoComplete="zipCode"
              required
              value={formData.ZipCode}
              onChange={handleChange}
              onBlur={() =>
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  zipCode: formData.ZipCode !== '',
                }))
              }
              errorMessage={
                !errors.zipCode && initialSend
                  ? formatMessage({ id: 'zipCode' }) + formatErrorMessage({ id: 'mandatory.error' })
                  : undefined
              }
              wrapperCustomStyle={{ paddingRight: '15px' }}
              inputCustomStyle={{
                height: '28px',
                padding: '0 8px',
              }}
            />
          </Block>
          <Block maxWidth="70%" customStyle={{ width: '100%' }}>
            <InputText
              style={'white'}
              id="city"
              name="City"
              type="text"
              autoComplete=""
              required
              value={formData.City}
              onChange={handleChange}
              onBlur={() =>
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  city: formData.City !== '',
                }))
              }
              errorMessage={
                !errors.city && initialSend
                  ? formatMessage({ id: 'place' }) + formatErrorMessage({ id: 'mandatory.error' })
                  : undefined
              }
              wrapperCustomStyle={{ paddingLeft: '15px' }}
              inputCustomStyle={{
                height: '28px',
                padding: '0 8px',
              }}
            />
          </Block>
        </div>
      </Block>
      <Block
        marginBottom={4}
        customStyle={{
          width: '100%',
          display: 'inline-flex',
        }}
      >
        <label className="w-1/4 text-sm" htmlFor="custNo">
          {labels.labelEngbersCard ??
            formatMessage({
              id: 'custNo',
              defaultMessage: 'Customer No.',
            })}
        </label>
        <InputText
          style={'white'}
          id="custNo"
          name="CustNo"
          type="number"
          onChange={handleChange}
          value={`${formData.CustNo}`}
          wrapperCustomStyle={{ width: '75%' }}
          inputCustomStyle={{
            height: '28px',
            padding: '0 8px',
          }}
        />
      </Block>
      <Block
        marginBottom={4}
        customStyle={{
          width: '100%',
          display: 'inline-flex',
        }}
      >
        <label className="w-1/4 text-sm" htmlFor="email">
          {labels.labelEmail
            ? `${labels.labelEmail} *`
            : formatMessage({
              id: 'email',
              defaultMessage: 'enterMail',
            })}
        </label>
        <InputText
          style={'white'}
          id="email"
          name="Email"
          type="email"
          autoComplete="email"
          required
          errorMessage={!emailErrors ? undefined : emailErrors === 'empty' ? mailErrorMessage : mailMessage}
          onBlur={validateEmail}
          onChange={handleChange}
          value={formData.Email}
          wrapperCustomStyle={{ width: '75%' }}
          inputCustomStyle={{
            height: '28px',
            padding: '0 8px',
          }}
        />
      </Block>
      <Block
        marginBottom={4}
        customStyle={{
          width: '100%',
          display: 'inline-flex',
        }}
        className={styles.phoneWrap}
      >
        <label className="w-1/4 text-sm" htmlFor="phone">
          {labels.labelTelephone ??
            formatMessage({
              id: 'phone',
              defaultMessage: 'enterPhoneNumber',
            })}
        </label>
        <InputText
          style={'white'}
          id="phone"
          name="Phone"
          type="number"
          autoComplete="phone"
          onChange={handleChange}
          value={formData.Phone}
          wrapperCustomStyle={{ width: '25%' }}
          inputCustomStyle={{
            height: '28px',
            padding: '0 8px',
          }}
        />
      </Block>
      <Block
        marginBottom={4}
        customStyle={{
          width: '100%',
          display: 'inline-flex',
        }}
      >
        <label className="w-1/4 text-sm" htmlFor="about">
          {labels.labelAbout
            ? `${labels.labelAbout} *`
            : formatMessage({
              id: 'fieldIsRequired',
              defaultMessage: 'enterSubject',
            })}
        </label>
        <InputText
          style={'white'}
          id="about"
          name="About"
          type="text"
          errorMessage={!errors.about && initialSend && formatErrorMessage({ id: 'fillInput' })}
          autoComplete="about"
          required
          onBlur={() =>
            setErrors((prevErrors) => ({
              ...prevErrors,
              about: formData.About !== '',
            }))
          }
          onChange={handleChange}
          value={formData.About}
          wrapperCustomStyle={{ width: '75%' }}
          inputCustomStyle={{
            height: '28px',
            padding: '0 8px',
          }}
        />
      </Block>
      <Block
        marginBottom={4}
        customStyle={{
          width: '100%',
          display: 'inline-flex',
        }}
      >
        <label className="w-1/4 text-sm" htmlFor="message">
          {labels.labelMessage
            ? `${labels.labelMessage} *`
            : formatMessage({
              id: 'message',
              defaultMessage: 'Your enquiry',
            })}
        </label>
        <span className="w-3/4">
          <Textarea
            style={'white'}
            id="message"
            name="Message"
            errorMessage={
              !errors.message &&
              initialSend &&
              formatMessage({ id: 'message' }) + formatErrorMessage({ id: 'mandatory.error' })
            }
            onBlur={() =>
              setErrors((prevErrors) => ({
                ...prevErrors,
                message: formData.Message !== '',
              }))
            }
            onChange={handleChange}
            rows={2}
            required
            value={formData.Message}
            textareaCustomStyle={{ padding: '8px' }}
          />
        </span>
      </Block>
      <Block
        marginBottom={4}
        customStyle={{
          width: '100%',
          display: 'inline-flex',
        }}
      >
        <label className="w-1/4 text-sm">
          {labels.labelRecaptcha
            ? `${labels.labelRecaptcha} *`
            : formatMessage({
              id: 'fieldIsRequired',
              defaultMessage: 'acceptTos',
            })}
        </label>
        <span className="w-3/4">
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={reCaptchaKey}
            onChange={(v) =>
              setErrors((prevErrors) => ({
                ...prevErrors,
                acceptReCaptcha: v !== '',
              }))
            }
          />
          <span className={styles.errorMsg}>
            {!errors.acceptReCaptcha &&
              initialSend &&
              formatErrorMessage({
                id: 'fillCaptcha',
                defaultMessage: 'fillCaptcha',
              })}
          </span>
        </span>
      </Block>
      <Block
        marginBottom={4}
        customStyle={{
          width: '100%',
          display: 'inline-flex',
        }}
      >
        <span className="w-1/4"></span>
        <span className="w-3/4">
          <InputCheckbox
            id="accept_tos"
            name="AcceptTos"
            label={
              <span className={styles.acceptTosWrap}>
                <span className={errors.acceptTos === false ? styles.errorMsgColor : ''}>
                  <Markdown linkToNewTab text={labels.textTos} className={styles.markdownGuidelines} />
                </span>
                <span className="flex w-min items-center">
                  {errors.acceptTos === false ? (
                    <IconCustom width={18} icon="Close" className={styles.errorMsgColor} />
                  ) : (
                    ''
                  )}
                </span>
              </span>
            }
            onChange={handleCheckboxChange}
            errorMessage={!errors.acceptTos && initialSend && formatErrorMessage({ id: 'acceptedTerms' })}
            hasHoverEffect
          />
        </span>
      </Block>
      <Block
        marginBottom={4}
        customStyle={{
          width: '100%',
          display: 'inline-flex',
        }}
      >
        <label className="w-1/4 text-sm">{formatMessage({ id: 'mandatoryFields' })}</label>
        <Button
          size="large"
          label={labels.labelDesktopBtnSubmit}
          isLoading={loading}
          customStyle={{
            padding: '14px 30px',
            width: 'fit-content',
            fontSize: '17.5px',
          }}
          onClick={handleSubmit}
        />
      </Block>
    </form>
  );
};
