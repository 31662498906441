import React, { useCallback, useState } from 'react';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { useAccount } from '@frontastic-engbers/lib';
import { useToastNotificationsActions } from '@frontastic-engbers/lib/state/notification/actions';
import { EmailValidatorHelper } from '@frontastic-engbers/helpers/emailValidatorHelper';
import { IFTLinkReference, IFTPageFolderReference } from '@frontastic-engbers/types/engbers-custom';
import { getReferenceTarget } from '@frontastic-engbers/helpers/reference';
import { Block, Button, InputText } from '@engbers/components';
import styles from './my-data.module.scss';
import { useRouter } from 'next/router';

type ChangeEmailProps = {
  currentEmail: string;
  myDataChangeEmailTitle: string;
  myDataEmailTooltip: string;
  doiNotificationLink?: IFTLinkReference | IFTPageFolderReference;
};

type ChangeEmailFormState = {
  email: string;
  loginPassword: string;
};

const validate = (data: ChangeEmailFormState) => {
  return {
    email: data.email !== '',
    loginPassword: data.loginPassword !== '',
  };
};

export const MyDataChangeEmail: React.FC<ChangeEmailProps> = ({
  currentEmail,
  myDataChangeEmailTitle,
  myDataEmailTooltip,
  doiNotificationLink,
}) => {
  const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { formatMessage } = useFormat({ name: 'common' });

  const { changeEmail, logout } = useAccount();
  const router = useRouter();

  const { pushNotification } = useToastNotificationsActions();
  const [errors, setErrors] = useState({
    email: true,
    loginPassword: true,
  });
  const [emailErrors, setEmailErrors] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<ChangeEmailFormState>({
    email: currentEmail,
    loginPassword: '',
  });

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setData((prev) => ({ ...prev, [event.target.name]: event.target.value }));
    },
    [setData],
  );

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const err = validate(data);
      await validateEmail();

      setErrors(err);
      if (Object.values(err).some((error) => !error)) {
        return;
      }

      setLoading(true);
      try {
        const changeEmailResponse = await changeEmail(data.email, data.loginPassword);
        const redirectLink = doiNotificationLink ? getReferenceTarget(doiNotificationLink) : '/login';

        if (changeEmailResponse.status === 'SUCCESS') {
          pushNotification(
            formatAccountMessage({
              id: 'account.changeSuccess',
              defaultMessage: 'Your data has been successfully updated',
            }),
            'success',
          );

          await logout();
          return router.push(redirectLink);
        } else if (changeEmailResponse.status === 'UNKNOWN_ERROR') {
          pushNotification(
            formatErrorMessage({
              id: 'wentWrong',
              defaultMessage: 'Sorry. Something went wrong..',
            }),
            'error',
          );

          setLoading(false);
          return;
        } else if (changeEmailResponse.status === 'PASSWORD_NOT_MATCH') {
          pushNotification(
            formatAccountMessage({
              id: 'account.passwordWrong',
              defaultMessage: 'Sorry, your password does not match',
            }),
            'error',
          );
        } else if (changeEmailResponse.status === 'EMAIL_USED') {
          pushNotification(
            formatAccountMessage({
              id: 'account.haveAlready',
              defaultMessage: 'Email is already used',
            }),
            'error',
          );
        }

        setLoading(false);
        return;
      } catch (e) {
        pushNotification(
          formatErrorMessage({
            id: 'wentWrong',
            defaultMessage: 'Sorry. Something went wrong..',
          }),
          'error',
        );

        setLoading(false);
        return;
      }
    },
    [setLoading, setErrors, data],
  );

  const validateEmail = async () => {
    const ruleDefinitions: string | null = await EmailValidatorHelper.ruleDefinitions(data.email);

    if (!ruleDefinitions) {
      setEmailErrors(null);
      setErrors({ ...errors, email: true });
      return;
    }

    setEmailErrors(ruleDefinitions);
    setErrors({ ...errors, email: false });
  };

  return (
    <form className={styles.form}>
      <Block marginBottom={4}>
        <h3>{myDataChangeEmailTitle}</h3>
      </Block>
      <Block>
        <Block marginBottom={4}>
          <InputText
            id="email"
            name="email"
            type="text"
            placeholder={formatMessage({ id: 'emailAddress' })}
            autoComplete=""
            required
            value={data.email}
            infoText={myDataEmailTooltip}
            infoTextHeadline={myDataChangeEmailTitle}
            useInfoModal={true}
            onChange={handleChange}
            onBlur={validateEmail}
            errorMessage={
              !emailErrors
                ? undefined
                : emailErrors === 'empty'
                ? formatAccountMessage({ id: 'email.enter.again' })
                : formatErrorMessage({ id: 'emailUsernameNotValid' })
            }
          />
        </Block>
        <Block marginBottom={4}>
          <InputText
            id="loginPassword"
            name="loginPassword"
            type="password"
            placeholder={formatAccountMessage({ id: 'password' })}
            required
            value={data.loginPassword}
            onChange={handleChange}
            onBlur={() => setErrors({ ...errors, loginPassword: data.loginPassword !== '' })}
            errorMessage={!errors.loginPassword ? formatAccountMessage({ id: 'password.enter' }) : undefined}
          />
        </Block>
        <Block customStyle={{ marginLeft: 'auto' }} maxWidth="fit-content">
          <Button
            buttonType="submit"
            size="large"
            label={formatMessage({ id: 'save.bold' })}
            isLoading={loading}
            customStyle={{ paddingLeft: '30px', paddingRight: '30px' }}
            onClick={handleSubmit}
          />
        </Block>
      </Block>
    </form>
  );
};
