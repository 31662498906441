import React from 'react';
import { createQuerySuggestionsPlugin } from '@algolia/autocomplete-plugin-query-suggestions';
import { SearchClient } from 'algoliasearch/dist/algoliasearch-lite';
import { SuggestedSearch } from '../suggested-search';

export const querySuggestionsPlugin = (
  searchClient: SearchClient,
  algoliaConfig: any,
  suggestIndexNamePrefix: string,
  onClick: () => void,
) => {
  return createQuerySuggestionsPlugin({
    searchClient,
    indexName: `${algoliaConfig.dataSource.indexName}${suggestIndexNamePrefix}`,
    getSearchParams() {
      return {
        hitsPerPage: 6,
      };
    },
    transformSource({ source }) {
      return {
        ...source,
        getItemUrl({ item }) {
          return `/search/${item.query}`;
        },
        onSelect({ setQuery, item, setIsOpen }) {
          setQuery(item.query);
          setIsOpen(false);
          onClick();
        },
        templates: {
          item({ item }) {
            return <SuggestedSearch value={item.query} />;
          },
        },
      };
    },
  });
};
