import React, { useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';
import { useSWRConfig } from 'swr';
import classnames from 'classnames';
import { useAccount } from '@frontastic-engbers/lib';
import { IFTLinkReference, IFTPageFolderReference } from '@frontastic-engbers/types/engbers-custom';
import { isPwaUser } from '@frontastic-engbers/helpers/utils/accountAuth';
import { AccountNavPoint } from './navigation-point';
import styles from './my-account.module.scss';
import { Markdown } from '@engbers/components';

interface MyAccountType {
  title: string;
  textSize?: number;
  navPoints: NavPoint[];
  isEmilio?: boolean;
}

interface NavPoint {
  navPointLabel: string;
  navPointLink: IFTPageFolderReference | IFTLinkReference;
  isLogout: boolean;
  subnavPoints: SubnavPoint[];
  pwaUserOnly: boolean;
}

interface SubnavPoint {
  subnavPointLabel: string;
  subnavPointLink: IFTPageFolderReference | IFTLinkReference;
}

export const MyAccount: React.FC<MyAccountType> = (data) => {
  const { mutate } = useSWRConfig();
  const { logout, loggedIn, account } = useAccount();
  const router = useRouter();

  const handleLogout = useCallback(async () => {
    await mutate(() => true, undefined, { revalidate: true });
    await logout();

    return router.push('/login');
  }, [logout, mutate, router]);

  const isNavPointActive = (link: IFTPageFolderReference | IFTLinkReference) =>
    link && 'pageFolder' in link && link.pageFolder._url === router.asPath;

  const mappedNavPoints = useMemo(
    () =>
      data.navPoints
        .filter((point) => !((point.isLogout && !loggedIn) || (point.pwaUserOnly && !isPwaUser(account))))
        .map((navPoint, index) => (
          <AccountNavPoint
            key={`nav-point-key-${index}`}
            navPointLabel={navPoint.navPointLabel}
            navPointLink={navPoint.navPointLink}
            isLogout={navPoint.isLogout}
            subnavPoints={navPoint.subnavPoints}
            isNavPointActive={isNavPointActive}
            handleLogout={handleLogout}
          />
        )),
    [data.navPoints, loggedIn, account],
  );

  return (
    <div className={styles.myAccount}>
      <Markdown className={styles.heading} text={data.title} textSize={data.textSize} />
      <div
        className={classnames(styles.accountNavPoints, {
          [styles.emilioAccountNavPoints]: data.isEmilio,
        })}
      >
        {mappedNavPoints}
      </div>
    </div>
  );
};
