import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';
import { useRouter } from 'next/router';
import { useAccount } from '@frontastic-engbers/lib';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { Cart } from '@frontastic-engbers/types/cart/Cart';
import { VoucherInfo } from '@frontastic-engbers/types/engbers-custom';
import classNames from 'classnames';
import { InputText } from '@engbers/components';
import styles from '../flyout-cart.module.scss';

interface ICartButtons {
  toCheckoutBtnLabel: string;
  voucherBtnLabel: string;
  toBasketBtnLabel: string;
  voucherPlaceholder: string;
  redeemVoucherBtnLabel: string;
  backBtnLabel: string;
  updateGlobalState: (isFlyoutCartOpen: boolean) => void;
  redeemDiscountCode: (code: string, pin: string) => Promise<Cart | string>;
  isEmptyCart: boolean;
  voucherRedeemedLabel: string;
  voucherInvalidLabel: string;
  setLoading: Dispatch<SetStateAction<boolean>>;
  pushDatalayer;
}

export const FlyoutCartButtons: React.FC<ICartButtons> = ({
  toCheckoutBtnLabel,
  voucherBtnLabel,
  toBasketBtnLabel,
  voucherPlaceholder,
  redeemVoucherBtnLabel,
  backBtnLabel,
  updateGlobalState,
  redeemDiscountCode,
  isEmptyCart,
  voucherRedeemedLabel,
  voucherInvalidLabel,
  setLoading,
  pushDatalayer,
}) => {
  const { loggedIn } = useAccount();
  const [isVoucherSectionOpen, setIsVoucherSectionOpen] = useState<boolean>(false);
  const [voucherValue, setVoucherValue] = useState<VoucherInfo>({
    voucherCode: '',
    voucherPin: '',
  });
  const [hasVoucherError, setHasVoucherError] = useState<boolean>(false);
  const [error, setError] = useState<string>(undefined);
  const [showHint, setShowHint] = useState<boolean>(false);
  const router = useRouter();
  const { formatMessage } = useFormat({ name: 'cart' });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setVoucherValue({
      ...voucherValue,
      [e.target.name]: e.target.value.trim(),
    });
  };

  const onApplyDiscount = () => {
    setLoading(true);

    redeemDiscountCode(voucherValue.voucherCode, voucherValue.voucherPin)
      .then((response) => {
        const hasError = typeof response === 'string';

        setHasVoucherError(hasError);

        if (hasError) {
          setError(response);
        }
      })
      .catch(() => {
        setHasVoucherError(true);
      })
      .finally(() => {
        setLoading(false);
        setVoucherValue({
          voucherCode: '',
          voucherPin: '',
        });
        setShowHint(true);
      });
  };

  const closeCallback = () => {
    if (isVoucherSectionOpen) {
      setIsVoucherSectionOpen(hasVoucherError);
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowHint(false);
    }, 5000);

    return () => clearTimeout(timeout);
  }, [showHint]);

  const goToCheckout = () => {
    pushDatalayer('begin_checkout');
    router.push(loggedIn ? '/checkout' : '/checkout/login');
    updateGlobalState(false);
  };

  return (
    <>
      <div
        className={classNames(
          styles.flyoutCartVoucherSection,
          isVoucherSectionOpen ? styles.voucherSectionShown : undefined,
        )}
      >
        <InputText
          id="voucherCode"
          name="voucherCode"
          type="text"
          placeholder={voucherPlaceholder}
          value={voucherValue.voucherCode}
          onChange={handleInputChange}
          onEnter={onApplyDiscount}
        />
        <div className={styles.voucherApplyWrapper}>
          <div className={classNames(styles.pinWrapper, { [styles.active]: voucherValue.voucherCode.length >= 11 })}>
            <InputText
              id="voucherPin"
              name="voucherPin"
              type="text"
              placeholder={formatMessage({
                id: 'onlinePin',
                defaultMessage: 'Online-Pin',
              })}
              value={voucherValue.voucherPin}
              onChange={handleInputChange}
              onEnter={onApplyDiscount}
            />
          </div>
          <button
            className={`cta ${styles.applyButton}`}
            onClick={onApplyDiscount}
            disabled={voucherValue.voucherCode === ''}
          >
            {redeemVoucherBtnLabel}
          </button>
          <div className={styles.voucherHint}>
            <Transition
              show={showHint}
              enter="transition-transform duration-4"
              enterFrom="translate-y-full"
              enterTo="translate-y-0"
              leave="transition-transform duration-4"
              leaveFrom="translate-y-0"
              leaveTo="translate-y-full"
              afterLeave={closeCallback}
            >
              <div className={classNames(styles.hint, { [styles.hasError]: hasVoucherError })}>
                {hasVoucherError ? error ?? voucherInvalidLabel : voucherRedeemedLabel}
              </div>
            </Transition>
          </div>
        </div>
        <button
          className={classNames('primary', styles.primaryBtn)}
          onClick={() => {
            setIsVoucherSectionOpen(false);
            setShowHint(false);
          }}
        >
          {backBtnLabel}
        </button>
      </div>

      <div
        className={classNames(styles.flyoutCartButtons, isVoucherSectionOpen ? styles.buttonsSectionHidden : undefined)}
      >
        {!isEmptyCart && (
          <button className="cta" onClick={goToCheckout}>
            {toCheckoutBtnLabel}
          </button>
        )}
        <button
          className={classNames('primary', styles.primaryBtn)}
          onClick={() => {
            setHasVoucherError(false);
            setIsVoucherSectionOpen(true);
          }}
        >
          {voucherBtnLabel}
        </button>
        <button
          className={classNames('primary', styles.primaryBtn)}
          onClick={() => {
            router.push('/cart');
            updateGlobalState(false);
          }}
        >
          {toBasketBtnLabel}
        </button>
      </div>
    </>
  );
};
