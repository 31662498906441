import React, { useState } from 'react';
import Link from 'next/link';
import classnames from 'classnames';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { getSKUParts } from '@frontastic-engbers/helpers/utils/getSKUParts';
import { sortSizes } from '@frontastic-engbers/helpers/utils/sizeSorting';
import { useProductOutfitTitle } from '@frontastic-engbers/helpers/seoHelper';
import { Product } from '@frontastic-engbers/types/product/Product';
import { Variant } from '@frontastic-engbers/types/product/Variant';
import { Image, InputCheckbox } from '@engbers/components';
import Price from '../../price';
import { MediaType } from '@frontastic-engbers/types/engbers-custom';
import styles from './outfit-product.module.scss';

export interface IOutfitProduct {
  product: Product;
  goToProductLabel: string;
  sizesLabel: string;
  sizesUnselectedMessage: string;
  itemsToCart: { id?: string; name?: string; variant?: Variant; validPurchase: boolean }[];
  setItemsToCart: React.Dispatch<
    React.SetStateAction<
      {
        id?: string;
        name?: string;
        variant?: Variant;
        validPurchase: boolean;
      }[]
    >
  >;
  fallbackImage?: MediaType;
}

export const OutfitProduct: React.FC<IOutfitProduct> = ({
  product,
  goToProductLabel,
  sizesLabel,
  sizesUnselectedMessage,
  itemsToCart,
  setItemsToCart,
  fallbackImage,
}) => {
  const [selectedVariant, setSelectedVariant] = useState<Variant>(null);
  const [isInputChecked, setIsInputChecked] = useState<boolean>(false);
  const { formatMessage } = useFormat({ name: 'product' });
  const { title } = useProductOutfitTitle({
    product,
    attributes: product.attributes,
  });

  const imageSrc = product.variants?.[0]?.images?.[0] || fallbackImage?.media?.file;
  const priceVariant = product.variants?.find((variant) => variant.price?.centAmount !== undefined);

  const prepareItemToCart = (variant: Variant) => {
    const variants = itemsToCart.filter((item) => item.id !== getSKUParts(variant?.sku)?.key);

    variants.push({
      id: product.productId,
      name: product.name,
      variant,
      validPurchase: true,
    });

    setItemsToCart(variants);
    setSelectedVariant(variant);
    setIsInputChecked(true);
  };

  const handleInputCheck = (e) => {
    setIsInputChecked(e.target.checked);

    if (!selectedVariant) {
      const variants = itemsToCart.filter((item) => item.id !== product.productId);

      if (e.target.checked) {
        variants.push({
          id: product.productId,
          validPurchase: false,
        });
      }

      return setItemsToCart(variants);
    }

    if (!e.target.checked && selectedVariant) {
      const variants = itemsToCart.filter((item) => item.id !== product.productId);

      setItemsToCart(variants);
    } else {
      setItemsToCart((state) => [
        ...state,
        {
          id: product.productId,
          name: product.name,
          variant: selectedVariant,
          validPurchase: true,
        },
      ]);
    }
  };

  const hasAvailableSizes = (): boolean => {
    return product.variants?.some((variant) => !!variant.isInStock) ?? false;
  };

  return (
    <div className={styles.outfitProductWrap}>
      <Link href={product.url}>
        <a>
          <Image src={imageSrc} width={200} alt={title} title={title} />
        </a>
      </Link>
      <div className="w-full grow">
        <div className="mb-1 flex justify-between gap-2">
          <h3 className={styles.oPName}>
            <InputCheckbox
              label={<span className="sr-only">{product.name}</span>}
              id={product.productId}
              name={product.productId}
              onChange={handleInputCheck}
              checked={isInputChecked}
              disabled={!hasAvailableSizes()}
              hasHoverEffect={true}
              style="white"
            />
            {product.name}
          </h3>
          {priceVariant && (
            <Price
              price={priceVariant.price}
              discountedPrice={priceVariant.discountedPrice}
              className={styles.oPPrice}
              isBold
              showCurrencySymbol
            />
          )}
        </div>
        <div className="flex justify-between gap-2">
          <span className={styles.oPTextArtNr}>
            {formatMessage({
              id: 'articleNumber',
              defaultMessage: 'Artikel-Nr.',
            })}
            : {product.productNumber ?? product.productId}
          </span>
          <Link href={product.url}>
            <a className={styles.oPToProduct}>
              <span>{goToProductLabel}</span>
            </a>
          </Link>
        </div>
        <div className="mt-3">
          <span>
            {sizesLabel}&nbsp;{selectedVariant?.attributes?.Sizing}
          </span>
          <div className={styles.oPSizesWrap}>
            {[...product.variants]
              ?.sort((a, b) => sortSizes({ value: a.attributes.Sizing }, { value: b.attributes.Sizing }))
              ?.map((variant, index) => {
                const size = variant.attributes?.['Sizing'] || getSKUParts(variant.sku).size;

                return (
                  <button
                    key={`outfit-product-size-${index}`}
                    value={size}
                    disabled={!variant.isInStock}
                    className={classnames('btn', styles.oPSizeBtn, {
                      [styles.oPSizeBtnDisabled]: !variant.isInStock,
                      [styles.oPSizeBtnSelected]: selectedVariant?.sku === variant.sku,
                    })}
                    onClick={() => prepareItemToCart(variant)}
                  >
                    {size}
                  </button>
                );
              })}
          </div>
        </div>
        {isInputChecked && !selectedVariant && <div className={styles.opSizeError}>{sizesUnselectedMessage}</div>}
      </div>
    </div>
  );
};
