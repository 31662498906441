import styles from './search-again.module.scss';
import { IconCustom } from '@engbers/components';
import React, { useState } from 'react';
import { useRouter } from 'next/router';

interface SearchAgainProps {
  sectionText: string;
  searchPlaceholderText: string;
}

export const SearchAgain: React.FC<SearchAgainProps> = ({ sectionText, searchPlaceholderText }) => {
  const router = useRouter();
  const [formData, setFormData] = useState<{ search: string }>({
    search: '',
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    router.push(`search/${formData.search}`);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.wrapperInner}>
          <h3 className={styles.sectionText}>{sectionText}</h3>
          <form className={styles.searchForm} onSubmit={handleSubmit}>
            <div className={styles.inputWrapper}>
              <input name="search" type="text" placeholder={searchPlaceholderText} onChange={handleInputChange} />
            </div>
            <button className={styles.searchButton} type="submit">
              <IconCustom width={20} icon="Search" color="white" />
            </button>
          </form>
        </div>
      </div>
    </>
  );
};
