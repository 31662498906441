import React from 'react';
import { findAndReplace } from 'hast-util-find-and-replace';
import ReactDOMServer from 'react-dom/server';

const find = /[\t ]*(?:\r?\n|\r)/g;

export default (): any => {
  const getBreak = () =>
    ReactDOMServer.renderToStaticMarkup(
      React.createElement('span', {
        className: 'block',
      }),
    );

  return (tree) => {
    findAndReplace(tree, find, getBreak);
  };
};
