import { useModalActions } from '@frontastic-engbers/lib/state/modal/actions';
import { Button, IconCustom } from '@engbers/components';

const StyleGuideModal = () => {
  const { pushModal } = useModalActions();

  const createModal = () => {
    pushModal({
      title: 'Modal Title',
      id: 'modal_id',
      content: 'Content of the Modal',
      canCloseByBackdropClick: true,
    });
  };

  return (
    <div className="dark:bg-encom-900 mb-10 border border-solid border-slate-300 dark:text-white">
      <h2 className="mx-6 my-4 dark:text-white">Modal</h2>

      <div className="mx-3 p-4">
        <Button label="Open Modal" type="cta" hasIcon={false} onClick={createModal} />
      </div>

      <div className="mx-3 p-4">
        <button className="flex items-center gap-2" onClick={createModal}>
          Info Modal
          <IconCustom icon="Info" width={20} />
        </button>
      </div>

      <div>
        <h4 className="m-6 dark:text-white">Usage:</h4>
        <ol className="mx-10 mb-6 list-inside list-decimal">
          <li className="pb-2">
            Import the useModalActions
            <code className="ml-3 block bg-gray-100 p-1">
              {"import { useModalActions } from '@frontastic-engbers/lib/state/modal/actions';"}
            </code>
          </li>
          <li className="pb-2">
            Add OnClick event:
            <code className="ml-3 block bg-gray-100 p-1">
              {
                "pushModal({ title: 'Modal Title', id: 'modal_id', content: 'Content of the Modal', canCloseByBackdropClick: true, })"
              }
            </code>
          </li>
          <li className="pb-2">
            If you want the modal's background to be scrollable then pass "disableBgScroll: false," to the pushModal
            function, otherwise no need to pass anything.
          </li>
          <li className="pb-2">Check component for more details.</li>
        </ol>
      </div>
    </div>
  );
};

export default StyleGuideModal;
