import React, { useEffect, useState } from 'react';
import { getProjectInfo } from '@frontastic-engbers/lib';
import { useTrustedShopsConfig } from '@frontastic-engbers/lib/actions/config';
import { TrustedShopsScript } from '../script';
import styles from './stars.module.scss';

type Props = {
  sku: string;
};

export const TrustedShopsStars: React.FC<Props> = ({ sku }) => {
  const projectInfo = getProjectInfo();
  const trustedShopsConfig = useTrustedShopsConfig();
  const [scriptLoaded, setScriptLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (trustedShopsConfig?.trustedShopsId && scriptLoaded) {
      window.productStickerSummary().showSummary({
        tsId: trustedShopsConfig?.trustedShopsId,
        apiServer: 'https://cdn1.api.trustedshops.com/',
        element: '#review_stars',
        starColor: '#FFDC0F',
        starSize: '14px',
        fontSize: '12px',
        showRating: false,
        scrollToReviews: false,
        enablePlaceholder: false,
        sku,
      });
    }

    return () => {
      setScriptLoaded(false);
    };
  }, [trustedShopsConfig?.trustedShopsId, scriptLoaded]);

  if (projectInfo?.projectId !== 'en' || !trustedShopsConfig?.trustedShopsId || !sku) {
    return null;
  }

  return (
    <>
      <TrustedShopsScript src="reviews/tsSticker/tsProductStickerSummary.js" onLoad={() => setScriptLoaded(true)} />
      <div id="review_stars" className={styles.reviewStars} />
    </>
  );
};
