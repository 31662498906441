import React, { useMemo } from 'react';
import classnames from 'classnames';
import { Product } from '@frontastic-engbers/types/product/Product';
import { Outfit } from '@frontastic-engbers/types/product/Outfit';
import { Slider, SliderProps } from '@engbers/components/slider';
import type { Swiper } from 'swiper/types';
import useMediaQuery from '@frontastic-engbers/helpers/hooks/useMediaQuery';
import { Reference, ReferenceLink } from '@frontastic-engbers/helpers/reference';
import { desktop, mobile } from '@frontastic-engbers/helpers/utils/screensizes';
import styles from './product-slider.module.scss';
import { ProductBox } from '../product-list/components/product-box';
import { OutfitProductBox } from '../product-list/components/outfit-product-box';
import { ProductUtils } from '@frontastic-engbers/helpers/utils/productUtils';
import { Markdown } from '@engbers/components';

export interface Props {
  products?: Product[];
  title?: string;
  textSize?: number;
  ctaLabel?: string;
  ctaLink?: Reference;
  isStartPage?: boolean;
  hasArrows?: boolean;
  hasDots?: boolean;
  useHoverBox?: boolean;
  customTitleStyle?: object;
  isOutfitSlider?: boolean;
  isTopSellerSlider?: boolean;
  productListPage?: number;
  slidesPerView?: {
    mobile: number;
    desktop: number;
  };
  onSelect?: () => void;
}

export default function ProductSlider({
  products,
  title,
  textSize,
  ctaLabel,
  ctaLink,
  isStartPage = false,
  hasArrows = true,
  hasDots = false,
  useHoverBox = false,
  customTitleStyle,
  isOutfitSlider = false,
  isTopSellerSlider,
  productListPage,
  slidesPerView = {
    mobile: 2.3,
    desktop: 4,
  },
  onSelect,
}: Props) {
  const [isMobileSize] = useMediaQuery(mobile);
  const [isDesktopSize] = useMediaQuery(desktop);

  const sliderProducts: Array<Product | Outfit> = useMemo(
    () => products?.filter((product) => !isOutfitSlider || ProductUtils.isOutfit(product)),
    [products],
  );

  if (!products) {
    return null;
  }

  const updateSliderClass = (swiper: Swiper) => {
    if (!isDesktopSize && !swiper.isBeginning && !swiper.isEnd) {
      swiper.$wrapperEl.addClass(styles.swiperWrapperMobile);
    } else {
      swiper.$wrapperEl.removeClass(styles.swiperWrapperMobile);
    }
  };

  const sliderConfiguration: SliderProps = {
    arrows: hasArrows,
    dots: hasDots && !isMobileSize,
    onSliderMove: updateSliderClass,
    onNavigationNext: updateSliderClass,
    onNavigationPrev: updateSliderClass,
    breakpoints: {
      0: {
        slidesPerView: slidesPerView.mobile,
      },
      [desktop]: {
        slidesPerView: slidesPerView.desktop,
      },
    },
  };

  const Title = isStartPage ? 'h2' : 'h3';

  if (!sliderProducts?.length) {
    return null;
  }

  return (
    <div className={styles.productSlider}>
      {title && (
        <div className="text-center">
          <Title className="uppercase" style={customTitleStyle}>
            <Markdown text={title} textSize={textSize} disableMargin />
          </Title>
        </div>
      )}
      {ctaLabel && ctaLink && (
        <div className="flex items-center justify-end px-4 sm:px-6 lg:px-0">
          <ReferenceLink target={ctaLink} className="text-accent-400 hidden text-sm font-semibold sm:block">
            {ctaLabel}
            <span aria-hidden="true"> &rarr;</span>
          </ReferenceLink>
        </div>
      )}
      <div
        className={classnames('relative', {
          'mt-8': !isOutfitSlider,
          'mt-5': isOutfitSlider,
        })}
      >
        <div
          className="relative -mb-6 w-full overflow-x-auto pb-6"
          style={{ '--swiper-navigation-size': `${isTopSellerSlider ? 40 : 100}px` } as React.CSSProperties}
        >
          <Slider spaceBetween={!isTopSellerSlider && isDesktopSize ? 20 : 10} {...sliderConfiguration}>
            {sliderProducts
              .slice(0, 15)
              .map((product, index: number) =>
                ProductUtils.isOutfit(product) ? (
                  <OutfitProductBox
                    outfit={product as Outfit}
                    key={index}
                    pageNumber={productListPage}
                    isOutfitSlider
                  />
                ) : (
                  <ProductBox
                    product={product as Product}
                    supportHover={false}
                    className="!m-0 !max-w-full !border-0 !p-0"
                    key={index}
                    isTopSellerSlider={isTopSellerSlider}
                    onSelect={onSelect}
                  />
                ),
              )}
          </Slider>
        </div>
      </div>

      {ctaLabel && ctaLink && (
        <div className="mt-12 flex px-4 sm:hidden">
          <ReferenceLink target={ctaLink} className="text-accent-400 hover:text-accent-500 text-sm font-semibold">
            {ctaLabel}
            <span aria-hidden="true"> &rarr;</span>
          </ReferenceLink>
        </div>
      )}
    </div>
  );
}
