import { PageDataResponse, PageFolder } from '@frontastic-engbers/lib/lib/types';
import { EcondaDataLayer, EcondaProductDataLayer } from '@frontastic-engbers/types/tagmanager/DataLayer';
import { Product } from '@frontastic-engbers/types/product/Product';
import { Variant } from '@frontastic-engbers/types/product/Variant';
import { Account } from '@frontastic-engbers/types/account/Account';
import { Order } from '@frontastic-engbers/types/cart/Order';
import { DataLayerFactory } from '@frontastic-engbers/lib/lib/tracking/datalayerFactory';
// @ts-ignore
import { languageMapper } from '@frontastic-engbers/lib/project.config';
import { CurrencyHelpers } from '@frontastic-engbers/helpers/currencyHelpers';
import { CategorieMapper } from '@engbers/shop-backend/online-shop/utils/CategorieMapper';
import md5 from 'crypto-js/md5';

export const getEcondaData = (data: PageDataResponse, account?: Account, locale = 'de') => {
  const hasProductListTastic = !!data.page.sections.main.layoutElements.filter(
    (layout) => !!layout.tastics.filter((tastic) => tastic.tasticType.includes('product-list-algolia')).length,
  ).length;
  const product = // @ts-ignore
    data.pageFolder.dataSourceConfigurations.find((data) => data.type === 'frontastic/product')?.preloadedValue
      ?.product;

  return {
    ecSiteId: window.location.host,
    ecContent: getEcondaPageTypeContent(data.pageFolder, hasProductListTastic, locale),
    ecPageId: md5(window.location.pathname).toString(),
    ecVisitorID: getVisitorId(account),
    ecCustomProperties: getCustomProperties(account),
    ecLangId: locale,
    ecCategory: getCategories(data.pageFolder, locale, product),
    ecOrderProcess: getEcOrderProcess(data.pageFolder.pageFolderType),
    ecProducts:
      data.pageFolder.pageFolderType === 'frontastic/product-detail-page' ||
      data.pageFolder.pageFolderType === 'frontastic/outfit-detail-page'
        ? getEcProducts(
            // @ts-ignore
            data.pageFolder.dataSourceConfigurations.find((data) => data.type === 'frontastic/product')?.preloadedValue
              ?.product,
            undefined,
            'view',
          )
        : undefined,
  };
};

export const getVisitorId = (account?: Account) => {
  let userId = 'NULL';

  if (account?.cardId) {
    userId = account.cardId;
  }

  return md5(userId).toString();
};

const getCustomProperties = (account?: Account) => {
  return account
    ? {
        hashedvalue: [[md5(account.email).toString()]],
      }
    : undefined;
};

const getEcOrderProcess = (pageFolderType: string) => {
  switch (pageFolderType) {
    case 'frontastic/cart':
      return '1_Warenkorb';
    case 'frontastic/checkout/login':
      return '2_Kundendaten';
    default:
      return undefined;
  }
};

export const getEcondaPageTypeContent = (pageFolder: PageFolder, hasProductListTastic = false, locale = 'de') => {
  if (pageFolder.pageFolderType === 'landingpage') {
    if (pageFolder.configuration.path === '/') {
      return 'Startseite';
    }

    if (window.location.pathname.includes('/mein-konto')) {
      switch (pageFolder.configuration.path) {
        case 'meine-adressen':
          return 'Login/Kundendaten';
        case 'meine-gutscheine':
          return 'Login/Gutscheine';
        case 'bestellhistorie':
          return 'Login/Bestellungen';
        case 'mein-newsletter':
          return 'Login/Newsletter';
        case 'mein-merkzettel':
          return 'Login/Merkzettel';
        case 'engberscard':
          return 'Login/Engbers-Card';
        default:
          return 'Login/Uebersicht';
      }
    }

    if (hasProductListTastic) {
      return `Kategorie/${getCategories(pageFolder, locale)}`;
    }
    return `Content/${getCategories(pageFolder, locale)}`;
  }

  switch (pageFolder.pageFolderType) {
    case 'frontastic/cart':
      return 'Shop/Kaufprozess/Warenkorb';
    case 'frontastic/checkout/login':
      return 'Shop/Kaufprozess/Kundendaten';
    case 'frontastic/product-detail-page':
      return `Details${window.location.pathname}`;
    case 'frontastic/outfit-detail-page':
      return `Details${window.location.pathname}`;
    case 'frontastic/search':
      return 'Suche/Ergebnisse';
    case 'frontastic/search/zero-results':
      return 'Suche/Ergebnisse';
    case 'frontastic/category':
      return 'Kategorie';
    default:
      return pageFolder.pageFolderType;
  }
};

export const getCategories = (pageFolder: PageFolder, locale = 'de', product?: Product) => {
  if (pageFolder.configuration.path === '/') {
    return 'Startseite';
  }

  if (
    product?.attributes?.GoodsGroupWithoutPurchGroup ||
    product?.variants[0]?.attributes?.GoodsGroupWithoutPurchGroup
  ) {
    return getProductGroup(product);
  }

  if (!pageFolder?.breadcrumbs?.length) {
    return undefined;
  }

  const categories = pageFolder.breadcrumbs
    .filter((category) => category.pathTranslations[languageMapper[locale]] !== '/')
    .map((category) => category.pathTranslations[languageMapper[locale]])
    .reverse();

  return `${categories.join('/')}/${pageFolder.name}`;
};

export const getProductGroup = (product: Product): string => {
  const goodsGroup =
    product?.attributes?.GoodsGroupWithoutPurchGroup ?? product?.variants[0]?.attributes?.GoodsGroupWithoutPurchGroup;

  return CategorieMapper.getCategoryPath(goodsGroup).replace(/ > /g, '/') ?? undefined;
};

export const getEcProducts = (
  product: Product,
  selectedVariant: Variant | undefined,
  type: string,
  count?: number | string,
): EcondaProductDataLayer[] | undefined => {
  if (!product) {
    return undefined;
  }

  return [
    {
      type,
      pid: product.productId ?? '',
      sku: selectedVariant?.sku ?? product.variants[0].sku,
      name: product.name ?? '',
      price: CurrencyHelpers.formatForCurrency(
        selectedVariant?.discountedPrice?.centAmount ??
          selectedVariant?.price?.centAmount ??
          product.variants[0]?.discountedPrice?.centAmount ??
          product.variants[0]?.price?.centAmount ??
          0,
        false,
      ),
      brand: selectedVariant?.attributes?.DesignerName ?? product.attributes?.DesignerName,
      variant: selectedVariant?.attributes?.Sizing,
      group: getProductGroup(product),
      count: count ?? 1,
    },
  ];
};

export const getEcondaPurchaseLayer = (order: Order, account: Account, cachedDataLayer: any): EcondaDataLayer => {
  if (cachedDataLayer) {
    cachedDataLayer.ecContent = 'Shop/Kaufprozess/Bestaetigung';
    cachedDataLayer.ecOrderProcess = '5_Bestaetigung';
    cachedDataLayer.ecBillingId = order.orderId;
    cachedDataLayer.ecBillingSum = DataLayerFactory.convertCentAmount(order.sum);
    cachedDataLayer.ecCustomerId = account?.cardId;
    cachedDataLayer.ecCustomerLocation = `${order.shippingAddress?.country ?? 'DE'}/${order.shippingAddress?.city}/${
      order.shippingAddress?.postalCode
    }`;
    cachedDataLayer.ecProducts = order.lineItems?.map((lineItem) => {
      return {
        type: 'buy',
        pid: lineItem.variant?.sku.split(/[-.]/)[0],
        sku: lineItem.variant?.sku,
        name: lineItem.name,
        price: DataLayerFactory.convertCentAmount(lineItem.discountedPrice ?? lineItem.price),
        account: lineItem.count,
      };
    });
  }

  return cachedDataLayer;
};
