import React, { createElement, Fragment, useEffect, useRef, useState } from 'react';
import { render } from 'react-dom';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { RouterContext } from 'next/dist/shared/lib/router-context';
import classnames from 'classnames';
import { autocomplete } from '@algolia/autocomplete-js';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import {
  FrontasticProvider,
  getActiveShops,
  URLHistoryProvider,
  useInstantSearchClient,
} from '@frontastic-engbers/lib';
import {
  EcondaWidgetCredentials,
  IFTLinkReference,
  IFTPageFolderReference,
} from '@frontastic-engbers/types/engbers-custom';
import { IconCustom } from '@engbers/components';
import { EcondaSlider } from '../econda/econda-slider';
import { querySuggestionsPlugin } from './plugins/querySuggestionsPlugin';
import { productsPlugin } from './plugins/productsPlugin';
import { recentSearchesPlugin } from './plugins/recentSearchesPlugin';
import { categoriesPlugin } from './plugins/categoriesPlugin';
import { PopularCategory } from './popular-category';
import styles from './autocomplete.module.scss';

interface IDesktopSearch {
  searchPlaceholder?: string;
  popularCategoriesLabel: string;
  popularCategories: {
    categoryLabel: string;
    categoryLink: IFTLinkReference | IFTPageFolderReference;
  }[];
  topSellerLabel: string;
  topSellerWidgetId: string;
  topSellerWidgetConfiguration: EcondaWidgetCredentials;
  suggestedProductsLabel: string;
  suggestedProductsLimit: number;
  suggestedCategoriesLabel: string;
  suggestedCategoriesLimit: number;
  allResultsLabel: string;
  algoliaConfig: any;
  isMobile?: boolean;
  flyoutWrapperRef: React.RefObject<HTMLDivElement>;
  searchFormWrapperRef: React.RefObject<HTMLDivElement>;
  onChange?: (query: string) => void;
  onReset?: () => void;
  onClick: () => void;
  setOpen: (open: boolean) => void;
}

export const Autocomplete: React.FC<IDesktopSearch> = ({
  searchPlaceholder,
  popularCategoriesLabel,
  popularCategories,
  topSellerLabel,
  topSellerWidgetId,
  topSellerWidgetConfiguration,
  suggestedProductsLabel,
  suggestedProductsLimit,
  suggestedCategoriesLabel,
  suggestedCategoriesLimit,
  allResultsLabel,
  algoliaConfig,
  isMobile = false,
  flyoutWrapperRef,
  searchFormWrapperRef,
  onChange,
  onReset,
  onClick,
  setOpen,
}) => {
  const suggestIndexNamePrefix = '_query_suggestions';
  const searchClient = useInstantSearchClient(algoliaConfig.dataSource);
  const { formatMessage } = useFormat({ name: 'common' });
  const router = useRouter();
  const activeShops = getActiveShops();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const triggeredSearch = useRef<boolean>(false);

  useEffect(() => {
    const query = router.query?.search || '';

    if (typeof query === 'string') {
      setSearchQuery(query);
      triggeredSearch.current = true;
    }
  }, [router]);

  useEffect(() => {
    if (!flyoutWrapperRef.current || !searchFormWrapperRef.current || !triggeredSearch.current) {
      return;
    }

    const classNames = {
      inputWrapperPrefix: styles.searchIcon,
      inputWrapperSuffix: styles.searchIcon,
      inputWrapper: styles.inputWrapper,
      input: 'searchInput',
      form: styles.searchForm,
      panel: styles.searchFlyoutContentWrapper,
    };

    const mobileClassNames = {
      inputWrapperPrefix: styles.mobileSearchIcon,
      inputWrapper: styles.mobileInputWrapper,
      input: styles.mobileSearchInput,
      form: styles.mobileSearchForm,
    };

    const autocompleteInstance = autocomplete({
      classNames: isMobile ? mobileClassNames : classNames,
      container: searchFormWrapperRef.current,
      openOnFocus: true,
      insights: true,
      detachedMediaQuery: 'none',
      placeholder: searchPlaceholder || formatMessage({ id: 'searchExpression' }),
      initialState: {
        query: searchQuery,
      },
      panelContainer: flyoutWrapperRef.current,
      panelPlacement: 'full-width',
      debug: isMobile,
      onSubmit({ state }) {
        if (state.query) {
          if (isMobile) {
            onClick();
          } else {
            setOpen(false);
          }

          router.push({
            pathname: `search/[term]`,
            query: { term: state.query },
          });
        }
      },
      onStateChange({ prevState, state }) {
        if (prevState.query !== state.query) {
          setSearchQuery(state.query);
          onChange?.(state.query);
        }
      },
      onReset({ setQuery, refresh, setIsOpen }) {
        onReset?.();
        setQuery('');
        setIsOpen(true);
        refresh();
      },
      getInputProps(params) {
        return {
          ...params.props,
          onFocus() {
            setTimeout(() => {
              setOpen(true);
            }, 100);
          },
        };
      },
      plugins: [
        recentSearchesPlugin(onClick),
        querySuggestionsPlugin(searchClient, algoliaConfig, suggestIndexNamePrefix, onClick),
        productsPlugin(searchClient, algoliaConfig, suggestedProductsLimit, onClick),
        categoriesPlugin(searchClient, algoliaConfig, suggestedCategoriesLimit, onClick)
      ],
      renderer: {
        createElement,
        Fragment,
        render: render as any,
      },
      render({ elements, state, Fragment, setContext, setIsOpen, refresh }, root) {
        const { recentSearchesPlugin: recentSearches, querySuggestionsPlugin: querySuggestions, products, categories } = elements;

        const onSelect = () => {
          onClick();
          setIsOpen(false);
        };

        render(
          <RouterContext.Provider value={router}>
            {state.query ? (
              <Fragment>
                <div className={styles.searchOverview}>
                  <div className={styles.searchTerm}>
                    <IconCustom icon="Search" width={20} className={styles.icon} />
                    <span className="text-xs">{state.query}</span>
                  </div>
                  <Link href={`/search/${state.query}`}>
                    <a className={styles.allResultsLink} onClick={onSelect}>
                      <span>{allResultsLabel}</span>
                      <IconCustom icon="ChevronRightIcon" width={12} className={styles.icon} />
                    </a>
                  </Link>
                </div>
                <div className="flex flex-col justify-between gap-5 lg:flex-row">
                  <div className={styles.categorySuggestionSection}>
                    <div className={styles.flyoutSectionHeader}>
                      <h4 className="text-base-bold">{suggestedCategoriesLabel}</h4>
                      <button
                        className={styles.showMoreToggle}
                        onClick={() => {
                          setContext({ showAllCategories: !state.context.showAllCategories });
                          refresh();
                        }}
                      >
                        {!state.context.showAllCategories
                          ? formatMessage({
                            id: 'showMore',
                            defaultMessage: 'Mehr anzeigen',
                          })
                          : formatMessage({
                            id: 'showLess',
                            defaultMessage: 'Weniger anzeigen',
                          })}
                      </button>
                    </div>
                    {categories}
                  </div>
                  <div className={styles.productSuggestionSection}>
                    <div className={styles.flyoutSectionHeader}>
                      <h4 className="text-base-bold">{suggestedProductsLabel}</h4>
                      {Number(state.context.nbProducts) > 3 && (
                        <button
                          className={styles.showMoreToggle}
                          onClick={() => {
                            setContext({ showAllProducts: !state.context.showAllProducts });
                            refresh();
                          }}
                        >
                          {!state.context.showAllProducts
                            ? formatMessage({
                              id: 'showMore',
                              defaultMessage: 'Mehr anzeigen',
                            })
                            : formatMessage({
                              id: 'showLess',
                              defaultMessage: 'Weniger anzeigen',
                            })}
                        </button>
                      )}
                    </div>
                    {products}
                  </div>
                </div>
              </Fragment>
            ) : (
              <div className="flex flex-col justify-between gap-5 lg:flex-row">
                <div className={styles.flyoutSection}>
                  <div className={styles.flyoutSectionHeader}>{popularCategoriesLabel}</div>
                  <ul className="flex flex-wrap justify-start gap-2">
                    {popularCategories?.map((category) => (
                      <PopularCategory
                        item={category}
                        onSelect={onSelect}
                        key={`popular-category-${category.categoryLabel}`}
                      />
                    ))}
                  </ul>
                </div>
                <div className={styles.flyoutSection}>
                  <div className={styles.flyoutSectionHeader}>
                    {recentSearches
                      ? formatMessage({
                        id: 'recentSearches',
                        defaultMessage: 'Letzte Suchen',
                      })
                      : formatMessage({
                        id: 'suggestedSearches',
                        defaultMessage: 'Beliebte Suchen',
                      })}
                  </div>
                  {recentSearches ?? querySuggestions}
                </div>
                <div className={classnames(styles.flyoutSection, styles.topSellerSection)}>
                  <FrontasticProvider context="shop">
                    <URLHistoryProvider>
                      <EcondaSlider
                        config={topSellerWidgetConfiguration}
                        widgetId={topSellerWidgetId}
                        title={topSellerLabel}
                        isStartPage={false}
                        customTitleStyle={{
                          fontSize: '16px',
                          textAlign: 'left',
                          textTransform: 'capitalize',
                          letterSpacing: 'normal',
                          lineHeight: '22px',
                          paddingBottom: '16px',
                          fontFamily: activeShops['engbers-germany'] && 'Gotham, sans-serif',
                        }}
                        isTopSellerSlider
                      />
                    </URLHistoryProvider>
                  </FrontasticProvider>
                </div>
              </div>
            )}
          </RouterContext.Provider>,
          root,
        );
      },
    });

    return () => autocompleteInstance.destroy();
  }, [triggeredSearch]);

  return null;
};
