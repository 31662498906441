import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import Link from 'next/link';
import Sticky from 'react-sticky-el';
import classnames from 'classnames';
import {
  EcondaWidgetCredentials,
  IFTLinkReference,
  IFTPageFolderReference,
  PageFolderTree,
} from '@frontastic-engbers/types/engbers-custom';
import { fetchApiHub, getProjectInfo, useCurrentShop } from '@frontastic-engbers/lib';
import useI18n from '@frontastic-engbers/helpers/hooks/useI18n';
import { DesktopSearch } from '../online-shops/desktop-search';
import styles from './navigation.module.scss';
import Spinner from '../online-shops/commercetools-ui/spinner';

interface NavType {
  // If categories are passed as prop, always prefer these
  categories?: PageFolderTree;
  popularCategoriesLabel: string;
  popularCategories: {
    categoryLabel: string;
    categoryLink: IFTLinkReference | IFTPageFolderReference;
  }[];
  topSellerLabel: string;
  topSellerWidgetId: string;
  topSellerWidgetConfiguration: EcondaWidgetCredentials;
  suggestedProductsLabel: string;
  suggestedProductsLimit: number;
  suggestedCategoriesLabel: string;
  suggestedCategoriesLimit?: number;
  allResultsLabel: string;
  algoliaConfig: any;
  isEmilio?: boolean;
  loadingLabel?: string;
}

export const Navigation: React.FC<NavType> = (data) => {
  const { t } = useI18n();
  const [navFlyoutId, setNavFlyoutId] = useState<string>(null);
  const [isMinWidthMedium, setIsMinWidthMedium] = useState<boolean>(false);
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const currentSection = useCurrentShop();
  const navWrapRef = useRef<HTMLDivElement>(null);
  const [categoriesFromServer, setCategoriesfromServer] = useState<any | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const { projectId } = getProjectInfo();

  const displayCategory = (category: PageFolderTree): boolean => category && !category?.configuration?.hideInNavigation;

  useEffect(() => {
    if (isMobile !== isMinWidthMedium) {
      setIsMinWidthMedium(isMobile);
    }
  }, [isMobile]);

  // Fetching Navigation client-side for performance reasons
  // Cache result in session storage to not refetch this information all the time until a new session is opened
  const fetchNavigation = async () => {
    setIsLoading(true);
    let navigation;
    if (sessionStorage.getItem('mainNavigation')) {
      navigation = JSON.parse(sessionStorage.getItem(`mainNavigation`));
      navigation = navigation.nav;
    } else {
      navigation = await fetchApiHub(`/page?path=%2Fheader-navigation-tree`);
      navigation = navigation?.page?.sections?.main?.layoutElements[0].tastics[0].configuration.headerNavigationTree;
      sessionStorage.setItem('mainNavigation', JSON.stringify({ nav: navigation }));
    }
    setCategoriesfromServer(navigation);
    setIsLoading(false);
  };

  // Fetch navigation items
  useEffect(() => {
    fetchNavigation();
  }, []);

  // Filter categories by current shop
  const currentCategories = useMemo(() => {
    // If categories are passed as prop, always prefer these
    if (data.categories) {
      return data.categories;
    }

    const shop = currentSection;
    if (!categoriesFromServer) {
      return [];
    }
    return categoriesFromServer?.find((nav) => nav.shop === shop)?.tree;
  }, [currentSection, categoriesFromServer, data.categories]);

  if (!isMinWidthMedium && isLoading) {
    return (
      <div className={styles.loadingOuterWrap}>
        <div className={styles.loadingInnerWrap}>
          <Spinner size="x-small" color="#0f202f" />
          <div className={styles.loadingLabel}>{data.loadingLabel}</div>
        </div>
      </div>
    );
  }

  return (
    <>
      {!isMinWidthMedium ? (
        <Sticky
          stickyClassName={classnames(styles.sticky, {
            [styles.emilioSticky]: data.isEmilio,
            [styles.enGermanySticky]: currentSection === 'engbers-germany',
          })}
          wrapperClassName={classnames(styles.stickyWrapper, {
            [styles.emilioStickyWrapper]: data.isEmilio,
            [styles.enGermanyStickyWrapper]: currentSection === 'engbers-germany',
          })}
        >
          <div className={styles.wrap} ref={navWrapRef}>
            <ul className={styles.navigationWrapper}>
              {currentCategories?.children?.map((category, index) => {
                if (!displayCategory(category)) {
                  return null;
                }

                let href = t(category._urls);
                const isBlog = category.configuration.path === 'blog';

                if (isBlog) {
                  href =
                    projectId === 'en'
                      ? 'https://www.engbers.com/blog/?utm_source=intern&utm_medium=verlinkung&utm_campaign=blog&utm_content=startseiten-menue'
                      : 'https://www.engbers.com/blog/category/emilioadaniblog/?utm_source=intern&utm_medium=verlinkung&utm_campaign=blog&utm_content=startseiten-menue';
                }

                return (
                  <li
                    key={`navigation-desktop-${index}`}
                    className={classnames(styles.navFlyout, {
                      [styles.emilioNavFlyout]: data.isEmilio,
                    })}
                    onMouseEnter={() => setNavFlyoutId(`navFlyout-${index}`)}
                    onMouseLeave={() => setNavFlyoutId(null)}
                  >
                    <Link
                      href={href}
                      title={category.name}
                      legacyBehavior={false}
                      onClick={() => setNavFlyoutId(null)}
                      target={isBlog ? '_blank' : '_self'}
                    >
                      {category.name}
                    </Link>
                    {category?.children && category?.children?.length ? (
                      <div
                        className={classnames('dropdown-menu', styles.dropdownMenu, {
                          [styles.showDropdownMenu]: navFlyoutId === `navFlyout-${index}`,
                        })}
                      >
                        <ul className={styles.dropdownMenuWrapper}>
                          {category.children.map((subcategory, inx) => {
                            if (!displayCategory(subcategory)) {
                              return null;
                            }

                            return (
                              <li key={`navigation-desktop-${index}-${inx}`}>
                                <Link
                                  href={t(subcategory._urls)}
                                  title={subcategory.name}
                                  legacyBehavior={false}
                                  onClick={() => setNavFlyoutId(null)}
                                >
                                  {subcategory.name}
                                </Link>
                                {subcategory?.children && subcategory?.children?.length ? (
                                  <ul className={styles.dropdownMenuSubNav}>
                                    {subcategory.children.map((child, i) => {
                                      if (!displayCategory(child)) {
                                        return null;
                                      }

                                      return (
                                        <li key={`navigation-desktop-${index}-${inx}-${i}`}>
                                          <Link
                                            href={t(child._urls)}
                                            title={child.name}
                                            legacyBehavior={false}
                                            onClick={() => setNavFlyoutId(null)}
                                          >
                                            {child.name}
                                          </Link>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                ) : null}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    ) : null}
                  </li>
                );
              })}
            </ul>
            <DesktopSearch
              popularCategoriesLabel={data.popularCategoriesLabel}
              popularCategories={data.popularCategories}
              topSellerLabel={data.topSellerLabel}
              topSellerWidgetId={data.topSellerWidgetId}
              topSellerWidgetConfiguration={data.topSellerWidgetConfiguration}
              suggestedProductsLabel={data.suggestedProductsLabel}
              suggestedProductsLimit={data.suggestedProductsLimit}
              suggestedCategoriesLabel={data.suggestedCategoriesLabel}
              suggestedCategoriesLimit={data.suggestedCategoriesLimit ?? data.suggestedProductsLimit}
              allResultsLabel={data.allResultsLabel}
              algoliaConfig={data.algoliaConfig}
              isEmilio={data.isEmilio}
              navWrapRef={navWrapRef}
            />
          </div>
        </Sticky>
      ) : null}
    </>
  );
};
