import { useMemo } from 'react';
import { NavigationSide } from '@engbers/components/navigation-side';
import { FullPageWidthWrapper } from '@engbers/components/online-shops';
import { useAccount } from '@frontastic-engbers/lib';

const EngbersContentNavigationTastic = ({ data }) => {
  const { account } = useAccount();
  const navItems = useMemo(
    () =>
      data.navigationItems
        .filter((item) => !(item.pwaUserOnly && !account?.custom?.fields?.PWAInstalled))
        .map((item) => ({
          label: item.label,
          textSize: item.textSize,
          id: item.label,
          reference: item.reference,
          icon: {
            width: item[`icon-width`],
            url: item.icon?.media.file,
          },
          isLogoutButton: item.isLogoutButton,
        })),
    [data.navigationItems, account],
  );
  const topIcon = {
    url: data.icon?.media.file,
    width: 28,
  };

  if (data.isFullWidth) {
    return (
      <FullPageWidthWrapper>
        <NavigationSide
          headline={data.headline}
          textSize={data.textSize}
          headlineTextColor={data.headlineTextColor}
          headlineBgColor={data.headlineBgColor}
          headlinePosition={data.headlinePosition}
          bannerPicture={data.bannerImage}
          items={navItems}
          topIcon={topIcon}
        />
      </FullPageWidthWrapper>
    );
  }

  return (
    <NavigationSide
      headline={data.headline}
      textSize={data.textSize}
      headlineTextColor={data.headlineTextColor}
      headlineBgColor={data.headlineBgColor}
      headlinePosition={data.headlinePosition}
      bannerPicture={data.bannerImage}
      items={navItems}
      topIcon={topIcon}
    />
  );
};

export default EngbersContentNavigationTastic;
