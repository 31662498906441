import { useSWRConfig } from 'swr';
import { useAccount } from '@frontastic-engbers/lib';
import { useRouter } from 'next/router';
import { ReferenceLink } from '@frontastic-engbers/helpers/reference';
import { IconCustom, Markdown } from '@engbers/components';
import styles from './navigation-side-navigation-item.module.scss';
import { IFTLinkReference, IFTPageFolderReference } from '@frontastic-engbers/types/engbers-custom';

export interface INavigationSideNavigationItem {
  label: string;
  textSize?: number;
  reference?: IFTPageFolderReference | IFTLinkReference;
  icon: {
    width: number;
    url: string;
  };
  isLogoutButton?: boolean;
  counter?: number;
}

export const NavigationSideNavigationItem: React.FC<INavigationSideNavigationItem> = ({
  label,
  textSize,
  icon,
  counter,
  reference,
  isLogoutButton,
}) => {
  const { mutate } = useSWRConfig();
  const { logout } = useAccount();
  const router = useRouter();

  return (
    <ReferenceLink target={reference}>
      <div
        className={styles.item}
        onClick={async (event) => {
          if (isLogoutButton) {
            event.stopPropagation();
            event.preventDefault();
            await mutate(() => true, undefined, { revalidate: false });
            await logout();

            router.push('/login');
          }
        }}
      >
        <div className={styles.iconWrap}>
          <img width={icon.width} src={icon.url} alt="icon" title="icon" />
          {counter ? <div className={styles.counter}>{counter}</div> : undefined}
        </div>
        <Markdown className={styles.label} text={label} textSize={textSize} disableMargin />
        <div className={styles.chevronIconContainer}>
          <IconCustom color="primary" width={14} icon="ChevronRightIcon" />
        </div>
      </div>
    </ReferenceLink>
  );
};
