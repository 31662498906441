import { useAccount } from '@frontastic-engbers/lib';
import { MyAccountIcons } from '@engbers/components/my-account/my-account-icons';
import { FullPageWidthWrapper } from '@engbers/components/online-shops';

export const MyAccountIconsTastic = ({ data }) => {
  const { loggedIn } = useAccount();

  if (!loggedIn && !data.showOnNotLoggedInUser) {
    return null;
  }

  if (data.isFullWidth) {
    return (
      <FullPageWidthWrapper>
        <MyAccountIcons icons={data.icons} />
      </FullPageWidthWrapper>
    );
  }

  return <MyAccountIcons icons={data.icons} />;
};
