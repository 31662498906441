export const MapMarkerPwaIcon = () => {
  return (
    <svg width="500" height="500" viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M249.93 64.8C185.23 64.8 132.66 117.27 132.66 181.95C132.66 246.65 242.41 422.95 242.41 422.95C246.63 430.01 253.49 430.01 257.65 422.95C257.65 422.95 367.24 246.82 367.24 181.95C367.24 117.28 314.81 64.8 249.93 64.8ZM249.93 124.98C272.74 124.98 290.99 143.41 290.99 166.05C290.99 188.81 272.73 207.11 249.93 207.11C227.32 207.11 208.89 188.81 208.89 166.05C208.9 143.41 227.32 124.98 249.93 124.98Z"
        fill="#8699AD"
      />
    </svg>
  );
};
