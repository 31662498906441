import React, { ChangeEvent, useEffect, useState } from 'react';
import classnames from 'classnames';
import useI18n from '@frontastic-engbers/helpers/hooks/useI18n';
import {
  CheckoutData,
  CustomerOrigin,
  PaymentMethod as PaymentMethodType,
  Solvency,
} from '@frontastic-engbers/types/engbers-custom';
import { IconCustom, Image } from '@engbers/components';
import styles from './payment-method.module.scss';

interface IPaymentMethod {
  payment: PaymentMethodType;
  solvency: Solvency;
  customerOrigin: CustomerOrigin;
  checkoutData: CheckoutData;
  onChoosePayment: (e: ChangeEvent<HTMLInputElement>, type: string, canUsePayment: boolean) => void;
}

export const PaymentMethod: React.FC<IPaymentMethod> = ({
  payment,
  solvency,
  customerOrigin,
  checkoutData,
  onChoosePayment,
}) => {
  const { t } = useI18n();
  const [showInfo, setShowInfo] = useState<string | null>(null);
  const [canUsePayment, setCanUsePayment] = useState<boolean>(true);

  useEffect(() => {
    setCanUsePayment(!(checkoutData.shippingMethod === 'packStation' || (customerOrigin.isGuest && !payment.canUse)));
  }, [checkoutData.shippingMethod]);

  return (
    <div
      className={classnames(styles.paymentMethodBoxWrapper, {
        [styles.isChecked]: payment.type === checkoutData.paymentMethod,
        [styles.isDisabled]:
          (solvency.result === 1 && !payment.canUse) || (!canUsePayment && !payment.canUse && customerOrigin.isGuest),
      })}
    >
      <label htmlFor={payment.type} className={styles.paymentMethodBox}>
        <input
          id={payment.type}
          name={payment.type}
          type="checkbox"
          className={styles.paymentMethodBoxInput}
          checked={payment.type === checkoutData.paymentMethod}
          onChange={(e) => onChoosePayment(e, payment.type, canUsePayment)}
        />
        {payment.infoText ? (
          <button
            className={styles.paymentMethodInfoWrap}
            onMouseOver={() => setShowInfo(payment.type)}
            onMouseLeave={() => setShowInfo(null)}
          >
            <IconCustom width={18} color="white" icon="Info" />
            <div
              className={classnames(
                styles.paymentMethodInfoText,
                payment.type === showInfo ? styles.showInfo : undefined,
              )}
            >
              <div className={styles.paymentMethodInfoTextInner}>{payment.infoText}</div>
            </div>
          </button>
        ) : null}
        {payment.icon.file ? (
          <Image
            src={payment.icon.file}
            width={60}
            alt={(payment.icon.title && t(payment.icon.title)) ?? payment.icon.name}
            title={(payment.icon.title && t(payment.icon.title)) ?? payment.icon.name}
          />
        ) : (
          <strong>{payment.label}</strong>
        )}
      </label>
    </div>
  );
};
