import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useRouter } from 'next/router';
import { getActiveShops, setActiveShops, useCurrentShop } from '@frontastic-engbers/lib';
import { HeaderMain } from '@engbers/components/header-main';
import { HeaderMinimized } from '@engbers/components/header-minimized';
import { HeaderServiceArea } from '@engbers/components/header-service-area';
import { FullPageWidthWrapper } from '@engbers/components/online-shops';

export const HeaderTastic = ({ data }) => {
  const { asPath } = useRouter();
  const activeShops = getActiveShops();
  const [isMinWidthMedium, setIsMinWidthMedium] = useState<boolean>(false);
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const currentSection = useCurrentShop();

  data.logos.forEach((logo) => {
    if (!asPath.includes(logo.relativePath + '-')) {
      activeShops[logo.activeShop] = asPath.includes(logo.relativePath);
    }
  });

  setActiveShops(activeShops);

  useEffect(() => {
    document.body.classList.toggle('is-en-germany', currentSection === 'engbers-germany');
  }, [currentSection]);

  useEffect(() => {
    if (isMobile !== isMinWidthMedium) {
      setIsMinWidthMedium(isMobile);
    }
  }, [isMobile]);

  return (
    <FullPageWidthWrapper>
      {isMinWidthMedium ? (
        <HeaderMinimized
          mobileCheckoutLogo={data.mobileCheckoutLogo}
          mobileCheckoutLogoLink={data.mobileCheckoutLogoLink}
          trustedShopIcon={data.trustedShopIcon}
          trustedShopText={data.trustedShopText}
          isCheckout={false}
          isEnGermany={currentSection === 'engbers-germany'}
        />
      ) : (
        <>
          <HeaderServiceArea services={data.services} isFullWidth={data.isFullWidth} />
          <HeaderMain logos={data.logos} items={data.items} />
        </>
      )}
    </FullPageWidthWrapper>
  );
};
