import { useMemo } from 'react';
import { OrderHistoryData } from '@frontastic-engbers/types/account/Account';
import { OrderItemAccordion } from '../order-item-accordion';
import { OrderItemContent } from '../order-item-content';
import { dateFormat } from '@engbers/components/formatted-date';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import styles from './order-item.module.css';

interface IOrderItemComponent {
  order: OrderHistoryData;
  retoure?: OrderHistoryData;
  isInitiallyOpen?: boolean;
  labels: {
    orderStatus: {
      1: string;
      2: string;
      3: string;
      4: string;
    };
  };
  colors?: { [key: string]: string };
  trackingLink?: string;
}

export const OrderItem: React.FC<IOrderItemComponent> = ({
  order,
  isInitiallyOpen,
  retoure,
  labels,
  colors,
  trackingLink,
}) => {
  const parsedDate = useMemo(() => new Date(order.saleDate), [order.saleDate]);
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });

  if (order.type === 'WEB') {
    const mappedOrderStatus = labels.orderStatus[`${order.status}`];
    return (
      <OrderItemAccordion
        hasShiftedContent
        isInitiallyOpen={isInitiallyOpen}
        headline={`${formatProductMessage({ id: 'order' })} ${order.id}`}
        subHeadline={`${formatProductMessage({ id: 'from' })} ${dateFormat.format(parsedDate)} ${mappedOrderStatus ? `| ${mappedOrderStatus}` : ''}`}
        invoiceAmount={order.invoiceAmount}
        colors={colors}
      >
        <OrderItemContent labels={labels} order={order} colors={colors} trackingLink={trackingLink} />

        {retoure ? (
          <div className={styles.retoureWrap}>
            <OrderItemAccordion
              hasShiftedContent
              isInitiallyOpen={isInitiallyOpen}
              headline={formatProductMessage({ id: 'return' })}
              subHeadline={`${formatProductMessage({ id: 'from' })} ${dateFormat.format(parsedDate)}`}
              invoiceAmount={retoure.invoiceAmount}
              colors={colors}
            >
              <OrderItemContent labels={labels} order={retoure} colors={colors} trackingLink={trackingLink} />
            </OrderItemAccordion>
          </div>
        ) : null}
      </OrderItemAccordion>
    );
  } else if (order.type === 'KASSE') {
    return (
      <OrderItemAccordion
        hasShiftedContent
        isInitiallyOpen={isInitiallyOpen}
        headline={formatProductMessage({ id: 'branchPurchasing' })}
        subHeadline={`${formatProductMessage({ id: 'from' })} ${dateFormat.format(parsedDate)}`}
        invoiceAmount={order.invoiceAmount}
        colors={colors}
      >
        <OrderItemContent labels={labels} order={order} colors={colors} trackingLink={trackingLink} />

        {retoure ? (
          <div className={styles.retoureWrap}>
            <OrderItemAccordion
              hasShiftedContent
              isInitiallyOpen={isInitiallyOpen}
              headline={`${formatProductMessage({ id: 'return' })}`}
              subHeadline={`vom ${dateFormat.format(parsedDate)}`}
            >
              <OrderItemContent labels={labels} order={retoure} colors={colors} trackingLink={trackingLink} />
            </OrderItemAccordion>
          </div>
        ) : null}
      </OrderItemAccordion>
    );
  } else if (order.type === 'RET') {
    return (
      <OrderItemAccordion
        hasShiftedContent
        isInitiallyOpen={isInitiallyOpen}
        headline={`${formatProductMessage({ id: 'return' })}`}
        subHeadline={`vom ${dateFormat.format(parsedDate)}`}
        invoiceAmount={order.invoiceAmount}
        colors={colors}
      >
        <OrderItemContent labels={labels} order={order} colors={colors} trackingLink={trackingLink} />
      </OrderItemAccordion>
    );
  }

  // unhandled order type
  return null;
};
