import classNames from 'classnames';
import styles from './input-radio-group.module.scss';

interface IInputRadioOption {
  label: string;
  id: string;
}

interface IInputRadio {
  onChange: (selectedOptionString: string) => void;
  options: IInputRadioOption[];
  activeId?: string;
  errorMessage?: string;
  size?: 'regular' | 'small';
}

export const InputRadioGroup: React.FC<IInputRadio> = ({ onChange, options, activeId, errorMessage, size }) => {
  return (
    <div>
      <div className={styles.row}>
        {options?.map((option) => {
          return (
            <div
              className={classNames(styles.radioButton, {
                [styles[`radioButton--isActive`]]: activeId === option.id,
                [styles[`size--${size}`]]: !!size,
              })}
              onClick={() => onChange(option.id)}
              key={`radio-option-${option.id}`}
            >
              {option.label}
            </div>
          );
        })}
      </div>
      {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
    </div>
  );
};
