import React, { useCallback, useState } from 'react';
import { useAccount } from '@frontastic-engbers/lib';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { Block, Button, InputText, PasswordCheckList } from '@engbers/components';
import { useToastNotificationsActions } from '@frontastic-engbers/lib/state/notification/actions';
import { PasswordCheckListHelper } from '@frontastic-engbers/helpers/passwordCheckListHelper';

type ChangePasswordFormState = {
  password: string;
  newPassword: string;
  newPasswordRepeat: string;
};

const validate = (data: ChangePasswordFormState) => {
  return {
    password: data.password !== '',
    newPassword: data.newPassword !== '',
    newPasswordRepeat: data.newPassword === data.newPasswordRepeat,
  };
};

export const ChangePasswordForm = () => {
  const { formatMessage: formatSuccessMessage } = useFormat({ name: 'success' });
  const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { formatMessage } = useFormat({ name: 'common' });
  const { changePassword } = useAccount();
  const { pushNotification } = useToastNotificationsActions();
  const [errors, setErrors] = useState({
    password: true,
    newPassword: true,
    newPasswordRepeat: true,
  });
  const [newPasswordErrors, setNewPasswordErrors] = useState<{
    valid: boolean;
    message: string;
  } | null>(null);
  const [isPassInputFocused, setIsPassInputFocused] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<ChangePasswordFormState>({
    password: '',
    newPassword: '',
    newPasswordRepeat: '',
  });

  const resetPasswordForm = useCallback(() => {
    setData({
      password: '',
      newPassword: '',
      newPasswordRepeat: '',
    });
  }, []);

  const handleSubmit = useCallback(async () => {
    const err = validate(data);
    validateNewPassword();
    setErrors(err);
    if (Object.values(err).some((error) => !error) || !newPasswordErrors || !newPasswordErrors.valid) {
      return;
    }

    setLoading(true);
    try {
      const response = await changePassword(data.password, data.newPassword);
      if (response.confirmed) {
        pushNotification(formatSuccessMessage({ id: 'updatedData' }), 'success');

        resetPasswordForm();
      } else {
        pushNotification(formatErrorMessage({ id: 'passwordChangeFailedOldPassword' }), 'error');
      }
    } catch (e) {
      console.error(e);
    }

    setLoading(false);
  }, [setLoading, setErrors, changePassword, data]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setData((prev) => ({
        ...prev,
        [event.target.name]: event.target.value,
      }));
    },
    [setData],
  );

  const validateNewPassword = (e?: React.ChangeEvent<HTMLInputElement>) => {
    const ruleDefinitions: any = PasswordCheckListHelper.ruleDefinitions(e ? e.target.value : data.newPassword, 6);
    const rulesKeys: string[] = Object.keys(ruleDefinitions).filter((key) => !ruleDefinitions[key].valid);

    rulesKeys.forEach((key) => {
      if (!ruleDefinitions[key].valid) {
        setErrors({
          ...errors,
          newPassword: ruleDefinitions[key].valid,
        });
        setNewPasswordErrors(ruleDefinitions[key]);
      }
    });

    if (!rulesKeys.length) {
      setErrors({
        ...errors,
        newPassword: true,
      });
      setNewPasswordErrors({
        valid: true,
        message: '',
      });
    }
  };

  if (!data) {
    return null;
  }

  return (
    <Block>
      <Block marginBottom={4}>
        <InputText
          placeholder={formatAccountMessage({ id: 'password' })}
          name="password"
          type="password"
          onChange={handleChange}
          value={data.password}
          onBlur={() =>
            setErrors({
              ...errors,
              password: data.password !== '',
            })
          }
          errorMessage={!errors.password ? formatAccountMessage({ id: 'password.enter' }) : undefined}
        />
      </Block>
      <Block marginBottom={4}>
        <Block>
          <InputText
            placeholder={formatAccountMessage({ id: 'password.new' })}
            name="newPassword"
            type="password"
            onChange={(e) => {
              handleChange(e);
              validateNewPassword(e);
            }}
            value={data.newPassword}
            onFocus={() => setIsPassInputFocused(true)}
            onBlur={(e) => {
              setIsPassInputFocused(false);
              validateNewPassword(e);
            }}
            errorMessage={newPasswordErrors && !newPasswordErrors.valid ? newPasswordErrors.message : undefined}
          />
        </Block>
        {isPassInputFocused ? (
          <Block marginTop={2}>
            <PasswordCheckList
              rules={['minLength', 'lowercase', 'capital', 'number', 'specialChar']}
              minLength={6}
              value={data.newPassword}
            />
          </Block>
        ) : null}
      </Block>
      <Block marginBottom={4}>
        <InputText
          placeholder={formatAccountMessage({ id: 'password.repeatNew' })}
          name="newPasswordRepeat"
          type="password"
          onChange={handleChange}
          value={data.newPasswordRepeat}
          onBlur={() =>
            setErrors({
              ...errors,
              newPasswordRepeat: data.newPassword === data.newPasswordRepeat,
            })
          }
          errorMessage={!errors.newPasswordRepeat ? formatErrorMessage({ id: 'password.noMatch' }) : undefined}
        />
      </Block>
      <Block marginBottom={4}>
        <Button size="large" isLoading={loading} label={formatMessage({ id: 'save' })} onClick={handleSubmit} />
      </Block>
    </Block>
  );
};
