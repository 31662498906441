import classnames from 'classnames';
import React, { useState } from 'react';
import useI18n from '@frontastic-engbers/helpers/hooks/useI18n';
import { fetchApiHub } from '@frontastic-engbers/lib';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { MediaType } from '@frontastic-engbers/types/engbers-custom';
import { Block, Button, Image, InputError, Markdown, Typography } from '@engbers/components';
import styles from './engbers-card-balance.module.scss';

export type Props = {
  headline: string;
  textSize?: number;
  description: string;
  ctaText: string;
  image: MediaType;
  balanceSuccess: string;
  balanceError: string;
};

type CardDiscountData = {
  cardId: string;
  zipCode: string;
  firstName: string;
};

type CardDiscountResponse = {
  isLoading: boolean;
  loaded: boolean;
  isError: boolean;
  openDiscount: string;
};

type ValidationErrors = {
  cardId: boolean;
  zipCode: boolean;
  firstName: boolean;
};

const fetchCardDiscount = (data: CardDiscountData): Promise<Pick<CardDiscountResponse, 'openDiscount'>> =>
  fetchApiHub('/action/account/getOpenCardDiscount', { method: 'POST' }, data);

export const EngbersCardBalance: React.FC<Props> = ({
  headline,
  textSize,
  description,
  ctaText,
  image,
  balanceSuccess,
  balanceError,
}) => {
  const { t } = useI18n();
  const { formatMessage } = useFormat({ name: 'common' });
  const [data, setData] = useState<CardDiscountData>({
    cardId: '',
    zipCode: '',
    firstName: '',
  });
  const { cardId, zipCode, firstName } = data;
  const [{ isLoading, loaded, isError, openDiscount }, setDiscountResponse] = useState<CardDiscountResponse>({
    isLoading: false,
    loaded: false,
    isError: false,
    openDiscount: '',
  });
  const [errors, setErrors] = useState<Partial<ValidationErrors>>({});

  const validate = () => {
    const validationErrors: ValidationErrors = {
      cardId: cardId === '',
      zipCode: zipCode === '',
      firstName: firstName === '',
    };

    setErrors(validationErrors);

    return !Object.values(validationErrors).some((v) => v);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setData((state) => ({
      ...state,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validate()) {
      return;
    }

    setDiscountResponse((state) => ({
      ...state,
      isLoading: true,
    }));

    const response = await fetchCardDiscount(data);

    setData((state) => ({
      ...state,
      zipCode: '',
      firstName: '',
    }));
    setDiscountResponse({
      isLoading: false,
      loaded: true,
      isError: response.openDiscount === '-1',
      ...response,
    });
  };

  return (
    <div className={styles.cardBalance}>
      {headline && (
        <div className="mb-8">
          <Typography tag="h3" variant="heading-3" weight="regular">
            <Markdown text={headline} textSize={textSize} disableMargin />
          </Typography>
        </div>
      )}
      {description && (
        <div className="mb-9">
          <Typography tag="h4" variant="heading-4" weight="regular">
            {description}
          </Typography>
        </div>
      )}

      <div className={styles.container}>
        <form onSubmit={handleSubmit}>
          <div className={classnames(styles.inputGroup, styles.required)}>
            <label htmlFor="card-id">
              <Typography variant="text-sm">{formatMessage({ id: 'card.number' })}</Typography>
            </label>
            <Block customStyle={{ width: '100%' }}>
              <input id="card-id" type="text" name="cardId" value={cardId} onChange={handleInputChange} />
              <InputError condition={errors.cardId} message="fillInput" />
            </Block>
          </div>

          <div className={classnames(styles.inputGroup, styles.required)}>
            <label htmlFor="zip-code">
              <Typography variant="text-sm">{formatMessage({ id: 'zipCode' })}</Typography>
            </label>
            <Block customStyle={{ width: '100%' }}>
              <input
                id="zip-code"
                type="text"
                name="zipCode"
                autoComplete="zipCode"
                value={zipCode}
                onChange={handleInputChange}
              />
              <InputError condition={errors.zipCode} message="fillInput" />
            </Block>
          </div>

          <div className={classnames(styles.inputGroup, styles.required)}>
            <label htmlFor="first-name">
              <Typography variant="text-sm">{formatMessage({ id: 'firstName' })}</Typography>
            </label>
            <Block customStyle={{ width: '100%' }}>
              <input
                id="first-name"
                type="text"
                name="firstName"
                autoComplete="given-name"
                value={firstName}
                onChange={handleInputChange}
              />
              <InputError condition={errors.firstName} message="fillInput" />
            </Block>
          </div>

          <div className={styles.inputGroup}>
            <Typography variant="text-sm">{formatMessage({ id: 'mandatoryFields' })}</Typography>
            <Button label={ctaText} type="cta" buttonType="submit" size="large" isLoading={isLoading} />
          </div>
        </form>
        <Image media={image.media} alt={image.title ? t(image.title) : image.media.name} />
      </div>

      {loaded && (
        <div className="mb-8 mt-2">
          <Typography variant="text-base" color={isError ? 'danger' : 'success'}>
            {isError ? balanceError : balanceSuccess.replace(/%balance%/i, openDiscount)}
          </Typography>
        </div>
      )}
    </div>
  );
};
