import React from 'react';
import { MediaType } from '@frontastic-engbers/types/engbers-custom';
import { useImageSEO } from '@frontastic-engbers/helpers/hooks/useImageSEO';

type Props = {
  media: MediaType;
  autoPlay?: boolean;
  loop?: boolean;
  muted?: boolean;
  controls?: boolean;
  className?: string;
};

export const Video: React.FC<Props> = ({
  media,
  autoPlay = false,
  loop = false,
  muted = false,
  controls = false,
  className,
}) => {
  const { getTitle } = useImageSEO();

  if (media?.media?.resourceType !== 'video') {
    return null;
  }

  return (
    <video className={className} autoPlay={autoPlay} loop={loop} muted={muted} controls={controls} playsInline>
      <source src={media.media.file} title={getTitle(media)} />
    </video>
  );
};
