import { Vouchers } from '@engbers/components/online-shops/vouchers';

interface Coupon {
  image: any;
  voucherCode: string;
}

interface ICouponVouchersTasticData {
  placeholderLabel?: string;
  voucherItems?: Array<Coupon>;
}

interface ICouponVouchersTasticComponent {
  data: ICouponVouchersTasticData;
}

export const VouchersTastic = ({ data }: ICouponVouchersTasticComponent) => {
  return (
    <div>
      <Vouchers couponsAsImage={data.voucherItems} placeholderLabel={data.placeholderLabel} />
    </div>
  );
};
