import { SectionLayout } from '@engbers/components';
import { Coupons } from '@engbers/components/online-shops/coupons';

interface ICouponVouchersTasticData {
  placeholderLabel?: string;
  description?: string;
}

interface ICouponVouchersTasticComponent {
  data: ICouponVouchersTasticData;
}

export const CouponsTastic = ({ data }: ICouponVouchersTasticComponent) => {
  return (
    <SectionLayout>
      <Coupons description={data.description} placeholderLabel={data.placeholderLabel} />
    </SectionLayout>
  );
};
