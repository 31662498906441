import React, { useMemo } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import classnames from 'classnames';
import useI18n from '@frontastic-engbers/helpers/hooks/useI18n';
import { InstantSearchHelpers } from '@frontastic-engbers/helpers/instantSearchHelpers';
import { PageFolderTree } from '@frontastic-engbers/types/engbers-custom';
import { IconCustom } from '@engbers/components';
import styles from './sidebar-navigation.module.scss';

export interface ISidebarNavigation {
  navTree: PageFolderTree;
  className: string;
  isEmilio?: boolean;
  isContentSidebar?: boolean;
}

export const SidebarNavigation: React.FC<ISidebarNavigation> = ({
  navTree,
  className,
  isEmilio = false,
  isContentSidebar = false,
}) => {
  const router = useRouter();
  const { t } = useI18n();

  const displayCategory = (category: PageFolderTree): boolean => category && !category?.configuration?.hideInNavigation;

  const path = useMemo(() => {
    const slug = router.asPath
      .replace(/(^\/|\/$)/g, '')
      .split('?')[0]
      .split('/');
    return InstantSearchHelpers.getPathFromSlug(slug);
  }, [router.asPath]);

  const isCategoryActive = (url: string, children: PageFolderTree[]) => {
    return path === url || !!children.filter((child) => t(child._urls) === path).length;
  };

  return (
    <div className={className}>
      <ul>
        {navTree.children.map((category) => {
          if (!displayCategory(category)) {
            return null;
          }

          return (
            <li
              key={`sidebar-nav-${category.pageFolderId}`}
              className={classnames(styles.sidebarCategory, {
                [styles.sidebarCategoryActive]: t(category._urls) === path,
                [styles.emilioSidebarCategory]: isEmilio,
              })}
            >
              <Link href={t(category._urls)} title={category.name} legacyBehavior={false}>
                {category.name}
                {isContentSidebar && <IconCustom color="primary" width={12} icon="ChevronRightIcon" />}
              </Link>
              {category?.children &&
              category?.children?.length &&
              isCategoryActive(t(category._urls), category?.children) ? (
                    <ul>
                      {category.children.map((subcategory, i) => {
                        const isSubcategoryActive = t(subcategory._urls) === path;
                        if (!displayCategory(subcategory)) {
                          return null;
                        }

                        return (
                          <li
                            key={`sidebar-subNav-${subcategory.pageFolderId}`}
                            className={classnames(
                              styles.sidebarSubcategory,
                              isSubcategoryActive ? styles.sidebarSubcategoryActive : undefined,
                            )}
                          >
                            <IconCustom
                              icon={'ChevronRightIcon'}
                              width={9}
                              className={classnames(styles.sidebarCategoryIcon, {
                                [styles.contentSidebarCategoryIcon]: isContentSidebar,
                              })}
                            />
                            <Link href={t(subcategory._urls)} title={subcategory.name} legacyBehavior={false}>
                              {subcategory.name}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  ) : null}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
