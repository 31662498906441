import React, { useEffect } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { RegistrationForm } from '@engbers/components/registration-form';
import { useAccount } from '@frontastic-engbers/lib/provider';
import { useSnippets } from '@frontastic-engbers/lib/actions/config';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { FullPageWidthWrapper } from '@engbers/components/online-shops';
import { IFTLinkReference, IFTPageFolderReference } from '@frontastic-engbers/types/engbers-custom';
import { getReferenceTarget } from '@frontastic-engbers/helpers/reference';

interface IRegisterPageData {
  isFullWidth: boolean;
  headline: string;
  textSize?: number;
  radioMister: string;
  radioMs: string;
  labelFirstName: string;
  lastLastName: string;
  labelStreet: string;
  labelHouse: string;
  labelZip: string;
  labelCity: string;
  labelCountry: string;
  labelBirth: string;
  birthdateInfo: string;
  labelEmail: string;
  labelEmailConfirm: string;
  labelNewsletter: string;
  labelAdaniNewsletter: string;
  labelXXLNewsletter: string;
  labelLetters: string;
  labelButtonRegister: string;
  labelBack: string;
  labelShoppingPreferenceHeadline: string;
  messageSuccess: string;
  errorRequired: string;
  errorMatch: string;
  invalidMailFormat: string;
  reCaptchaSiteKey: any;
  textGuidelinesMarkdown: string;
  doiConfirmationLink: IFTLinkReference | IFTPageFolderReference;
  homepageRedirectLink: IFTLinkReference | IFTPageFolderReference;
  projectInfo?: {
    name: string;
    projectId: string;
  };
  migrationVerifyBirthdate: string;
}

interface IRegisterPageTasticComponent {
  data: IRegisterPageData;
}

export const RegisterPageTastic: React.FC<IRegisterPageTasticComponent> = ({ data }) => {
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const [initialState, setInitialState] = React.useState(undefined);
  const { loggedIn, loaded } = useAccount();
  const router = useRouter();
  const { snippetAddressCityInfo } = useSnippets();

  useEffect(() => {
    try {
      const registerFormState = sessionStorage.getItem('registerFormState');

      if (registerFormState) {
        setInitialState(JSON.parse(registerFormState));
        sessionStorage.removeItem('registerFormState');
      }
    } catch (e) {}
  }, [typeof sessionStorage !== 'undefined' ? sessionStorage.registerFormState : undefined]);

  useEffect(() => {
    if (loaded && loggedIn) {
      router.push(getReferenceTarget(data.homepageRedirectLink));
    }
  }, [loaded, loggedIn, router, data.homepageRedirectLink]);

  const registrationForm = (
    <RegistrationForm
      labels={{
        headline: data.headline,
        textSize: data.textSize,
        radioMister: data.radioMister,
        radioMs: data.radioMs,
        labelFirstName: data.labelFirstName,
        lastLastName: data.lastLastName,
        labelStreet: data.labelStreet,
        labelHouse: data.labelHouse,
        labelZip: data.labelZip,
        labelCity: data.labelCity,
        cityInfo: snippetAddressCityInfo,
        labelCountry: data.labelCountry,
        labelBirth: data.labelBirth,
        birthdateInfo: data.birthdateInfo,
        labelEmail: data.labelEmail,
        labelEmailConfirm: data.labelEmailConfirm,
        labelNewsletter: data.labelNewsletter,
        labelAdaniNewsletter: data.labelAdaniNewsletter,
        labelXXLNewsletter: data.labelXXLNewsletter,
        labelLetters: data.labelLetters,
        migrationVerifyBirthdate: data.migrationVerifyBirthdate,
        labelButtonRegister: data.labelButtonRegister,
        labelBack: data.labelBack,
        labelShoppinngPreferenceHeadline: data.labelShoppingPreferenceHeadline,
        messageSuccess: data.messageSuccess,
        errorRequired: data.errorRequired,
        errorMatch: data.errorMatch,
        invalidMailFormat: data.invalidMailFormat,
        reCaptchaSiteKey: data?.reCaptchaSiteKey?.dataSource?.reCaptchaSiteKey,
        textGuidelinesMarkdown: data.textGuidelinesMarkdown,
      }}
      doiConfirmationLink={data.doiConfirmationLink}
      initialState={initialState}
      isEngbersOS
      isFormCentered
    />
  );

  if (data.isFullWidth) {
    return (
      <FullPageWidthWrapper>
        <Head>
          <title>
            {formatAccountMessage({
              id: 'sign.up',
              defaultMessage: 'Sign up',
            })}
          </title>
        </Head>
        <div>{registrationForm}</div>
      </FullPageWidthWrapper>
    );
  }

  return (
    <>
      <Head>
        <title>
          {formatAccountMessage({
            id: 'sign.up',
            defaultMessage: 'Sign up',
          })}
        </title>
      </Head>
      <div>{registrationForm}</div>
    </>
  );
};
