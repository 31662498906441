import React from 'react';
import styles from './index.module.scss';
import Spinner from '../online-shops/commercetools-ui/spinner';

export type Props = {
  isLoading: boolean;
  className?: string;
};

export const LoadingSpinner: React.FC<Props> = ({ isLoading, className }) =>
  isLoading && (
    <div className={className || styles.loadingSpinner}>
      <Spinner size="small" />
    </div>
  );
