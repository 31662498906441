export const InboxIcon = () => {
    return (
        <svg
            version="1.1"
            id="Ebene_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="500"
            height="500"
            viewBox="0 0 900.4 1008"
        >
            <g>
                <path
                    d="M448.9,821.6l-110.3-94.5L21,992.3c13.1,10.5,26.3,15.8,44.6,15.8h771.8c15.8,0,31.5-5.3,44.6-15.8
		L561.8,724.5L448.9,821.6z M0,435.8v525l309.8-259.9L0,435.8z M900.4,378L448.9,0L0,378l448.9,378L900.4,378z M900.4,958.1v-525
		L588,700.9L900.4,958.1z"
                />
            </g>
        </svg>
    );
};
