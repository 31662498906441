import React, { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Markdown } from '@engbers/components';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { getProjectInfo, useAccount } from '@frontastic-engbers/lib';
import { getReferenceTarget } from '@frontastic-engbers/helpers/reference';
import { IFTLinkReference, IFTPageFolderReference } from '@frontastic-engbers/types/engbers-custom';
import { useToastNotificationsActions } from '@frontastic-engbers/lib/state/notification/actions';
import Toast from 'react-hot-toast';

interface IEmailVerificationComponent {
  title?: string;
  errorMessage?: string;
  successMessage?: string;
  redirectLink?: IFTLinkReference | IFTPageFolderReference;
  usePushNotification?: boolean;
  tokenParam?: string;
  classNames?: Record<string, string>;
}

export const EmailVerification: React.FC<IEmailVerificationComponent> = ({
  title,
  errorMessage,
  successMessage,
  redirectLink,
  usePushNotification,
  classNames,
  tokenParam = 'doiId',
}) => {
  const isPwa = getProjectInfo()['projectId'] === 'Pwa';
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { pushNotification } = useToastNotificationsActions();
  const { confirm, loaded, loggedIn } = useAccount();
  const router = useRouter();

  const [verified, setVerified] = useState<boolean | null>(null);
  const [token, setToken] = useState<string | null>(null);

  const getVerificationToken = (): string | null => {
    const queryToken = router.query[tokenParam] ?? null;

    if (typeof queryToken === 'string') {
      return queryToken.trim().length > 0 ? queryToken.trim() : null;
    }

    if (Array.isArray(queryToken)) {
      return queryToken.find((token) => token.trim().length > 0).trim() ?? null;
    }

    return null;
  };

  const getMessage = (verified: boolean): string => {
    return verified
      ? successMessage ??
          formatAccountMessage({
            id: 'verification.done',
            defaultMessage: 'Email verified',
          })
      : errorMessage ??
          formatAccountMessage({
            id: 'verification.failed',
            defaultMessage: 'Invalid token',
          });
  };

  const verifyUser = useCallback(
    async (token: string): Promise<void> => {
      try {
        const { accountId } = await confirm(token);

        setVerified(!!accountId);
      } catch {
        // nothing to do here for now ...
      }
    },
    [token, confirm],
  );

  useEffect(() => {
    if (verified === null) {
      return;
    }

    if (usePushNotification) {
      if (!verified || (verified && !redirectLink)) {
        if (isPwa) {
          if (verified) {
            Toast.success(getMessage(verified));
          } else {
            Toast.error(getMessage(verified));
          }
          return;
        }
        pushNotification(getMessage(verified), verified ? 'success' : 'error');
      }
    }
  }, [verified]);

  useEffect(() => {
    const verificationToken = getVerificationToken()?.trim();
    if (verificationToken?.length > 0) {
      verifyUser(verificationToken);
      setToken(verificationToken);
    }
  }, []);

  useEffect(() => {
    if (verified && redirectLink && loaded && loggedIn) {
      router.push(getReferenceTarget(redirectLink)).then(() => {
        if (usePushNotification) {
          if (isPwa) {
            Toast.success(getMessage(verified));
            return;
          }

          pushNotification(getMessage(verified), 'success');
        }
      });
    }
  }, [verified, loaded, loggedIn]);

  if (usePushNotification || token === null) {
    return null;
  }

  return (
    <div className={classNames?.emailVerificationWrapper}>
      {title && <h3 className={classNames?.title}>{title}</h3>}
      {verified !== null && <Markdown className={classNames?.message} text={getMessage(verified)} />}
    </div>
  );
};
