export const UserCrownIcon = () => {
  return (
    <svg width="500" height="500" viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M260.53 320.25C305.166 320.25 341.35 284.066 341.35 239.43C341.35 194.794 305.166 158.61 260.53 158.61C215.894 158.61 179.71 194.794 179.71 239.43C179.71 284.066 215.894 320.25 260.53 320.25Z"
        fill="#8699AD"
      />
      <path
        d="M411.81 444.99V459.38H109.25V444.99C109.25 388.04 147.08 339.92 198.98 324.39C205.98 322.29 213.48 322.74 220.21 325.59C220.24 325.6 220.27 325.62 220.3 325.63C233.05 331.02 246.59 333.76 260.53 333.76C274.47 333.76 288 331.02 300.76 325.63C300.79 325.62 300.82 325.6 300.85 325.59C307.58 322.74 315.08 322.29 322.08 324.39C373.98 339.92 411.81 388.04 411.81 444.99Z"
        fill="#8699AD"
      />
      <path
        d="M336.47 58.83C332.94 68.27 329.02 75.87 324.86 81.68C324.51 82.16 324.17 82.64 323.82 83.1C323.12 84.02 322.41 84.89 321.7 85.71C320.99 86.53 320.27 87.3 319.55 88.02C318.83 88.74 318.1 89.42 317.37 90.04C314.08 92.85 310.71 94.67 307.32 95.52C305.81 95.9 304.3 96.09 302.79 96.09C300.25 96.09 297.72 95.56 295.22 94.5C294.72 94.29 294.22 94.06 293.72 93.8C292.23 93.04 290.75 92.09 289.28 90.96C287.82 89.83 286.37 88.51 284.96 87C284.01 86 283.08 84.91 282.16 83.75C281.7 83.17 281.24 82.56 280.79 81.94C279.88 80.69 278.98 79.36 278.1 77.96C277.66 77.26 277.22 76.53 276.79 75.79C275.49 73.56 274.23 71.14 273.01 68.55C272.2 66.82 271.4 65.02 270.63 63.13C269.85 61.24 269.1 59.28 268.37 57.24C268 56.22 267.64 55.18 267.29 54.12C266.58 52 265.89 49.8 265.23 47.53C263.9 42.98 262.67 38.12 261.56 32.96C252.62 74.25 236.55 96.1 220.3 96.1C208.18 96.1 196 84 186.59 58.83L176.91 32.95L194.38 156.05L195.94 164.65C197.15 164.09 198.49 163.77 199.62 163.02C214.66 152.95 238.46 140.95 261.54 140.95C275.96 140.95 292.4 146.77 304.14 152.17C311.52 155.56 314.77 156.9 320.84 161.3C321.22 161.57 321.39 161.72 324.22 163.65C326.17 164.98 325.9 165.05 327.11 165.61L328.71 156.06L346.18 32.96L336.47 58.83Z"
        fill="#8699AD"
      />
    </svg>
  );
};
