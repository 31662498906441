import { useCallback, useEffect, useState } from 'react';
import { Typography } from '@engbers/components/typography';
import { NewsletterSignupItemsItem } from './component/newsletter-signup-items-item';
import { Block } from '@engbers/components/block';
import { useModalActions } from '@frontastic-engbers/lib/state/modal/actions';
import { Button, LoadingIndicatorInline, Markdown } from '@engbers/components';
import { NewsletterSignupUnsubModal } from '../newsletter-signup-unsub-modal';
import { useNewsletter } from '@frontastic-engbers/lib/actions/account/useNewsletter';
import { MediaType } from '@frontastic-engbers/types/engbers-custom';
import { NewsletterSubscription } from '@frontastic-engbers/types/newsletter/types';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import styles from './newsletter-signup-items.module.scss';
import { getProjectInfo } from '@frontastic-engbers/lib';

type NewsletterItemsProps = {
  id: number;
  idMonthly?: number;
  idWeekly?: number;
  name: string;
  description: string;
  image: MediaType;
};

type NewsletterSignupItemsProps = {
  newsletters: NewsletterItemsProps[];
  labels: any;
};

const getAlternativeFrequencyOptions = (newsletterItemType: string) => {
  if (['Engbers_NL_Anmeldungen', 'Emilio_NL_Anmeldungen'].includes(newsletterItemType)) {
    return {
      weekly: `${newsletterItemType}_weekly`,
      monthly: `${newsletterItemType}_monthly`,
    };
  }

  return {};
};

const isNewsletterSubscribed = (newsletterName: string, subscriptions: NewsletterSubscription[]): boolean => {
  const mainNewsletter = subscriptions.find((subscription) => subscription.newsletterName === newsletterName);

  if (mainNewsletter) {
    const isMainSubscribed = mainNewsletter.isSubscribed;
    const hasWeeklySubscription = subscriptions.some(
      (subscription) =>
        subscription.newsletterName.includes(newsletterName) &&
        subscription.newsletterName.includes('_weekly') &&
        subscription.isSubscribed,
    );
    const hasMonthlySubscription = subscriptions.some(
      (subscription) =>
        subscription.newsletterName.includes(newsletterName) &&
        subscription.newsletterName.includes('_monthly') &&
        subscription.isSubscribed,
    );

    return isMainSubscribed || hasWeeklySubscription || hasMonthlySubscription;
  }

  return false;
};

export const NewsletterSignupItems = ({ newsletters, labels }: NewsletterSignupItemsProps) => {
  const isEmilio = getProjectInfo()?.projectId === 'ea';
  const { pushModal, removeModal } = useModalActions();
  const { updateSubscriptions, getSubscriptions } = useNewsletter();
  const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });
  const [loading, setLoading] = useState<boolean>(false);
  const [subscriptions, setSubscriptions] = useState<NewsletterSubscription[]>([]);

  const mapNewsletterSubscriptions = (newsletters: NewsletterItemsProps[]) => {
    const result = [];

    newsletters?.forEach((newsletter) => {
      result.push({
        id: newsletter.id,
        name: newsletter.name,
      });
      const alternativeOptions = getAlternativeFrequencyOptions(newsletter.name);

      if (alternativeOptions.weekly) {
        result.push({
          id: newsletter.idWeekly,
          name: alternativeOptions.weekly,
        });
      }

      if (alternativeOptions.monthly) {
        result.push({
          id: newsletter.idMonthly,
          name: alternativeOptions.monthly,
        });
      }
    });

    return result;
  };

  const handleChange = (newsletter: any, isActive: boolean) => {
    if (isActive) {
      const frequencyOptions = getAlternativeFrequencyOptions(newsletter.name);

      pushModal({
        title: labels.modalFrequencyHeadline,
        id: 'newsletter-unsubscribe',
        content: (
          <NewsletterSignupUnsubModal
            labels={labels}
            identifier={newsletter.name}
            frequencyOptions={frequencyOptions}
            onClose={() => removeModal()}
            updateSettings={(settings) => {
              handleSubmit(newsletter.name, settings, true);
            }}
          />
        ),
      });
      return;
    }

    handleSubmit(newsletter.name, {}, false);
  };

  const handleSubmit = useCallback(
    async (newsletterName, settings, isUnsubscription) => {
      const subscriptionsNew = subscriptions.map((subscription) => {
        for (const settingsKey in settings) {
          if (subscription.newsletterName === settingsKey) {
            return {
              ...subscription,
              isSubscribed: settings[settingsKey],
            };
          }
        }

        if (subscription.newsletterName === newsletterName) {
          return {
            ...subscription,
            isSubscribed: !subscription.isSubscribed,
          };
        }

        return subscription;
      });

      setLoading(true);
      let isSuccess = true;

      try {
        const result = await updateSubscriptions(subscriptionsNew);
        isSuccess = result.success ?? false;
      } catch (error) {
        isSuccess = false;
      } finally {
        const subscriptionStatus = await getSubscriptions(mapNewsletterSubscriptions(newsletters));
        setSubscriptions(subscriptionStatus);
        setLoading(false);

        if (!(isUnsubscription && Object.values(settings).every((v) => v === false))) {
          pushModal({
            title: labels.modalApproveHeadline,
            id: 'newsletter-subscribe',
            onClose: removeModal,
            content: (
              <div>
                {isSuccess ? <div>{labels.modalApproveDescription}</div> : formatErrorMessage({ id: 'wentWrong' })}
                <Block marginTop={4}>
                  <Button onClick={removeModal} size="small" label={labels.modalApproveButtonLabel} />
                </Block>
              </div>
            ),
          });
        }
      }
    },
    [subscriptions],
  );

  useEffect(() => {
    async function getSubscriptionStatus() {
      const subscriptionStatus = await getSubscriptions(mapNewsletterSubscriptions(newsletters));
      setSubscriptions(subscriptionStatus);
    }

    getSubscriptionStatus();
  }, []);

  if (subscriptions.length === 0) {
    return null;
  }

  return (
    <div className={styles.itemWrap}>
      {loading && <LoadingIndicatorInline color="blue" />}
      <Block marginTop={8} marginBottom={8}>
        <Typography weight="semi-bold" size="xl">
          <Markdown text={labels.headline} textSize={labels.textSize} disableMargin />
        </Typography>
      </Block>
      {newsletters.map((newsletter, index) => {
        const isActive = isNewsletterSubscribed(newsletter.name, subscriptions);
        const items = (
          <div key={`newsletter-items-${newsletter.id}`} className={styles.item}>
            <NewsletterSignupItemsItem
              labels={labels}
              description={newsletter.description}
              image={newsletter?.image}
              isActive={isActive}
              onChange={() => handleChange(newsletter, isActive)}
            />
          </div>
        );

        if (!isEmilio || (isEmilio && newsletter.name === 'Emilio_NL_Anmeldungen')) {
          return items;
        }
      })}
    </div>
  );
};
