import { OutfitDetailsPage } from '@engbers/components/online-shops/outfit-details-page';

export const OutfitDetailsPageTastic = ({ data }) => {
  return (
    <OutfitDetailsPage
      outfit={data?.data?.dataSource?.product}
      goToProductLabel={data.goToProductLabel}
      sizesLabel={data.sizesLabel}
      sizesUnselectedMessage={data.sizesUnselectedMessage}
      addToCartBtnLabel={data.addToCartBtnLabel}
      shippingCostsNote={data.shippingCostsNote}
      fallbackImage={data?.data?.dataSource?.fallBackImage}
    />
  );
};
