import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import { useRouter } from 'next/router';
import { Button } from '@engbers/components/button';
import { InputText } from '@engbers/components/shopmacher-ui/input-text';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { getReferenceTarget } from '@frontastic-engbers/helpers/reference';
import { useNewsletter } from '@frontastic-engbers/lib/actions/account/useNewsletter';
import { useToastNotificationsActions } from '@frontastic-engbers/lib/state/notification/actions';
import { IFTLinkReference, IFTPageFolderReference } from '@frontastic-engbers/types/engbers-custom';
import { NewsletterResubscriptionGroup } from './components/newsletter-resubscription-group';
import { GroupSelectionState, MailingListGroup } from './types';
import styles from './newsletter-resubscription.module.scss';

interface INewsletterResubscriptionComponent {
  groups: MailingListGroup[];
  labels?: Record<string, string>;
}

export const NewsletterResubscription: React.FC<INewsletterResubscriptionComponent> = ({ groups, labels }) => {
  const [isInitializing, setIsInitializing] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [groupSelectionState, setGroupSelectionState] = useState<GroupSelectionState[]>([]);
  const [email, setEmail] = useState<string>('');
  const [showPlaceholder, setShowPlaceholder] = useState<boolean>(true);
  const [doubleBorder, setDoubleBorder] = useState<boolean>(false);

  const { registerNewsletterByEmail } = useNewsletter();
  const { formatMessage } = useFormat({ name: 'common' });
  const { formatMessage: errorFormatMessage } = useFormat({ name: 'error' });
  const { pushNotification } = useToastNotificationsActions();
  const router = useRouter();

  const getGroupState = useCallback(
    (group: MailingListGroup): GroupSelectionState => {
      return groupSelectionState.find((groupState) => groupState.groupId === group.type);
    },
    [groupSelectionState],
  );

  const getSelectedMailingLists = useCallback((): Array<string | number> => {
    return groupSelectionState.find((groupState) => groupState.isSelected)?.selectedLists ?? [];
  }, [groupSelectionState]);

  const getRedirectLink = useCallback((): IFTPageFolderReference | IFTLinkReference => {
    const selectedGroup = groupSelectionState.find((groupState) => groupState.isSelected)?.groupId;
    const redirectLink = groups.find((group) => group.type === selectedGroup)?.redirectLink;
    return redirectLink ?? ({
      type: 'link',
      link: '/',
      openInNewWindow: false 
    } as IFTLinkReference);
  }, [groupSelectionState]);

  const updateGroupSelection = (groupId: string, selectedLists: Array<string | number>): void => {
    const newGroupStates = [];
    for (const groupState of groupSelectionState) {
      newGroupStates.push(
        groupState.groupId === groupId
          ? {
              groupId,
              selectedLists,
              isSelected: true 
            }
          : {
              ...groupState,
              isSelected: false 
            },
      );
    }
    setGroupSelectionState(newGroupStates);
  };

  const handlePlaceholder = () => {
    if (email) {
      setShowPlaceholder(false);
    } else {
      setShowPlaceholder(!showPlaceholder);
    }

    setDoubleBorder(!doubleBorder);
  };

  const validate = (): boolean => {
    return email.trim().length > 0 && email.indexOf('@') > 0 && getSelectedMailingLists().length > 0;
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (validate()) {
      const mailingLists = getSelectedMailingLists();

      setIsLoading(true);

      const result = await registerNewsletterByEmail({
        email,
        subscriptions: mailingLists.map((id) => ({ id: +id })),
      });

      if (result.success) {
        router.push(getReferenceTarget(getRedirectLink()));
        return;
      }

      pushNotification(errorFormatMessage({ id: 'wentWrongContact' }), 'error');

      setIsLoading(false);
    }
  };

  useEffect(() => {
    setGroupSelectionState(
      groups.map((group) => ({
        groupId: group.type,
        isSelected: false,
        selectedLists: group.mailingLists
          .filter((list) => list.isPreselected || group.mailingLists.length === 1)
          .map((list) => list.mailingListId),
      })),
    );
    setIsInitializing(false);
  }, []);

  if (groups?.length === 0 || isInitializing) {
    return null;
  }

  return (
    <div className={styles.newsletterResubscriptionWrapper}>
      <form className={styles.newsletterResubscriptionForm} onSubmit={handleSubmit}>
        <label className={classnames(styles.optionsLabel, styles.required)}>
          {labels?.options ?? formatMessage({
            id: 'option',
            defaultMessage: 'Option' 
          })}
        </label>
        <div className={styles.optionsWrapper}>
          {groups.map((group) => (
            <NewsletterResubscriptionGroup
              key={`group-${group.type}`}
              group={group}
              currentState={getGroupState(group)}
              updateGroupSelection={updateGroupSelection}
            />
          ))}
        </div>

        <label htmlFor="email" className={classnames(styles.label, styles.required)}>
          {formatMessage({
            id: 'email',
            defaultMessage: 'E-Mail' 
          })}
        </label>
        <InputText
          style={'white'}
          id="email"
          name="email"
          type="email"
          value={email}
          autoComplete="email"
          onChange={handleEmailChange}
          onFocus={handlePlaceholder}
          onBlur={handlePlaceholder}
          inputCustomStyle={{ padding: '0 8px' }}
          wrapperCustomStyle={{ outline: doubleBorder ? '1px solid #879097' : undefined }}
          placeholder={labels?.email}
          placeholderCustomStyle={{ display: !showPlaceholder ? 'none' : undefined }}
          required
        />

        <span className={classnames(styles.label, styles.mandatory)}>
          {formatMessage({
            id: 'mandatoryFields',
            defaultMessage: '* Pflichtfelder' 
          })}
        </span>
        <Button
          label={
            isLoading
              ? labels?.processing ?? formatMessage({
                id: 'pleaseWait',
                defaultMessage: 'Bitte warten' 
              })
              : labels?.submit ?? formatMessage({
                id: 'submit',
                defaultMessage: 'Abschicken' 
              })
          }
          type="cta"
          size="large"
          buttonType="submit"
          isLoading={isLoading}
          disabled={!validate()}
          className={styles.submitSubscriptionButton}
        />
      </form>
    </div>
  );
};
