import React, { useState } from 'react';
import { useRouter } from 'next/router';
import classnames from 'classnames';
import { useRecoilState } from 'recoil';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { getProjectInfo } from '@frontastic-engbers/lib';
import { isCheckoutSubmittedState } from '@frontastic-engbers/lib/state/checkoutErrors/atoms';
import {
  CheckoutData,
  CheckoutError,
  CheckoutErrorsType,
  NewsletterCheckbox,
} from '@frontastic-engbers/types/engbers-custom';
import { Block, InputCheckbox, Markdown } from '@engbers/components';
import styles from './cta-newsletter-overview.module.scss';

type ValidationErrors = {
  userAgbAccepted: boolean;
};

export interface ICtaNewsletterOverview {
  ctaSection: {
    submitBtnLabel: string;
    enNewsletterLabel: string;
    emilioNewsletterLabel: string;
    xxlNewsletterLabel: string;
    labelAgb: string;
    ctaCheckoutError: CheckoutError;
  };
  checkoutData: CheckoutData;
  checkoutErrors: CheckoutErrorsType;
  updateFormInput: (propName: string, newValue: NewsletterCheckbox | boolean) => void;
  onSubmit: () => void;
  disableSubmitButton: boolean;
  loggedIn: boolean;
}

export const CtaNewsletterOverview: React.FC<ICtaNewsletterOverview> = ({
  ctaSection,
  updateFormInput,
  onSubmit,
  checkoutData,
  checkoutErrors,
  disableSubmitButton,
  loggedIn,
}) => {
  const router = useRouter();
  const [, setIsCheckoutSubmitted] = useRecoilState(isCheckoutSubmittedState);
  const [errors, setErrors] = useState<Partial<ValidationErrors>>({});
  const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });
  const isEmilio = getProjectInfo()?.projectId === 'ea';

  const handleNewsletterCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateFormInput('newsletter', {
      ...checkoutData.newsletter,
      [e.target.name]: e.target.checked,
    });
  };

  const checkAutoFilledInputsBeforeSubmit = (inputs) => {
    inputs.forEach((input) => {
      setTimeout(() => {
        input?.focus();
        input?.blur();
      }, 1);
    });
  };

  const handleSubmit = () => {
    setIsCheckoutSubmitted(true);

    setErrors((errors) => ({
      ...errors,
      userAgbAccepted: checkoutData.userAgbAccepted,
    }));

    const filteredErrors = checkoutErrors
      ? Object.keys(checkoutErrors).filter((key) => !checkoutErrors[key].isValid)
      : null;

    if (disableSubmitButton || filteredErrors?.length) {
      const element = checkoutErrors[filteredErrors[0]]?.section;
      const inputs = element?.querySelectorAll('input:-webkit-autofill');

      if (!checkoutErrors['shippingAddress']?.isValid) {
        updateFormInput('isUnselectedShippingAddress', true);
      }

      element?.scrollIntoView({ behavior: 'smooth' });

      if (filteredErrors[0] === 'billingAddress' && inputs) {
        checkAutoFilledInputsBeforeSubmit(inputs);
      }
    } else {
      onSubmit();
    }
  };

  return (
    <div className={styles.ctaSectionWrap}>
      <div className={styles.ctaSection}>
        {!loggedIn || router.query?.isNew || router.query?.isGuest ? (
          <>
            {!isEmilio && (
              <Block marginBottom={4}>
                <InputCheckbox
                  id="engbers_newsletter"
                  name="enNewsletterAccepted"
                  label={<Markdown text={ctaSection.enNewsletterLabel} className={styles.labelHasNoMargin} />}
                  onChange={handleNewsletterCheckboxChange}
                  hasHoverEffect={true}
                />
              </Block>
            )}
            <Block marginBottom={4}>
              <InputCheckbox
                id="emilio_newsletter"
                name="emilioNewsletterAccepted"
                label={<Markdown text={ctaSection.emilioNewsletterLabel} className={styles.labelHasNoMargin} />}
                onChange={handleNewsletterCheckboxChange}
                hasHoverEffect={true}
              />
            </Block>
            {!isEmilio && (
              <Block marginBottom={4}>
                <InputCheckbox
                  id="xxl_newsletter"
                  name="xxlNewsletterAccepted"
                  label={<Markdown text={ctaSection.xxlNewsletterLabel} className={styles.labelHasNoMargin} />}
                  onChange={handleNewsletterCheckboxChange}
                  hasHoverEffect={true}
                />
              </Block>
            )}
          </>
        ) : (
          <Block marginBottom={4}>
            <InputCheckbox
              id="user_agb_accepted"
              name="userAgbAccepted"
              label={<Markdown linkToNewTab text={ctaSection.labelAgb} className={styles.labelHasNoMargin} />}
              onChange={(e) => {
                updateFormInput('userAgbAccepted', e.target.checked);
                setErrors((errors) => ({
                  ...errors,
                  userAgbAccepted: e.target.checked,
                }));
              }}
              errorMessage={errors.userAgbAccepted === false ? formatErrorMessage({ id: 'mandatory' }) : undefined}
              hasHoverEffect={true}
            />
          </Block>
        )}

        <button
          className={classnames('cta', styles.submitBtn, disableSubmitButton ? styles.isDisabled : undefined)}
          onClick={handleSubmit}
        >
          {ctaSection.submitBtnLabel}
        </button>
        {ctaSection.ctaCheckoutError && (
          <div className={styles.ctaError}>
            {ctaSection.ctaCheckoutError.code === 'OutOfStock'
              ? formatErrorMessage({
                  id: 'checkoutOutOfStock',
                  values: {
                    skus: ctaSection.ctaCheckoutError.skus.toString(),
                  },
                })
              : formatErrorMessage({ id: 'wentWrong' })}
          </div>
        )}
      </div>
    </div>
  );
};
