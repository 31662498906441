import { useHits } from 'react-instantsearch';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import styles from './current-refinements.module.scss';

export const CurrentRefinements = () => {
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  const { results } = useHits();

  return (
    <div className={styles.currentRefinements}>
      <span>
        {results?.nbSortedHits ?? 0} {formatProductMessage({ id: 'products', defaultMessage: 'Produkte' })}
      </span>
    </div>
  );
};
