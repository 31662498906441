import React from 'react';
import CategoryTeasers, { CategoryTeaser } from '@engbers/components/online-shops/commercetools-ui/category-teasers';
import { FullPageWidthWrapper } from '@engbers/components/online-shops';

type Props = {
  categoryTeasers: Array<CategoryTeaser>;
  isFullWidth?: boolean;
};

const ContentCategoriesTastic = ({ data }: { data: Props }) => {
  const { categoryTeasers } = data;

  if (data.isFullWidth) {
    return (
      <FullPageWidthWrapper>
        <CategoryTeasers items={categoryTeasers} />
      </FullPageWidthWrapper>
    );
  }

  return <CategoryTeasers items={categoryTeasers} />;
};

export default ContentCategoriesTastic;
