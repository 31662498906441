import React from 'react';

const StyleGuideGrid: React.FC = () => (
  <>
    <div className="mb-10 border border-solid border-slate-300 dark:bg-encom-900 dark:text-white">
      <h2 className="my-4 mx-6 dark:text-white">Grid & Container</h2>
      <div className="my-3 flex-col justify-start border-t border-solid border-slate-300 p-6 align-middle">
        <div>xs = 0px</div>
        <div>sm = 576px</div>
        <div>md = 768px</div>
        <div>lg = 1024px</div>
        <div>xl = 1280px</div>
        <div>2xl = 1536px</div>
        <div className="pt-3">
          Break point names can be used in class names like
          <code className="bg-encom-100 p-1">{'className="flex md:flex-col"'}</code>
        </div>
      </div>
    </div>

    <div className="mb-10 border border-solid border-slate-300 dark:bg-encom-900 dark:text-white">
      <h2 className="my-4 mx-6 dark:text-white">Spacing</h2>
      <div className="my-3 flex-col justify-start border-t border-solid border-slate-300 p-6 align-middle">
        <div className="pb-4">
          <h4 className="dark:text-white">4-point-grid</h4>
          <div className="pt-3">
            Use ‘increments of 4’ to size and space out the elements on a page. any defined height or width should be
            divisible by 4, including padding, margins and line heights.
            <br />
            Using an even number to size and space out components is more usable. for example, devices that have a 1.5x
            resolution will have a hard time cleanly rendering an odd number.
          </div>
          <div className="pt-3">
            Spacer can be used in class names like
            <code className="bg-encom-100 p-1">{'className="p-4 mt-10"'}</code>
            This equals to
            <code className="bg-encom-100 p-1">{'padding: 12px; margin-top: 40px;'}</code>
          </div>
        </div>
        <div>0 = 0px</div>
        <div>1 = 4px</div>
        <div>2 = 8px</div>
        <div>3 = 12px</div>
        <div>4 = 16px</div>
        <div>5 = 20px</div>
        <div>6 = 24px</div>
        <div>7 = 28px</div>
        <div>8 = 32px</div>
        <div>9 = 36px</div>
        <div>10 = 40px</div>
        <div>.... etc</div>
        <div>40 = 160px</div>
      </div>
    </div>
  </>
);

export default StyleGuideGrid;
