import { MyVouchers } from '@engbers/components/my-account/my-vouchers';

export const MyVouchersTastic = ({ data }) => {
  return (
    <MyVouchers
      headlineText={data.headlineText}
      textSize={data.textSize}
      headlineIcon={data.headlineIcon}
      items={data.tabItems}
    />
  );
};
