import React, { ReactNode, useCallback, useState } from 'react';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { MediaType } from '@frontastic-engbers/types/engbers-custom';
import { IconCustom, Image, Markdown } from '@engbers/components';
import { useImageSEO } from '@frontastic-engbers/helpers/hooks/useImageSEO';
import styles from './accordion-item.module.scss';

export interface IAccordionItem {
  label: string;
  textSize?: number;
  icon?: MediaType;
  iconWidth?: number;
  headerPadding?: number;
  headerFontSize?: number;
  borderColor?: string;
  children: ReactNode;
  isInitiallyOpen?: boolean;
}

const openVariants = {
  initial: {
    height: 0,
    opacity: 0,
  },
  animate: {
    height: 'auto',
    opacity: 1,
  },
  exit: {
    height: 0,
    opacity: 0,
  },
};

export const AccordionItem: React.FC<IAccordionItem> = ({
  label,
  textSize,
  icon,
  iconWidth = null,
  headerPadding,
  headerFontSize,
  borderColor,
  children,
  isInitiallyOpen,
}) => {
  const { getTitle } = useImageSEO();
  const [isOpen, setIsOpen] = useState(isInitiallyOpen || false);
  const toggleOpen = useCallback(() => {
    setIsOpen((prevState) => !prevState);
  }, []);
  const onClickContent = useCallback((event) => {
    event.stopPropagation();
    return false;
  }, []);

  return (
    <div
      className={styles.wrap}
      style={{
        marginBottom: headerPadding ? headerPadding : undefined,
        borderColor: borderColor ? borderColor : undefined,
      }}
    >
      <div
        className={styles.header}
        onClick={toggleOpen}
        style={{
          padding: headerPadding ? headerPadding : undefined,
          paddingBottom: headerPadding ? 0 : undefined,
          fontSize: headerFontSize ? `${headerFontSize}px` : undefined,
        }}
      >
        <div className={styles.headerIconWrap}>
          {icon?.media && (
            <Image src={icon.media.file} alt={getTitle(icon)} title={getTitle(icon)} width={iconWidth || 30} />
          )}
          <Markdown text={label} textSize={textSize} disableMargin />
        </div>
        <div
          className={classNames(styles.chevronIconWrap, {
            [styles[`chevronIconWrap--is-active`]]: isOpen,
          })}
        >
          <IconCustom width={14} icon="ChevronRightIcon" />
        </div>
      </div>
      <AnimatePresence>
        <motion.div
          variants={openVariants}
          initial={'initial'}
          animate={'animate'}
          exit={'exit'}
          onClick={onClickContent}
          className={classNames(styles.content, {
            [styles[`isActive`]]: isOpen,
          })}
        >
          {children}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};
