import { EcondaSlider } from '@engbers/components/online-shops/econda/econda-slider';
import { EcondaWidgetCredentials } from '@frontastic-engbers/types/engbers-custom';

export const EcondaSliderTastic = ({ data }) => {
  const econdaWidgetConfiguration: EcondaWidgetCredentials = data?.econdaWidgetConfiguration?.dataSource.credentials;

  return (
    <EcondaSlider
      config={econdaWidgetConfiguration}
      widgetId={data.widgetId}
      title={data.title}
      textSize={data.textSize}
      isStartPage={data.isStartPage}
    />
  );
};
