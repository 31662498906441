import React, { ChangeEvent, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import isoAlpha2Data from '@frontastic-engbers/helpers/utils/iso-alpha2-data';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { Address } from '@frontastic-engbers/types/account/Address';
import { PackstationInput, PackstationLocationResult } from '@frontastic-engbers/types/engbers-custom';
import { IconCustom, InputCheckbox, InputText } from '@engbers/components/index';
import styles from './packstation-result.module.scss';

interface IPackStationResult {
  location: PackstationLocationResult;
  select: (id: string, postNo: string, checked: boolean) => void;
  shippingAddress: Address;
  errorPostNo: string;
  labelPostNo: string;
  labelSelect: string;
  usePackstationInputs: {
    packstationInputs: { [key: string]: PackstationInput };
    setPackstationInputs: React.Dispatch<React.SetStateAction<{ [key: string]: PackstationInput }>>;
  };
}

export const PackStationResult: React.FC<IPackStationResult> = ({
  location,
  select,
  shippingAddress,
  errorPostNo,
  labelPostNo,
  labelSelect,
  usePackstationInputs,
}) => {
  const [isMinWidthMedium, setIsMinWidthMedium] = useState<boolean>(false);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const { formatMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });
  const { packstationInputs, setPackstationInputs } = usePackstationInputs;

  useEffect(() => {
    if (isMobile !== isMinWidthMedium) {
      setIsMinWidthMedium(isMobile);
    }
  }, [isMobile]);

  const handleSelect = (id: string, checked: boolean) => {
    if (!checked) {
      select(id, packstationInputs[location.id]?.postNo, checked);
      return;
    }

    if (id === shippingAddress?.streetNumber || !validate()) {
      return;
    }

    select(id, packstationInputs[location.id]?.postNo, checked);
  };

  const updatePostNo = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.id === shippingAddress?.streetNumber) {
      select(e.target.id, e.target.value, true);
    }
  };

  const updateInput = (key: string, value: string | boolean) => {
    setPackstationInputs({
      ...packstationInputs,
      [location.id]: {
        ...packstationInputs[location.id],
        [key]: value,
      },
    });
  };

  const validate = () => {
    const postNo = packstationInputs[location.id]?.postNo;

    if (!postNo || postNo.length < 6 || postNo.length > 10) {
      updateInput('hasError', true);
      return false;
    }

    updateInput('hasError', false);
    return true;
  };

  return (
    <div className={styles.location}>
      <div className={styles.locator}>
        <IconCustom icon={'MapMarker'} width={30} />
      </div>
      <div className={styles.locationMain}>
        <div className={styles.locationInfo}>
          <span className={styles.locationName}>{location.name}</span>
          {location.description && <span>{location.description}</span>}
          <span>{location.street}</span>
          <span>
            {location.zip} {location.city}
          </span>
          {isoAlpha2Data[location.country] && <span>{isoAlpha2Data[location.country]}</span>}
        </div>

        <InputText
          type={'number'}
          name={'postNo'}
          id={location.id}
          placeholder={labelPostNo && labelPostNo !== ''
            ? labelPostNo
            : formatMessage({ id: 'postNo' })}
          required
          value={packstationInputs[location.id]?.postNo}
          onChange={(e) => {
            updateInput('postNo', e.target.value);
            updatePostNo(e);
          }}
          successValidation
          hasError={packstationInputs[location.id]?.hasError}
          errorMessage={
            packstationInputs[location.id]?.hasError
              ? errorPostNo !== ''
                ? errorPostNo
                : formatErrorMessage({ id: 'postNo' })
              : undefined
          }
          onBlur={validate}
          styleNumberAsTextInput
        />
      </div>
      <InputCheckbox
        label={isMinWidthMedium
          ? formatMessage({ id: 'select' })
          : labelSelect !== ''
            ? labelSelect
            : undefined}
        name={'selectedLocation'}
        id={location.id}
        onChange={(e) => handleSelect(location.id, e.target.checked)}
        checked={shippingAddress && shippingAddress.streetNumber === location.id
          ? true
          : false}
        swapLabelPosition={true}
      />
    </div>
  );
};
