import { useEffect } from 'react';
import { Button, CustomMarkdown } from '@engbers/components';

interface IRedirectModal {
  redirectUrl: string;
  infoText: string;
  label: string;
}

export const RedirectModal: React.FC<IRedirectModal> = ({
  redirectUrl,
  infoText,
  label,
}) => {
  const redirect = () => {
    window.location.href = redirectUrl;
  }

  useEffect(() => {
    setTimeout(() => redirect(), 5000);
  }, []);

  return (
    <>
      <div className="pb-4">
        <CustomMarkdown text={infoText} />
      </div>
      <Button label={label} onClick={redirect}/>
    </>
  );
};
