export const ShoppingBagIcon = () => {
  return (
    <svg width="500" height="500" viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M397.82 379.13L381.97 133.13C381.7 126.77 376.31 121.88 370.04 121.88H154.85C148.57 121.88 143.36 126.77 142.96 133.13L127.07 379.77C126.84 383.07 128.07 386.37 130.24 388.67C132.55 390.98 135.72 392.33 138.98 392.33H385.94C386.05 392.33 386.05 392.33 386.05 392.33C392.63 392.33 397.98 387 397.98 380.49C397.99 380.05 397.99 379.64 397.82 379.13ZM331.84 177.96C326.11 225.75 297.82 260.93 262.48 260.93C227.14 260.93 198.83 225.75 193.07 177.96C186.51 174.74 181.74 168.02 181.74 160.07C181.74 148.98 190.76 139.96 202.01 139.96C213.07 139.96 222.09 148.98 222.09 160.07C222.09 167.07 218.4 172.79 213.23 176.53C218 212.63 238.66 240.81 262.49 240.81C286.47 240.81 306.98 212.63 311.91 176.53C306.58 172.79 302.88 167.08 302.88 160.07C302.88 148.98 311.91 139.96 323.11 139.96C334.2 139.96 343.27 148.98 343.27 160.07C343.25 168.03 338.56 174.75 331.84 177.96Z"
        fill="#8699AD"
      />
    </svg>
  );
};
