import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { useAccount } from '@frontastic-engbers/lib';
import { setPwaInstallStatus } from '@frontastic-engbers/lib/actions/account';
import { useModalActions } from '@frontastic-engbers/lib/state/modal/actions';
import { getReferenceTarget } from '@frontastic-engbers/helpers/reference';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { isStandaloneMode } from '@frontastic-engbers/helpers/utils/standaloneMode';
import { AccountMigrationLoginResponse, GetAccountResult } from '@frontastic-engbers/types/account/Account';
import { IFTLinkReference, IFTPageFolderReference } from '@frontastic-engbers/types/engbers-custom';
import { InputText } from '@engbers/components/shopmacher-ui/input-text';
import { Block, Button, IconCustom, Link } from '@engbers/components';
import { MigrationStepTwoModal } from '@engbers/components/migration-login-form/step-two-modal';
import { useToastNotificationsActions } from '@frontastic-engbers/lib/state/notification/actions';
import styles from './login-form.module.scss';

type LoginFormData = { email: string; password: string };

interface ILoginFormComponent {
  onLoginCallback?: (formData: LoginFormData, onResponse: (response: any) => void) => Promise<void>;
  onMigrationLoginSecondStepCallback?: (response: AccountMigrationLoginResponse) => Promise<void>;
  homepageRedirectLink?: IFTLinkReference | IFTPageFolderReference;
  firstTimeInShopLink?: IFTLinkReference | IFTPageFolderReference | undefined;
  passwordForgotLink?: IFTLinkReference | IFTPageFolderReference | undefined;
  doiNotificationLink?: IFTLinkReference | IFTPageFolderReference | undefined;
  isEngbersOS?: boolean;
  labels: {
    emailTooltipHeadline?: string;
    emailTooltipContent?: string;
    passwordTooltipContent?: string;
    passwordTooltipHeadline?: string;
    passwordForgottenTooltipContent?: string;
    passwordForgottenTooltipHeadline?: string;
    emailInputPlaceholder?: string;
    passwordInputPlaceholder?: string;
    loginButtonLabel?: string;
    passwordForgotLinkLabel?: string;
    firstTimeInShopLabel?: string;
    migrationVerifyInformation?: string;
    migrationVerifyBirthdate?: string;
  };
}

export const LoginForm: React.FC<ILoginFormComponent> = ({
  onLoginCallback,
  onMigrationLoginSecondStepCallback,
  homepageRedirectLink,
  firstTimeInShopLink = undefined,
  passwordForgotLink = undefined,
  doiNotificationLink = undefined,
  isEngbersOS = false,
  labels,
}) => {
  const {
    emailTooltipHeadline,
    emailTooltipContent,
    passwordTooltipContent,
    passwordTooltipHeadline,
    passwordForgottenTooltipContent,
    passwordForgottenTooltipHeadline,
    emailInputPlaceholder,
    passwordInputPlaceholder,
    loginButtonLabel,
    passwordForgotLinkLabel,
    firstTimeInShopLabel,
    migrationVerifyInformation,
    migrationVerifyBirthdate,
  } = labels;

  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });

  const { pushNotification } = useToastNotificationsActions();
  const { login } = useAccount();
  const router = useRouter();
  const { pushModal, removeModal } = useModalActions();
  const cardId = typeof router.query.cardId === 'string' ? router.query.cardId : '';

  const [loading, setLoading] = useState(false);
  const [redirecting, setRedirecting] = useState(false);
  const [error, setError] = useState('');
  // TODO: As soon as we want this feature enabled, set to false
  const [showPasswordField, setShowPasswordField] = useState(true);
  const [loginError, setLoginError] = useState('');
  const [formData, setFormData] = useState<LoginFormData>({
    email: cardId,
    password: '',
  });
  const [isMinWidthMedium, setIsMinWidthMedium] = useState<boolean>(false);
  const isMobile = useMediaQuery({ maxWidth: 1024 });

  useEffect(() => {
    if (isMobile !== isMinWidthMedium) {
      setIsMinWidthMedium(isMobile);
    }
  }, [isMobile]);

  const onLogin = useCallback(
    async (formData, onResponse) => {
      const response = await login(formData.email, formData.password);
      onResponse(response);
      if (response.loggedIn && homepageRedirectLink) {
        if (isStandaloneMode()) {
          setPwaInstallStatus();
        }
        setRedirecting(true);
        router.push(getReferenceTarget(homepageRedirectLink));
      }
    },
    [login, router, homepageRedirectLink],
  );

  const onLoginResponse = useCallback(
    (response: GetAccountResult) => {
      try {
        if (response.status === 'MIGRATION_LOGIN') {
          setError('');
          // TODO: As soon as we want this feature enabled, comment int
          // setShowPasswordField(false);
          setFormData((prev) => ({
            ...prev,
            password: '',
          }));

          pushModal({
            id: 'migration-login-step-2',
            title: formatErrorMessage({ id: 'securityQuery' }),
            content: (
              <MigrationStepTwoModal
                loading={loading || redirecting}
                onMigrationLoginSecondStepCallback={onMigrationLoginSecondStepCallback}
                cardId={response.account?.cardId}
                migrationVerifyInformation={migrationVerifyInformation}
                migrationVerifyBirthdate={migrationVerifyBirthdate}
              />
            ),
          });
          return;
        }

        if (!showPasswordField && !formData.password) {
          setShowPasswordField(true);
          return;
        }

        if (response.status === 'INVALID_PASSWORD' && response.failedAttempts) {
          const remainingAttempts = 10 - response.failedAttempts;

          pushNotification(
            formatAccountMessage({
              id: 'password.remainingAttempts',
              defaultMessage:
                'You have {remainingAttempts} attempts left. Please use the "Forgotten password" function',
              values: { remainingAttempts: remainingAttempts },
            }),
            'error',
          );

          return;
        }
        if (response.status === 'CUSTOMER_IS_BLOCKED') {
          pushNotification(
            formatAccountMessage({
              id: 'account.blocked',
              defaultMessage:
                'You have entered the wrong combination of email/customer number and password too often. We have sent you a reactivation link by email. Please check your mails to be able to access your account.',
            }),
            'error',
          );
          setError('');
          return;
        }
        if (response.status === 'EMAIL_NOT_VERIFIED') {
          if (!doiNotificationLink) {
            setError(formatErrorMessage({ id: 'emailNotVerified' }));
            return;
          }
          setRedirecting(true);
          router.push(getReferenceTarget(doiNotificationLink));
          return;
        }
        if (response.status === 'INVALID_CREDENTIALS') {
          setError(formatErrorMessage({ id: 'checkLoginData' }));
          return;
        }
        if (response.status === 'UNKNOWN_ERROR') {
          setError(formatErrorMessage({ id: 'wentWrongContact' }));
          return;
        }
      } catch (err) {
        setError(formatErrorMessage({ id: 'wentWrongContact' }));
        return;
      }
    },
    [setError, showPasswordField, formData.password],
  );

  //login user
  const loginUser = async () => {
    if (typeof onLoginCallback === 'function') {
      return onLoginCallback(formData, onLoginResponse);
    }
    await onLogin(formData, onLoginResponse);
  };

  //form submission
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    //processing starts
    setLoading(true);
    //if user wants to login
    await loginUser();
    //processing ends
    setLoading(false);
  };

  //handle text input change
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    setLoginError(null);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Block
          marginBottom={isEngbersOS ? 4 : 2}
          customStyle={{
            position: 'relative',
            width: '100%',
          }}
        >
          <div className={styles.inputWrap}>
            <InputText
              id="email"
              name="email"
              type="text"
              onChange={handleChange}
              placeholder={emailInputPlaceholder || formatAccountMessage({ id: 'emailOrCustomerNumber' })}
              value={formData.email}
              errorMessage={error}
              hasError={!!error}
              wrapperCustomStyle={{ width: '100%' }}
              useInfoModal
              infoTextHeadline={emailTooltipHeadline ?? ''}
              infoModalHasOkayButton
              infoText={emailTooltipContent ?? ''}
            />
          </div>
        </Block>
        {
          <Block
            className={classNames(styles.passwordBlock, {
              [styles.passwordBlockIsVisible]: showPasswordField || formData.password,
            })}
            marginBottom={isEngbersOS ? 4 : 2}
            customStyle={{ position: 'relative' }}
          >
            <div className={styles.inputWrap}>
              <InputText
                id="password"
                name="password"
                hasError={!!error}
                type="password"
                onChange={handleChange}
                placeholder={passwordInputPlaceholder || formatAccountMessage({ id: 'password' })}
                value={formData.password}
                wrapperCustomStyle={{ width: '100%' }}
                useInfoModal
                infoTextHeadline={passwordTooltipHeadline}
                infoModalHasOkayButton
                infoText={passwordTooltipContent ?? ''}
              />
            </div>
          </Block>
        }
        {!isEngbersOS ? (
          <>
            <Block marginBottom={2}>
              <Button
                size="large"
                isLoading={loading || redirecting}
                onClick={handleSubmit}
                label={loginButtonLabel || formatAccountMessage({ id: 'sign.in' })}
              />
            </Block>
            <Block marginLeft={isEngbersOS ? 0 : 3} marginRight={isEngbersOS ? 0 : 3}>
              <Block marginBottom={isEngbersOS ? 0 : 2}>
                {passwordForgotLink && (
                  <div className={styles.infoLinkWrap}>
                    <Link reference={passwordForgotLink}>
                      {passwordForgotLinkLabel || formatAccountMessage({ id: 'password.forgot' })}
                    </Link>
                    <div
                      onClick={() => {
                        pushModal({
                          title: passwordForgottenTooltipHeadline ?? '',
                          hasCloseButton: true,
                          hasOkayButton: true,
                          content: (
                            <div className={styles.infoModalContent}>
                              <ReactMarkdown>{passwordForgottenTooltipContent ?? ''}</ReactMarkdown>
                            </div>
                          ),
                          id: 'input-modal',
                          onClose: () => {
                            removeModal();
                          },
                        });
                      }}
                    >
                      <IconCustom width={22} icon="Info" />
                    </div>
                  </div>
                )}
              </Block>
            </Block>
          </>
        ) : (
          <Block
            customStyle={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexDirection: isMinWidthMedium ? 'column' : 'row-reverse',
            }}
          >
            <div style={{ order: isMinWidthMedium ? 2 : 1 }}>
              {passwordForgotLink && (
                <div className={styles.infoLinkWrap}>
                  <Link reference={passwordForgotLink}>
                    {passwordForgotLinkLabel || formatAccountMessage({ id: 'password.forgot' })}
                  </Link>
                  <div
                    onClick={() => {
                      pushModal({
                        title: passwordForgottenTooltipHeadline ?? '',
                        hasCloseButton: true,
                        hasOkayButton: true,
                        content: (
                          <div className={styles.infoModalContent}>
                            <ReactMarkdown>{passwordForgottenTooltipContent ?? ''}</ReactMarkdown>
                          </div>
                        ),
                        id: 'input-modal',
                        onClose: () => {
                          removeModal();
                        },
                      });
                    }}
                  >
                    <IconCustom width={22} icon="Info" className="ml-2" />
                  </div>
                </div>
              )}
              {firstTimeInShopLabel && firstTimeInShopLink ? (
                <Link reference={firstTimeInShopLink}>{firstTimeInShopLabel}</Link>
              ) : null}
            </div>
            <div className={styles.btnWrap}>
              <Button
                buttonType="submit"
                size="large"
                isLoading={loading || redirecting}
                onClick={handleSubmit}
                label={loginButtonLabel || formatAccountMessage({ id: 'sign.in' })}
                customStyle={{
                  paddingLeft: '60px',
                  paddingRight: '60px',
                  order: isMinWidthMedium ? 1 : 3,
                  marginBottom: isMinWidthMedium ? '16px' : undefined,
                  width: isMinWidthMedium ? '100%' : undefined,
                }}
              />
              {/* <span className={styles.loginError}>{loginError}</span> */}
            </div>
          </Block>
        )}
      </form>
    </>
  );
};
