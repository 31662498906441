import React from 'react';
import classnames from 'classnames';
import { useRefinementList } from 'react-instantsearch';
import { IFacetProps } from './types';
import styles from './facets.module.scss';
import { handleFilterDataLayer } from '@frontastic-engbers/helpers/dataLayerHelper';

const ColorFacet: React.FC<IFacetProps> = ({ label, attribute, wrapper: Wrapper = React.Fragment, showItemCount = false }) => {
  const { items, refine } = useRefinementList({
    attribute,
    limit: 100,
  });

  return (
    <>
      {items.map((term) => (
        <Wrapper key={term.value + 'wrapper'}>
          <div className={styles.termFacetValue} key={term.value}>
            <input
              className={styles.checkbox}
              onChange={() => {
                refine(term.value);
                handleFilterDataLayer(items, label, term.value);
              }}
              id={term.value}
              name={term.value}
              checked={term.isRefined}
              type="checkbox"
            />
            <label className={styles.label} htmlFor={term.value}>
              <span className={classnames(styles.colorTile, term.value.toLowerCase())} />
              {term.label}{showItemCount && ` ({term.count.toString()})`}
            </label>
          </div>
        </Wrapper>
      ))}
    </>
  );
};

export default ColorFacet;
