import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { fetchApiHub, useCart } from '@frontastic-engbers/lib';
import { TagManager } from '@frontastic-engbers/lib/lib/tracking';
import { ItemMapper } from '@frontastic-engbers/lib/lib/tracking/itemMapper';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { checkLineItemDuplicates } from '@frontastic-engbers/helpers/utils/checkLineItemDuplicates';
import { getSKUParts } from '@frontastic-engbers/helpers/utils/getSKUParts';
import { sortSizes } from '@frontastic-engbers/helpers/utils/sizeSorting';
import { LineItem } from '@frontastic-engbers/types/cart/LineItem';
import { Money } from '@frontastic-engbers/types/product/Money';
import { Product } from '@frontastic-engbers/types/product/Product';
import { Variant } from '@frontastic-engbers/types/product/Variant';
import { SoldOutVariants } from '@frontastic-engbers/types/engbers-custom';
import Dropdown, { DropdownProps } from '@engbers/components/online-shops/commercetools-ui/dropdown';
import Spinner from '@engbers/components/online-shops/commercetools-ui/spinner';
import { IconCustom, Image, Link } from '@engbers/components';
import Price from '@engbers/components/online-shops/price';
import cartStyles from './cart.module.scss';
import styles from './line-item.module.scss';

interface Props {
  lineItem: LineItem;
  updateItemQuantity: (lineItem: LineItem, newQuantity: number) => Promise<void>;
  updateItemSize: (variant: Variant, quantity: number) => Promise<void>;
  deleteItem: (lineItem: LineItem) => Promise<void>;
  setIsUpdating: React.Dispatch<React.SetStateAction<boolean>>;
  setUpdatingLineItems: React.Dispatch<React.SetStateAction<boolean>>;
  shippingTime?: string;
  isCheckout: boolean;
  sizeSuggestion?: string;
  soldOutItems?: SoldOutVariants[];
}

const Item = ({
  lineItem,
  updateItemQuantity,
  updateItemSize,
  deleteItem,
  setIsUpdating,
  setUpdatingLineItems,
  shippingTime,
  isCheckout,
  sizeSuggestion,
  soldOutItems = [],
}: Props) => {
  const { data: cartList } = useCart();
  const [product, setProduct] = useState<Product>(null);
  const [sizeVariants, setSizeVariants] = useState<DropdownProps['items']>(null);
  const [emptyDropdown, setEmptyDropdown] = useState<boolean>(false);
  const { formatMessage } = useFormat({ name: 'cart' });
  const { formatMessage: productFormatMessage } = useFormat({ name: 'product' });
  const currentSize = lineItem.variant.attributes.Sizing || getSKUParts(lineItem.variant.sku).size;
  const currentColor = lineItem.variant.attributes.ColorName || lineItem.variant.attributes.BaseColor;
  const savedPrice: Money = lineItem.totalPrice && {
    fractionDigits: lineItem.price.fractionDigits,
    centAmount: lineItem.price.centAmount * lineItem.count - lineItem.totalPrice.centAmount,
    currencyCode: lineItem.price.currencyCode,
  };

  const amountLimit = lineItem.count >= 10 ? lineItem.count : 10;
  const quantityValue = (
    (lineItem.count <= lineItem.variant.quantity ? lineItem.count : lineItem.variant.quantity) +
    (lineItem.hiddenCount || 0)
  ).toString();

  const amountArray: DropdownProps['items'] = Array.from(
    { length: Math.min(lineItem.variant.quantity, amountLimit) },
    (_, i) => i + 1,
  )
    .filter((num) => num - (lineItem.hiddenCount || 0) >= 1)
    .map((num) => ({
      label: (num - (lineItem.hiddenCount || 0)).toString(),
      value: num.toString(),
    }));

  useEffect(() => {
    for (const soldOutVariant of soldOutItems) {
      if (soldOutVariant.sku === lineItem.variant.sku) {
        if (soldOutVariant.soldOut) {
          return setEmptyDropdown(true);
        }

        updateItemQuantity(lineItem, soldOutVariant.availableQuantity);
      }
    }
  }, [soldOutItems]);

  useEffect(() => {
    fetchApiHub(`/action/product/getProduct?sku=${lineItem.variant.sku}`).then((currentProduct: Product) => {
      setProduct(currentProduct);

      const availableSizeVariants = currentProduct?.variants
        ?.filter((variant) => variant.isInStock)
        .sort((a, b) => sortSizes({ value: a.attributes.Sizing }, { value: b.attributes.Sizing }))
        .map((variant) => {
          return {
            label: variant.attributes.Sizing || getSKUParts(variant.sku).size,
            value: variant.sku,
            disabled: !variant.quantity || variant.quantity <= 0,
          };
        });

      setSizeVariants(availableSizeVariants);
    });
  }, [lineItem]);

  const handleAddItem = async (sku: string) => {
    setUpdatingLineItems(true);
    setIsUpdating(true);
    const currentVariant = product?.variants?.find((variant: Variant) => variant.sku === sku);
    const removedVariant = product?.variants.find((variant) => variant.sku === lineItem?.variant?.sku);

    if (!currentVariant?.isInStock) {
      return setIsUpdating(false);
    }

    const quantity = lineItem.count <= currentVariant.quantity ? lineItem.count : currentVariant.quantity;
    const fractionDigits: number = currentVariant.price.fractionDigits ?? 2;
    const totalPriceAddedItem = Number(
      ((currentVariant.price.centAmount ?? 0) / 10 ** fractionDigits).toFixed(fractionDigits),
    );

    await updateItemSize(currentVariant, quantity);

    // prevent position split by discount being removed completely when changing size of the cloned position
    const duplicateCheck = checkLineItemDuplicates(cartList, lineItem);

    if (duplicateCheck.occurrences >= 2) {
      await updateItemQuantity(lineItem, duplicateCheck.count - quantity);
    } else {
      await deleteItem(lineItem);
    }

    new TagManager()
      .addToCartEvent([ItemMapper.productToItem(product, quantity, 1, currentVariant)], totalPriceAddedItem * quantity)
      .econdaCartEvent(product, currentVariant, 'econdaAddToCart', 'c_add', quantity)
      .econdaCartEvent(product, removedVariant, 'econdaRemoveFromCart', 'c_rmv', lineItem.count)
      .executePush();

    setIsUpdating(false);
    setUpdatingLineItems(false);
  };

  const handleUpdateQuantity = async (newQuantity: number) => {
    setUpdatingLineItems(true);
    await updateItemQuantity(lineItem, newQuantity);
    new TagManager()
      .econdaCartEvent(
        product,
        lineItem.variant,
        newQuantity > lineItem.count ? 'econdaAddToCart' : 'econdaRemoveFromCart',
        newQuantity > lineItem.count ? 'c_add' : 'c_rmv',
        newQuantity > lineItem.count ? newQuantity - lineItem.count : lineItem.count - newQuantity,
      )
      .executePush();
    setUpdatingLineItems(false);
  };

  const getDiscountDescription = (isMobile: boolean) => {
    if (lineItem.discountTexts?.length > 0) {
      return (
        <div className={classnames(styles.itemDiscountDescriptionWrap, isMobile ? 'md:hidden' : 'hidden md:block')}>
          {lineItem.discountTexts.map((discountDescription) => (
            <span key={discountDescription} className={styles.itemDiscountDescription}>
              {discountDescription}
            </span>
          ))}
        </div>
      );
    }

    return null;
  };

  const emptyDropdownComponent = (
    <Dropdown
      className={styles.itemDropdown}
      value={''}
      items={[
        {
          label: '',
          value: '',
        },
      ]}
    />
  );

  return (
    <div
      className={classnames(cartStyles.orderOverviewHeadlines, styles.itemWrap, {
        [styles.checkoutItemWrap]: isCheckout,
      })}
    >
      <div className={classnames(styles.itemImage, { [styles.checkoutItemImage]: isCheckout })}>
        <Link href={lineItem._url}>
          {lineItem.variant?.images && (
            <Image src={lineItem.variant.images[0]} alt={lineItem.name} title={lineItem.name} width={135} />
          )}
        </Link>
      </div>
      <div
        className={classnames(cartStyles.productOverviewInfoWrap, {
          [cartStyles.checkoutProductOverviewInfoWrap]: isCheckout,
        })}
      >
        <div className={classnames(styles.itemInfo, { [styles.checkoutItemInfo]: isCheckout })}>
          <Link href={lineItem._url}>
            {lineItem.name}, {currentSize}
          </Link>
          <div>
            {formatMessage({
              id: 'articleNumber',
              defaultMessage: 'Art. Nr.',
            })}{' '}
            {getSKUParts(lineItem.variant.sku).key}
          </div>
          {currentColor && !lineItem.variant.attributes.IsGiftcard && (
            <div>
              {formatMessage({
                id: 'color',
                defaultMessage: 'Farbe',
              })}
              : {currentColor}
            </div>
          )}
          <div className={styles.itemInfoSize}>
            <span>
              {lineItem.variant.attributes.IsGiftcard
                ? productFormatMessage({
                  id: 'giftCardVariantsLabel',
                  defaultMessage: 'Wert:',
                })
                : `${formatMessage({
                  id: 'size',
                  defaultMessage: 'Größe',
                })}:`}
            </span>
            {sizeVariants ? (
              <Dropdown
                className={classnames(styles.itemDropdown, styles.wide)}
                value={lineItem.variant.sku}
                items={sizeVariants || []}
                onChange={handleAddItem}
              />
            ) : (
              <Spinner size="small" />
            )}
          </div>
          {sizeSuggestion && (
            <div className={styles.sizeSuggestion}>
              <IconCustom icon="SizeInfo" width={16} className={styles.sizeSuggestionIcon} />
              <span
                dangerouslySetInnerHTML={{
                  __html: formatMessage({
                    id: 'sizeSuggestion',
                    defaultMessage:
                      'Dieser Artikel fällt <u>kleiner</u> aus. Wir empfehlen Ihnen die Größe <strong>{sizeSuggestion}</strong> zu kaufen.',
                    values: {
                      sizeSuggestion,
                    },
                  }),
                }}
              />
            </div>
          )}

          {getDiscountDescription(false)}

          {shippingTime && !lineItem.variant.attributes.IsGiftcard && (
            <div className={styles.itemInfoDeliveryTime}>
              {formatMessage({
                id: 'deliveryTime',
                defaultMessage: 'Lieferzeit',
              })}
              : {shippingTime}
            </div>
          )}
        </div>
        <div className={styles.itemQuantity}>
          <span>
            {formatMessage({
              id: 'quantity',
              defaultMessage: 'Anzahl',
            })}
            :
          </span>
          {emptyDropdown ? (
            emptyDropdownComponent
          ) : (
            <Dropdown
              className={styles.itemDropdown}
              value={quantityValue}
              items={amountArray}
              onChange={(value) => handleUpdateQuantity(Number(value))}
            />
          )}
        </div>

        {getDiscountDescription(true)}

        <div className={styles.itemPrice}>
          <div>
            <Price
              price={lineItem.price}
              discountedPrice={lineItem.totalPrice}
              count={lineItem.count}
              className={classnames(styles.itemPriceInfo, { [styles.checkoutPriceInfo]: isCheckout })}
              isBold
              showCurrencySymbol
            />

            {savedPrice?.centAmount > 0 && !isCheckout && (
              <div className={styles.itemPriceSaved}>
                <strong>
                  {formatMessage({
                    id: 'youSaved',
                    defaultMessage: 'Sie sparen: ',
                  })}
                </strong>
                <Price price={savedPrice} className={styles.itemPriceInfo} isBold showCurrencySymbol />
              </div>
            )}
          </div>

          {!isCheckout && (
            <button
              onClick={async () => {
                setUpdatingLineItems(true);
                await deleteItem(lineItem);
                new TagManager()
                  .econdaCartEvent(product, lineItem.variant, 'econdaRemoveFromCart', 'c_rmv', lineItem.count)
                  .executePush();
                setUpdatingLineItems(false);
              }}
              className={styles.itemDelete}
            >
              <span className={styles.itemDeleteIcon}>×</span>
              {formatMessage({
                id: 'deleteItem',
                defaultMessage: 'Artikel entfernen',
              })}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Item;
