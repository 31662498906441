import classnames from 'classnames';
import React, { useState } from 'react';
import { Product } from '@frontastic-engbers/types/product/Product';
import { ProductBoxImage } from './product-box-image';
import { ProductBoxHover } from './product-box-hover';
import Price from '../../../price';
import Link from 'next/link';
import { ProductFlags, WishlistButton } from '@engbers/components';
import styles from './product-box.module.scss';
import { TagManager } from '@frontastic-engbers/lib/lib/tracking';
import { ItemMapper } from '@frontastic-engbers/lib/lib/tracking/itemMapper';
import { useProductOutfitTitle } from '@frontastic-engbers/helpers/seoHelper';
import { MediaType } from '@frontastic-engbers/types/engbers-custom';

export interface IProductBox {
  product: Product;
  className?: string;
  pageNumber?: number;
  supportHover?: boolean;
  isTopSellerSlider?: boolean;
  hasHoverEffect?: boolean;
  isEnGermany?: boolean;
  disableFlags?: boolean;
  isEmilio?: boolean;
  fallbackImage?: MediaType;
  onSelect?: () => void;
}

export const ProductBox: React.FC<IProductBox> = ({
  product,
  className,
  pageNumber,
  isTopSellerSlider,
  supportHover = true,
  hasHoverEffect = true,
  isEnGermany = false,
  disableFlags = false,
  isEmilio = false,
  fallbackImage,
  onSelect,
}) => {
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const priceVariant = product.variants?.find((variant) => variant.price !== undefined);

  const setBeforeLinkToProduct = () => {
    onSelect?.();
    sendDatalayer();

    if (product.attributes?.ProductGroup) {
      localStorage.setItem('productGroup', product.attributes.ProductGroup);
    }

    sessionStorage.setItem('storage_last_visited_product', JSON.stringify({ id: product.productId }));
  };

  const sendDatalayer = () => {
    new TagManager().selectItemEvent([ItemMapper.productToItem(product, 1, 0)]).executePush();
  };

  const productNumber = product?.variants?.[0]?.sku;
  const designerName = product.attributes?.['DesignerName'] ?? 'designer name';
  const { title } = useProductOutfitTitle({
    product,
    attributes: product.attributes,
  });

  return (
    <div
      className={classnames(styles.productBox, className, {
        [styles.enGermanyProductBox]: isEnGermany,
      })}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      id={product.productId}
      data-page-number={pageNumber}
    >
      {productNumber && (
        <WishlistButton sku={productNumber} wrapperClassName={styles.wishlistWrapper} product={product} />
      )}

      {product.flags && !disableFlags && (
        <div className={styles.productBoxFlags}>
          <ProductFlags flags={product.flags} isEnGermany={isEnGermany} flagOrder={['sale', 'new']} />
          <ProductFlags flags={product.flags} isEnGermany={isEnGermany} flagOrder={['campaign', 'engbersGermany']} />
        </div>
      )}

      <Link
        href={product.url}
        className={styles.productBoxLinkWrap}
        legacyBehavior={false}
        onClick={setBeforeLinkToProduct}
      />
      <div className={styles.productBoxWrap}>
        <ProductBoxImage
          images={priceVariant.images.length === 0 ? [fallbackImage?.media?.file] : priceVariant.images}
          title={title}
          isHovering={isHovering && hasHoverEffect}
          loadEager={isEnGermany && (navigator.userAgent.includes('iPhone') || navigator.userAgent.includes('iPad'))}
        />
        <div
          className={classnames('productBoxInfo', styles.productBoxInfo, {
            [styles.enGermanyProductBoxInfo]: isEnGermany,
            [styles.enGermanyProductBoxInfoHover]: isEnGermany && isHovering,
          })}
        >
          <div className={isTopSellerSlider && '!block'}>
            <div className={isTopSellerSlider ? styles.topSellerDesigner : styles.infoLeftSide}>
              {!isEmilio && designerName}
            </div>
            <div
              className={!isTopSellerSlider ? classnames(styles.productBoxInfoArtNum, styles.infoRightSide) : 'hidden'}
            >
              {product.productId}
            </div>
          </div>
          <div className={isTopSellerSlider && styles.columnWrap}>
            <div
              className={classnames(
                styles.productBoxInfoName,
                !isTopSellerSlider ? styles.infoLeftSide : 'text-center',
              )}
            >
              {product.name}
            </div>
            <Price
              price={priceVariant.price}
              discountedPrice={priceVariant.discountedPrice}
              className={classnames(styles.productBoxInfoPrice, !isTopSellerSlider && styles.infoRightSide)}
              isBold
              isGiftCard={product.attributes?.IsGiftcard}
              showCurrencySymbol={product.attributes?.IsGiftcard}
            />
          </div>
          {supportHover && (
            <ProductBoxHover
              product={product as Product}
              isHovering={isHovering && hasHoverEffect}
              isEnGermany={isEnGermany}
            />
          )}
        </div>
      </div>
    </div>
  );
};
