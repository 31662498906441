import ProductSlider from '@engbers/components/online-shops/product-slider';

function ProductSliderTastic({ data }) {
  if (!data?.data?.dataSource?.items) {return <p>No products found.</p>;}

  return (
    <ProductSlider
      products={data?.data?.dataSource?.items}
      title={data.title}
      textSize={data.textSize}
      isStartPage={data.isStartPage}
      ctaLabel={data.ctaLabel}
      ctaLink={data.ctaLink}
    />
  );
}

export default ProductSliderTastic;
