import React from 'react';
import StyleGuideButtons from './style-guide-buttons';
import StyleGuideGrid from './style-guide-grid';
import StyleGuideIcons from './style-guide-icons';
import StyleGuideInputs from './style-guide-inputs';
import StyleGuideTypography from './style-guide-typography';
import StyleGuideColors from './style-guide-colors';
import StyleGuideModal from './style-guide-modal';

const StyleGuide: React.FC = () => (
  <div>
    <StyleGuideColors />
    <StyleGuideTypography />
    <StyleGuideButtons />
    <StyleGuideInputs />
    <StyleGuideModal />
    <StyleGuideIcons />
    <StyleGuideGrid />
  </div>
);

export default StyleGuide;
