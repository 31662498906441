import React, { useRef, useState } from 'react';
import { useRouter } from 'next/router';
import ReCAPTCHA from 'react-google-recaptcha';
import { Block, Button, InputCheckbox, InputError } from '@engbers/components';
import { getReferenceTarget, Reference } from '@frontastic-engbers/helpers/reference';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { useToastNotificationsActions } from '@frontastic-engbers/lib/state/notification/actions';
import { useNewsletter } from '@frontastic-engbers/lib/actions/account/useNewsletter';
import styles from './newsletter.module.scss';

export type Props = {
  newsletterMapping: {
    mailingListId: number;
    name: string;
    label: string;
    preselected: boolean;
  }[];
  ctaLabel: string;
  subscriptionRedirect: Reference;
  reCaptchaSiteKey: any;
  errorNoSelectedNewsletter: string;
};

type NewsletterFormData = {
  email: string;
  salutation: string;
  firstName: string;
  lastName: string;
  subscriptions: {
    [key: string]: {
      newsletterId: number;
      selected: boolean;
    };
  };
};

type ValidationErrors = {
  email: boolean;
  firstName: boolean;
  lastName: boolean;
  acceptReCaptcha: boolean;
  missingNewsletter: boolean;
};

export const Newsletter: React.FC<Props> = ({
  newsletterMapping,
  ctaLabel,
  subscriptionRedirect,
  reCaptchaSiteKey,
  errorNoSelectedNewsletter,
}) => {
  const router = useRouter();
  const recaptchaRef = useRef();
  const { formatMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });
  const { registerNewsletterByEmail, getSubscriptionsByEmail } = useNewsletter();
  const { pushNotification } = useToastNotificationsActions();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<Partial<ValidationErrors>>({});
  const [data, setData] = useState<NewsletterFormData>({
    email: (router.query.email as string) ?? '',
    salutation: '',
    firstName: '',
    lastName: '',
    subscriptions: newsletterMapping.reduce(
      (obj, { mailingListId, name, preselected }) => ({
        ...obj,
        [name]: {
          newsletterId: mailingListId,
          selected: preselected,
        },
      }),
      {},
    ),
  });

  const { email, firstName, lastName, subscriptions } = data;

  const validate = () => {
    const validationErrors: ValidationErrors = {
      email: data.email === '',
      firstName: data.firstName === '',
      lastName: data.lastName === '',
      acceptReCaptcha: (recaptchaRef.current as any)?.getValue() === '',
      missingNewsletter: !Object.values(data.subscriptions).some(({ selected }) => selected),
    };

    setErrors(validationErrors);

    return Object.values(validationErrors).every((v) => v === false);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleNewsletterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    setData((prevState) => {
      const { subscriptions } = prevState;

      subscriptions[name].selected = checked;

      return {
        ...prevState,
        subscriptions,
      };
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);

    if (!validate()) {
      setIsLoading(false);
      return;
    }

    const subscriptionData = {
      ...data,
      subscriptions: Object.values(data.subscriptions)
        .filter((s) => s.selected)
        .map((s) => ({ id: s.newsletterId })),
    };

    const response = await getSubscriptionsByEmail(subscriptionData);

    if (response.length > 0) {
      const allSubscribed = response.every((newsletter) => newsletter.isSubscribed);

      if (allSubscribed) {
        setIsLoading(false);
        pushNotification(formatErrorMessage({ id: 'newsletterAlreadyUsed' }), 'error');
        return;
      }

      const unsubscribedNewsletter = response.filter((newsletter) => !newsletter.isSubscribed);

      if (unsubscribedNewsletter.length > 0) {
        subscriptionData.subscriptions = unsubscribedNewsletter.map((newsletter) => ({ id: newsletter.newsletterId }));
      }
    }

    const result = await registerNewsletterByEmail(subscriptionData);

    if (result.success) {
      router.push(getReferenceTarget(subscriptionRedirect));
    }

    setIsLoading(false);
  };

  return (
    <div className={styles.newsletter}>
      <form onSubmit={handleSubmit}>
        <div className={styles.inputGroup}>
          <div className={styles.label}>
            {`${formatMessage({
              id: 'salutation',
              defaultMessage: 'Anrede',
            })}:`}
          </div>
          <div className={'input-radio-group'}>
            <label className={styles.radioLabel}>
              <input
                className={styles.radioInput}
                type="radio"
                name="salutation"
                value="MR"
                onChange={handleInputChange}
              />
              {formatMessage({
                id: 'gender.male',
                defaultMessage: 'Herr',
              })}
            </label>
            <label className={styles.radioLabel}>
              <input
                className={styles.radioInput}
                type="radio"
                name="salutation"
                value="MRS"
                onChange={handleInputChange}
              />
              {formatMessage({
                id: 'gender.female',
                defaultMessage: 'Frau',
              })}
            </label>
          </div>
        </div>
        <div className={styles.inputGroup}>
          <span className={styles.label}>
            {`${formatMessage({
              id: 'email',
              defaultMessage: 'E-Mail',
            })}*:`}
          </span>
          <Block customStyle={{ width: '100%' }}>
            <input
              type="email"
              name="email"
              autoComplete="email"
              placeholder="Ihre E-Mail Adresse"
              value={email}
              onChange={handleInputChange}
            />
            <InputError condition={errors.email} message="fillInput" />
          </Block>
        </div>
        <div className={styles.inputGroup}>
          <span className={styles.label}>
            {`${formatMessage({
              id: 'firstName',
              defaultMessage: 'Vorname',
            })}*:`}
          </span>
          <Block customStyle={{ width: '100%' }}>
            <input
              type="text"
              name="firstName"
              autoComplete="given-name"
              value={firstName}
              onChange={handleInputChange}
            />
            <InputError condition={errors.firstName} message="fillInput" />
          </Block>
        </div>
        <div className={styles.inputGroup}>
          <span className={styles.label}>
            {`${formatMessage({
              id: 'lastName',
              defaultMessage: 'Nachname',
            })}*:`}
          </span>
          <Block customStyle={{ width: '100%' }}>
            <input
              type="text"
              name="lastName"
              autoComplete="family-name"
              value={lastName}
              onChange={handleInputChange}
            />
            <InputError condition={errors.lastName} message="fillInput" />
          </Block>
        </div>

        <div className={styles.inputGroup}>
          <span className={styles.label}>
            {`${formatMessage({
              id: 'reCaptchaSecurityCode',
              defaultMessage: 'Sicherheitscode',
            })}:`}
          </span>
          <Block>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={reCaptchaSiteKey?.dataSource?.reCaptchaSiteKey}
              onChange={(v) => {
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  acceptReCaptcha: v === '',
                }));
              }}
            />
            <InputError condition={errors.acceptReCaptcha} message="fillCaptcha" />
          </Block>
        </div>

        <div className={styles.checkboxes}>
          {newsletterMapping.map((newsletter) => (
            <InputCheckbox
              label={newsletter.label}
              name={newsletter.name}
              id={newsletter.name}
              onChange={handleNewsletterChange}
              checked={subscriptions[newsletter.name].selected}
              key={newsletter.mailingListId}
              labelIsMarkdown
            />
          ))}
          <InputError
            condition={errors.missingNewsletter && !!errorNoSelectedNewsletter}
            message={errorNoSelectedNewsletter}
          />
        </div>

        <div className={styles.submitWrapper}>
          <span className={styles.label}>
            {formatMessage({
              id: 'mandatoryFields',
              defaultMessage: '* Pflichtfelder',
            })}
          </span>
          <Button
            label={
              ctaLabel && ctaLabel !== ''
                ? ctaLabel
                : formatMessage({
                  id: 'submit',
                  defaultMessage: 'Abschicken',
                })
            }
            type="cta"
            size="large"
            buttonType="submit"
            isLoading={isLoading}
          />
        </div>
      </form>
    </div>
  );
};
