import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { usePagination } from 'react-instantsearch';
import classnames from 'classnames';
import { Outfit } from '@frontastic-engbers/types/product/Outfit';
import ProductSlider from '../index';
import styles from './outfit-slider.module.scss';

interface IOutfitSlider {
  outfits?: Outfit[];
  outfitSliderHeadline: string;
  outfitSliderCount: number;
  outfitSliderRowNumber: number;
}

export const OutfitSlider: React.FC<IOutfitSlider> = ({
  outfits,
  outfitSliderHeadline,
  outfitSliderCount,
  outfitSliderRowNumber,
}) => {
  const [isMinWidthMedium, setIsMinWidthMedium] = useState<boolean>(false);
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const { currentRefinement: pageNumber } = usePagination();
  const hitsPerPage = document.querySelectorAll('[data-page-number]').length;

  useEffect(() => {
    if (isMobile !== isMinWidthMedium) {
      setIsMinWidthMedium(isMobile);
    }
  }, [isMobile]);

  const maxSliders = Math.floor(hitsPerPage / (isMinWidthMedium ? 2 : 3) / (outfitSliderRowNumber - 1));
  const sliderChunks = (arr: Array<Outfit>, size: number) =>
    Array.from({ length: Math.ceil(arr.length / size) }, (v, i) => arr.slice(i * size, i * size + size)).slice(
      0,
      outfitSliderCount,
    );

  return (
    <>
      {sliderChunks(outfits, 10).map((chunk: Outfit[], i) => {
        const index = i + 1;

        if (index > maxSliders) {
          return null;
        }

        return (
          <div
            key={`outfit-slider-${index}`}
            className={classnames(styles.outfitSliderWrap)}
            style={
              {
                '--row-start': index * outfitSliderRowNumber,
                '--row-end': index * outfitSliderRowNumber + 1,
              } as React.CSSProperties
            }
          >
            <ProductSlider
              products={chunk}
              title={outfitSliderHeadline}
              customTitleStyle={{
                fontSize: isMinWidthMedium ? '16px' : '28px',
                textTransform: 'none',
                letterSpacing: '1px',
                marginTop: isMinWidthMedium ? '12px' : undefined,
              }}
              hasArrows
              isOutfitSlider
              productListPage={pageNumber + 1}
              slidesPerView={{
                mobile: 2.5,
                desktop: 3,
              }}
            />
          </div>
        );
      })}
    </>
  );
};
