import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import isoAlpha2Data from '@frontastic-engbers/helpers/utils/iso-alpha2-data';
import { Address } from '@frontastic-engbers/types/account/Address';
import { IconCustom, InputCheckbox } from '@engbers/components/index';
import LocationResult from '@engbers/shop-backend/online-shop/models/LocationResult';
import styles from './store-result.module.scss';

interface IStoreResult {
  location: LocationResult;
  select: (id: string, checked: boolean) => void;
  shippingAddress: Address;
  labelOpeningHours: string;
  labelSelect: string;
  storeClosedText: string;
}

export const StoreResult: React.FC<IStoreResult> = ({
  location,
  select,
  shippingAddress,
  labelOpeningHours,
  labelSelect,
  storeClosedText,
}) => {
  const [isMinWidthMedium, setIsMinWidthMedium] = useState<boolean>(false);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const { formatMessage } = useFormat({ name: 'common' });
  const country = isoAlpha2Data[location.country];

  useEffect(() => {
    if (isMobile !== isMinWidthMedium) {
      setIsMinWidthMedium(isMobile);
    }
  }, [isMobile]);

  useEffect(() => {
    if (shippingAddress && shippingAddress.addressId === location.id) {
      select(location.id, true);
    }
  }, []);

  return (
    <div className={styles.location}>
      <div className={styles.locator}>
        <IconCustom icon={'MapMarker'} width={30} />
      </div>
      <div className={styles.locationMain}>
        <div className={styles.locationInfo}>
          <span className={styles.locationName}>{location.name}</span>
          <span>
            {location.street} {location.streetNumber}
          </span>
          <span>
            {location.zip} {location.city}
          </span>
          {country && <span>{country}</span>}
        </div>

        {location.isClosed && <span className={styles.isClosed}>{storeClosedText}</span>}

        {!location.isClosed && location.openingHours && (
          <div>
            <strong>{labelOpeningHours}</strong>
            {location.openingHours.split('<br>').map((text, i) => (
              <div key={`opening-hours-${i}`}>{text}</div>
            ))}
          </div>
        )}
      </div>
      <InputCheckbox
        label={isMinWidthMedium ? formatMessage({ id: 'select' }) : labelSelect !== '' ? labelSelect : undefined}
        name={'selectedLocation'}
        id={location.id}
        onChange={(e) => select(e.target.id, e.target.checked)}
        checked={shippingAddress && shippingAddress.addressId === location.id}
        swapLabelPosition={true}
      />
    </div>
  );
};
