import { useRefinementList } from 'react-instantsearch';
import { InputCheckbox } from '@engbers/components';
import { handleFilterDataLayer } from '@frontastic-engbers/helpers/dataLayerHelper';
import { IFacetProps } from './types';
import styles from './facets.module.scss';
import React from 'react';

const TermFacet: React.FC<IFacetProps> = ({ label, attribute, wrapper: Wrapper = React.Fragment, showItemCount = false }) => {
  const { refine, items } = useRefinementList({ attribute });

  return (
    <>
      {items.map((term) => (
        <Wrapper key={term.value + 'wrapper'}>
          <div className={styles.termFacetValue} key={term.value}>
            <InputCheckbox
              id={term.value}
              name={term.value}
              checked={term.isRefined}
              onChange={() => {
                refine(term.value);
                handleFilterDataLayer(items, label, term.value);
              }}
              label={`${term.label}${showItemCount ? ` (${term.count.toString()})` : ``}`}
            />
          </div>
        </Wrapper>
      ))}
    </>
  );
};

export default TermFacet;
