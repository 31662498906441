import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { useAvailableCitiesAndAssortments, useStoresAvailability } from '@frontastic-engbers/lib/actions/stores';
import { Product } from '@frontastic-engbers/types/product/Product';
import {
  AvailableStoreCity,
  StoreAvailabilityResult as StoreAvailabilityResultType,
} from '@frontastic-engbers/types/product/StoreAvailability';
import { IconCustom, InputText } from '@engbers/components/';
import Spinner from '@engbers/components/online-shops/commercetools-ui/spinner';
import { IAvailabilityModalContent } from '../../index';
import { StoreAvailabilityResult } from './store-availability-result';
import styles from './store-availability-modal.module.scss';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';


export interface IStoreAvailabilityModal {
  product: Product;
  availabilityModalContent: IAvailabilityModalContent;
}

export const StoreAvailabilityModal: React.FC<IStoreAvailabilityModal> = ({ product, availabilityModalContent }) => {
  const { formatMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  const [error, setError] = useState<boolean>(false);
  const [searchState, setSearchState] = useState({
    citySearch: '',
    citySelection: '',
  });
const [modalRef, setModalRef] = useState<Element | null>(null);


  useEffect(() => {
    if (!modalRef) {
      return;
    }
    disableBodyScroll(modalRef, {
      allowTouchMove: (el: Element) => {
        return el.closest(".scrollable");
      }
    });
    return () => {
      enableBodyScroll(modalRef);
    }
  }, [modalRef]);
  
  const [searchValue, setSearchValue] = useState<string>('');

  const { data: options, isLoading: areOptionsLoading } = useAvailableCitiesAndAssortments(
    'storeAvailability',
    product.attributes?.DesignerName,
  );
  const { data: results, isLoading } = useStoresAvailability(
    searchState.citySearch || searchState.citySelection,
    product.productId,
    product.attributes?.DesignerName,
  );

  const onlineAvailability = useMemo<Record<string, boolean>>(() => {
    return product.variants?.reduce((acc, variant) => {
      acc[variant.attributes?.Sizing] = variant.isInStock && variant.quantity > 0;
      return acc;
    }, {});
  }, [product.variants]);

  useEffect(() => {
    const currentSearchState = JSON.parse(localStorage.getItem('availabilityModalSearchState'));

    if (currentSearchState) {
      setSearchState(currentSearchState);

      if (currentSearchState.citySearch) {
        setSearchValue(currentSearchState.citySearch);
      }

      if (!Object.values(currentSearchState).every((v) => v)) {
        setError(true);
      }
    }
  }, []);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    localStorage.setItem('availabilityModalSearchState', JSON.stringify({ [e.target.name]: e.target.value }));

    setError(e.target.value === '');

    if (e.target instanceof HTMLSelectElement) {
      setSearchValue('');
    }

    setSearchState({
      citySearch: '',
      citySelection: '',
      [e.target.name]: e.target.value,
    });
  };

  const handleSearchSubmit = () => {
    localStorage.setItem('availabilityModalSearchState', JSON.stringify({ citySearch: searchValue }));

    setError(searchValue === '');

    setSearchState({
      citySearch: searchValue,
      citySelection: '',
    });
  };

  return (
    <div className={classNames(styles.wrap)} ref={setModalRef}>
      <div className={styles.title}>
        <span>
          {`${product.attributes.DesignerName} - ${product.name} ${product.attributes.ColorName}`}
        </span>
        <strong className={styles.artNum}>
          {formatProductMessage({
            id: 'articleNumber',
            defaultMessage: 'Artikelnummer',
          })}
          : {product.productId}
        </strong>
      </div>
      <div className={styles.formWrap}>
        <div className={styles.searchWrap}>
          <InputText
            id="citySearch"
            name="citySearch"
            type="text"
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder={availabilityModalContent.searchPlaceholder}
            required
            value={searchValue}
            onEnter={handleSearch}
          />
          <button onClick={handleSearchSubmit} className={styles.searchButton}>
            <IconCustom icon="Search" width={18} color="white" />
            <span className="sr-only">
              {formatMessage({
                id: 'search',
                defaultMessage: 'Suchen',
              })}
            </span>
          </button>
        </div>
        <span>
          {formatMessage({
            id: 'or',
            defaultMessage: 'oder',
          })}
        </span>
        {options?.cities?.length > 0 && (
          <select id="citySelection" name="citySelection" value={searchState.citySelection} onChange={handleSearch}>
            <option key="empty" value="">
              {availabilityModalContent.selectPlaceholder}
            </option>
            {options?.cities?.map((city: AvailableStoreCity) => {
              const value = city.zip + ' ' + city.city.toLowerCase().replace(/[ /]/g, '-');

              return (
                <option key={`${city.zip} ${city.city}`} value={value}>
                  {city.city} ({city.country})
                </option>
              );
            })}
          </select>
        )}
      </div>
      <div className={ classNames(styles.resultsWrap, "scrollable")}>
        {(isLoading || areOptionsLoading) && (
          <div className={styles.spinnerWrap}>
            <Spinner />
          </div>
        )}
        {error && <span>{availabilityModalContent.emptySearchError}</span>}
        {(searchState.citySearch || searchState.citySelection) && !results?.length && (
          <span>{availabilityModalContent.noResultsError}</span>
        )}
        {!error &&
          results?.map((result: StoreAvailabilityResultType) => (
            <StoreAvailabilityResult
              result={result}
              onlineAvailability={onlineAvailability}
              availabilityModalContent={availabilityModalContent}
              key={result.store.id}
            />
          ))}
      </div>
      <div className={styles.noteWrap}>
        <div className={styles.key}>
          <div>
            <span className={classNames(styles.tile, styles.available)}></span>
            <span>{availabilityModalContent.availableLabel}</span>
          </div>
          <div>
            <span className={classNames(styles.tile, styles.onlineonly)}></span>
            <span>{availabilityModalContent.onlineOnlyLabel}</span>
          </div>
          <div>
            <span className={classNames(styles.tile, styles.notavailable)}></span>
            <span>{availabilityModalContent.notAvailableLabel}</span>
          </div>
        </div>
        <p>{availabilityModalContent.note}</p>
      </div>
    </div>
  );
};
