import { Markdown } from '@engbers/components';
import { FullPageWidthWrapper } from '@engbers/components/online-shops';

const MarkdownTastic = ({ data }) => {
  if (data.isFullWidth) {
    return (
      <FullPageWidthWrapper>
        <Markdown text={data.markdown} textSize={data.textSize} linkToNewTab={data.linkToNewTab} />
      </FullPageWidthWrapper>
    );
  }

  return <Markdown text={data.markdown} textSize={data.textSize} linkToNewTab={data.linkToNewTab} />;
};

export default MarkdownTastic;
