import React from 'react';
import { Link, Video } from '@engbers/components';
import { FTReference, MediaType } from '@frontastic-engbers/types/engbers-custom';

type Props = {
  data: {
    video: MediaType;
    reference: FTReference;
    autoplay: boolean;
    loop: boolean;
    muted: boolean;
    controls: boolean;
  };
};

export const VideoTastic: React.FC<Props> = ({ data }) => {
  const video = (
    <Video
      media={data.video}
      autoPlay={data.autoplay}
      loop={data.loop}
      muted={data.muted || data.autoplay}
      controls={data.controls}
      className="inline-block"
    />
  );

  if (!video) {
    return null;
  }

  return data.reference?.type ? (
    <Link reference={data.reference} className="inline-block">
      {video}
    </Link>
  ) : (
    video
  );
};
