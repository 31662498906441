import { Product } from '@frontastic-engbers/types/product/Product';
import { Variant } from '@frontastic-engbers/types/product/Variant';
import { EcommerceDataLayerItem } from '@frontastic-engbers/types/tagmanager/EcommerceDataLayerItem';
import { DataLayer, EcondaDataLayer } from '@frontastic-engbers/types/tagmanager/DataLayer';
import { Account } from '@frontastic-engbers/types/account/Account';
import GoogleTagManager from 'react-gtm-module';
import { DataLayerFactory } from './datalayerFactory';
import { PageDataResponse } from '../types';
import { getEcondaData, getEcProducts } from '@frontastic-engbers/helpers/dataLayerHelper/econdaDataHelper';
import { Money } from '@frontastic-engbers/types/product/Money';
import { Order } from '@frontastic-engbers/types/cart/Order';

export class TagManager {
  private dataLayerId: string;
  private dataLayers: DataLayer[];

  constructor(dataLayerId = 'dataLayer') {
    this.dataLayerId = dataLayerId;
    this.dataLayers = [];
  }

  executePush(): void {
    while (this.dataLayers.length) {
      this.pushDataLayer(this.dataLayers.shift());
    }
  }

  viewItemEvent(variant: Variant, datalayerItems: EcommerceDataLayerItem[], payload?: any): this {
    this.dataLayers.push(DataLayerFactory.getViewItemLayer(variant, datalayerItems, payload));
    return this;
  }

  viewItemOutfitEvent(
    variant: Variant,
    outfitSum: number,
    datalayerItems: EcommerceDataLayerItem[],
    payload?: any,
  ): this {
    this.dataLayers.push(DataLayerFactory.getViewItemOutfitLayer(variant, outfitSum, datalayerItems, payload));
    return this;
  }

  viewItemListEvent(id: string, name: string, datalayerItems: EcommerceDataLayerItem[], payload?: any): this {
    this.dataLayers.push(DataLayerFactory.getViewItemListLayer(id, name, datalayerItems, payload));
    return this;
  }

  addToCartEvent(datalayerItems: EcommerceDataLayerItem[], addToCartSum: number, payload?: any): this {
    this.dataLayers.push(DataLayerFactory.getAddToCartLayer(datalayerItems, addToCartSum, payload));
    return this;
  }

  removeFromCartEvent(datalayerItems: EcommerceDataLayerItem[], removeFromCartSum: number, payload?: any): this {
    this.dataLayers.push(DataLayerFactory.getRemoveFromCartLayer(datalayerItems, removeFromCartSum, payload));
    return this;
  }

  addToWishlistEvent(datalayerItems: EcommerceDataLayerItem[], sum: Money, payload?: any): this {
    this.dataLayers.push(DataLayerFactory.getAddToWishlistLayer(datalayerItems, sum, payload));
    return this;
  }

  selectItemEvent(datalayerItems: EcommerceDataLayerItem[], payload?: any): this {
    this.dataLayers.push(DataLayerFactory.getSelectItemLayer(datalayerItems, payload));
    return this;
  }

  customEvent(event: string, payload?: any): this {
    this.dataLayers.push(DataLayerFactory.getCustomEventLayer(event, payload));
    return this;
  }

  ecommerceEvent(
    event: string,
    datalayerItems: EcommerceDataLayerItem[],
    value?: Money,
    couponCode?: string,
    payload?: any,
  ) {
    this.dataLayers.push(DataLayerFactory.getEcommerceLayer(event, datalayerItems, value, couponCode, payload));
    return this;
  }

  purchaseEvent(
    sum: Money,
    tax: number,
    shipping: Money,
    transactionId: string,
    datalayerItems: EcommerceDataLayerItem[],
    couponCode?: string,
    discount?: number,
  ) {
    this.dataLayers.push(
      DataLayerFactory.getPurchaseLayer(sum, tax, shipping, transactionId, couponCode, datalayerItems, discount),
    );
    return this;
  }

  orderSubmitEvent(order: Order): this {
    this.dataLayers.push(DataLayerFactory.getOrderSubmitLayer(order));
    return this;
  }

  econdaEvent(data: PageDataResponse, account?: Account, locale = 'de'): this {
    const econdaData = getEcondaData(data, account, locale);
    this.dataLayers.push(DataLayerFactory.getEcondaEventLayer(econdaData));
    return this;
  }

  updateEcondaDataEvent(econdaData: EcondaDataLayer): this {
    this.dataLayers.push(DataLayerFactory.getEcondaEventLayer(econdaData));
    return this;
  }

  econdaCartEvent(
    product: Product,
    selectedVariant: Variant | undefined,
    event: string,
    type: string,
    count?: number | string,
  ): this {
    const ecProducts = getEcProducts(product, selectedVariant, type, count);
    this.dataLayers.push(DataLayerFactory.getEcondaCartLayer(ecProducts, event));
    return this;
  }

  econdaWishlistEvent(
    lineItemData: { id: string; quantity: string | number; date: Date | string },
    event: string,
    type: string,
    visitorID: string,
  ): this {
    this.dataLayers.push(DataLayerFactory.getEcondaWishlistLayer(lineItemData, event, type, visitorID));
    return this;
  }

  econdaWishlistToCart(product: Product, selectedVariant: Variant | undefined, visitorID: string): this {
    this.dataLayers.push(DataLayerFactory.getEcondaWishlistToCartLayer(product, selectedVariant, visitorID));
    return this;
  }

  private pushDataLayer(dataLayer?: DataLayer): void {
    GoogleTagManager.dataLayer(this.getGtmLayer(DataLayerFactory.getClearEcommerceLayer()));
    GoogleTagManager.dataLayer(this.getGtmLayer(dataLayer));
  }

  private getGtmLayer(dataLayer: any) {
    return {
      dataLayerName: this.dataLayerId,
      dataLayer,
    };
  }
}
