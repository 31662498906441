import classnames from 'classnames';
import React, { useCallback, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import QuickPinchZoom, { make3dTransformValue } from 'react-quick-pinch-zoom';
import { IconCustom } from '@engbers/components/icon-custom';
import { getOriginalSize } from '@frontastic-engbers/helpers/utils/meleven';

import styles from './pinch-zoom-image.module.scss';

export type Props = {
  src: string;
  title: string;
};

export const PinchZoomImage: React.FC<Props> = ({
  src,
  title,
}) => {
  const [isActive, setIsActive] = useState(false);
  const imgRef = useRef<HTMLImageElement>();

  const onUpdate = useCallback((updateAction) => {
    const { current: img } = imgRef;

    if (img) {
      const value = make3dTransformValue(updateAction);

      img.style.setProperty('transform', value);
    }
  }, []);

  return (
    <div className="relative">
      {isActive && createPortal(
        <div id={styles.pinchContainer}>
          <span className={styles.iconContainer}>
            <IconCustom icon="PinchHand" />
            <IconCustom icon="PinchClose" onClick={() => setIsActive(false)} />
          </span>
          <QuickPinchZoom
            onUpdate={onUpdate}
            centerContained
            doubleTapToggleZoom
            inertia={false}
            containerProps={{ className: 'w-full h-full' }}
          >
            <img
              src={getOriginalSize(src)}
              alt={title}
              title={title}
              key={src}
              ref={imgRef}
            />
          </QuickPinchZoom>
        </div>,
        document.body,
      )}
      <span className={classnames(styles.iconContainer, styles.padded)} onClick={() => setIsActive(true)}>
        <IconCustom icon="PinchMagnifier" width={24} />
      </span>
      <img
        src={src}
        alt={title}
        title={title}
        key={src}
      />
    </div>
  );
};
