import { OrderHistoryData } from '@frontastic-engbers/types/account/Account';
import styles from './order-status-confirmation.module.scss';
import { FormattedDate, Link } from '@engbers/components';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import React from 'react';

interface IOrderStatusConfirmation {
  order: OrderHistoryData;
  labels: {
    orderStatus: {
      1: string;
      2: string;
      3: string;
      4: string;
    };
  };
  statusColor?: string;
  statusTextColor?: string;
  trackingLink?: string;
}

export const OrderStatusConfirmation: React.FC<IOrderStatusConfirmation> = ({
  order,
  labels,
  statusColor,
  statusTextColor,
  trackingLink,
}) => {
  const mappedOrderStatus = labels.orderStatus[`${order.status}`];
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });

  if (order.type === 'RET') {
    return (
      <div
        className={styles.wrap}
        style={{
          backgroundColor: statusColor,
          color: statusTextColor,
        }}
      >
        <div className={styles.checkmarkWrap}>
          <div className={styles.test} />
        </div>
        <div className={styles.title}>{formatProductMessage({ id: 'returnReceived' })}</div>
        {order.shippingDate && <div className={styles.description}>{`am ${order.shippingDate}`}</div>}
        <div className={styles.content}>{formatAccountMessage({ id: 'returnOnPayMethod' })}</div>
      </div>
    );
  }

  return (
    <div
      className={styles.wrap}
      style={{
        backgroundColor: statusColor,
        color: statusTextColor,
      }}
    >
      <div className={styles.checkmarkWrap}>
        <div className={styles.test} />
      </div>
      <div className={styles.title}>{mappedOrderStatus}</div>
      {order.shippingDate && (
        <div className={styles.description}>
          <FormattedDate dateStr={order.shippingDate} render={(str) => `am ${str} durch DHL`} />
        </div>
      )}
      {order.trackingNumber && (
        <div className={styles.content}>
          <div>
            <span>{formatProductMessage({ id: 'trackingCode' })}: </span>
            { trackingLink ? (
              <Link href={trackingLink.replace('#ID', order.trackingNumber)}>{order.trackingNumber}</Link>
            ) : (
              order.trackingNumber
            )}
          </div>
        </div>
      )}
    </div>
  );
};
