import { CSSProperties } from 'react';

export const EngbersGermanyLogoFlagIcon = () => {
  const styles: CSSProperties = { fill: '#30302f', fillRule: 'evenodd', strokeWidth: 0 };

  return (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 94 68">
      <path
        style={styles}
        d="M46.4,8.9v17.9l15.4-8.9v-3.3l-12.4,7.2v-3.4l12.4-7.2v-3.9l-12.4,7.2v-3.4l12.4-7.2V0l-15.4,8.9Z"
      />
      <path style={styles} d="M31,11.2l12.4,7.2v-3.9l-12.4-7.2v3.9Z" />
      <path style={styles} d="M31,17.9l12.4,7.2v-3.3l-12.4-7.2v3.3Z" />
      <path style={styles} d="M31,3.9l12.4,7.2v-3.9L31,0v3.9Z" />
      <path
        style={styles}
        d="M29.2,67v-1.9h-2v.5h1.5v1.2c-.3.3-.8.5-1.4.5-1.2,0-1.9-.8-1.9-2s.8-2,1.8-2,1.1.2,1.5.5l.3-.4c-.5-.4-1-.6-1.8-.6-1.4,0-2.4,1.2-2.4,2.5s.9,2.5,2.4,2.5,1.5-.3,1.9-.7ZM35.2,62.9h-3.5v4.8h3.5v-.5h-3v-1.7h2.6v-.5h-2.6v-1.6h2.9v-.5ZM41.7,67.7l-1.5-1.9c.8-.1,1.3-.6,1.3-1.4s-.7-1.4-1.7-1.4h-2.1v4.8h.5v-1.9h1.4l1.4,1.9h.7ZM41,64.3c0,.6-.5,1-1.2,1h-1.5v-1.9h1.5c.8,0,1.2.4,1.2.9ZM48.1,63.8v3.9h.5v-4.8h-.5l-1.7,2.6-1.7-2.6h-.5v4.8h.5v-3.9l1.7,2.5h0l1.7-2.6ZM53.6,62.9h-.5l-2.2,4.8h.6l.6-1.3h2.6l.6,1.3h.6l-2.2-4.8ZM54.5,65.9h-2.2l1.1-2.4,1.1,2.4ZM61.7,66.7l-3-3.8h-.5v4.8h.5v-3.9l3.1,3.9h.4v-4.8h-.5v3.8ZM67,65.8l2-2.9h-.6l-1.6,2.4-1.6-2.4h-.7l2,2.9v1.9h.5v-1.9Z"
      />
      <path
        style={styles}
        d="M10,48.3v-2.8h-1.7c-.1.5-.2.8-.3,1.2h-3.9v-3.3h2.9c0,.4.1.8.2,1.1h1.5v-3.6h-1.5c-.1.3-.2.6-.2,1.1h-2.9v-3.1h3.8c0,.4.1.7.3,1.2h1.7v-2.7H0v1.3c.6.1,1.2.3,1.9.3v7.8c-.6,0-1.3.1-1.9.3v1.3h10ZM27.4,38.6v-1.3h-5.3v1.3c.4.1,1,.2,1.6.3v3.4c0,1.4,0,2.5,0,3.2h0c-.4-.7-.9-1.5-1.3-2.1l-3.9-6h-4.9v1.3c.6.1,1.2.3,1.9.3v7.8c-.6,0-1.3.1-1.9.3v1.3h5.7v-1.3c-.4-.1-1-.2-1.6-.3v-4.3c0-1,0-1.7,0-2.3h0c.3.7.8,1.4,1.2,2.1l4,6.1h3.1v-9.4c.5,0,1-.2,1.4-.3ZM41.6,43.9v-1.3h-5.2v1.3c.5.1,1.1.2,1.6.3v2.2c-.5.1-1,.2-1.6.2-2.1,0-3.6-1.3-3.6-3.7s1.6-3.9,3.7-3.9,1.2,0,1.6.2c0,.5.1,1,.2,1.7h1.6v-3.3c-1.2-.3-2.3-.4-3.5-.4-3.9,0-6.1,2.6-6.1,5.9s2.5,5.5,5.8,5.5,2.9-.2,4.2-.7v-3.7c.4,0,.9-.1,1.3-.2ZM54.4,45c0-1.2-.7-2.2-2-2.5h0c1.2-.5,1.8-1.4,1.8-2.5,0-1.7-1.2-2.7-4-2.7s-3,0-4.1,0h-1.9v1.3c.6.1,1.2.3,1.9.3v7.8c-.6,0-1.3.1-1.9.3v1.3h1.9c1.5,0,2.8,0,3.6,0,3.7,0,4.7-1.9,4.7-3.4ZM51.8,40.3c0,1.1-1,1.6-2.5,1.6s-.5,0-.9,0v-3c.4,0,.9,0,1.3,0,1.6,0,2,.6,2,1.4ZM52,45c0,1-.7,1.7-2.3,1.7s-1,0-1.3,0v-3.2c.3,0,.7,0,1,0,1.5,0,2.6.4,2.6,1.6ZM67.8,48.3v-2.8h-1.7c-.1.5-.2.8-.3,1.2h-3.9v-3.3h2.9c0,.4.1.8.2,1.1h1.5v-3.6h-1.5c-.1.3-.2.6-.2,1.1h-2.9v-3.1h3.8c0,.4.1.7.3,1.2h1.7v-2.7h-9.9v1.3c.6.1,1.2.3,1.9.3v7.8c-.6,0-1.3.1-1.9.3v1.3h10ZM82.2,48.3v-1.3c-.4-.1-.9-.2-1.4-.3l-1.6-2.4c-.2-.3-.5-.7-.7-1,1.6-.3,2.9-1.3,2.9-3.1s-1.7-3.1-4.2-3.1-3.1,0-3.9,0h-1.8v1.3c.6.1,1.2.3,1.9.3v7.8c-.6,0-1.3.1-1.9.3v1.3h5.5v-1.3c-.4-.1-.9-.2-1.4-.3v-3h.4l2.8,4.5h3.3ZM78.9,40.5c0,1-.8,1.7-2.1,1.7s-.8,0-1.1,0v-3.2c.3,0,.7,0,1.1,0,1.3,0,2.1.5,2.1,1.5ZM93.8,44.9c0-3.6-5.7-2.6-5.7-4.7s.7-1.4,2.1-1.4.8,0,1.2,0c0,.5.1,1,.3,1.6h1.5v-3.1c-.9-.1-2-.3-2.9-.3-3,0-4.7,1.4-4.7,3.4,0,3.6,5.7,2.5,5.7,4.7s-1.1,1.6-2.2,1.6-1,0-1.4-.1c0-.5-.1-1-.2-1.6h-1.5v3.2c.9.2,2.1.3,3,.3,2.8,0,4.9-1.4,4.9-3.6Z"
      />
      <path style={{ ...styles, fill: '#0e9bd6' }} d="M25,57h44v-2H25v2Z" />
    </svg>
  );
};
