import React from 'react';
import { getProjectInfo, useAccount } from '@frontastic-engbers/lib';
import { Order } from '@frontastic-engbers/types/cart/Order';

type Props = {
  order: Order;
};

export const TrustedShopsCheckout: React.FC<Props> = ({ order }) => {
  const { account } = useAccount();
  const projectInfo = getProjectInfo();

  return (
    <div id="trustedShopsCheckout" style={{ display: 'none' }}>
      <span id="tsCheckoutOrderNr">{order.orderId}</span>
      <span id="tsCheckoutBuyerEmail">{account?.email || order.email}</span>
      <span id="tsCheckoutOrderAmount">{order.sum.centAmount / 100}</span>
      <span id="tsCheckoutOrderCurrency">{order.sum.currencyCode}</span>
      <span id="tsCheckoutOrderPaymentType">{order.payments[0].paymentMethodName}</span>

      {projectInfo?.projectId === 'en' && // product reviews only for EN, not EA
        order.lineItems.map((item, index) => (
          <span className="tsCheckoutProductItem" key={index}>
            <span className="tsCheckoutProductUrl">{location.origin + item._url}</span>
            <span className="tsCheckoutProductImageUrl">{item.variant.images[0]}</span>
            <span className="tsCheckoutProductName">{item.name}</span>
            <span className="tsCheckoutProductSKU">{item.variant.sku}</span>
            <span className="tsCheckoutProductGTIN">{item.variant.attributes.EAN}</span>
            <span className="tsCheckoutProductBrand">{item.variant.attributes.DesignerName}</span>
          </span>
        ))}
    </div>
  );
};
