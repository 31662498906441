import React, { useCallback, useState } from 'react';
import { useAccount } from '@frontastic-engbers/lib';
import { Block, Button, InputText } from '@engbers/components';
import { useToastNotificationsActions } from '@frontastic-engbers/lib/state/notification/actions';
import { EmailValidatorHelper } from '@frontastic-engbers/helpers/emailValidatorHelper';
import { IFTLinkReference, IFTPageFolderReference } from '@frontastic-engbers/types/engbers-custom';
import { getReferenceTarget } from '@frontastic-engbers/helpers/reference';
import { useRouter } from 'next/router';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';

interface IChangeEmailFormComponent {
  doiNotificationLink?: IFTLinkReference | IFTPageFolderReference;
}

type ChangeEmailFormState = {
  email: string;
  loginPassword: string;
};

const validate = (data: ChangeEmailFormState) => {
  return {
    email: data.email !== '',
    loginPassword: data.loginPassword !== '',
  };
};

export const ChangeEmailForm: React.FC<IChangeEmailFormComponent> = ({ doiNotificationLink }) => {
  const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { changeEmail, logout } = useAccount();
  const router = useRouter();
  const { pushNotification } = useToastNotificationsActions();
  const [errors, setErrors] = useState({
    email: true,
    loginPassword: true,
  });
  const [emailErrors, setEmailErrors] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<ChangeEmailFormState>({
    email: '',
    loginPassword: '',
  });

  const mailMessage = formatErrorMessage({ id: 'email.wrong', defaultMessage: 'E-Mail Adresse format ist invalid.' });
  const mailErrorMessage = formatAccountMessage({
    id: 'email.enter.again',
    defaultMessage: 'Please enter your e-mail address.',
  });
  const passwordMessage = formatAccountMessage({
    id: 'password.enter',
    defaultMessage: 'Please enter your current password.',
  });

  const handleSubmit = useCallback(async () => {
    const err = validate(data);
    setErrors(err);

    err.email = await validateEmail();

    if (Object.values(err).some((error) => !error)) {
      return;
    }

    setLoading(true);
    try {
      const changeEmailResponse = await changeEmail(data.email, data.loginPassword);
      const redirectLink = doiNotificationLink ? getReferenceTarget(doiNotificationLink) : '/login';

      if (changeEmailResponse.status === 'SUCCESS') {
        pushNotification(
          formatAccountMessage({
            id: 'account.changeSuccess',
            defaultMessage: 'Your data has been successfully updated',
          }),
          'success',
        );

        await logout();
        return router.push(redirectLink);
      } else if (changeEmailResponse.status === 'UNKNOWN_ERROR') {
        pushNotification(
          formatErrorMessage({
            id: 'wentWrong',
            defaultMessage: 'Sorry. Something went wrong..',
          }),
          'error',
        );

        setLoading(false);
        return;
      } else if (changeEmailResponse.status === 'PASSWORD_NOT_MATCH') {
        pushNotification(
          formatAccountMessage({
            id: 'account.passwordWrong',
            defaultMessage: 'Sorry, your password does not match',
          }),
          'error',
        );
      } else if (changeEmailResponse.status === 'EMAIL_USED') {
        pushNotification(
          formatAccountMessage({
            id: 'account.haveAlready',
            defaultMessage: 'Email is already used',
          }),
          'error',
        );
      }

      setLoading(false);
      return;
    } catch (e) {
      pushNotification(
        formatErrorMessage({
          id: 'wentWrong',
          defaultMessage: 'Sorry. Something went wrong..',
        }),
        'error',
      );

      setLoading(false);
      return;
    }
  }, [setLoading, data]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setData((prev) => ({ ...prev, [event.target.name]: event.target.value }));
    },
    [setData],
  );

  const validateEmail = async (): Promise<boolean> => {
    const ruleDefinitions: string | null = await EmailValidatorHelper.ruleDefinitions(data.email);

    if (!ruleDefinitions) {
      setEmailErrors(null);
      setErrors((prevErrors) => ({ ...prevErrors, email: true }));
      return true;
    }

    setEmailErrors(ruleDefinitions);
    setErrors((prevErrors) => ({ ...prevErrors, email: false }));

    return false;
  };

  return (
    <Block>
      <Block marginBottom={4}>
        <InputText
          placeholder="E-Mail-Adresse"
          name="email"
          type="email"
          required
          onChange={handleChange}
          value={data.email}
          onBlur={validateEmail}
          errorMessage={!emailErrors ? undefined : emailErrors === 'empty' ? mailErrorMessage : mailMessage}
        />
      </Block>
      <Block marginBottom={4}>
        <InputText
          placeholder="Passwort"
          name="loginPassword"
          type="password"
          required
          onChange={handleChange}
          value={data.loginPassword}
          onBlur={() => setErrors({ ...errors, loginPassword: data.loginPassword !== '' })}
          errorMessage={!errors.loginPassword ? passwordMessage : undefined}
        />
      </Block>
      <Block marginBottom={4}>
        <Button size="large" isLoading={loading} label="Speichern" onClick={handleSubmit} />
      </Block>
    </Block>
  );
};
