import classnames from 'classnames';
import { CustomMarkdown } from '@engbers/components/custom-markdown';
import styles from './table.module.scss';

export interface ITable {
  tableHeadline: string;
  tableColumns: Column[];
  isSizeAdvisorTable?: boolean;
}

export interface Column {
  tableCells: Cell[];
}

export interface Cell {
  tableCellText: string;
}

export const Table: React.FC<ITable> = ({ tableHeadline, tableColumns, isSizeAdvisorTable = false }) => {
  if (!tableColumns.length) {
    return null;
  }

  const count = tableColumns.map((column) => column.tableCells.length);
  const cellsCount = Math.max(...count);

  return (
    <div className={styles.tableWrapper}>
      {tableHeadline && (
        <div className={styles.tableHeadline}>
          <CustomMarkdown text={tableHeadline} />
        </div>
      )}
      <div className="overflow-auto">
        <table className={classnames(styles.table, { [styles.sizeAdvisorTable]: isSizeAdvisorTable })}>
          <tbody>
            {Array.from({ length: cellsCount }).map((_, index) => (
              <tr key={`tr-${index}`}>
                {tableColumns.map((column, i) => (
                  <td
                    key={`td-${index}-${i}`}
                    className={classnames(styles.tableTd, {
                      [styles.firstColumn]: i === 0,
                    })}
                  >
                    <CustomMarkdown text={column.tableCells[index]?.tableCellText} />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
