import { SeparationLine } from '@engbers/components/online-shops/separation-line'

export const SeparationLineTastic = ({ data }) => {
  return <SeparationLine
    sizeTop={data.sizeTop}
    sizeBottom={data.sizeBottom}
    height={data.height}
    isFullWidth={data.isFullWidth}
  />;
};
