import React from 'react';
import { IFTLinkReference, IFTPageFolderReference } from '@frontastic-engbers/types/engbers-custom';
import { NavigationSideHeader } from './components/navigation-side-header';
import { NavigationSideNavigationItem } from './components/navigation-side-navigation-item';
import styles from './navigation-side.module.scss';

interface INavigationSideItem {
  label: string;
  textSize?: number;
  id: string;
  reference?: IFTPageFolderReference | IFTLinkReference;
  isLogoutButton?: boolean;
  counter?: number;
  icon: {
    width: number;
    url: string;
  };
}

export interface INavigationSide {
  items: INavigationSideItem[];
  bannerPicture: any;
  headline: string;
  textSize?: number;
  headlineTextColor: string;
  headlineBgColor: string;
  headlinePosition: 'flex-start' | 'center' | 'flex-end';
  topIcon: {
    url: string;
    width: number;
  };
}

export const NavigationSide: React.FC<INavigationSide> = ({
  items,
  bannerPicture,
  headline,
  textSize,
  headlineTextColor,
  headlineBgColor,
  headlinePosition,
  topIcon,
}) => {
  return (
    <nav className={styles.navigation}>
      <div>
        <NavigationSideHeader
          topImage={bannerPicture}
          headline={headline}
          textSize={textSize}
          headlineTextColor={headlineTextColor}
          headlineBgColor={headlineBgColor}
          headlinePosition={headlinePosition}
          icon={topIcon}
        />
      </div>
      <ul>
        {items?.map((item) => {
          return (
            <li className={styles.navigationItem} key={`navigation-side-${item.id}`}>
              <NavigationSideNavigationItem
                isLogoutButton={item.isLogoutButton}
                reference={item.reference}
                label={item.label}
                textSize={item.textSize}
                icon={item.icon}
                counter={item.counter}
              />
            </li>
          );
        })}
      </ul>
    </nav>
  );
};
