import { useEffect, useState } from 'react';
import {
  OrderHistoryData,
  OrderHistoryLineItemProduct,
  OrderHistoryLineItemVoucher,
} from '@frontastic-engbers/types/account/Account';
import { FormattedAmount, Typography } from '@engbers/components';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import styles from './order-calculation.module.scss';

export const OrderCalculation: React.FC<{ order: OrderHistoryData }> = ({ order }) => {
  const [isMinWidthMedium, setIsMinWidthMedium] = useState<boolean>(false);
  const isMobile = useMediaQuery({ maxWidth: 1024 });

  useEffect(() => {
    if (isMobile !== isMinWidthMedium){
      setIsMinWidthMedium(isMobile);
    }
  }, [isMobile]);

  const vouchers = order.lineItems.filter((item) => item.type === 'voucher');
  const voucherAmount = vouchers.reduce(
    (accumulator, currentValue) => accumulator + (currentValue as OrderHistoryLineItemVoucher).voucher.amount,
    0,
  );
  const products = order.lineItems.filter((item) => item.type !== 'voucher');
  const productsAmount = products.reduce(
    (accumulator, currentValue) => accumulator + (currentValue as OrderHistoryLineItemProduct).product.amount,
    0,
  );
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });

  if (order.type === 'RET') {
    return (
      <div className={styles.wrap} style={{
        order: isMinWidthMedium ? 0 : 1,
      }}>
        <div className={classNames(styles.row, styles.sumRow)}>
          <span>{formatAccountMessage({ id: 'orders.total.amount' })}:</span>
          <Typography weight={'bold'} size="l">
            <FormattedAmount amount={order.invoiceAmount} />
          </Typography>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.wrap}  style={{
      order: isMinWidthMedium ? 0 : 1,
    }}>
      <div className={styles.row}>
        <span>{formatAccountMessage({ id: 'orders.subtotal' })}:</span>
        <Typography size="l" weight={'bold'}>
          <FormattedAmount amount={productsAmount} />
        </Typography>
      </div>
      <div className={styles.row}>
        <span>{formatCartMessage({ id: 'serviceShipping' })}:</span>
        <Typography size="l" weight={'bold'}>
          <FormattedAmount amount={order.markupAmount} />
        </Typography>
      </div>
      {voucherAmount ? (
        <div className={styles.row}>
          <span>{formatCartMessage({ id: 'promotional.Vouchers' })}:</span>
          <Typography size="l" weight={'bold'}>
            <FormattedAmount amount={voucherAmount} />
          </Typography>
        </div>
      ) : null}
      <div className={classNames(styles.row, styles.sumRow)}>
        <span>{formatAccountMessage({ id: 'orders.total.amount' })}:</span>
        <Typography weight={'bold'} size="l">
          <FormattedAmount amount={order.invoiceAmount} />
        </Typography>
      </div>
      <div className={styles.subNoteRow}>
        <Typography size="base">{formatCartMessage({ id: 'inclTax' })}</Typography>
      </div>
    </div>
  );
};
