import { EnGermanyImageSlider } from '@engbers/components/online-shops/enGermany-image-slider';
import { FullPageWidthWrapper } from '@engbers/components/online-shops';

export const EnGermanyImageSliderTastic = ({ data }) => {
  if (data.isFullWidth) {
    return (
      <FullPageWidthWrapper>
        <EnGermanyImageSlider arrowIcon={data.arrowIcon} autoPlayDelay={data.autoPlayDelay} sliderItems={data.sliderItems} />
      </FullPageWidthWrapper>
    );
  }

  return <EnGermanyImageSlider arrowIcon={data.arrowIcon} autoPlayDelay={data.autoPlayDelay} sliderItems={data.sliderItems} />
};
