import { MyAccount } from '@engbers/components/my-account';
import { FullPageWidthWrapper } from '@engbers/components/online-shops';
import { getProjectInfo } from '@frontastic-engbers/lib';

export const MyAccountTastic = ({ data }) => {
  const projectInfo = getProjectInfo();
  const isEmilio = projectInfo?.projectId === 'ea';

  if (data.isFullWidth) {
    return (
      <FullPageWidthWrapper>
        <MyAccount title={data.title} textSize={data.textSize} navPoints={data.navPoints} isEmilio={isEmilio} />
      </FullPageWidthWrapper>
    );
  }

  return <MyAccount title={data.title} textSize={data.textSize} navPoints={data.navPoints} isEmilio={isEmilio} />;
};
