import React from 'react';
import { HeaderMinimized } from '@engbers/components/header-minimized';
import { FullPageWidthWrapper } from '@engbers/components/online-shops';

export const HeaderCheckoutTastic = ({ data }) => {
  return (
    <FullPageWidthWrapper>
      <HeaderMinimized
        mobileCheckoutLogo={data.mobileCheckoutLogo}
        mobileCheckoutLogoLink={data.mobileCheckoutLogoLink}
        trustedShopIcon={data.trustedShopIcon}
        trustedShopText={data.trustedShopText}
        isEnGermany={false}
        isCheckout
        isEmilio={data.isEmilio}
      />
    </FullPageWidthWrapper>
  );
};
