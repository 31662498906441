import { InputToggle, Typography } from '@engbers/components';
import { MediaType } from '@frontastic-engbers/types/engbers-custom';
import { useImageSEO } from '@frontastic-engbers/helpers/hooks/useImageSEO';
import styles from './newsletter-signup-items-item.module.scss';

type NewsletterSignupItemsItemProps = {
  description: string;
  image?: MediaType;
  isActive?: boolean;
  onChange: () => void;
  labels: any;
};

export const NewsletterSignupItemsItem = ({
  description,
  image,
  isActive,
  labels,
  onChange,
}: NewsletterSignupItemsItemProps) => {
  const { getTitle } = useImageSEO();

  return (
    <div>
      <div>
        <img className={styles.logo} src={image.media.file} alt={getTitle(image)} title={getTitle(image)} />
      </div>
      <div className={styles.description}>
        <Typography tag="div" size="l">
          {description}
        </Typography>
      </div>
      <div className={styles.subscriptionRow}>
        <div>
          <Typography weight="bold" size="xxl">
            {labels.subscribeRadioLabel}
          </Typography>
        </div>
        <div>
          <InputToggle isActive={isActive} onChange={onChange} />
        </div>
      </div>
    </div>
  );
};
