import React, { useEffect, useMemo, useState } from 'react';
import algoliasearch from 'algoliasearch/lite';
import { Configure, InstantSearch, useHits } from 'react-instantsearch';
import { useInstantSearchClient } from '@frontastic-engbers/lib';
import { useRouter } from 'next/router';
import LocalizedIndex from '@frontastic-engbers/lib/provider/algolia/localized-index';
import { queryProductsByIds } from '@frontastic-engbers/lib/actions/product';
import { Product } from '@frontastic-engbers/types/product/Product';
import { IFTLinkReference, IFTPageFolderReference } from '@frontastic-engbers/types/engbers-custom';
import ProductSlider from '@engbers/components/online-shops/product-slider';
import { EngbersGermanyProductSlider } from '@engbers/components/online-shops/engbers-germany-product-slider';
import { FullPageWidthWrapper } from '@engbers/components/online-shops';
import { ProductMapper } from '@frontastic-engbers/lib/lib/mappers/productMapper';
import { useEngbersFlagsConfig } from '@frontastic-engbers/lib/actions/config';
import { Markdown } from '@engbers/components';

type AlgoliaSliderData = {
  isFullWidth: boolean;
  title?: string;
  textSize?: number;
  productIds?: string;
  config?: any;
  isEnGermanySlider: boolean;
  enGermanySliderDelay: number;
  enGermanySubheadline: string;
  enGermanyHeadline: string;
  enGermanyLinkText: string;
  enGermanyLink: IFTLinkReference | IFTPageFolderReference;
};

interface AlgoliaSliderDataExtended extends AlgoliaSliderData {
  defaultFilters: string;
  ruleContexts: string[];
}

type Props = {
  data: AlgoliaSliderData;
};

export const AlgoliaSliderTastic: React.FC<Props> = ({ data }) => {
  if (data.isFullWidth) {
    return (
      <FullPageWidthWrapper>
        <AlgoliaSliderWrapper {...data} />
      </FullPageWidthWrapper>
    );
  }

  return <AlgoliaSliderWrapper {...data} />;
};

const AlgoliaSliderWrapper: React.FC<AlgoliaSliderData> = (data) => {
  const defaultFilters = useMemo(() => {
    return data.config?.dataSource?.filters ?? '';
  }, [data.config?.dataSource?.filters]);

  const ruleContexts = useMemo(() => {
    return data.config?.dataSource?.ruleContexts ?? [];
  }, [data.config?.dataSource?.ruleContexts]);

  if (!defaultFilters && !ruleContexts.length && !data.productIds) {
    return null;
  }

  return (
    <>
      {data.title && <Markdown className="text-center uppercase" text={data.title} textSize={data.textSize} />}
      {data.config && defaultFilters ? (
        <AlgoliaSlider {...data} defaultFilters={defaultFilters} ruleContexts={ruleContexts} />
      ) : (
        <ProductIdsSlider {...data} />
      )}
    </>
  );
};

const AlgoliaSlider: React.FC<AlgoliaSliderDataExtended> = ({ defaultFilters, ruleContexts, ...data }) => {
  const searchClient = useInstantSearchClient(data.config.dataSource);
  algoliasearch(data.config.dataSource.appId, data.config.dataSource.appKey);

  return (
    <InstantSearch searchClient={searchClient}>
      <LocalizedIndex config={data.config.dataSource}>
        <Configure filters={defaultFilters} ruleContexts={ruleContexts} hitsPerPage={16} />
        <HitsSlider {...data} />
      </LocalizedIndex>
    </InstantSearch>
  );
};

const HitsSlider = (data: AlgoliaSliderData) => {
  const { hits } = useHits();
  const router = useRouter();
  const [products, setProducts] = useState<Product[]>([]);
  const locale = router.locale || router.defaultLocale;
  const { flagsConfig, isLoading } = useEngbersFlagsConfig();

  useEffect(() => {
    if (!isLoading) {
      setProducts(
        hits.map((hit) =>
          ProductMapper.algoliaHitToProduct(hit, {
            locale,
            flagsConfig,
          }),
        ),
      );
    }
  }, [hits, isLoading]);

  if (products.length === 0) {
    return null;
  }

  return data.isEnGermanySlider ? (
    <EngbersGermanyProductSlider
      products={products}
      isFullWidth={data.isFullWidth}
      enGermanySliderDelay={data.enGermanySliderDelay}
      enGermanySubheadline={data.enGermanySubheadline}
      enGermanyHeadline={data.enGermanyHeadline}
      enGermanyLinkText={data.enGermanyLinkText}
      enGermanyLink={data.enGermanyLink}
    />
  ) : (
    <ProductSlider products={products} useHoverBox={true} />
  );
};

const ProductIdsSlider = (data: AlgoliaSliderData) => {
  const ids = data.productIds.split(';');
  const [products, setProducts] = useState<Product[]>([]);

  useEffect(() => {
    if (ids.length > 0) {
      queryProductsByIds(ids).then((items) => {
        setProducts(items);
      });
    }
  }, [data.productIds]);

  if (products.length === 0) {
    return null;
  }

  return data.isEnGermanySlider ? (
    <EngbersGermanyProductSlider
      products={products}
      isFullWidth={data.isFullWidth}
      enGermanySliderDelay={data.enGermanySliderDelay}
      enGermanySubheadline={data.enGermanySubheadline}
      enGermanyHeadline={data.enGermanyHeadline}
      enGermanyLinkText={data.enGermanyLinkText}
      enGermanyLink={data.enGermanyLink}
    />
  ) : (
    <ProductSlider products={products} />
  );
};
