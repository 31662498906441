import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, IconCustom, InputCheckbox } from '@engbers/components';
import styles from './store-finder.module.scss';
import { fetchApiHub } from '@frontastic-engbers/lib';
import Spinner from '../../../online-shops/commercetools-ui/spinner';

export const StoreFinder = ({ selectedStoreId, currentZipCode, setSelectedStoreId, labels }) => {
  const [foundStores, setFoundStores] = useState([]);
  const [isInitial, setIsInitial] = useState(true);
  const [isLoadingList, setIsLoadingList] = useState(false);
  const [isLoadingResult, setIsLoadingResult] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedSearchTerm, setSelectedSearchTerm] = useState('');
  const [checkedStoreId, setCheckedStoreID] = useState('');
  const [hasOpenStorefinder, setHasOpenStorefinder] = useState(false);
  const [selectedStoreDetails, setSelectedStoreDetails] = useState<any | undefined>(undefined);

  const chooseZip =  useCallback(async () => {
    const foundZips = await search(currentZipCode);
    if (foundZips?.[0]) {
      setSelectedStoreId(foundZips[0].storeId);
      setSelectedStoreDetails(foundZips[0]);
    }
  }, [currentZipCode]);

  const getInitialState = async () => {
    setIsLoadingResult(true);
    if (String(currentZipCode)?.length === 5) {
      await chooseZip();
    } else {
      setSelectedStoreId('');
    }
    setIsLoadingResult(false);
    setIsInitial(false);
  };

  const reactToZipChanges = useCallback(async () => {
    if (!selectedStoreId) {
      setIsLoadingResult(true);
      await chooseZip();
      setIsLoadingResult(false);
    }
  }, [selectedStoreId, chooseZip]);

  // later zip changes reaction
  useEffect(() => {
    if (!isInitial && String(currentZipCode)?.length === 5) {
      reactToZipChanges();
    }
  }, [reactToZipChanges, currentZipCode, isInitial]);

  // Initial adjustment of storeid
  useEffect(() => {
    // if store id already given but don't have details, fetch details
    getInitialState();
  }, []);

  const toggleStorefinder = useCallback(() => {
    setHasOpenStorefinder((prev) => !prev);
  }, []);

  const search = useCallback(
    async (searchTerm: string) => {
      const returnedStores = await fetchApiHub(`/action/engbers/getStores?search=${searchTerm}`, { method: 'GET' });
      setFoundStores(returnedStores.stores?.stores);
      return returnedStores.stores?.stores;
    },
    [selectedSearchTerm],
  );

  const onSearchInput = useCallback(
    (event) => {
      setCheckedStoreID('');
      setSearchTerm(event.target.value);
    },
    [setSearchTerm],
  );

  const selectTemporarySelectStoreId = useCallback((storeId: string) => {
    setCheckedStoreID(storeId);
  }, []);

  const confirmTemporarySelectedStore = useCallback(() => {
    setSelectedStoreId(checkedStoreId);
    setSelectedStoreDetails(foundStores.find((store) => store.storeId === checkedStoreId));
    setCheckedStoreID('');
    setSelectedSearchTerm('');
    setSearchTerm('');
    setHasOpenStorefinder(false);
  }, [checkedStoreId]);

  const currentStore = useMemo(() => {
    return selectedStoreDetails;
  }, [selectedStoreId, foundStores, selectedStoreDetails]);

  const onRemoveSearchTerm = useCallback(() => {
    setSearchTerm('');
    setSelectedSearchTerm('');
  }, []);

  const startSearch = useCallback(async () => {
    setIsLoadingList(true);
    setSelectedSearchTerm(searchTerm);
    await search(searchTerm);
    setIsLoadingList(false);
    return false;
  }, [searchTerm, search]);

  const onInputKeyDown = useCallback(
    (event: any) => {
      if (event.key === 'Enter') {
        event.stopPropagation();
        event.preventDefault();
        startSearch();
      }
    },
    [startSearch],
  );

  return (
    <div className={styles.wrap}>
      {currentStore && !isLoadingResult && (
        <div>
          <div className={styles.selectedItem}>
            <div>{currentStore.name}</div>
            <div>{`${currentStore.street} ${currentStore.streetNo}`}</div>
            <div className={styles.selectedItemZip}>{`${currentStore.zip} ${currentStore.city}`}</div>
            <div>{`Filial-Nr. ${currentStore.storeId}`}</div>
          </div>
        </div>
      )}
      {isLoadingResult && (
        <div className={styles.selectedItem}>
          <Spinner size="x-small" color="#0f202f" />
        </div>
      )}
      {
        selectedStoreId && (
          <div className={styles.changeButton} onClick={toggleStorefinder}>
            {labels.changeStore}
          </div>
        )
      }
      {(hasOpenStorefinder || !selectedStoreId) && (
        <div>
          <div className={styles.searchWrap}>
            <div className={styles.searchInputWrap}>
              <input
                name="search"
                onKeyDown={onInputKeyDown}
                value={searchTerm}
                placeholder="Stadt oder PLZ"
                className={styles.searchInput}
                onChange={onSearchInput}
                type="search"
              />
              {!!searchTerm && (
                <div className={styles.removeButton} onClick={onRemoveSearchTerm}>
                  <IconCustom width={10} icon="Close" />
                </div>
              )}
              <div className={styles.searchButtonWrap} onClick={startSearch}>
                <IconCustom width={24} icon="Search" />
              </div>
            </div>
            {isLoadingList && (
              <div className={styles.searchResultsWrap}>
                <div className={styles.searchResultItem}>
                  <Spinner size="x-small" color="#0f202f" />
                </div>
              </div>
            )}
            {!!foundStores?.length && !isLoadingList && (
              <div className={styles.searchResultsWrap}>
                {foundStores.map((store) => {
                  return (
                    <div
                      onClick={() => selectTemporarySelectStoreId(store.storeId)}
                      className={styles.searchResultItem}
                    >
                      <div>{store.name}</div>
                      <div>{`${store.street} ${store.streetNo}`}</div>
                      <div className={styles.searchResultItemCity}>{`${store.zip} ${store.city}`}</div>
                      <div>{`Filial-Nr. ${store.storeId}`}</div>

                      <div className={styles.searchResultItemCheckboxWrap}>
                        <InputCheckbox style="white" checked={store.storeId === checkedStoreId} />
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          {!!foundStores?.length && !isLoadingList && (
            <div className={styles.doneButtonWrap} onClick={confirmTemporarySelectedStore}>
              <Button disabled={!checkedStoreId} hasIcon={false} label="Fertig" />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
