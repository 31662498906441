import classnames from 'classnames';
import { Money } from '@frontastic-engbers/types/product/Money';
import { CurrencyHelpers } from '@frontastic-engbers/helpers/currencyHelpers';
import styles from './price.module.scss';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';

export interface PriceProps {
  price: Money;
  discountedPrice?: Money;
  count?: number;
  className?: string;
  isBold?: boolean;
  isGiftCard?: boolean;
  showCurrencySymbol?: boolean;
}

const Price: React.FC<PriceProps> = ({
  price: { currencyCode = 'EUR', ...price },
  discountedPrice,
  count,
  className,
  isBold,
  isGiftCard,
  showCurrencySymbol,
}) => {
  const { formatMessage } = useFormat({ name: 'common' });
  const currentPrice = {
    ...price,
    centAmount: price.centAmount * count,
  };

  const showCurrencyCode = showCurrencySymbol ?? false;

  return (
    <div
      className={classnames(
        className,
        styles.priceWrap,
        isBold ? styles.isBold : undefined,
        discountedPrice ? styles.isDiscounted : undefined,
      )}
    >
      {isGiftCard && (
        <div className={styles.giftcardLabel}>
          {formatMessage({
            id: 'price.from',
            defaultMessage: 'ab',
          })}
        </div>
      )}
      <div
        className={
          discountedPrice && currentPrice.centAmount !== discountedPrice.centAmount ? styles.oldPrice : undefined
        }
      >
        {CurrencyHelpers.formatForCurrency(count && currentPrice ? currentPrice : price, showCurrencyCode).replace(
          /\u00A0/g,
          ' ',
        )}
      </div>
      {discountedPrice && currentPrice.centAmount !== discountedPrice.centAmount && (
        <div className={styles.newPrice}>
          {CurrencyHelpers.formatForCurrency(discountedPrice, showCurrencyCode).replace(/\u00A0/g, ' ')}
        </div>
      )}
    </div>
  );
};

export default Price;
