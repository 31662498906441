import React, { Fragment, useEffect, useRef, useState } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useInfiniteHits, useInstantSearch } from 'react-instantsearch';
import md5 from 'crypto-js/md5';
import classnames from 'classnames';
import { useEngbersFlagsConfig } from '@frontastic-engbers/lib/actions/config';
import { ProductMapper } from '@frontastic-engbers/lib/lib/mappers/productMapper';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { TagManager } from '@frontastic-engbers/lib/lib/tracking';
import { ProductUtils } from '@frontastic-engbers/helpers/utils/productUtils';
import { ItemMapper } from '@frontastic-engbers/lib/lib/tracking/itemMapper';
import { fetchApiHub, getProjectInfo } from '@frontastic-engbers/lib';
import { MediaType, ProductListMedia } from '@frontastic-engbers/types/engbers-custom';
import { Outfit } from '@frontastic-engbers/types/product/Outfit';
import { Product } from '@frontastic-engbers/types/product/Product';
import { Button, LoadingIndicatorInline, Markdown } from '@engbers/components';
import { ProductBox } from '../product-box';
import { OutfitProductBox } from '../outfit-product-box';
import { MediaTile } from '../media-tile';
import { OutfitSlider } from '@engbers/components/online-shops/product-slider/outfit-slider';
import styles from './infinite-hits.module.scss';
import {
  getEcommProductsIds,
  getEcommProductsTotalValue,
} from '@frontastic-engbers/helpers/dataLayerHelper/ecommProductsHelper';
import { useCanonical } from '@frontastic-engbers/helpers/seoHelper';

interface IInfiniteHits {
  hitsPerPage: number;
  progressBarText?: string;
  progressBarEndText?: string;
  isOutfit?: boolean;
  mediaConfig?: ProductListMedia;
  categoryId: string;
  categoryName: string;
  indexName?: string;
  filterQuery?: string;
  showOutfitSlider: boolean;
  outfitSliderHeadline: string;
  outfitSliderCount: number;
  outfitSliderRowNumber: number;
  isEnGermanyProductList?: boolean;
  enGermanySubheadline?: string;
  enGermanyHeadline?: string;
  searchQuery?: string;
  isEmilio?: boolean;
  fallbackImage?: MediaType;
}

export const InfiniteHits: React.FC<IInfiniteHits> = ({
  hitsPerPage,
  progressBarText,
  progressBarEndText,
  indexName,
  isOutfit = false,
  mediaConfig,
  categoryId,
  categoryName,
  filterQuery,
  showOutfitSlider,
  outfitSliderHeadline,
  outfitSliderCount,
  outfitSliderRowNumber,
  isEnGermanyProductList,
  enGermanySubheadline,
  enGermanyHeadline,
  searchQuery,
  isEmilio,
  fallbackImage,
}) => {
  const router = useRouter();
  const [sliderOutfits, setSliderOutfits] = useState<Outfit[]>([]);
  const sentinelRef = useRef(null);
  const productListWrap = useRef(null);
  const { hits, isFirstPage, isLastPage, showMore, results } = useInfiniteHits({ showPrevious: true });
  const { status, setUiState, uiState } = useInstantSearch();
  const { formatMessage } = useFormat({ name: 'product' });
  const projectInfo = getProjectInfo();
  const [lastTrackedItems, setLastTrackedItems] = useState<string>('');
  const [products, setProducts] = useState<Product[]>([]);
  const { isLoading, flagsConfig } = useEngbersFlagsConfig();
  const locale = router.locale || router.defaultLocale;
  const { getCanonical } = useCanonical();

  let direction: 'up' | 'down' = null;
  let scrollPos = 0;

  const getSliderOutfits = async (filters) => {
    const sliderOutfitsCache = JSON.parse(sessionStorage.getItem('pl_outfit_slider_cache'));
    const outfits =
      sliderOutfitsCache && sliderOutfitsCache.filters === filters
        ? sliderOutfitsCache.outfits
        : await fetchApiHub(
          '/action/outfitSlider/getOutfitSlider',
          { method: 'POST' },
          {
            filters,
            isEmilio: projectInfo?.projectId === 'ea',
          },
        );

    sessionStorage.setItem(
      'pl_outfit_slider_cache',
      JSON.stringify({
        filters: filters,
        outfits: outfits,
      }),
    );
    setSliderOutfits(outfits);
  };

  useEffect(() => {
    if (showOutfitSlider) {
      getSliderOutfits(filterQuery);
    } else {
      setSliderOutfits([]);
    }
  }, [showOutfitSlider]);

  const detectDirection = (): void => {
    direction = document.body.getBoundingClientRect().top > scrollPos ? 'up' : 'down';
    scrollPos = document.body.getBoundingClientRect().top;
  };

  const getPrevNextLinks = () => {
    // regex to ensure we get the clean path and page, filter and query just included to "get rid of it"
    const regex = /(?<path>\/.*?)(?:\/(?<filter>(?:_.*?)+))?(?:\/(?<page>\d+)?)?(?:\/?(?<query>\?.*))?$/;
    const match = router.asPath.match(regex);
    const path = match?.groups?.path || router.asPath;

    const currentPage = match?.groups?.page ? parseInt(match?.groups?.page, 10) : 1;
    const prevPage = currentPage > 1 ? currentPage - 1 : false;
    const nextPage = currentPage < results.nbPages ? currentPage + 1 : false;

    return (
      <Head>
        <link {...getCanonical(currentPage === 1 ? path : `${path}/${currentPage}`)} />
        {prevPage && <link rel="prev" href={prevPage === 1 ? path : `${path}/${prevPage}`} />}
        {nextPage && <link rel="next" href={`${path}/${nextPage}`} />}
      </Head>
    );
  };

  const updateUiState = (pageNumber?: number): void => {
    if (pageNumber === uiState[indexName]?.page) {
      return null;
    }

    setUiState((previousUiState) => {
      const uiState = previousUiState;

      if (indexName) {
        uiState[indexName].page = pageNumber ?? uiState[indexName].page - 1;
      }

      return uiState;
    });
  };

  useEffect(() => {
    const timeOut = setTimeout(() => {
      const lastVisitedProduct = sessionStorage.getItem('storage_last_visited_product');

      if (lastVisitedProduct) {
        const { id, visited } = JSON.parse(lastVisitedProduct);
        const visitedProduct = document.getElementById(id);

        if (visitedProduct && visited) {
          visitedProduct.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
          sessionStorage.removeItem('storage_last_visited_product');
        }
      }

      clearTimeout(timeOut);
    }, 1000);
  }, [status === 'idle']);

  useEffect(() => {
    history.scrollRestoration = 'manual';
  }, []);

  useEffect(() => {
    const progressBars = document.querySelectorAll('div[data-page-separator]');

    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries.find((entry) => entry.isIntersecting);

        if (entry) {
          const currentPage =
            direction === 'up'
              ? parseInt((entry.target as HTMLDivElement).dataset.pageSeparator, 10) - 1
              : parseInt((entry.target as HTMLDivElement).dataset.pageSeparator, 10);

          updateUiState(currentPage);
        }
      },
      { rootMargin: '-50%' },
    );

    window.addEventListener('scroll', detectDirection);
    progressBars?.forEach((progressBar) => observer.observe(progressBar));

    return () => {
      window.removeEventListener('scroll', detectDirection);
      observer.disconnect();
    };
  }, [results]);

  useEffect(() => {
    if (sentinelRef.current !== null && productListWrap.current !== null) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (
            entry.isIntersecting &&
            !isLastPage &&
            productListWrap.current.clientHeight > 0 &&
            results.page < results.nbPages - 1 &&
            status === 'idle' &&
            scrollPos !== 0
          ) {
            showMore();
          }
        });
      });

      observer.observe(sentinelRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [showMore, status === 'idle']);

  useEffect(() => {
    if (!isLoading) {
      setProducts(
        hits.map((hit) =>
          ProductMapper.algoliaHitToProduct(hit, {
            locale,
            flagsConfig,
          }),
        ),
      );
    }
  }, [hits, isLoading]);

  useEffect(() => {
    if (products.length > 0) {
      const datalayerItems = products.map((product, index) =>
        ItemMapper.productToItem(product, 1, index + 1, undefined, false, categoryName),
      );
      const currentTrackedItems = md5(JSON.stringify(datalayerItems)).toString();

      if (currentTrackedItems !== lastTrackedItems && datalayerItems.length > 0) {
        new TagManager()
          .customEvent('ecomm', {
            ecomm_pagetype: searchQuery ? 'searchresults' : 'category',
            ecomm_prodid: getEcommProductsIds(products),
            ecomm_totalvalue: getEcommProductsTotalValue(products),
          })
          .viewItemListEvent(categoryId, categoryName, datalayerItems)
          .executePush();
        setLastTrackedItems(currentTrackedItems);
      }
    }
  }, [products]);

  return (
    <>
      {getPrevNextLinks()}
      <div
        ref={productListWrap}
        className={classnames(styles.productListProductsWrap, {
          [styles.enGermanyProductListProductsWrap]: isEnGermanyProductList,
        })}
      >
        {isEnGermanyProductList && (enGermanySubheadline || enGermanyHeadline) && (
          <div className={classnames('col-span-full row-span-1 mb-3 md:-mb-4', styles.enGermanyProductListTitle)}>
            {enGermanySubheadline && <div className="text-xl">{enGermanySubheadline}</div>}

            {enGermanyHeadline && <h1>{enGermanyHeadline}</h1>}
          </div>
        )}
        {!isFirstPage && (
          <div className="col-span-full mb-6 md:-mb-6">
            <Button
              label={formatMessage({
                id: 'loadPreviousProducts',
                defaultMessage: 'Vorherige Produkte laden',
              })}
              type="cta"
              size="default"
              className="m-auto uppercase"
              customStyle={{ padding: '12px 28px' }}
              hasIcon={false}
              isLoading={status !== 'idle'}
              onClick={() => updateUiState()}
              color={isEnGermanyProductList ? '#342e2b' : undefined}
            />
          </div>
        )}
        {!isOutfit && hits.length > 0 && mediaConfig?.source?.media && !isEnGermanyProductList && (
          <MediaTile
            mediaConfig={mediaConfig}
            isFirstPage={isFirstPage}
            outfitSliderRowNumber={isFirstPage ? outfitSliderRowNumber : outfitSliderRowNumber + 1}
          />
        )}
        {products.map((product, i) => {
          const index = i + 1;

          const productBox =
            isOutfit || ProductUtils.isOutfit(product) ? (
              <OutfitProductBox
                key={product.productId}
                outfit={product as Outfit}
                pageNumber={Math.ceil((product.position ?? 1) / results.hitsPerPage)}
                fallbackImage={fallbackImage}
              />
            ) : (
              <ProductBox
                key={product.productId}
                product={product}
                pageNumber={Math.ceil((product.position ?? 1) / results.hitsPerPage)}
                isEnGermany={isEnGermanyProductList}
                isEmilio={isEmilio}
                fallbackImage={fallbackImage}
              />
            );

          if (!isOutfit && !(index % hitsPerPage)) {
            const progressBarPage = Math.ceil((product.position ?? 1) / results.hitsPerPage);
            const formattedText = progressBarText
              .replace(
                '%currentProducts%',
                `<span style="font-weight: 700;"> ${results.hitsPerPage * progressBarPage} </span>`,
              )
              .replace('%maxProducts%', `<span style="font-weight: 700;"> ${results.nbSortedHits} </span>`);

            return (
              <Fragment key={`viewed-${product.productId}`}>
                {productBox}
                <div
                  key={`viewed-products-${product.productId}`}
                  className={classnames('pb-7 md:-mb-6 md:pb-0', styles.viewedProductsNote)}
                  data-page-separator={progressBarPage + 1}
                >
                  <div className={styles.progressBarWrap}>
                    <div dangerouslySetInnerHTML={{ __html: formattedText }} />
                    <progress
                      className={styles.progressBar}
                      value={results.hitsPerPage * progressBarPage}
                      max={results.nbSortedHits}
                    ></progress>
                  </div>

                  {index === hits.length && (status === 'loading' || status === 'stalled') && (
                    <div key={`loading-indicator-${i}`} className={styles.loadingIndicatorWrap}>
                      <LoadingIndicatorInline color={'blue'} />
                    </div>
                  )}
                </div>
              </Fragment>
            );
          }

          if (!isOutfit && index === products.length) {
            return (
              <Fragment key={`viewed-all-${i}`}>
                {productBox}
                <div key={`viewed-all-products-${i}`} className={styles.viewedProductsNote}>
                  <div className={styles.progressBarWrap}>
                    <div>
                      <Markdown text={progressBarEndText} />
                    </div>
                    <progress className={styles.progressBar} value={index} max={products.length}></progress>
                  </div>
                </div>
              </Fragment>
            );
          }

          return (
            <Fragment key={`outfit-slider-fragment-${i}`}>
              {index === 1 && sliderOutfits.length > 0 && !isEnGermanyProductList && (
                <OutfitSlider
                  outfits={sliderOutfits}
                  outfitSliderHeadline={outfitSliderHeadline}
                  outfitSliderCount={outfitSliderCount}
                  outfitSliderRowNumber={isFirstPage ? outfitSliderRowNumber : outfitSliderRowNumber + 1}
                />
              )}
              {productBox}
            </Fragment>
          );
        })}

        {results.page < results.nbPages - 1 && <div ref={sentinelRef} aria-hidden="true" />}
      </div>
    </>
  );
};
