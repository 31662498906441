import { TabMenu } from '@engbers/components/online-shops/tab-menu';

export const TabMenuTastic = ({ data }) => {
  return (
    <TabMenu
      items={data.tabItems.map((item) => ({
        label: item.label,
        reference: item.reference,
      }))}
    />
  );
};
