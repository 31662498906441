import classNames from 'classnames';

interface ISpacer {
  spacing: number;
}

export const Spacer: React.FC<ISpacer> = ({ spacing }) => {
  return (
    <div
      className={classNames({
        [`h-${spacing}`]: !!spacing,
      })}
    />
  );
};
