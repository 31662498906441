import { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import classnames from 'classnames';
import { useInstantSearch } from 'react-instantsearch-core';
import { NotFound } from '@engbers/components/online-shops/product-list/components/not-found';
import ProductList from '@engbers/components/online-shops/product-list/';
import ProductListProvider, { useProductList } from '@engbers/components/online-shops/product-list/context';
import { FacetConfiguration } from '@engbers/components/online-shops/product-list/types';
import { ProductListMedia } from '@frontastic-engbers/types/engbers-custom';
import { getProjectInfo, InstantSearchProvider } from '@frontastic-engbers/lib';
import LocalizedIndex, { useLocalizedIndex } from '@frontastic-engbers/lib/provider/algolia/localized-index';
import useI18n from '@frontastic-engbers/helpers/hooks/useI18n';
import { SidebarNavigation } from '@engbers/components/online-shops/sidebar-navigation';
import { TagManager } from '@frontastic-engbers/lib/lib/tracking';
import styles from './productList-sidebar.module.scss';

const ProductListTastic = ({ data, pageFolder }) => {
  const { t } = useI18n(true);
  const projectInfo = getProjectInfo();
  const { updateFacetsConfiguration } = useProductList();
  const { results } = useInstantSearch();
  const indexName = useLocalizedIndex(data.config.dataSource);
  const router = useRouter();
  const isEmilio = projectInfo?.projectId === 'ea';
  const mediaConfig: ProductListMedia = {
    source: data.mediaSource,
    reference: data.mediaReference,
    sizeTwoTiles: data.mediaSizeTwoTiles,
    videoAutoplay: data.videoAutoplay,
    videoLoop: data.videoLoop,
    videoMuted: data.videoMuted,
    videoControls: data.videoControls,
  };

  const [searchQuery, setSearchQuery] = useState<string>(undefined);
  const { uiState } = useInstantSearch();

  const facetsConfiguration = useMemo<Record<string, FacetConfiguration>>(() => {
    return (data.config.dataSource.facetsConfiguration ?? []).reduce(
      (acc, configuration) => ({
        ...acc,
        [configuration.key]: {
          type: configuration.type,
          label: configuration.label,
        } as FacetConfiguration,
      }),
      {},
    );
  }, [data.config.dataSource.facetsConfiguration]);

  const defaultFilters = useMemo(() => {
    return data.config.dataSource.filters ?? '';
  }, [data.config?.dataSource?.filters]);

  const defaultContext = useMemo(() => {
    return data.config.dataSource.ruleContexts ?? [];
  }, [data.config?.dataSource?.ruleContexts]);

  useEffect(() => {
    if (!results.__isArtificial && results.nbSortedHits === 0) {
      const econdaDataLayer = JSON.parse(sessionStorage.getItem('econda_data_layer'));
      router.push('/zero-results');

      if (econdaDataLayer) {
        econdaDataLayer.ecContent = 'Suche/Ergebnisse';
        econdaDataLayer.ecCategory = undefined;
        econdaDataLayer.ecSearchPhrase = searchQuery;
        econdaDataLayer.ecSearchResults = results.nbSortedHits;

        new TagManager().updateEcondaDataEvent(econdaDataLayer).executePush();
      }
    }
  }, [results, router]);

  useEffect(() => {
    if (results.nbSortedHits === 1) {
      router.replace(`/${t(results.hits[0].slug)}`);
    }
  }, [results, router]);

  useEffect(() => {
    updateFacetsConfiguration(facetsConfiguration);
  }, [facetsConfiguration, updateFacetsConfiguration]);

  useEffect(() => {
    if (uiState[indexName]) {
      setSearchQuery(uiState[indexName].configure?.query);
    }
  }, [uiState]);

  const hasInstantSearchConfig = useMemo(
    () => uiState[indexName]?.configure?.query?.length > 0 || uiState[indexName]?.configure?.filters?.length > 0,
    [uiState],
  );

  return (
    <div
      className={classnames({
        [styles.productListSidebarWrap]: data.sidebarNavTree,
      })}
    >
      {data.sidebarNavTree && (
        <SidebarNavigation navTree={data.sidebarNavTree} className={styles.sidebarNavigation} isEmilio={isEmilio} />
      )}

      {hasInstantSearchConfig || defaultFilters ? (
        <div
          className={classnames({
            [styles.productListWithSidebar]: data.sidebarNavTree,
          })}
        >
          <ProductList
            searchQuery={searchQuery}
            filterQuery={defaultFilters}
            contextQuery={defaultContext}
            progressBarText={data?.config?.dataSource?.progressBarText}
            progressBarEndText={data?.config?.dataSource?.progressBarEndText}
            pageFolder={pageFolder}
            indexName={indexName}
            sortingLabel={data?.config?.dataSource?.sortingLabel}
            filterButtonLabel={data?.config?.dataSource?.filterButtonLabel}
            showProductsLabel={data?.config?.dataSource?.showProductsLabel}
            isOutfit={data.isOutfit}
            mediaConfig={mediaConfig}
            categoryId={data.item_list_id}
            categoryName={data.item_list_name}
            navTree={data.sidebarNavTree}
            showOutfitSlider={data.showOutfitSlider}
            outfitSliderHeadline={data.outfitSliderHeadline}
            outfitSliderCount={data.outfitSliderCount}
            outfitSliderRowNumber={data.outfitSliderRowNumber}
            isEnGermanyProductList={data.isEnGermanyProductList}
            enGermanyHeadline={data.enGermanyHeadline}
            enGermanySubheadline={data.enGermanySubheadline}
            isEmilio={isEmilio}
            fallbackImage={data?.config?.dataSource?.fallBackImage}
          />
        </div>
      ) : (
        <NotFound />
      )}
    </div>
  );
};

export const ProductListAlgoliaTastic = ({ data, pageFolder }) => {
  if (!data?.config) {
    return <NotFound />;
  }

  return (
    <InstantSearchProvider config={data.config.dataSource}>
      <LocalizedIndex config={data.config.dataSource}>
        <ProductListProvider>
          <ProductListTastic data={{ ...data }} pageFolder={pageFolder} />
        </ProductListProvider>
      </LocalizedIndex>
    </InstantSearchProvider>
  );
};
