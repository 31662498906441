import React from 'react';
import { Image } from '@engbers/components';

export interface IProductBoxImage {
  images: string[];
  title: string;
  isHovering: boolean;
  loadEager?: boolean;
}

export const ProductBoxImage: React.FC<IProductBoxImage> = ({ images, title, isHovering, loadEager = false }) => {
  // eslint-disable-next-line prefer-const
  let [first, second] = images;

  if (!second) {
    second = first;
  }

  return <Image loading={loadEager ? 'eager' : 'lazy'} src={isHovering ? second : first} alt={title} title={title} />;
};
