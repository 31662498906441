import { FullPageWidthWrapper } from '@engbers/components/online-shops';
import { SearchAgain } from '@engbers/components/online-shops/search-again';
import { Block } from '@engbers/components';

export const EngbersSearchAgainTastic = ({ data }) => {
  const searchAgain: JSX.Element = (
    <SearchAgain
      sectionText={data.sectionText}
      searchPlaceholderText={data.searchPlaceholderText}
    />
  );

  if (data.isFullWidth) {
    return (
      <FullPageWidthWrapper>
        {searchAgain}
      </FullPageWidthWrapper>
    );
  }

  return (
    <Block>
      {searchAgain}
    </Block>
  );
};
