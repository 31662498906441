import React from 'react';
import classnames from 'classnames';
import { IFTLinkReference, IFTPageFolderReference } from '@frontastic-engbers/types/engbers-custom';
import { ReferenceLink } from '@frontastic-engbers/helpers/reference';
import { IconCustom } from '@engbers/components';
import styles from '../my-account.module.scss';

interface NavPoint {
  navPointLabel: string;
  navPointLink: IFTPageFolderReference | IFTLinkReference;
  isLogout: boolean;
  subnavPoints: SubnavPoint[];
  isNavPointActive: (link: IFTPageFolderReference | IFTLinkReference) => boolean;
  handleLogout: () => void;
}

interface SubnavPoint {
  subnavPointLabel: string;
  subnavPointLink: IFTPageFolderReference | IFTLinkReference;
}

export const AccountNavPoint: React.FC<NavPoint> = ({
  navPointLabel,
  navPointLink,
  subnavPoints,
  isLogout,
  isNavPointActive,
  handleLogout,
}) => !isLogout ? (
  <div
    className={styles.accountNavPointWrapper}
  >
    <div>
      <ReferenceLink
        target={navPointLink}
        className={classnames(
          styles.accountNavPoint,
          {
            [styles.accountNavPointActive]: isNavPointActive(navPointLink),
          }
        )}
      >
        {navPointLabel}
        <IconCustom color="primary" width={14} icon="ChevronRightIcon" />
      </ReferenceLink>
    </div>
    {(isNavPointActive(navPointLink)
      || subnavPoints.find((subnavPoint) => isNavPointActive(subnavPoint.subnavPointLink)))
      && subnavPoints.length
      ? subnavPoints.map((subnavPoint, i) => (
        <div
          key={`subnav-point-key-${i}`}
          style={{ fontWeight: isNavPointActive(subnavPoint.subnavPointLink) ? '700' : undefined }}
        >
          <ReferenceLink target={subnavPoint.subnavPointLink} className={styles.accountSubnavPoint}>
            <IconCustom color="primary" width={12} icon="ChevronRightIcon" />
            {subnavPoint.subnavPointLabel}
          </ReferenceLink>
        </div>
      ))
      : null}
  </div>
) : (
  <button
    className={styles.accountNavPoint}
    onClick={() => handleLogout()}
  >
    {navPointLabel}
  </button>
);
