import React, { useState } from 'react';
import { Button, Markdown } from '@engbers/components';
import { IFTLinkReference, IFTPageFolderReference } from '@frontastic-engbers/types/engbers-custom';
import { EmailValidatorHelper } from '@frontastic-engbers/helpers/emailValidatorHelper';
import { StringHelpers } from '@frontastic-engbers/helpers/stringHelpers';
import { useToastNotificationsActions } from '@frontastic-engbers/lib/state/notification/actions';
import { useRouter } from 'next/router';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { getReferenceTarget } from '@frontastic-engbers/helpers/reference';
import { useAccount } from '@frontastic-engbers/lib';
import styles from './forgot-password-form.module.scss';
import classnames from 'classnames';

interface IForgotPasswordFormComponent {
  title?: string;
  textSize?: number;
  subHeading?: string;
  description?: string;
  emailInputLabel?: string;
  requestButtonLabel?: string;
  redirectLink?: IFTLinkReference | IFTPageFolderReference;
  errorMessages?: Record<string, string>;
  classNames?: Record<string, string>;
}

export const ForgotPasswordForm: React.FC<IForgotPasswordFormComponent> = ({
  title,
  textSize,
  subHeading,
  description,
  emailInputLabel,
  requestButtonLabel,
  redirectLink,
  errorMessages,
  classNames,
}) => {
  const { formatMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { requestPasswordReset } = useAccount();
  const { pushNotification } = useToastNotificationsActions();
  const router = useRouter();

  const [validationStatus, setValidationStatus] = useState<string | null>(null);
  const [emailAddress, setEmailAddress] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  if (!redirectLink) {
    redirectLink = {
      type: 'link',
      link: '/',
    } as IFTLinkReference;
  }

  const getErrorMessage = (id: string, defaultMessage?: string): string => {
    return StringHelpers.parameterReplace(
      errorMessages?.[id] ??
        formatErrorMessage({
          id,
          defaultMessage: defaultMessage ?? id,
        }),
      { emailAddress },
    );
  };

  const validate = async () => {
    const ruleDefinitions: string | null = await EmailValidatorHelper.ruleDefinitions(emailAddress, false);
    setValidationStatus(ruleDefinitions);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailAddress(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    await validate();
    if (validationStatus !== null || !emailAddress) {
      return;
    }

    setLoading(true);
    try {
      await requestPasswordReset(emailAddress);
      const target = getReferenceTarget(redirectLink);
      router.push(`${target}${target.indexOf('?') > 0 ? '&' : '?'}email=${emailAddress}`);
    } catch (error) {
      pushNotification(getErrorMessage('wentWrong', 'Sorry, something went wrong.'), 'error');
      setLoading(false);
    }
  };

  return (
    <>
      <Markdown
        className={classnames(classNames?.headline, { [styles.headline]: !classNames?.headline })}
        text={
          title ??
          formatAccountMessage({
            id: 'password.forgot',
            defaultMessage: 'Forgot your password?',
          })
        }
        textSize={textSize}
        disableMargin
      />
      <div
        className={classnames(classNames?.forgotPasswordFormWrapper, {
          [styles.forgotPasswordFormWrapper]: !classNames?.forgotPasswordFormWrapper,
        })}
      >
        {subHeading && (
          <Markdown
            className={classnames(classNames?.subHeading, { [styles.subHeading]: !classNames?.subHeading })}
            text={subHeading}
          />
        )}
        <form
          className={classnames(classNames?.forgotPasswordForm, {
            [styles.forgotPasswordForm]: !classNames?.forgotPasswordForm,
          })}
          onSubmit={handleSubmit}
        >
          <label
            htmlFor="email"
            className={classnames(classNames?.inputLabel, { [styles.inputLabel]: !classNames?.inputLabel })}
          >
            <span className={classnames({ [styles.inputInvalid]: !!validationStatus })}>
              {emailInputLabel ??
                formatMessage({
                  id: 'emailAddress',
                  defaultMessage: 'E-Mail-Addresse',
                })}
            </span>
          </label>

          <input
            required
            type="email"
            name="email"
            id="email"
            autoComplete="email"
            className={classnames(classNames?.inputField, {
              [styles.inputField]: !classNames?.inputField,
              [styles.inputInvalidField]: !!validationStatus,
            })}
            onChange={handleInputChange}
            onBlur={validate}
          />

          {!!validationStatus && (
            <div
              className={classnames(classNames?.inputInfoText, { [styles.inputInfoText]: !classNames?.inputInfoText })}
            >
              <span className={styles.inputInvalid}>
                {getErrorMessage(validationStatus === 'empty' ? 'mandatory' : 'emailWrongFormat')}
              </span>
            </div>
          )}

          <div className={classnames(classNames?.ctaButton, { [styles.ctaButton]: !classNames?.ctaButton })}>
            <Button size="large" isLoading={loading} label={requestButtonLabel} onClick={handleSubmit} />
          </div>
        </form>
        {description && (
          <Markdown
            className={classnames(classNames?.description, { [styles.description]: !classNames?.description })}
            text={description}
          />
        )}
      </div>
    </>
  );
};
