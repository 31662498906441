import { useEffect, useState } from 'react';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import isoAlpha2Data from '@frontastic-engbers/helpers/utils/iso-alpha2-data';
import { getProjectInfo } from '@frontastic-engbers/lib';
import styles from './country-select.module.scss';
import classnames from 'classnames';

type CountrySelectProps = {
  id?: string;
  name?: string;
  placeholder?: string;
  value: null | string;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  required?: boolean;
  autoComplete?: string;
  errorMessage?: string;
  wrapperCustomStyle?: object;
  successValidation?: boolean;
  allowed?: string[];
  showDropdownArrow?: boolean;
};

export const CountrySelect = ({
  id,
  name,
  placeholder,
  value,
  onChange,
  required,
  autoComplete,
  errorMessage,
  wrapperCustomStyle,
  successValidation,
  allowed,
  showDropdownArrow = false,
}: CountrySelectProps) => {
  const isPwa = getProjectInfo()['projectId'] === 'Pwa';
  const { formatMessage } = useFormat({ name: 'common' });
  const [options, setOptions] = useState<{ [key: string]: string }>();

  useEffect(() => {
    if (!allowed) {
      return;
    }

    const countries = Object.fromEntries(Object.entries(isoAlpha2Data).filter(([key]) => allowed.includes(key)));

    setOptions(countries);
  }, []);

  return (
    <div
      style={{
        ...wrapperCustomStyle,
      }}
    >
      <div className={styles.selectWrap}>
        {placeholder && (
          <div className={styles.placeholder}>
            {placeholder} {required && '*'}
          </div>
        )}
        <select
          placeholder={placeholder}
          id={id}
          name={name}
          value={value ?? (!isPwa && isoAlpha2Data.DE)}
          onChange={onChange}
          required={required}
          className={classnames(
            placeholder ? styles.countrySelect : styles.countrySelectWithout,
            showDropdownArrow && styles.showArrows,
          )}
          autoComplete={autoComplete}
        >
          {isPwa && <option key="empty">{formatMessage({ id: 'chooseCountry' })}</option>}
          {Object.entries(options ?? isoAlpha2Data).map(([key, value]) => (
            <option key={key} value={key}>
              {value}
            </option>
          ))}
        </select>

        {errorMessage && (
          <div className={styles.statusWrap}>
            <div className={styles.errorIcon} />
          </div>
        )}
        {successValidation && value.length === 2 && !errorMessage && (
          <div className={styles.statusWrap}>
            <div className={styles.successIcon} />
          </div>
        )}
      </div>

      {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
    </div>
  );
};
