import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import { fetchApiHub, getProjectInfo } from '@frontastic-engbers/lib';
import { EcondaSlider } from '@engbers/components/online-shops/econda/econda-slider';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { addProductToLastSeen } from '@frontastic-engbers/helpers/utils/lastSeenProducts';
import { EcondaWidgetConfiguration, MediaType, TBreadcrumb } from '@frontastic-engbers/types/engbers-custom';
import { Outfit } from '@frontastic-engbers/types/product/Outfit';
import { Product } from '@frontastic-engbers/types/product/Product';
import { Variant } from '@frontastic-engbers/types/product/Variant';
import { PdpImages } from './pdp-images';
import { PdpInfo } from './pdp-info';
import { PdpDescription } from './pdp-description';
import { PdpShopUsps } from './pdp-shop-usps';
import { PdpLastSeenProducts } from './pdp-last-seen';
import { PdpOutfitSlider } from './pdp-outfit-slider';
import styles from './product-details-page.module.scss';
import { Breadcrumb } from '../breadcrumb';
import { useMediaQuery } from 'react-responsive';
import { TagManager } from '@frontastic-engbers/lib/lib/tracking';
import { ItemMapper } from '@frontastic-engbers/lib/lib/tracking/itemMapper';
import { ITable } from '@engbers/components/online-shops/table';
import { TIconId } from '@engbers/components/icon-custom';
import { TrustedShopsReviews } from '@engbers/components/online-shops/trusted-shops/reviews';
import { MetaPDP } from '@frontastic-engbers/helpers/metaTags';
import { StructuredDataPDP } from '@frontastic-engbers/helpers/structuredData';
import { siteUrl } from 'next-sitemap.config';

export interface IPdpUsps {
  headline: string;
  text: string;
  image: MediaType;
}

export interface IAvailabilityModalContent {
  icon: MediaType;
  label: string;
  title: string;
  searchPlaceholder: string;
  selectPlaceholder: string;
  emptySearchError: string;
  noResultsError: string;
  note: string;
  storeClosedHint: string;
  notListedHint: string;
  availableLabel: string;
  onlineOnlyLabel: string;
  notAvailableLabel: string;
  appointmentLabel: string;
  locationLabel: string;
}

export interface IPdp {
  product: Product;
  variant: Variant;
  sizesLabel: string;
  sizesUnavailableMessage: string;
  sizesModalHeadline: string;
  addToCartBtnLabel: string;
  shippingCostsNote: string;
  notAvailableModalText: string;
  textSize?: number;
  outfitSliderHeadline: string;
  lastSeenSliderHeadline: string;
  alternativeSliderHeadline: string;
  matchingSliderHeadline: string;
  usps: IPdpUsps[];
  fallbackIcon: MediaType;
  econdaWidgetConfiguration: EcondaWidgetConfiguration;
  availabilityModalContent: IAvailabilityModalContent;
  sizeAdvisorLabel: string;
  sizeAdvisorTables: {
    sizeAdvisorTableProductGroup: string;
    sizeAdvisorImage: MediaType;
    sizeTables: ITable[];
  }[];
  sizeDeviationLabel: string;
  sizeDeviationIcon: TIconId;
  sizeDeviationTexts: {
    sizeDeviationProductGroup: string;
    sizeDeviationText: string;
  }[];
  notBuyableText: string;
  fallbackImage?: MediaType;
}

export const ProductDetailsPage: React.FC<IPdp> = ({
  product,
  variant,
  sizesLabel,
  sizesUnavailableMessage,
  sizesModalHeadline,
  addToCartBtnLabel,
  shippingCostsNote,
  notAvailableModalText,
  textSize,
  outfitSliderHeadline,
  lastSeenSliderHeadline,
  alternativeSliderHeadline,
  matchingSliderHeadline,
  usps,
  fallbackIcon,
  econdaWidgetConfiguration,
  availabilityModalContent,
  sizeAdvisorLabel,
  sizeAdvisorTables,
  sizeDeviationLabel,
  sizeDeviationIcon,
  sizeDeviationTexts,
  notBuyableText,
  fallbackImage,
}) => {
  const { data: outfit } = useSWR(`/action/product/getProduct?id=${product.attributes?.OutfitMainItem_2}`, fetchApiHub);
  const { formatMessage } = useFormat({ name: 'product' });
  const [breadcrumb, setBreadcrumb] = useState<TBreadcrumb[]>(null);
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const isEmilio = getProjectInfo()?.projectId === 'ea';

  useEffect(() => {
    let breadcrumbArray: TBreadcrumb[] = [];
    if (
      localStorage.getItem('breadcrumb') &&
      localStorage.getItem('productGroup') === product.attributes.ProductGroup
    ) {
      const localBreadcrumb = localStorage.getItem('breadcrumb');

      if (typeof localBreadcrumb !== 'undefined' && localBreadcrumb !== null) {
        breadcrumbArray = JSON.parse(localBreadcrumb).reverse();
        const index = breadcrumbArray.findIndex((item) => item.active === true);
        breadcrumbArray[index].active = false;

        breadcrumbArray.unshift({
          pageFolderId: 'PDP',
          active: true,
          name: `${product.attributes.DesignerName} - ${product.name}`,
          link: '/',
        });

        setBreadcrumb(breadcrumbArray.reverse());
      }
    }
  }, []);

  useEffect(() => {
    addProductToLastSeen(product.productId);
  }, []);

  useEffect(() => {
    new TagManager()
      .customEvent('ecomm', {
        ecomm_pagetype: 'product',
        ecomm_prodid: [product.productId],
        ecomm_totalvalue: (variant?.discountedPrice?.centAmount ?? variant?.price?.centAmount ?? 0) / 100,
      })
      .viewItemEvent(variant, [ItemMapper.commercetoolsProductToItem(product, variant, 1)])
      .executePush();
  }, []);

  if (!variant) {
    return null;
  }

  return (
    <>
      <MetaPDP product={product} />
      <div className={styles.pdpPadding}>
        {breadcrumb && !isMobile && <Breadcrumb breadCrumbs={breadcrumb} paddingBottomInPix={10} />}
        <div className={styles.pdpWrap}>
          <PdpImages
            className={styles.pdpImagesWrap}
            product={product}
            variant={variant}
            fallbackImage={fallbackImage}
          />
          <PdpInfo
            product={product}
            variant={variant}
            className={styles.pdpInfoWrap}
            sizeSection={{
              sizesLabel,
              sizesUnavailableMessage,
              sizesModalHeadline,
            }}
            addToCartSection={{
              addToCartBtnLabel,
              shippingCostsNote,
              notAvailableModalText,
            }}
            econdaWidgetConfiguration={econdaWidgetConfiguration}
            availabilityModalContent={availabilityModalContent}
            categoryUrl={breadcrumb ? breadcrumb[breadcrumb.length - 2]?.link : undefined}
            sizeAdvisorLabel={sizeAdvisorLabel}
            sizeAdvisorTables={sizeAdvisorTables}
            sizeDeviationLabel={sizeDeviationLabel}
            sizeDeviationIcon={sizeDeviationIcon}
            sizeDeviationTexts={sizeDeviationTexts}
            outfit={outfit}
            notBuyableText={notBuyableText}
            isEmilio={isEmilio}
            fallbackImage={fallbackImage}
          />
        </div>
      </div>

      <PdpDescription product={product} variant={variant} />

      <TrustedShopsReviews sku={product.productId} />

      <ul className={styles.shopUspsWrap}>
        {usps?.map((usp, i) => {
          return (
            <PdpShopUsps
              key={`usp--${i}`}
              usp={usp}
              headline={usp.headline}
              text={usp.text}
              fallbackIcon={fallbackIcon}
            />
          );
        })}
      </ul>

      <PdpOutfitSlider outfit={outfit as Outfit} outfitSliderHeadline={outfitSliderHeadline} textSize={textSize} />

      <EcondaSlider
        config={econdaWidgetConfiguration.credentials}
        widgetId={econdaWidgetConfiguration.alternativeSlider}
        textSize={textSize}
        title={
          alternativeSliderHeadline ||
          formatMessage({
            id: 'alternativeHeadline',
            defaultMessage: 'Passende Alternativen',
          })
        }
        contextProductIds={[product.productId]}
        customTitleStyle={{
          marginTop: '36px',
        }}
      />

      <EcondaSlider
        config={econdaWidgetConfiguration.credentials}
        widgetId={econdaWidgetConfiguration.matchingSlider}
        textSize={textSize}
        title={
          matchingSliderHeadline ||
          formatMessage({
            id: 'matchingHeadline',
            defaultMessage: 'Passend dazu',
          })
        }
        contextProductIds={[product.productId]}
        customTitleStyle={{
          marginTop: !isMobile ? '44px' : '0px',
        }}
      />

      <PdpLastSeenProducts
        title={lastSeenSliderHeadline}
        textSize={textSize}
        customTitleStyle={{
          marginTop: !isMobile ? '44px' : '0px',
        }}
      />

      <StructuredDataPDP product={product} siteUrl={siteUrl} />
    </>
  );
};
