import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import useSWR from 'swr';
import { FrontasticRenderer } from '@frontastic-engbers/lib/lib/renderer';
import { fetchApiHub, LocaleStorage, PageDataResponse, PagePreviewDataResponse } from '@frontastic-engbers/lib';
import { tastics } from '@engbers/tastics';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { mapLanguage } from '@frontastic-engbers/lib/project.config';
import { AnimatePresence, motion } from 'framer-motion';
import { LoadingIndicatorInline } from '@engbers/components';
import { ITabMenuItem, TabMenuItem } from './components/tab-menu-item';
import styles from './tab-menu.module.scss';

export interface ITabMenu {
  items?: ITabMenuItem[];
}

const wrapVariants = {
  initial: {
    opacity: 0,
    x: -20,
  },
  animate: {
    opacity: 1,
    x: 0,
  },
  exit: {
    opacity: 0,
    x: 20,
  },
};

export const TabMenu: React.FC<ITabMenu> = ({ items }) => {
  const { formatMessage } = useFormat({ name: 'common' });
  const router = useRouter();
  const [path, setPath] = useState(items[0]?.reference?._url);
  const { data: tabData, isLoading } = useSWR<PageDataResponse & PagePreviewDataResponse>(`/page/${path}`, () =>
    fetchApiHub('/page', {
      headers: {
        'Frontastic-Path': path,
        'Frontastic-Locale': mapLanguage(LocaleStorage.locale),
      },
    }),
  );

  useEffect(() => {
    if (router.asPath.includes('#')) {
      setPath(router.asPath.replace('#', '/'));
    }
  }, []);

  return (
    <>
      <ul className={styles.tabs}>
        {items.map((item) => {
          const label = item.label || item.reference.name;

          return (
            <TabMenuItem
              label={label}
              counter={item.counter}
              reference={item.reference}
              isActive={item.reference._url === path}
              key={`tab-menu-key-${label}`}
              onClick={setPath}
            />
          );
        })}
      </ul>

      <div className={styles.tabContent}>
        <AnimatePresence exitBeforeEnter>
          <motion.div
            key={`${tabData?.page?.pageId}-${isLoading && 'loading'}`}
            variants={wrapVariants}
            initial="initial"
            animate={'animate'}
            exit="exit"
          >
            {isLoading ? (
              <div className={styles.loadingIndicator}>
                <LoadingIndicatorInline color="blue" />
              </div>
            ) : tabData?.page ? (
              <FrontasticRenderer
                data={tabData}
                tastics={tastics}
                gridClassName="min-h-0"
                wrapperClassName={`px-0 ${styles[`wrap-tab-modifier`]}`}
              />
            ) : (
              formatMessage({
                id: 'page.notFound',
                defaultMessage: 'Page not found',
              })
            )}
          </motion.div>
        </AnimatePresence>
      </div>
    </>
  );
};
