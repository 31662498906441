import React, { useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useRouter } from 'next/router';
import { useMediaQuery } from 'react-responsive';
import { fetchApiHub, useAccount, useCart } from '@frontastic-engbers/lib';
import { handlePayment, handleRedirectPayment } from '@frontastic-engbers/lib/actions/payone';
import { checkoutErrorsState, isCheckoutSubmittedState } from '@frontastic-engbers/lib/state/checkoutErrors/atoms';
import { useModalActions } from '@frontastic-engbers/lib/state/modal/actions';
import { get3dsInformation } from '@frontastic-engbers/helpers/utils/get3dsInformation';
import { getSKUParts } from '@frontastic-engbers/helpers/utils/getSKUParts';
import {
  CheckoutData,
  CheckoutError,
  CheckoutResponse,
  CustomerData,
  CustomerOrigin,
  IPaymentMethod,
  MediaType,
  NewsletterCheckbox,
  PaymentErrors,
  PaymentMethod as PaymentMethodType,
  SoldOutVariants,
  Solvency,
} from '@frontastic-engbers/types/engbers-custom';
import { AccountUpdateBody } from '@frontastic-engbers/types/account/Account';
import { Address } from '@frontastic-engbers/types/account/Address';
import { Discount } from '@frontastic-engbers/types/cart/Discount';
import { Variant } from '@frontastic-engbers/types/product/Variant';
import { Cart as CartType } from '@frontastic-engbers/types/cart/Cart';
import Spinner from '@engbers/components/online-shops/commercetools-ui/spinner';
import { ISizeVariant } from '../flyout-cart/cart-line-items';
import { Section } from './section';
import { BillingAddress } from './section/billing-address';
import { ShippingAddress } from './section/shipping-address';
import { Payment } from './section/payment';
import { RedirectModal } from './section/payment/redirect-modal';
import { Overview } from './section/overview';
import { mapToCartStructure } from './utils/mapFormData';
import {
  requiredDataIsValid,
  validateBillingAddress,
  validateCustomerData,
  validatePayment,
  validateShippingAddress,
} from './utils/requiredDataIsValid';
import { useValidateAndRegisterUserCallback } from './hooks/useValidateUserDataCallback';
import { TagManager } from '@frontastic-engbers/lib/lib/tracking';
import { ItemMapper } from '@frontastic-engbers/lib/lib/tracking/itemMapper';
import { LineItem } from '@frontastic-engbers/types/cart/LineItem';
import styles from './checkout.module.scss';
import { joinDiscounts } from '@frontastic-engbers/helpers/dataLayerHelper/couponCodeDataHelper';
import { Markdown } from '@engbers/components';
import { updateItemDatalayerPush } from '@frontastic-engbers/helpers/dataLayerHelper/updateItemDatalayerHelper';
import { calculateCartLineItemsPrice } from '@frontastic-engbers/helpers/dataLayerHelper/calculateCartLineItemsPrice';

interface ICheckout {
  headlines: {
    existing: string;
    new: string;
    guest: string;
    textSize?: number;
  };
  addressLabels: {
    radioMister: string;
    radioMs: string;
    labelFirstName: string;
    labelLastName: string;
    labelStreet: string;
    labelHouse: string;
    labelCompany: string;
    labelZip: string;
    labelCity: string;
    labelCountry: string;
    labelBirthdate: string;
    labelEmail: string;
    labelEmailRepeat: string;
    labelPassword: string;
    labelPasswordRepeat: string;
    labelAgb: string;
    labelPostActions: string;
    labelCardAgb: string;
    invalidMailFormat: string;
  };
  billingAddressSection: {
    headline: string;
    migrationVerifyInformation: string;
    migrationVerifyBirthdate: string;
  };
  shippingSection: {
    headline: string;
    hasStoreDelivery: boolean;
    hasPackStationDelivery: boolean;
    billingAddressBoxIcon: MediaType;
    billingAddressBoxLabel: string;
    billingAddressDeliveryCosts: string;
    shippingAddressBoxIcon: MediaType;
    shippingAddressBoxLabel: string;
    shippingAddressDeliveryCosts: string;
    storeDeliveryBoxIcon: MediaType;
    storeDeliveryBoxLabel: string;
    storeDeliveryCosts: string;
    packStationBoxIcon: MediaType;
    packStationBoxLabel: string;
    packStationDeliveryCosts: string;
  };
  shippingAddressSection: {
    headlineShippingAddress: string;
    textShippingAddress: string;
  };
  packStationSection: {
    headlinePackstation: string;
    labelPostNo: string;
    labelSelectPackstation: string;
    textPackstation: string;
    errorPackstation: string;
    errorPostNo: string;
  };
  storeDeliverySection: {
    headlineStoreDelivery: string;
    textStoreDelivery: string;
    labelOpeningHours: string;
    labelSelectStoreDelivery: string;
    errorStoreDelivery: string;
    storeClosed: string;
  };
  paymentSection: {
    headline: string;
    paymentMethods: IPaymentMethod[];
    paymentHintForGuest?: string;
    paymentHintLockedProducts?: string;
    paymentError: string;
    paymentDeniedError: string;
    paymentCreditCardAuthorized: string;
    paymentRedirectHeadline: string;
    paymentRedirectInfoText: string;
    paymentRedirectButtonLabel: string;
    solvencyPositiveMessage: string;
    solvencyFailedMessage: string;
    solvencyInvoiceMessage: string;
    solvencyDebitMessage: string;
    solvencyModalTitle: string;
    solvencyModalTitleProgess: string;
    solvencyModalCancelLabel: string;
    solvencyModalSubmitLabel: string;
  };
  overviewSection: {
    headline: string;
    overviewBillingAddressHeadline: string;
    overviewShippingAddressHeadline: string;
    overviewDeliveryTimeHeadline: string;
    overviewPaymentHeadline: string;
    overviewDeliveryTimeDE: string;
    overviewDeliveryTimeEU: string;
    overviewDoubledSizeMessage: string;
  };
  forbiddenFields: {
    fieldValidationMessage: string;
    forbiddenFieldList: string;
  };
  voucherSection: {
    voucherHeadline: string;
    voucherInfo: string;
    voucherRedeemBtn: string;
    voucherInvalidLabel: string;
    voucherRedeemedLabel: string;
  };
  productOverviewSection: {
    productOverviewArticle: string;
    productOverviewDesc: string;
    productOverviewAmount: string;
    productOverviewSum: string;
  };
  totalSummarySection: {
    commentOnOrderLabel: string;
    shippingCostsNote: string;
    showCommentOnOrderLabel: boolean;
  };
  ctaSection: {
    submitBtnLabel: string;
    enNewsletterLabel: string;
    emilioNewsletterLabel: string;
    xxlNewsletterLabel: string;
  };
}

export const Checkout: React.FC<ICheckout> = ({
  headlines,
  addressLabels,
  billingAddressSection,
  shippingSection,
  shippingAddressSection,
  packStationSection,
  storeDeliverySection,
  paymentSection,
  overviewSection,
  forbiddenFields,
  voucherSection,
  productOverviewSection,
  totalSummarySection,
  ctaSection,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const { loggedIn, account, loaded, update: updateAccount } = useAccount();
  const router = useRouter();
  const validateAndRegisterUser = useValidateAndRegisterUserCallback();
  const { pushModal } = useModalActions();

  const urlQueryArg = router.query;
  const isGuest = !loggedIn && urlQueryArg.isGuest === 'true';
  const isNew = !loggedIn && urlQueryArg.isNew === 'true';

  const billingAddressSectionRef = useRef<HTMLDivElement>(null);
  const shippingSectionRef = useRef<HTMLDivElement>(null);
  const paymentSectionRef = useRef<HTMLDivElement>(null);
  const overviewSectionRef = useRef<HTMLDivElement>(null);
  const handledRedirect = useRef<boolean>(false);
  const customerOriginDetermined = useRef<boolean>(false);
  const handleDatalayer = useRef<boolean>(false);

  const [billingAdressPrefilled, setBillingAddressPrefilled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [discounts, setDiscounts] = useState<Discount[]>([]);
  const { data: cartList, addItem, removeItem, updateItem, updateCart, checkout, removeDiscountCode } = useCart();
  const [isMinWidthMedium, setIsMinWidthMedium] = useState<boolean>(false);
  const [dataIsValid, setDataIsValid] = useState<boolean>(false);
  const [disableSubmitButton, setDisableSubmitButton] = useState<boolean>(true);
  const [checkoutErrors, setCheckoutErrors] = useRecoilState(checkoutErrorsState);
  const [paymentErrors, setPaymentErrors] = useState<Partial<PaymentErrors>>({});
  const [isCheckoutSubmitted] = useRecoilState(isCheckoutSubmittedState);
  const [ctaCheckoutError, setCtaCheckoutError] = useState<CheckoutError>(null);
  const [datalayerItems, setDatalayerItems] = useState([]);
  const [datalayerCouponCodes, setDatalayerCouponCodes] = useState('');

  const [hostedTokenizationId, setHostedTokenizationId] = useState<string>('');
  const [paymentProductId, setPaymentProductId] = useState<number>();
  const [hasLockedProducts, setHasLockedProducts] = useState<boolean>(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethodType>();
  const [isIframePayment, setIsIframePayment] = useState<boolean>(false);
  const [cart, setCart] = useState<CartType>(cartList);
  const [updatingLineItems, setUpdatingLineItems] = useState<boolean>(false);
  const assignCart = useRef<boolean>(true);

  const [customerOrigin, setCustomerOrigin] = useState<CustomerOrigin>({
    isGuest: false,
    isNew: false,
  });

  const [checkoutData, setCheckoutData] = useState<CheckoutData>({
    billingAddress: {
      salutation: '',
      firstName: '',
      lastName: '',
      streetName: '',
      streetNumber: '',
      city: '',
      postalCode: '',
      country: 'DE',
    },
    shippingAddress: {
      salutation: '',
      firstName: '',
      lastName: '',
      streetName: '',
      streetNumber: '',
      company: '',
      postalCode: '',
      city: '',
      country: 'DE',
    },
    storeDeliveryAddress: {
      country: 'DE',
    },
    packstationAddress: {
      country: 'DE',
    },
    shippingMethod: 'billingAddress',
    paymentMethod: null,
    customerData: {
      birthdayDay: null,
      birthdayMonth: null,
      birthdayYear: null,
      email: '',
      emailRepeat: '',
      agbAccepted: false,
      password: '',
      passwordRepeat: '',
      postSubmit: false,
      cardAgbAccepted: false,
    },
    newsletter: {
      enNewsletterAccepted: false,
      emilioNewsletterAccepted: false,
      xxlNewsletterAccepted: false,
    },
    commentOnOrder: '',
    userAgbAccepted: false,
    solvency: {
      result: 0,
      accepted: false,
    },
    isUnselectedShippingAddress: false,
  });

  const handleLineItemUpdate = async (fn: () => Promise<void>) => {
    setLoading(true);
    await fn();
    setLoading(false);
  };

  const updateItemQuantity = (lineItem: LineItem, newQuantity: number) => {
    updateItemDatalayerPush(lineItem, newQuantity);
    return handleLineItemUpdate(() => updateItem(lineItem.lineItemId, newQuantity));
  };

  const updateItemSize = (variant: Variant, quantity: number) => handleLineItemUpdate(() => addItem(variant, quantity));

  const deleteItem = (lineItem: LineItem) => {
    const total = Number(
      ((lineItem.totalPrice.centAmount ?? 0) / 10 ** lineItem.price.fractionDigits).toFixed(
        lineItem.price.fractionDigits,
      ),
    );
    new TagManager().removeFromCartEvent([ItemMapper.lineItemToItem(lineItem, lineItem.count)], total).executePush();

    return handleLineItemUpdate(() => removeItem(lineItem.lineItemId));
  };

  const stripSkus = (skus: string[]) => {
    return skus.map((sku) => getSKUParts(sku).key);
  };

  const updateFormInput = (
    propName: string,
    newValue: string | boolean | Address | CustomerData | NewsletterCheckbox | Solvency,
  ) => {
    setCheckoutData((prevState) => ({
      ...prevState,
      [propName]: newValue,
    }));
  };

  const updateCartData = async (validate = false, checkoutDataWithPayment?: CheckoutData) => {
    const isValid = await requiredDataIsValid(
      checkoutDataWithPayment || checkoutData,
      !loggedIn ? customerOrigin : undefined,
    );

    if (!validate || isValid) {
      const updatedData = mapToCartStructure(checkoutDataWithPayment || checkoutData, customerOrigin);

      await updateCart(updatedData);
    }
  };

  const updateAccountData = async () => {
    const billingAddress = checkoutData.billingAddress;
    const updatedAccountBody: AccountUpdateBody = {
      email: account.email,
      salutation: billingAddress.salutation,
      firstName: billingAddress.firstName,
      lastName: billingAddress.lastName,
      addressStreetName: billingAddress.streetName,
      addressCity: billingAddress.city,
      addressPostalCode: billingAddress.postalCode,
      addressStreetNumber: billingAddress.streetNumber,
      addressAdditionalAddress: billingAddress.additionalAddressInfo,
      addressCountry: billingAddress.country,
      phone: billingAddress.phone,
    };

    await updateAccount(updatedAccountBody);
  };

  const checkAvailibility = async () => {
    let escape = false;
    const variants: SoldOutVariants[] = [];
    const skus: string[] = [];

    for (const lineItem of cartList.lineItems) {
      const skuParts = getSKUParts(lineItem.variant.sku);
      const response: ISizeVariant[] = await fetchApiHub(
        `/action/stock/getVariantsStockInfo?key=${skuParts.key}&onlyInStock=false`,
      );
      const size = response.find((size) => size.sku == lineItem.variant.sku);

      if (size && size.availableQuantity < lineItem.count) {
        variants.push({
          sku: lineItem.variant.sku,
          soldOut: size.availableQuantity == 0,
          availableQuantity: size.availableQuantity,
        });
        skus.push(lineItem.variant.sku);
        escape = true;
      }
    }

    if (escape === true) {
      setCtaCheckoutError({
        code: 'OutOfStock',
        skus: stripSkus(skus),
        variants,
      });

      setLoading(false);
    }

    return escape;
  };

  const submit = async () => {
    const filteredErrors = checkoutErrors
      ? Object.keys(checkoutErrors).filter((key) => !checkoutErrors[key].isValid)
      : null;

    if (!dataIsValid || disableSubmitButton || filteredErrors?.length) {
      return;
    }

    setLoading(true);

    if (cartList.lineItems.length > 0) {
      const foundOutOfStock = await checkAvailibility();

      if (foundOutOfStock) {
        return;
      }
    }

    try {
      await updateCartData(true);

      if (
        !(await validateAndRegisterUser(
          checkoutData,
          billingAddressSectionRef.current,
          updateFormInput,
          billingAddressSection.migrationVerifyInformation,
          billingAddressSection.migrationVerifyBirthdate,
          isGuest || loggedIn,
          loading,
          checkoutErrors,
          customerOrigin,
        ))
      ) {
        setLoading(false);
        return;
      }

      if (!loading) {
        setLoading(true);
      }

      if (loggedIn && account) {
        await updateAccountData();
      }

      const method = paymentSection.paymentMethods?.find(
        (method) => method.paymentMethodType === checkoutData.paymentMethod,
      );

      const { isAuthorized, redirectUrl } = await handlePayment(
        method,
        cartList,
        customerOrigin,
        hostedTokenizationId,
        paymentProductId,
        get3dsInformation(),
        account?.accountId,
      );

      if (redirectUrl) {
        setLoading(false);

        pushModal({
          id: 'redirect-payment-modal',
          title: paymentSection.paymentRedirectHeadline,
          content: (
            <RedirectModal
              redirectUrl={redirectUrl}
              infoText={paymentSection.paymentRedirectInfoText}
              label={paymentSection.paymentRedirectButtonLabel}
            />
          ),
          isMedium: true,
          hasCloseButton: false,
          canCloseByBackdropClick: false,
        });

        return;
      }

      if (!isAuthorized) {
        onPaymentError();
        return;
      }

      await checkout().then(async (response: CheckoutResponse) => {
        if (!response.errors) {
          router.push('/thank-you');
          return;
        }

        for (const error of response.errors) {
          if (error.code === 'OutOfStock') {
            if (error.skus.length > 0) {
              await setSoldOutErrors(error.skus, error.code);
            }
          }

          setLoading(false);
        }
      });
    } catch (e) {
      // Todo: all kinds of error handling (already ordered in other tab, out of stock in meantime, etc)
    }
  };

  const setSoldOutErrors = async (skus: string[], code: string) => {
    const variants: SoldOutVariants[] = [];

    for (const sku of skus) {
      const skuParts = getSKUParts(sku);
      const response: ISizeVariant[] = await fetchApiHub(
        `/action/stock/getVariantsStockInfo?key=${skuParts.key}&onlyInStock=false`,
      );
      const size = response.find((size) => size.sku == sku);

      if (size) {
        variants.push({
          sku,
          soldOut: size.availableQuantity == 0,
          availableQuantity: size.availableQuantity,
        });
      }
    }

    setCtaCheckoutError({
      code,
      skus: stripSkus(skus),
      variants,
    });
  };

  const resetPayment = () => {
    setHasLockedProducts(false);
    setIsIframePayment(false);
    setSelectedPaymentMethod(null);
    setHostedTokenizationId('');
    updateFormInput('paymentMethod', null);
  };

  const onPaymentError = () => {
    resetPayment();
    setPaymentErrors((errors) => ({
      ...errors,
      paymentDenied: true,
    }));
    paymentSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    setLoading(false);
  };

  const updateEcondaDataLayer = (ecContent: string, ecOrderProcess: string) => {
    const econdaDataLayer = JSON.parse(sessionStorage.getItem('econda_data_layer'));

    if (econdaDataLayer) {
      econdaDataLayer.ecContent = ecContent;
      econdaDataLayer.ecOrderProcess = ecOrderProcess;

      sessionStorage.setItem('econda_data_layer', JSON.stringify(econdaDataLayer));
      new TagManager().updateEcondaDataEvent(econdaDataLayer).executePush();
    }
  };

  useEffect(() => {
    if (!cartList || handledRedirect.current) {
      return;
    }

    if (assignCart?.current && cartList) {
      setCart(cartList);
      assignCart.current = false;
    }

    const { paymentId, hostedCheckoutId } = router.query;

    const getPaymentResult = async (paymentId: string) => {
      const isAuthorized = await handleRedirectPayment(paymentId, cartList);

      if (!isAuthorized) {
        onPaymentError();
        return;
      }

      await checkout().then(async (response: CheckoutResponse) => {
        if (!response.errors) {
          router.push('/thank-you');
          return;
        }

        for (const error of response.errors) {
          if (error.code === 'OutOfStock') {
            if (error.skus.length > 0) {
              await setSoldOutErrors(error.skus, error.code);
            }
          }

          setLoading(false);
        }
      });
    };

    handledRedirect.current = true;

    if (paymentId || hostedCheckoutId) {
      setLoading(true);
      getPaymentResult((paymentId ?? hostedCheckoutId) as string);
      return;
    }

    const updateInitialCartData = async () => {
      const address = account?.addresses?.find((address) => address.isDefaultBillingAddress);

      updateFormInput('commentOnOrder', '');

      await updateCartData(false, {
        ...checkoutData,
        commentOnOrder: '',
        ...(address && {
          billingAddress: {
            country: address.country,
          },
        }),
      });
    };

    updateInitialCartData();
  }, [cartList]);

  useEffect(() => {
    if (
      cartList &&
      (!updatingLineItems ||
        (updatingLineItems && (!cart.lineItems || cart.lineItems.length === cartList.lineItems.length)))
    ) {
      setCart(cartList);
    }
  }, [updatingLineItems]);

  useEffect(() => {
    if (isGuest && !loggedIn) {
      setCustomerOrigin({
        isGuest: true,
        isNew: false,
      });
    } else if (!loggedIn) {
      setCustomerOrigin({
        isGuest: false,
        isNew: isNew || (!isGuest && !loggedIn),
      });
    } else {
      setCustomerOrigin({
        isGuest: false,
        isNew: false,
      });
    }

    customerOriginDetermined.current = true;
  }, [loggedIn]);

  useEffect(() => {
    const validate = async () => {
      setDataIsValid(await requiredDataIsValid(checkoutData, !loggedIn ? customerOrigin : undefined));

      setCheckoutErrors({
        billingAddress: {
          isValid: loggedIn
            ? validateBillingAddress(checkoutData)
            : validateBillingAddress(checkoutData) && (await validateCustomerData(checkoutData, customerOrigin)),
          section: billingAddressSectionRef.current,
        },
        shippingAddress: {
          isValid: !!validateShippingAddress(checkoutData),
          section: shippingSectionRef.current,
        },
        payment: {
          isValid: validatePayment(checkoutData),
          section: paymentSectionRef.current,
        },
        userAgbAccepted: {
          isValid: loggedIn ? checkoutData.userAgbAccepted : true,
        },
      });
    };

    validate();
  }, [checkoutData, loaded, isCheckoutSubmitted]);

  useEffect(() => {
    if (loaded) {
      updateEcondaDataLayer(
        `Shop/Kaufprozess/Kundendaten/${loggedIn ? 'BereitsKunde' : isGuest ? 'OhneReg' : 'NeuesKonto'}`,
        `2_Kundendaten/${loggedIn ? 'BereitsKunde' : isGuest ? 'OhneReg' : 'NeuesKonto'}`,
      );
    }

    if (
      (checkoutErrors?.billingAddress?.isValid && !checkoutErrors?.payment?.isValid) ||
      (loaded && loggedIn && !checkoutData.paymentMethod)
    ) {
      updateEcondaDataLayer('Shop/Kaufprozess/Zahlungsoptionen', '3_Zahlungsoptionen');
    }

    if (
      loaded &&
      checkoutErrors?.billingAddress?.isValid &&
      checkoutErrors?.payment?.isValid &&
      checkoutErrors?.userAgbAccepted?.isValid
    ) {
      updateEcondaDataLayer('Shop/Kaufprozess/Bestelluebersicht', '4_Bestelluebersicht');
    }
  }, []);

  useEffect(() => {
    const loginValue = loggedIn ? 'Bestandskunde' : isGuest ? 'Gastbesteller' : 'Neukunde';
    new TagManager().customEvent('login', { value: loginValue }).executePush();
  }, []);

  useEffect(() => {
    const filteredErrors = checkoutErrors
      ? Object.keys(checkoutErrors).filter((key) => !checkoutErrors[key].isValid)
      : null;

    setDisableSubmitButton(!(dataIsValid && !filteredErrors?.length));
  }, [dataIsValid, checkoutErrors]);

  useEffect(() => {
    if (isMobile !== isMinWidthMedium) {
      setIsMinWidthMedium(isMobile);
    }
  }, [isMobile]);

  useEffect(() => {
    if (!cartList) {
      return;
    }
    setDatalayerItems(
      cartList?.lineItems?.map((lineItem: LineItem, index) =>
        ItemMapper.lineItemToItem(lineItem, lineItem.count, index, lineItem.variant, true),
      ),
    );
    setDatalayerCouponCodes(joinDiscounts(cartList?.discountCodes, cartList?.customLineItems));
  }, [cartList]);

  useEffect(() => {
    if (!cartList || handleDatalayer.current) {
      return;
    }
    if (datalayerItems?.length > 0) {
      new TagManager()
        .ecommerceEvent(
          'add_shipping_info',
          datalayerItems,
          calculateCartLineItemsPrice(cartList) ?? cartList.sum,
          datalayerCouponCodes,
          {
            shipping_tier: checkoutData.shippingMethod,
          },
        )
        .executePush();
      handleDatalayer.current = true;
    }
  }, [cartList]);

  useEffect(() => {
    if (datalayerItems?.length > 0) {
      new TagManager()
        .ecommerceEvent(
          'add_shipping_info',
          datalayerItems,
          calculateCartLineItemsPrice(cartList) ?? cartList.sum,
          datalayerCouponCodes,
          {
            shipping_tier: checkoutData.shippingMethod,
          },
        )
        .executePush();
    }
  }, [checkoutData.shippingMethod]);

  useEffect(() => {
    if (datalayerItems?.length > 0 && checkoutData.paymentMethod !== null) {
      new TagManager()
        .ecommerceEvent(
          'add_payment_info',
          datalayerItems,
          calculateCartLineItemsPrice(cartList) ?? cartList.sum,
          datalayerCouponCodes,
          {
            payment_type: checkoutData.paymentMethod,
          },
        )
        .executePush();
    }
  }, [checkoutData.paymentMethod]);

  return (
    <div className={styles.wrapper}>
      {loading ? (
        <div className={styles.spinnerWrap}>
          <Spinner size={isMinWidthMedium ? 'small' : 'large'} />
        </div>
      ) : null}
      <Markdown
        className={styles.title}
        text={customerOrigin.isGuest ? headlines.guest : loggedIn ? headlines.existing : headlines.new}
        textSize={headlines.textSize}
      />
      <Section elementRef={billingAddressSectionRef} headline={billingAddressSection.headline}>
        <BillingAddress
          data={checkoutData}
          updateFormInput={updateFormInput}
          labels={addressLabels}
          customerOrigin={customerOrigin}
          forbiddenFields={forbiddenFields}
          setBillingAddressPrefilled={setBillingAddressPrefilled}
          setCheckoutData={setCheckoutData}
          setCheckoutErrors={setCheckoutErrors}
        />
      </Section>
      <Section elementRef={shippingSectionRef} headline={shippingSection.headline}>
        <ShippingAddress
          shippingSection={shippingSection}
          updateFormInput={updateFormInput}
          updateCartData={updateCartData}
          labels={addressLabels}
          data={checkoutData}
          shippingAddressSection={shippingAddressSection}
          packStationSection={packStationSection}
          storeDeliverySection={storeDeliverySection}
          setLoading={setLoading}
          loading={loading}
          forbiddenFields={forbiddenFields}
          checkoutErrors={checkoutErrors}
          setCheckoutErrors={setCheckoutErrors}
          loggedIn={loggedIn}
          setUpdatingLineItems={setUpdatingLineItems}
        />
      </Section>
      <Section elementRef={paymentSectionRef} headline={paymentSection.headline}>
        <Payment
          cartList={cart}
          updateFormInput={updateFormInput}
          updateCartData={updateCartData}
          loading={loading}
          setLoading={setLoading}
          paymentSection={paymentSection}
          checkoutData={checkoutData}
          customerOrigin={customerOrigin}
          billingAddressSectionRef={billingAddressSectionRef.current}
          paymentSectionRef={paymentSectionRef.current}
          overviewSectionRef={overviewSectionRef.current}
          migrationVerifyInformation={billingAddressSection.migrationVerifyInformation}
          migrationVerifyBirthdate={billingAddressSection.migrationVerifyBirthdate}
          customerOriginDetermined={customerOriginDetermined.current}
          billingAdressPrefilled={billingAdressPrefilled}
          checkoutErrors={checkoutErrors}
          paymentErrors={paymentErrors}
          setPaymentErrors={setPaymentErrors}
          setTokenizationId={setHostedTokenizationId}
          setPaymentProductId={setPaymentProductId}
          resetPayment={resetPayment}
          isIframePayment={isIframePayment}
          setIsIframePayment={setIsIframePayment}
          selectedPaymentMethod={selectedPaymentMethod}
          setSelectedPaymentMethod={setSelectedPaymentMethod}
          hasLockedProducts={hasLockedProducts}
          setHasLockedProducts={setHasLockedProducts}
        />
      </Section>
      <Section elementRef={overviewSectionRef} headline={overviewSection.headline}>
        <Overview
          checkoutData={checkoutData}
          overviewSection={overviewSection}
          billingAddressSectionRef={billingAddressSectionRef.current}
          shippingSectionRef={shippingSectionRef.current}
          paymentSectionRef={paymentSectionRef.current}
          overviewSectionRef={overviewSectionRef.current}
          voucherSection={{
            ...voucherSection,
            discounts: discounts,
            setDiscounts: setDiscounts,
          }}
          productOverviewSection={productOverviewSection}
          cart={{
            cartList: cart,
            updateItemQuantity: updateItemQuantity,
            updateItemSize: updateItemSize,
            deleteItem: deleteItem,
            removeDiscountCode: removeDiscountCode,
            setIsUpdating: setLoading,
          }}
          totalSummarySection={totalSummarySection}
          updateFormInput={updateFormInput}
          disableSubmitButton={disableSubmitButton}
          onSubmit={submit}
          checkoutErrors={checkoutErrors}
          loggedIn={loggedIn}
          ctaSection={{
            ...ctaSection,
            labelAgb: addressLabels.labelAgb,
            ctaCheckoutError,
          }}
          setLoading={setLoading}
          setUpdatingLineItems={setUpdatingLineItems}
        />
      </Section>
    </div>
  );
};
