import styles from './section.module.scss';

interface ISection {
  children: React.ReactNode;
  headline: string;
  elementRef: React.Ref<HTMLDivElement>;
}

export const Section: React.FC<ISection> = ({ children, headline, elementRef }) => {
  return (
    <div ref={elementRef} className={styles.section}>
      <span className={styles.title}>{headline}</span>
      {children}
    </div>
  );
};
