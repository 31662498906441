import React, { useEffect, useRef, useState } from 'react';
import { useImageSEO } from '@frontastic-engbers/helpers/hooks/useImageSEO';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { useAccount, useCart } from '@frontastic-engbers/lib';
import { TagManager } from '@frontastic-engbers/lib/lib/tracking';
import { ItemMapper } from '@frontastic-engbers/lib/lib/tracking/itemMapper';
import { UseCart } from '@frontastic-engbers/lib/provider/frontastic/UseCart';
import { joinDiscounts } from '@frontastic-engbers/helpers/dataLayerHelper/couponCodeDataHelper';
import { updateItemDatalayerPush } from '@frontastic-engbers/helpers/dataLayerHelper/updateItemDatalayerHelper';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { checkLineItemDuplicates } from '@frontastic-engbers/helpers/utils/checkLineItemDuplicates';
import { Cart as CartType } from '@frontastic-engbers/types/cart/Cart';
import { Discount } from '@frontastic-engbers/types/cart/Discount';
import { LineItem } from '@frontastic-engbers/types/cart/LineItem';
import { MediaType } from '@frontastic-engbers/types/engbers-custom';
import { Variant } from '@frontastic-engbers/types/product/Variant';
import DiscountForm from '../commercetools-ui/discount-form';
import Spinner from '../commercetools-ui/spinner';
import ItemList from './itemList';
import { OrderSummary } from '@engbers/components/online-shops';
import { IconCustom, Image, LoadingSpinner } from '@engbers/components';
import styles from './cart.module.scss';
import { calculateCartLineItemsPrice } from '@frontastic-engbers/helpers/dataLayerHelper/calculateCartLineItemsPrice';

export interface Props {
  emptyCartText: string;
  continueShoppingLabel: string;
  ctaLabel: string;
  ctaBgColor?: string;
  ctaTextColor?: string;
  cartTitle: string;
  productOverviewArticle: string;
  productOverviewDesc: string;
  productOverviewAmount: string;
  productOverviewSum: string;
  voucherSection: {
    voucherInstruction: string;
    voucherHeadline: string;
    voucherInfo: string;
    voucherRedeemBtn: string;
    voucherRedeemedLabel?: string;
    voucherInvalidLabel?: string;
  };
  shippingTime: string;
  shippingInfo: string;
  serviceShippingCostsNote: string;
  fallbackShippingCostsDe: number;
  fallbackShippingCostsEu: number;
  shippingCostsNote: string;
  TSIcon?: MediaType;
  TSText?: string;
  SSLIcon?: MediaType;
  SSLText?: string;
}

const Cart = ({
  emptyCartText,
  continueShoppingLabel,
  ctaLabel,
  ctaBgColor,
  ctaTextColor,
  cartTitle,
  productOverviewArticle,
  productOverviewDesc,
  productOverviewAmount,
  productOverviewSum,
  shippingTime,
  shippingInfo,
  serviceShippingCostsNote,
  fallbackShippingCostsDe,
  fallbackShippingCostsEu,
  shippingCostsNote,
  voucherSection,
  TSIcon,
  TSText,
  SSLIcon,
  SSLText,
}: Props) => {
  const { getTitle } = useImageSEO();
  const { loggedIn } = useAccount();
  const { data: cartList, addItem, removeItem, updateItem, removeDiscountCode }: UseCart = useCart();
  const [loading, setLoading] = useState<boolean>(true);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [cart, setCart] = useState<CartType>(cartList);
  const [discounts, setDiscounts] = useState<Discount[]>([]);
  const [updatingLineItems, setUpdatingLineItems] = useState<boolean>(false);
  const assignCart = useRef<boolean>(true);
  const noItems = (!loading && !cart?.lineItems) || cart?.lineItems?.length < 1;
  const hasOnlyGiftCard = cart?.lineItems?.every((item) => item.variant.attributes.IsGiftcard);

  const { formatMessage } = useFormat({ name: 'common' });
  const router = useRouter();

  const handleUpdate = async (fn: () => Promise<void>) => {
    setIsUpdating(true);
    await fn();
    setIsUpdating(false);
  };

  const updateItemQuantity = (lineItem: LineItem, newQuantity: number) => {
    updateItemDatalayerPush(lineItem, newQuantity);

    return handleUpdate(() => updateItem(lineItem.lineItemId, newQuantity));
  };

  const updateItemSize = (variant: Variant, quantity: number) => handleUpdate(() => addItem(variant, quantity));

  const deleteItem = (lineItem: LineItem) => {
    const total = Number(
      ((lineItem.totalPrice.centAmount ?? 0) / 10 ** lineItem.price.fractionDigits).toFixed(
        lineItem.price.fractionDigits,
      ),
    );

    new TagManager().removeFromCartEvent([ItemMapper.lineItemToItem(lineItem, lineItem.count)], total).executePush();

    // prevent position split by discount being removed completely when removing the cloned position
    const duplicateCheck = checkLineItemDuplicates(cartList, lineItem);

    if (duplicateCheck.occurrences >= 2) {
      return handleUpdate(() => updateItemQuantity(lineItem, duplicateCheck.count - lineItem.count));
    }

    return handleUpdate(() => removeItem(lineItem.lineItemId));
  };

  const goToPage = (_url: string) => router.push(_url);

  const handleClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    await goToPage(loggedIn ? '/checkout' : '/checkout/login');

    const datalayerCouponCodes = joinDiscounts(cartList.discountCodes, cartList.customLineItems);
    const datalayerItems = cartList?.lineItems?.map((lineItem: LineItem, index) =>
      ItemMapper.lineItemToItem(lineItem, lineItem.count, index, lineItem.variant, true),
    );
    new TagManager()
      .ecommerceEvent(
        'begin_checkout',
        datalayerItems,
        calculateCartLineItemsPrice(cartList) ?? cartList.sum,
        datalayerCouponCodes,
      )
      .executePush();
  };

  useEffect(() => {
    if (assignCart?.current && cartList) {
      setCart(cartList);
      assignCart.current = false;
    }

    if (cartList?.lineItems) {
      const lineItemsTotalValue = cartList.lineItems.reduce(
        (price, lineItem) =>
          price +
          (lineItem.variant?.discountedPrice?.centAmount ?? lineItem.variant?.price?.centAmount ?? 0) * lineItem.count,
        0,
      );

      const lineItemsTotalValueMoney = {
        fractionDigits: cartList.sum.fractionDigits ?? 2,
        centAmount: lineItemsTotalValue,
        currency: cartList.sum.currencyCode ?? 'EUR',
      };

      const datalayerCouponCodes = joinDiscounts(cartList.discountCodes, cartList.customLineItems);
      const datalayerItems = cartList?.lineItems?.map((lineItem: LineItem, index) =>
        ItemMapper.lineItemToItem(lineItem, lineItem.count, index, lineItem.variant, true),
      );

      new TagManager()
        .customEvent('ecomm', {
          ecomm_pagetype: 'cart',
          ecomm_prodid: cartList.lineItems.map((lineItem) => lineItem.variant.sku.split(/[-.]/)[0]),
          ecomm_totalvalue: lineItemsTotalValue / 100,
        })
        .ecommerceEvent('view_cart', datalayerItems, lineItemsTotalValueMoney, datalayerCouponCodes)
        .executePush();

      setLoading(false);
    }
  }, [cartList]);

  useEffect(() => {
    if (
      cartList &&
      (!updatingLineItems ||
        (updatingLineItems && (!cart.lineItems || cart.lineItems.length === cartList.lineItems.length)))
    ) {
      setCart(cartList);
    }
  }, [updatingLineItems]);

  if (loading) {
    return (
      <div className="flex items-stretch justify-center px-12 py-20">
        <Spinner />
      </div>
    );
  }

  const CTASection = (hasBorder = false) => (
    <div className={classNames(styles.ctaSectionWrap, hasBorder ? styles.hasBorder : undefined)}>
      {continueShoppingLabel ? (
        <button className={classNames('btn', styles.continueShopping)} onClick={() => router.back()}>
          <IconCustom width={12} icon="ChevronRightIcon" className="mr-1" />
          {continueShoppingLabel}
        </button>
      ) : null}
      {ctaLabel && !noItems && (
        <button
          className={classNames('cta', styles.ctaBtn)}
          onClick={handleClick}
          style={{
            backgroundColor: ctaBgColor ? ctaBgColor : undefined,
            color: ctaTextColor ? ctaTextColor : undefined,
          }}
        >
          {ctaLabel}
          <IconCustom
            width={12}
            color={ctaTextColor ? 'custom' : 'white'}
            icon="ChevronRightIcon"
            customColor={ctaTextColor ? ctaTextColor : undefined}
            className="mr-1"
          />
        </button>
      )}
    </div>
  );

  return (
    <main>
      <LoadingSpinner isLoading={isUpdating} />
      {CTASection(true)}
      <div className={styles.cartTitle}>
        <h2>{cartTitle}</h2>
      </div>
      {noItems ? (
        <div className={styles.emptyCart}>{emptyCartText}</div>
      ) : (
        <>
          <div className={styles.orderOverviewHeadlines}>
            <div style={{ width: '20%' }}>{productOverviewArticle}</div>
            <div style={{ width: '80%' }} className={styles.productOverviewInfoWrap}>
              <div style={{ width: '60%' }}>{productOverviewDesc}</div>
              <div style={{ width: '20%' }}>{productOverviewAmount}</div>
              <div style={{ width: '20%' }}>{productOverviewSum}</div>
            </div>
          </div>
          <div className={styles.lineItemsWrap}>
            <ItemList
              updateItemQuantity={updateItemQuantity}
              updateItemSize={updateItemSize}
              deleteItem={deleteItem}
              setIsUpdating={setIsUpdating}
              setUpdatingLineItems={setUpdatingLineItems}
              shippingTime={shippingTime}
              cart={cart}
            />
          </div>
          <div className={styles.voucherWrap}>
            <DiscountForm
              cart={cart}
              voucherSection={{
                ...voucherSection,
                discounts: discounts,
                setDiscounts: setDiscounts,
              }}
              setLoading={setIsUpdating}
              setUpdatingLineItems={setUpdatingLineItems}
            />
          </div>
          <div className={styles.orderSummaryWrap}>
            {!hasOnlyGiftCard && (
              <div className={styles.shippingWrapper}>
                {shippingTime || shippingInfo ? (
                  <>
                    <span className={styles.shippingHeadline}>{formatMessage({ id: 'delivery' })}:</span>
                    <span>{shippingTime}</span>
                    <span className={styles.shippingInfo}>{shippingInfo}</span>
                  </>
                ) : null}
              </div>
            )}
            <div className={styles.tsWrapper}>
              {TSIcon?.media && TSText && (
                <div className={styles.tsIconWrapper}>
                  <Image src={TSIcon.media.file} alt={getTitle(TSIcon)} title={getTitle(TSIcon)} />
                  <span>{TSText}</span>
                </div>
              )}
              {SSLIcon?.media && SSLText && (
                <div className={styles.tsIconWrapper}>
                  <Image src={SSLIcon.media.file} alt={getTitle(SSLIcon)} title={getTitle(SSLIcon)} />
                  <span>{SSLText}</span>
                </div>
              )}
            </div>
            <section>
              <OrderSummary
                shippingCostsNote={shippingCostsNote}
                removeDiscountCode={removeDiscountCode}
                serviceShippingCostsNote={serviceShippingCostsNote}
                fallbackShippingCostsDe={fallbackShippingCostsDe}
                fallbackShippingCostsEu={fallbackShippingCostsEu}
                setUpdatingLineItems={setUpdatingLineItems}
                cart={cart}
              />
            </section>
          </div>
          {CTASection()}
        </>
      )}
    </main>
  );
};

export default Cart;
