import React from 'react';
import { useRouter } from 'next/router';
import { IconCustom } from '@engbers/components';

export type BackButtonType = {
  classNames: string;
}

export const BackButton: React.FC<BackButtonType> = ({ classNames }) => {
  const router = useRouter();

  return (
    <button className={classNames}
      onClick={() => router.back()}>
      <IconCustom width={20} icon="ArrowThinLeft" />
    </button>
  );
};
