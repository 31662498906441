import { VoucherCard } from '../voucher-card';
import { useVouchers } from '@frontastic-engbers/lib/actions/account';
import { LoadingIndicatorInline, SectionLayout } from '@engbers/components';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import styles from './coupons.module.scss';
import { useMemo } from 'react';

type CouponsProps = {
  placeholderLabel: string;
  description?: string;
};

export const Coupons = ({ placeholderLabel, description }: CouponsProps) => {
  const { loaded: loadedVouchers, vouchers } = useVouchers();
  const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });

  const filteredVouchers = useMemo(() => {
    return vouchers?.filter((x) => x.kind === 'Aktionsgutschein') || [];
  }, [loadedVouchers, vouchers]);

  if (!loadedVouchers) {
    return <LoadingIndicatorInline color="blue" />;
  }

  const showPlaceholder = loadedVouchers && filteredVouchers.length === 0;

  return (
    <div>
      {showPlaceholder ? (
        <SectionLayout>
          <VoucherCard
            header={
              <div className={styles.currentCredit}>
                <div>{placeholderLabel || formatErrorMessage({ id: 'noAvailabeVouchers' })}</div>
              </div>
            }
          />
        </SectionLayout>
      ) : undefined}
      {filteredVouchers?.map((voucher) => {
        if (voucher.barcodeNumber === 'None') {
          return null;
        }

        return (
          <div key={`voucher-${voucher.kind}-${voucher.creditNoteNumber}`} className={styles.item}>
            <VoucherCard
              voucherCode={voucher.creditNoteNumber}
              barcodeSvg={voucher.barcodeImage.svg}
              onlinePin={voucher.creditNotePin}
              description={description}
            />
          </div>
        );
      })}
    </div>
  );
};
