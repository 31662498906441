import classnames from 'classnames';
import { useRefinementList } from 'react-instantsearch';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { sortSizes } from '@frontastic-engbers/helpers/utils/sizeSorting';
import { InputCheckbox } from '@engbers/components';
import { IFacetProps } from './types';
import styles from './facets.module.scss';
import { handleFilterDataLayer } from '@frontastic-engbers/helpers/dataLayerHelper';
import React from 'react';

const SizeFacet: React.FC<IFacetProps> = ({
  label,
  attribute,
  wrapper: Wrapper = React.Fragment,
  showItemCount = false,
}) => {
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  const { refine, items } = useRefinementList({
    attribute,
    limit: 1000,
  });
  const { refine: refineLargeSizes, items: largeSizeItems } = useRefinementList({ attribute: 'attributes.IsXXL' });

  items.sort(sortSizes);

  return (
    <div className={styles.tileFacetValues}>
      {items.map((term) => (
        <Wrapper key={term.value + 'wrapper'}>
          <button
            key={term.value}
            type="button"
            className={classnames(
              'GA4-size-filter-trigger',
              styles.tileFacetValue,
              term.isRefined ? styles.checked : undefined,
            )}
            onClick={() => {
              refine(term.value);
              handleFilterDataLayer(items, label, term.value);
            }}
          >
            {term.label}
          </button>
        </Wrapper>
      ))}
      <div className={styles.onlyLargeSizes}>
        {largeSizeItems
          .filter((term) => term.value === '1')
          .map((term) => (
            <Wrapper key={term.value + 'wrapper'}>
              <div>
                <InputCheckbox
                  key={term.value}
                  id="onlyLargeSizes"
                  name="onlyLargeSizes"
                  checked={term.isRefined}
                  onChange={() => refineLargeSizes(term.value)}
                  label={`${formatProductMessage({
                    id: 'onlyLargeSizes',
                    defaultMessage: 'Nur große Größen',
                  })}${showItemCount ? ` (${term.count.toString()})` : ``}`}
                />
              </div>
            </Wrapper>
          ))}
      </div>
    </div>
  );
};

export default SizeFacet;
