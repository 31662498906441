import React from 'react';
import { createLocalStorageRecentSearchesPlugin } from '@algolia/autocomplete-plugin-recent-searches';
import { SuggestedSearch } from '../suggested-search';

export const recentSearchesPlugin = (onClick: () => void) => {
  return createLocalStorageRecentSearchesPlugin({
    key: 'search-flyout',
    limit: 6,
    transformSource({ source, onRemove }) {
      return {
        ...source,
        getItemUrl({ item }) {
          return `/search/${item.label}`;
        },
        onSelect({ setQuery, item, setIsOpen }) {
          setQuery(item.label);
          setIsOpen(false);
          onClick();
        },
        templates: {
          ...source.templates,
          item({ item }) {
            return <SuggestedSearch value={item.label} isRecentSearch={true} onRemove={onRemove} />;
          },
        },
      };
    },
  });
};
