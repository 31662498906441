import { useAccount,  } from '@frontastic-engbers/lib';
import { useCardData } from '@frontastic-engbers/lib/actions/account/useCardData';
import { MyAccountEngbersCard } from '@engbers/components/my-account/my-engbers-card';
import { FullPageWidthWrapper } from '@engbers/components/online-shops';

export const MyAccountEngbersCardTastic = ({ data }) => {
  const { loaded, cardData } = useCardData();
  const { loggedIn } = useAccount();

  if (!loggedIn || !loaded) {
    return null;
  }

  if (data.isFullWidth) {
    return (
      <FullPageWidthWrapper>
        <MyAccountEngbersCard cardId={cardData.cardId} data={data} />
      </FullPageWidthWrapper>
    );
  }

  return <MyAccountEngbersCard cardId={cardData.cardId} data={data} />;
}
