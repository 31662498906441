import styles from './credit-voucher.module.scss';
import { VoucherCard } from '../voucher-card';
import { useVouchers } from '@frontastic-engbers/lib/actions/account';
import { LoadingIndicatorInline } from '@engbers/components';
import { useMemo } from 'react';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';

export const CreditVoucher = ({ placeholderText, labelYourCredit }) => {
  const { loaded, vouchers } = useVouchers();
  const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });

  const filteredVouchers = useMemo(() => {
    return vouchers?.filter((x) => x.kind === 'Rabattgutschein') || [];
  }, [loaded, vouchers]);

  if (!loaded) {
    return <LoadingIndicatorInline color="blue" />;
  }

  const showPlaceholder = loaded && filteredVouchers.length === 0;

  return (
    <>
      {showPlaceholder ? (
        <VoucherCard
          key={`voucher-placeholder`}
          header={
            <div className={styles.currentCredit}>
              <div>{labelYourCredit || formatCheckoutMessage({ id: 'distributedCredit' })}</div>
              <div className={styles.amount}>0 €</div>
            </div>
          }
          placeholder={placeholderText || formatCheckoutMessage({ id: 'notAvailableCredit' })}
        />
      ) : undefined}
      {filteredVouchers?.map((voucher) => {
        return (
          <VoucherCard
            key={`voucher-${voucher.creditNoteNumber}`}
            header={
              <div className={styles.currentCredit}>
                <div>{labelYourCredit || formatCheckoutMessage({ id: 'distributedCredit' })}</div>
                <div className={styles.amount}>{`${voucher.amount} €`}</div>
              </div>
            }
            voucherCode={voucher.creditNoteNumber}
            barcodeSvg={voucher.barcodeImage?.svg}
            onlinePin={voucher.creditNotePin}
          />
        );
      })}
    </>
  );
};
