import React from 'react';
import { useRouter } from 'next/router';
import { useImageSEO } from './hooks/useImageSEO';
import { Product } from '@frontastic-engbers/types/product/Product';
import { CustomMarkdown } from '@engbers/components';

type Props = {
  projectId: string;
  structuredData: Record<string, any>;
  siteUrl: string;
};

export const StructuredData: React.FC<Props> = ({ projectId, structuredData, siteUrl }) => {
  const router = useRouter();
  const { getTitle } = useImageSEO();

  if (!structuredData) {
    return null;
  }

  return (
    <div className="hidden">
      <div itemScope itemType="https://schema.org/Organization">
        <span itemProp="name">{structuredData.name}</span>
        <link itemProp="url" href={siteUrl} />
        <span itemProp="email">{structuredData.email}</span>
        <span itemProp="telephone">{structuredData.telephone}</span>
        <span itemProp="description">{structuredData.description}</span>
        {structuredData.logo?.media?.file && (
          <img
            itemProp="logo"
            src={structuredData.logo.media?.file}
            alt={getTitle(structuredData.logo)}
            title={getTitle(structuredData.logo)}
          />
        )}
        <CustomMarkdown text={structuredData.sameAs} />
        {router.asPath !== '/' && (
          <div itemProp="aggregateRating" itemScope itemType="https://schema.org/AggregateRating">
            <span itemProp="bestRating">5.00</span>
            <span itemProp="ratingValue">{structuredData.aggregateRating}</span>
            <span itemProp="ratingCount">{structuredData.ratingCount}</span>
            <span itemProp="itemReviewed">{`${projectId === 'ea' ? 'emilioadani' : 'engbers'}.com`}</span>
          </div>
        )}
      </div>
    </div>
  );
};

type PDPProps = {
  product: Product;
  siteUrl: string;
};

export const StructuredDataPDP: React.FC<PDPProps> = ({ product, siteUrl }) => {
  return (
    <div className="hidden" itemScope itemType="https://schema.org/Product">
      {product.variants[0]?.images?.[0] && <meta itemProp="image" content={product.variants[0].images[0]} />}
      <meta itemProp="name" content={product.name} />
      <meta itemProp="mpn" content={product.productId} />
      <meta itemProp="sku" content={product.productId} />
      {product.attributes?.DesignerName && <meta itemProp="brand" content={product.attributes?.DesignerName} />}
      <div itemProp="description">{product.description}</div>
      <div itemProp="offers" itemScope itemType="https://schema.org/Offer">
        <link itemProp="url" href={siteUrl + product.url} />
        {product.variants[0]?.price?.centAmount && (
          <meta itemProp="price" content={`${product.variants[0].price.centAmount / 100}`} />
        )}
        <meta itemProp="priceCurrency" content={product.variants[0]?.price?.currencyCode ?? 'EUR'} />
        <link itemProp="itemCondition" href="https://schema.org/NewCondition" />
        <link
          itemProp="availability"
          href={
            product.variants.every((v) => v.isInStock === false)
              ? 'https://schema.org/OutOfStock'
              : product.variants.filter((item) => !item.isInStock).length >= 2
                ? 'https://schema.org/LimitedAvailability'
                : 'https://schema.org/InStock'
          }
        />
      </div>
    </div>
  );
};
