import React from 'react';
import { decode } from 'html-entities';
import rehypeAttrs from 'rehype-attr';
import rehypeParse from 'rehype-parse';
import rehypeRaw from 'rehype-raw';
import rehypeReact from 'rehype-react';
import rehypeStringify from 'rehype-stringify';
import remarkDirective from 'remark-directive';
import remarkParse from 'remark-parse';
import remarkRehype from 'remark-rehype';
import { unified } from 'unified';
import { VFileValue } from 'unified/lib';
import breaksPlugin from './breaksPlugin';
import remarkGfm from './remarkGfm';

export default class Unified {
  public static parse(text: string): React.ReactNode {
    const parsed = this.parseRemark(text);
    const encoded = decode(parsed.toString());

    const processor = unified().use(rehypeParse, { fragment: true }).use(rehypeReact, {
      createElement: React.createElement,
    });

    return processor.processSync(encoded).result;
  }

  private static parseRemark(text: string): VFileValue {
    const processor = unified()
      .use(remarkParse)
      .use(remarkRehype, { allowDangerousHtml: true })
      .use(remarkGfm)
      .use(remarkDirective)
      .use(rehypeRaw)
      .use(rehypeAttrs, { properties: 'attr' })
      .use(rehypeStringify)
      .use(breaksPlugin);

    return processor.processSync(text).value;
  }
}
