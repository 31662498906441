import classNames from 'classnames';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { StoreAvailability } from '@frontastic-engbers/types/product/StoreAvailability';
import styles from './store-availability-size.module.scss';

export interface IStoreAvailabilitySize {
  sizeStock: StoreAvailability;
  onlineAvailability: Record<string, boolean>;
  storeIsClosed: boolean;
  storeIsClosedHint: string;
}

export const StoreAvailabilitySize: React.FC<IStoreAvailabilitySize> = ({
  sizeStock,
  onlineAvailability,
  storeIsClosed,
  storeIsClosedHint,
}) => {
  const { formatMessage } = useFormat({ name: 'product' });
  const inStock = formatMessage({
    id: 'inStock',
    defaultMessage: 'Auf Lager',
  });

  if (!sizeStock.visible) {
    return null;
  }

  const availabilityClass =
    onlineAvailability[sizeStock.size] && (sizeStock.isOnlineShopExclusive || sizeStock.invent < 1)
      ? styles.onlineonly
      : sizeStock.invent > 0 && !sizeStock.isOnlineShopExclusive
        ? styles.available
        : styles.notavailable;

  return (
    <div
      key={sizeStock.size}
      itemProp="availability"
      title={storeIsClosed
        ? storeIsClosedHint
        : `${inStock}: ${sizeStock.invent}`}
      className={classNames(styles.size, { [availabilityClass]: !storeIsClosed })}
    >
      {sizeStock.size === '111'
        ? 'one size'
        : sizeStock.size}
    </div>
  );
};
