import { ReferenceLink, Reference } from '@frontastic-engbers/helpers/reference';
import Image, { MediaItemWithMods } from '@frontastic-engbers/lib/lib/image';
import { MediaType } from '@frontastic-engbers/types/engbers-custom';
import { ImageSliderNavButtons } from './image-slider-nav-button';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { useMediaQuery } from 'react-responsive';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from './image-slider.module.scss';

export interface ISliderItems  {
  image: MediaItemWithMods;
  imageMobile: MediaItemWithMods;
  imageText: string;
  imageRedirect: Reference;
}

export interface IEnGermanyImageSlider {
  arrowIcon: MediaType;
  autoPlayDelay: number;
  sliderItems: ISliderItems[];
}

export const EnGermanyImageSlider: React.FC<IEnGermanyImageSlider> = ({
  arrowIcon,
  autoPlayDelay,
  sliderItems
}) => {
  const [isMinWidthMedium, setIsMinWidthMedium] = useState<boolean>(false);
  const isMobile = useMediaQuery({ maxWidth: 768 });

  useEffect(() => {
    if (isMobile !== isMinWidthMedium){
      setIsMinWidthMedium(isMobile);
    }
  }, [isMobile]);

  return (
    <Swiper
      simulateTouch={false}
      centeredSlides={true}
      autoHeight={true}
      className={styles.imageSliderWrap}
    >
      {sliderItems?.map((item) =>
        item.image && (
        <SwiperSlide key={item.image.media.mediaId} className={styles.imageSliderInnerWrap}>
          <ReferenceLink target={item.imageRedirect}>
            {isMinWidthMedium ? (
              <Image
                media={item.imageMobile}
                alt={item.imageMobile.media.name}
                className={styles.sliderImageMobile}
              />
            ) : (
              <Image
                media={item.image}
                alt={item.image.media.name}
                className={styles.sliderImage}
              />
            )}
          </ReferenceLink>
          <div className={styles.imageSliderContentBlock}>
            <div>
              <div className={styles.imageSliderContentHeadline}>engbers germany</div>
              <ReactMarkdown className={styles.imageSliderContentText}>{item.imageText}</ReactMarkdown>
            </div>
          </div>
          <div className={classNames(styles.imageSliderNavigationWrap, styles.slideNavigationWrap)} />
        </SwiperSlide>
      ),
    )}
      <div className={styles.imageSliderNavigationWrap}>
        <ImageSliderNavButtons arrowIcon={arrowIcon} numberOfSlides={sliderItems.length} autoPlayDelay={autoPlayDelay} />
      </div>
    </Swiper>
  );
};
