import { CheckoutData } from '@frontastic-engbers/types/engbers-custom';
import { getSelectedShippingAddress } from '@frontastic-engbers/helpers/utils/getShippingInfo';
import { validateDateFormat } from '@frontastic-engbers/helpers/utils/validateDateFormat';
import { validatePostalCode } from '@frontastic-engbers/helpers/utils/validatePostalCode';
import { validateStreetName } from '@frontastic-engbers/helpers/utils/validateStreetName';
import { PasswordCheckListHelper } from '@frontastic-engbers/helpers/passwordCheckListHelper';
import { EmailValidatorHelper } from '@frontastic-engbers/helpers/emailValidatorHelper';

export const validateBillingAddress = (data: CheckoutData) => {
  const isCountryGermany = data?.billingAddress?.country?.toLowerCase() === 'de';
  const isCountryIreland = data?.billingAddress?.country?.toLowerCase() === 'ie';

  const isBillingAddressValid =
    !Object.keys(data.billingAddress).filter((key) =>
      key === 'postalCode' && isCountryIreland ? false : !data.billingAddress[key],
    ).length && validateStreetName(data?.billingAddress?.streetName);

  return isCountryGermany
    ? isBillingAddressValid && validatePostalCode(data?.billingAddress?.postalCode)
    : isBillingAddressValid;
};

export const validateShippingAddress = (data: CheckoutData) => {
  const selectedShippingAddress = getSelectedShippingAddress(data);
  const isCountryGermany = selectedShippingAddress?.country?.toLowerCase() === 'de';
  const isCountryIreland = selectedShippingAddress?.country?.toLowerCase() === 'ie';

  const isShippingAddressValid =
    selectedShippingAddress &&
    !Object.keys(selectedShippingAddress).filter((key) =>
      key === 'postalCode' && isCountryIreland ? false : key !== 'company' && !selectedShippingAddress[key],
    ).length &&
    validateStreetName(selectedShippingAddress?.streetName);

  return isCountryGermany
    ? isShippingAddressValid && validatePostalCode(selectedShippingAddress?.postalCode)
    : isShippingAddressValid;
};

export const validatePayment = (data: CheckoutData) => {
  return !!data.paymentMethod;
};

const validateEmail = async (data: CheckoutData) => {
  const ruleDefinitions = await EmailValidatorHelper.ruleDefinitions(data.customerData.email, false);
  return !!ruleDefinitions || data.customerData.email !== data.customerData.emailRepeat;
};

const validatePassword = (data: CheckoutData) => {
  const ruleDefinitions = PasswordCheckListHelper.ruleDefinitions(data.customerData.password, 6);

  return (
    !!Object.keys(ruleDefinitions).filter((key) => !ruleDefinitions[key].valid).length ||
    data.customerData.password !== data.customerData.passwordRepeat
  );
};

const checkGuestDataFields = (key: string) => {
  return key !== 'password' && key !== 'passwordRepeat' && key !== 'cardAgbAccepted' && key !== 'postSubmit';
};

export const validateCustomerData = async (
  data: CheckoutData,
  customerOrigin: {
    isGuest: boolean;
    isNew: boolean;
  },
) => {
  const isBirthdateValid = validateDateFormat(
    data.customerData['birthdayDay']?.toString(),
    data.customerData['birthdayMonth']?.toString(),
    data.customerData['birthdayYear']?.toString(),
  );

  const emailHasError = await validateEmail(data);
  const passwordHasError = !customerOrigin?.isGuest && validatePassword(data);

  return (
    isBirthdateValid &&
    !emailHasError &&
    !passwordHasError &&
    !Object.keys(data.customerData).filter((key) => {
      if (customerOrigin?.isGuest) {
        return checkGuestDataFields(key) && !data.customerData[key];
      }

      return !data.customerData[key] && key !== 'agbAccepted' && key !== 'postSubmit';
    }).length
  );
};

export const requiredDataIsValid = async (
  data: CheckoutData,
  customerOrigin?: {
    isGuest: boolean;
    isNew: boolean;
  },
) => {
  const validations = validateBillingAddress(data) && validateShippingAddress(data) && validatePayment(data);

  if (customerOrigin) {
    return validations && (await validateCustomerData(data, customerOrigin));
  }

  return validations;
};
