import React from 'react';
import { MediaType } from '@frontastic-engbers/types/engbers-custom';
import { ITabMenuItem } from '../../online-shops/tab-menu/components/tab-menu-item';
import { MyAccountHeadline } from '../my-account-headline';
import { TabMenu } from '../../online-shops/tab-menu';

type Props = {
  headlineText: string;
  textSize?: number;
  headlineIcon: MediaType;
  items: ITabMenuItem[];
};

export const MyVouchers: React.FC<Props> = ({ headlineText, textSize, headlineIcon, items }) => {
  return (
    <div>
      <MyAccountHeadline
        headlineText={headlineText}
        textSize={textSize}
        iconMedia={headlineIcon}
        iconCustom="CardsOpen"
        uppercase
      />
      <TabMenu items={items} />
    </div>
  );
};
