import { CategoryImageText } from '@engbers/components/categroy-image-text';
import { FullPageWidthWrapper } from '@engbers/components/online-shops';
import { getProjectInfo } from '@frontastic-engbers/lib';

export const EngbersCategoryImageTextTastic = ({ data }) => {
  const isEmilio = getProjectInfo()?.projectId === 'ea';

  if (data.isFullWidth) {
    return (
      <FullPageWidthWrapper>
        <CategoryImageText categoriesImageText={data.categoryImageText} isEmilio={isEmilio} />
      </FullPageWidthWrapper>
    );
  }

  return <CategoryImageText categoriesImageText={data.categoryImageText} isEmilio={isEmilio} />;
};
