import { ToTheNewsletter } from '@engbers/components/to-the-newsletter';
import { FullPageWidthWrapper } from '@engbers/components/online-shops';

export const EngbersToTheNewsletterTastic = ({ data }) => {
  if (data.isFullWidth) {
    return (
      <FullPageWidthWrapper>
        <ToTheNewsletter data={data} />
      </FullPageWidthWrapper>
    );
  }

  return <ToTheNewsletter data={data} />;
};
