import { InfoBox, SectionLayout } from '@engbers/components';
import { FullPageWidthWrapper } from '@engbers/components/online-shops';

const EngbersInfoBoxTastic = ({ data }) => {
  if (data.isFullWidth) {
    return (
      <FullPageWidthWrapper>
        <InfoBox type="os" content={data.infoText} reference={data.reference} />
      </FullPageWidthWrapper>
    );
  }

  return (
    <SectionLayout>
      <InfoBox type="os" content={data.infoText} reference={data.reference} />
    </SectionLayout>
  );
};

export default EngbersInfoBoxTastic;
