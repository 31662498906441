import React from 'react';
import { Moment } from 'moment-timezone';
import ReactCountdown, { CountdownTimeDelta } from 'react-countdown';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { IconCustom } from '@engbers/components';
import styles from './countdown.module.scss';

export type Props = {
  end: Moment;
  onComplete: (timeDelta: CountdownTimeDelta) => void;
  countdownTextColor?: string;
  countdownClockColor?: string;
};

export const Countdown: React.FC<Props> = ({ end, onComplete, countdownTextColor, countdownClockColor }: Props) => {
  const { formatMessage } = useFormat({ name: 'common' });

  const renderer = ({ days, hours, minutes, seconds }) => {
    return (
      <div className={styles.countdown} style={{ '--countdownTextColor': countdownTextColor } as React.CSSProperties}>
        <div className={styles.countdownClockBox}>
          <IconCustom icon="Clock" width={14} customColor={countdownClockColor} />
        </div>
        <div className={styles.countdownClockBox}>
          {days}
          {formatMessage({
            id: 'countdown.day',
            defaultMessage: 'D',
          })}
        </div>
        <div className={styles.countdownClockBox}>
          {hours}
          {formatMessage({
            id: 'countdown.hour',
            defaultMessage: 'H',
          })}
        </div>
        <div className={styles.countdownClockBox}>
          {minutes}
          {formatMessage({
            id: 'countdown.minute',
            defaultMessage: 'M',
          })}
        </div>
        <div className={styles.countdownClockBox}>
          {seconds}
          {formatMessage({
            id: 'countdown.second',
            defaultMessage: 'S',
          })}
        </div>
      </div>
    );
  };

  return <ReactCountdown renderer={renderer} date={end.toDate()} onComplete={onComplete} />;
};
