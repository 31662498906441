import { AccountBalance } from '@engbers/components';
import { FullPageWidthWrapper } from '@engbers/components/online-shops';

const EngbersAccountBalanceTastic = ({ data }) => {
  if (data?.isFullWidth) {
    return (
      <FullPageWidthWrapper>
        <AccountBalance heading={data.headline} textSize={data.textSize} label={data.label} />
      </FullPageWidthWrapper>
    );
  }

  return <AccountBalance heading={data.headline} textSize={data.textSize} label={data.label} />;
};

export default EngbersAccountBalanceTastic;
