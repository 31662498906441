import React from 'react';
import { useRouter } from 'next/router';
import { IFTLinkReference, IFTPageFolderReference } from '@frontastic-engbers/types/engbers-custom';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { getReferenceTarget } from '@frontastic-engbers/helpers/reference';
import BackIcon from '@heroicons/react/solid/ChevronLeftIcon';
import styles from './back-button.module.scss';
import classnames from 'classnames';

interface IBackButtonComponent {
  link?: IFTLinkReference |IFTPageFolderReference;
  label?: string;
  classNames?: string;
}

export const BackButton: React.FC<IBackButtonComponent> = ({label, link, classNames}) => {
  const { formatMessage } = useFormat({ name: 'checkout' });
  const router = useRouter();

  return (
    <div
      className={classnames(classNames, { [styles.buttonWrapper]: !classNames })}
      onClick={() => router.push(link ? getReferenceTarget(link) : '/')}
    >
      <div className={styles.button}>
        <BackIcon className="w-5 h-5" />
        <span className={styles.buttonLabel}>
          {label ?? formatMessage({ id: 'backToShop', defaultMessage: 'Back to shop' })}
        </span>
      </div>
    </div>
  );
};
