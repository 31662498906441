import { gfmFootnoteFromMarkdown, gfmFootnoteToMarkdown } from 'mdast-util-gfm-footnote';
import { gfmStrikethroughFromMarkdown, gfmStrikethroughToMarkdown } from 'mdast-util-gfm-strikethrough';
import { gfmTableFromMarkdown, gfmTableToMarkdown } from 'mdast-util-gfm-table';
import { gfmTaskListItemFromMarkdown, gfmTaskListItemToMarkdown } from 'mdast-util-gfm-task-list-item';
import { gfmFootnote } from 'micromark-extension-gfm-footnote';
import { gfmStrikethrough } from 'micromark-extension-gfm-strikethrough';
import { gfmTable } from 'micromark-extension-gfm-table';
import { gfmTaskListItem } from 'micromark-extension-gfm-task-list-item';
import { combineExtensions } from 'micromark-util-combine-extensions';
import { Plugin } from 'unified';

const gfmFromMarkdown = () => [
  gfmFootnoteFromMarkdown(),
  gfmStrikethroughFromMarkdown,
  gfmTableFromMarkdown,
  gfmTaskListItemFromMarkdown,
];

const gfmToMarkdown = (options) => ({
  extensions: [
    gfmFootnoteToMarkdown(),
    gfmStrikethroughToMarkdown,
    gfmTableToMarkdown(options),
    gfmTaskListItemToMarkdown,
  ],
});

/**
 * Support GFM or markdown on github.com.
 */
const gfm = (options) => combineExtensions([gfmFootnote(), gfmStrikethrough(options), gfmTable, gfmTaskListItem]);

/**
 * Plugin to support GFM (footnotes, strikethrough, tables, tasklists).
 */
const remarkGfm: Plugin = function remarkGfm(options = {}) {
  const data = this.data();

  const add = (field: string, value: unknown) => {
    const list = data[field] ? data[field] : (data[field] = []);

    (list as any).push(value);
  };

  add('micromarkExtensions', gfm(options));
  add('fromMarkdownExtensions', gfmFromMarkdown());
  add('toMarkdownExtensions', gfmToMarkdown(options));
};

/**
 * Plugin to support GFM (footnotes, strikethrough, tables, tasklists).
 */
export default remarkGfm;
