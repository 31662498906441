import Script from 'next/script';
import { MouseEventHandler, useEffect, useState } from 'react';
import { LoadingIndicatorInline } from '@engbers/components';
import styles from './usercentrics.module.scss';

export const Usercentrics = () => {
  return (
    <Script
      src={'https://app.usercentrics.eu/browser-ui/latest/loader.js'}
      id={'usercentrics-cmp'}
      data-settings-id={'qXKKrN-3'}
      async={true}
      onReady={() => {
        window.UC_UI_DOMAINS = {
          crossDomainConsentSharingIFrame: `https://${window.location.host}/cross-domain-bridge.html`,
        };
      }}
    />
  );
};

export interface IUsercentricsButton {
  buttonText?: string;
}

export const UsercentricsButton: React.FC<IUsercentricsButton> = ({ buttonText }) => {
  const [showSecondLayer, setShowSecondLayer] = useState<MouseEventHandler>();

  useEffect(() => {
    const handler = () => {
      setShowSecondLayer(() => {
        return () => window.UC_UI.showSecondLayer();
      });
    };

    window.addEventListener('UC_UI_INITIALIZED', handler);

    return () => {
      window.removeEventListener('UC_UI_INITIALIZED', handler);
    };
  }, []);

  if (!showSecondLayer) {
    return (
      <div className={styles.wrapper}>
        <LoadingIndicatorInline color="blue" />
      </div>
    );
  }
  return (
    <a href={'#'} className={`${styles.wrapper} text-base-medium link dark:link-dark`} onClick={showSecondLayer}>
      <p>{buttonText}</p>
    </a>
  );
};
