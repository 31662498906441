import React, { useMemo } from 'react';
import { useHits } from 'react-instantsearch-core';
import BooleanFacet from '../components/facets/boolean';
import ColorFacet from '../components/facets/color';
import TermFacet from '../components/facets/term';
import SizeFacet from '../components/facets/size';
import { IFacetProps } from '../components/facets/types';
import { FacetConfiguration } from '../types';

interface Options {
  configuration?: Record<string, FacetConfiguration>;
  ordering?: string[];
  isMobile?: boolean;
  showAll?: boolean;
  render?: (result: { attribute: string; Component: JSX.Element }) => JSX.Element;
  facetItemWrapper?: React.ElementType;
}

const useDynamicFacets = ({
  configuration,
  ordering,
  isMobile = false,
  showAll = true,
  facetItemWrapper,
  render,
}: Options = {}) => {
  const { results } = useHits();
  const columnCount = 4;
  const gap = 16;

  const facetMapping = useMemo<Record<FacetConfiguration['type'], React.ComponentType<IFacetProps>>>(
    () => ({
      color: ColorFacet,
      term: TermFacet,
      boolean: BooleanFacet,
      size: SizeFacet,
    }),
    [],
  );

  return useMemo(() => {
    if (!configuration) {
      return <></>;
    }

    const facets = Object.keys(configuration).map((attribute, i) => {
      const facet = configuration[attribute];
      const isEmptyFacet = !results?.disjunctiveFacets?.find((f) => f.name === attribute && f?.stats?.max !== 0);

      const Component = facetMapping[facet.type];
      const FinalComponent = (
        <Component key={attribute} label={facet.label} attribute={attribute} wrapper={facetItemWrapper} />
      );

      if (isMobile) {
        return {
          attribute,
          Component:
            render?.({
              attribute,
              Component: FinalComponent,
            }) ?? FinalComponent,
        };
      }

      return {
        attribute,
        Component: (
          <div
            className={isEmptyFacet || (!showAll && i >= columnCount) ? 'hidden' : 'block'}
            key={attribute}
            style={{ flexBasis: `calc(100% / ${columnCount} - ${gap}px / ${columnCount} * (${columnCount} - 1))` }}
          >
            {render?.({
              attribute,
              Component: FinalComponent,
            }) ?? FinalComponent}
          </div>
        ),
      };
    });

    if (ordering) {
      facets.sort((a, b) => {
        return ordering.indexOf(a.attribute) - ordering.indexOf(b.attribute);
      });
    }

    return facets.map((facet) => facet.Component);
  }, [facetMapping, configuration, ordering, render, results?.disjunctiveFacets]);
};

export default useDynamicFacets;
