import { MultiSectionContainer } from '@engbers/components/online-shops/multi-section-container';
import { FullPageWidthWrapper } from '@engbers/components/online-shops';

export const MultiSectionContainerTastic = ({ data }) => {
  const multiSectionContainer = (
    <MultiSectionContainer
      sectionDivision={data.sectionDivision}
      spaceBetweenImg={data.spaceBetweenImg}
      sections={data.sections}
    />
  );

  if (data.isFullWidth) {
    return <FullPageWidthWrapper>{multiSectionContainer}</FullPageWidthWrapper>;
  }

  return multiSectionContainer;
};
