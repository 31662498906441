import { FlyoutCart } from '@engbers/components/online-shops/flyout-cart';

export const FlyoutCartTastic = ({ data }) => (
  <FlyoutCart
    headline={data.headline}
    toCheckoutBtnLabel={data.toCheckoutBtnLabel}
    voucherBtnLabel={data.voucherBtnLabel}
    toBasketBtnLabel={data.toBasketBtnLabel}
    shippingCostsNote={data.shippingCostsNote}
    voucherPlaceholder={data.voucherPlaceholder}
    redeemVoucherBtnLabel={data.redeemVoucherBtnLabel}
    backBtnLabel={data.backBtnLabel}
    headlinePopUp={data.headlinePopUp}
    toCartBtn={data.toCartBtn}
    continueShoppingBtn={data.continueShoppingBtn}
    headlineAlternativeProduct={data.headlineAlternativeProduct}
    headlineAlternativeOutfit={data.headlineAlternativeOutfit}
    continueShoppingLabel={data.continueShoppingLabel}
    toOutfitLabel={data.toOutfitLabel}
    voucherRedeemedLabel={data.snippets?.dataSource?.snippetVoucherRedeemed}
    voucherInvalidLabel={data.snippets?.dataSource?.snippetVoucherErrorShort}
    climateNeutralShippingLabel={data.climateNeutralShippingLabel}
    fallbackShippingCostsDe={data.shippingCostFallback?.dataSource?.shippingCostsDe}
    fallbackShippingCostsEu={data.shippingCostFallback?.dataSource?.shippingCostsEu}
  />
);
