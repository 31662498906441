import { AccordionItem } from '@engbers/components';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { FullPageWidthWrapper } from '@engbers/components/online-shops';
import styles from './accordion-item.module.scss';

const EngbersAccordionItemTastic = ({ data }) => {
  if (data?.isFullWidth) {
    return (
      <FullPageWidthWrapper>
        <AccordionItem label={data.label} textSize={data.textSize} isInitiallyOpen={data.isInitiallyOpen}>
          <ReactMarkdown className={styles.text}>{data.content}</ReactMarkdown>
        </AccordionItem>
      </FullPageWidthWrapper>
    );
  }

  return (
    <AccordionItem label={data.label} textSize={data.text} isInitiallyOpen={data.isInitiallyOpen}>
      <ReactMarkdown className={styles.text}>{data.content}</ReactMarkdown>
    </AccordionItem>
  );
};

export default EngbersAccordionItemTastic;
