import React from 'react';
import { IconCustom } from '@engbers/components';
import { ICON, TIconId } from '@engbers/components/icon-custom';

const StyleGuideIcons: React.FC = () => (
  <div className="mb-10 border border-solid border-slate-300 dark:bg-encom-900 dark:text-white">
    <h2 className="my-4 mx-6 dark:text-white">Icons</h2>
    <div className="my-3 flex flex-wrap justify-start border-t border-solid border-slate-300 p-4 align-middle">
      {Object.keys(ICON).map((icon: TIconId) => (
        <div title={icon} key={icon}>
          <IconCustom
            icon={icon}
            width={22}
            className="m-2"
          />
        </div>
      ))}
    </div>

    <div>
      <h4 className="m-6 dark:text-white">Usage:</h4>
      <ol className="mx-10 mb-6 list-inside list-decimal">
        <li className="pb-2">
          Import the icon custom into your component
          <code className="bg-encom-100 p-1">{"import { IconCustom } from '@engbers/components';"}</code>
        </li>
        <li className="pb-2">
          Add for example:
          <code className="bg-encom-100 p-1">{'<IconCustom icon="Inbox" width={22} className="m-2" />'}</code>
        </li>
      </ol>
    </div>
  </div>
);

export default StyleGuideIcons;
