import React, { useMemo } from 'react';
import classNames from 'classnames';
import { useAccount, useCart } from '@frontastic-engbers/lib';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { mapCosts } from '@frontastic-engbers/helpers/utils/mapCosts';
import { cartHasFreeShippingItemsOnly } from '@frontastic-engbers/helpers/utils/cartHasFreeShippingItemsOnly';
import { Cart } from '@frontastic-engbers/types/cart/Cart';
import { Discount } from '@frontastic-engbers/types/cart/Discount';
import Price from '../../price';
import styles from './order-summary.module.scss';

export interface IOrderSummary {
  shippingCostsNote: string;
  removeDiscountCode: (discount: Discount) => void;
  serviceShippingCostsNote: string;
  fallbackShippingCostsDe: number;
  fallbackShippingCostsEu: number;
  setUpdatingLineItems: React.Dispatch<React.SetStateAction<boolean>>;
  cart: Cart;
}

export const OrderSummary: React.FC<IOrderSummary> = ({
  shippingCostsNote,
  removeDiscountCode,
  serviceShippingCostsNote,
  fallbackShippingCostsDe,
  fallbackShippingCostsEu,
  setUpdatingLineItems,
  cart,
}) => {
  const { removeItem } = useCart();
  const { account, loggedIn } = useAccount();
  const { formatMessage } = useFormat({ name: 'cart' });
  const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });

  const costs = useMemo(
    () => mapCosts(cart, loggedIn ? cart?.shippingAddress?.country || account?.addresses[0]?.country : undefined),
    [cart],
  );

  const fallbackShippingCostsPrice: number =
    cart?.shippingAddress?.country?.toLowerCase() === 'de' ||
    (!cart?.shippingAddress && account?.addresses[0]?.country?.toLowerCase() === 'de')
      ? fallbackShippingCostsDe
      : fallbackShippingCostsEu;

  const onRemoveDiscountCode = async (discountCode: Discount) => {
    setUpdatingLineItems(true);
    await removeDiscountCode(discountCode);
    setUpdatingLineItems(false);
  };

  return (
    <>
      <div className={styles.summaryRow}>
        <span className={styles.summaryLabel}>
          {formatMessage({
            id: 'subtotal',
            defaultMessage: 'Zwischensumme',
          })}
        </span>
        <Price price={costs.subtotal} className={styles.summaryPrice} showCurrencySymbol />
      </div>
      {!cartHasFreeShippingItemsOnly(cart) && (
        <div className={styles.summaryRow}>
          <span className={styles.summaryLabel}>
            {serviceShippingCostsNote ||
              formatMessage({
                id: 'serviceShipping',
                defaultMessage: 'Service- & Versandkosten',
              })}
          </span>
          {costs.shipping.centAmount === 0 ? (
            <span className="text-success text-sm-bold">
              {formatCheckoutMessage({
                id: 'freeShipping',
                defaultMessage: 'kostenlos',
              })}
            </span>
          ) : (
            <Price
              price={
                costs.shipping || {
                  centAmount: fallbackShippingCostsPrice,
                  currencyCode: cart?.sum?.currencyCode,
                  fractionDigits: 2,
                }
              }
              className={styles.summaryPrice}
              showCurrencySymbol
            />
          )}
        </div>
      )}
      {cart?.discountCodes?.map((discountCode) => (
        <div className={styles.summaryRow} key={`discountCode-${discountCode.code}`}>
          <div className={styles.summaryLabel}>
            <button onClick={() => onRemoveDiscountCode(discountCode)} className={styles.removeDiscountButton}>
              x
            </button>
            <span>{discountCode.description ?? discountCode.name}</span>
          </div>
        </div>
      ))}
      {cart?.customLineItems?.map((lineItem) => (
        <div className={styles.summaryRow} key={`discountCode-${lineItem.lineItemId}`}>
          <div className={styles.summaryLabel}>
            <button onClick={() => removeItem(lineItem.lineItemId, true)} className={styles.removeDiscountButton}>
              x
            </button>
            <span>
              {lineItem.type?.length > 0
                ? lineItem.type
                : formatMessage({
                  id: 'voucher',
                  defaultMessage: 'Gutschein',
                })}
            </span>
          </div>
          {lineItem.totalPrice.centAmount !== 0 && (
            <Price price={lineItem.totalPrice || {}} className={styles.summaryPrice} showCurrencySymbol />
          )}
        </div>
      ))}
      <div className={classNames(styles.summaryRow, styles.totalSum)}>
        <span className={styles.summaryLabel}>
          {formatMessage({
            id: 'totalSum',
            defaultMessage: 'Gesamtsumme',
          })}
        </span>
        <Price
          price={
            loggedIn && !costs.shipping && fallbackShippingCostsPrice
              ? {
                  centAmount: costs.total.centAmount + fallbackShippingCostsPrice,
                  currencyCode: cart?.sum?.currencyCode,
                  fractionDigits: 2,
                }
              : costs.total
          }
          isBold={true}
          className={styles.summaryPrice}
          showCurrencySymbol
        />
      </div>
      <span className={styles.shippingCostsNote}>{shippingCostsNote}</span>
    </>
  );
};
