import React from 'react';

type Props = {
  className?: string;
  children: React.ReactNode;
};

export const FullPageWidthWrapper: React.FC<Props> = ({ children, className = '' }) => (
  <div
    className={className}
    style={{
      width: '100vw',
      position: 'relative',
      left: '50%',
      right: '50%',
      marginLeft: '-50vw',
      marginRight: '-50vw',
    }}
  >
    {children}
  </div>
);
