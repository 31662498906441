import { Footer, FullPageWidthWrapper }  from '@engbers/components/online-shops';
import { getProjectInfo } from '@frontastic-engbers/lib';

const FooterTastic = ({ data }) => {
  const projectInfo = getProjectInfo();
  const isEmilio = projectInfo?.projectId === 'ea';

  if (data.isFullWidth) {
    return (
      <FullPageWidthWrapper>
        <Footer data={data} isFullWidth={data.isFullWidth} isEmilio={isEmilio} />
      </FullPageWidthWrapper>
    );
  }

  return (
    <div>
      <Footer data={data} isEmilio={isEmilio} />
    </div>
  );
};

export default FooterTastic;
