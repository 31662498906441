import React from 'react';
import { Markdown } from '@engbers/components';
import { BackButton } from './components/back-button';
import { IFTLinkReference, IFTPageFolderReference } from '@frontastic-engbers/types/engbers-custom';
import classnames from 'classnames';
import styles from './confirmation-box.module.scss';

interface IConfirmationBoxComponent {
  title?: string;
  textSize?: number;
  text: string;
  buttons?: IConfirmationBoxButton[];
  variables?: Record<string, string | number>;
  classNames?: Record<string, string>;
}

interface IConfirmationBoxButton {
  label: string;
  link: IFTLinkReference | IFTPageFolderReference;
}

export const ConfirmationBox: React.FC<IConfirmationBoxComponent> = ({
  title,
  textSize,
  text,
  buttons,
  variables,
  classNames,
}) => {
  return (
    <div
      className={classnames(classNames?.confirmationBoxWrapper, {
        [styles.confirmationBoxWrapper]: !classNames?.confirmationBoxWrapper,
      })}
    >
      {title && (
        <Markdown
          className={classnames(classNames?.headline, { [styles.headline]: !classNames?.headline })}
          text={title}
          textSize={textSize}
          disableMargin
        />
      )}

      <Markdown
        className={classnames(classNames?.text, { [styles.text]: !classNames?.text })}
        text={text}
        variables={variables}
      />

      {buttons && buttons.length > 0 && (
        <div
          className={classnames(classNames?.ctaButtonsWrapper, {
            [styles.ctaButtonsWrapper]: !classNames?.ctaButtonsWrapper,
          })}
        >
          {buttons.map((button) => (
            <div
              key={button.label}
              className={classnames(classNames?.ctaButtonWrapper, {
                [styles.ctaButtonWrapper]: !classNames?.ctaButtonWrapper,
              })}
            >
              <BackButton label={button.label} link={button.link} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
