import React, { useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import classnames from 'classnames';
import {
  EcondaWidgetCredentials,
  IFTLinkReference,
  IFTPageFolderReference,
} from '@frontastic-engbers/types/engbers-custom';
import { Autocomplete } from '../autocomplete';
import styles from './desktop-search.module.scss';

interface IDesktopSearch {
  popularCategoriesLabel: string;
  popularCategories: {
    categoryLabel: string;
    categoryLink: IFTLinkReference | IFTPageFolderReference;
  }[];
  topSellerLabel: string;
  topSellerWidgetId: string;
  topSellerWidgetConfiguration: EcondaWidgetCredentials;
  suggestedProductsLabel: string;
  suggestedProductsLimit: number;
  suggestedCategoriesLabel: string;
  suggestedCategoriesLimit: number;
  allResultsLabel: string;
  algoliaConfig: any;
  isEmilio?: boolean;
  navWrapRef: React.RefObject<HTMLDivElement>;
}

export const DesktopSearch: React.FC<IDesktopSearch> = ({
  popularCategoriesLabel,
  popularCategories,
  topSellerLabel,
  topSellerWidgetId,
  topSellerWidgetConfiguration,
  suggestedProductsLabel,
  suggestedProductsLimit,
  suggestedCategoriesLabel,
  suggestedCategoriesLimit,
  allResultsLabel,
  algoliaConfig,
  isEmilio,
  navWrapRef,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [openSearchFlyout, setOpenSearchFlyout] = useState<boolean>(false);

  return (
    <>
      {openSearchFlyout &&
        createPortal(
          <div className={styles.searchFlyoutBackDrop} onClick={() => setOpenSearchFlyout(false)} />,
          document.body,
        )}
      <div
        className={classnames({
          [styles.navigationSearchFlyout]: openSearchFlyout,
          [styles.emilioNavigationSearchFlyout]: isEmilio && openSearchFlyout,
        })}
        onClick={() => setOpenSearchFlyout(false)}
      />
      <div
        className={classnames(styles.searchFormWrapper, {
          [styles.searchFormActive]: openSearchFlyout,
          [styles.emilioSearchFormWrapper]: isEmilio,
        })}
        ref={containerRef}
      >
        <Autocomplete
          popularCategoriesLabel={popularCategoriesLabel}
          popularCategories={popularCategories}
          topSellerLabel={topSellerLabel}
          topSellerWidgetId={topSellerWidgetId}
          topSellerWidgetConfiguration={topSellerWidgetConfiguration}
          suggestedProductsLabel={suggestedProductsLabel}
          suggestedProductsLimit={suggestedProductsLimit}
          suggestedCategoriesLabel={suggestedCategoriesLabel}
          suggestedCategoriesLimit={suggestedCategoriesLimit}
          allResultsLabel={allResultsLabel}
          algoliaConfig={algoliaConfig}
          flyoutWrapperRef={navWrapRef}
          searchFormWrapperRef={containerRef}
          onClick={() => setOpenSearchFlyout(false)}
          setOpen={setOpenSearchFlyout}
        />
      </div>
    </>
  );
};
