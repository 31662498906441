import { AccordionItem, Block, ChangePasswordForm } from '@engbers/components';
import { FullPageWidthWrapper } from '@engbers/components/online-shops';

export const ChangePasswordFormTastic = ({ data }) => {
  if (data.isFullWidth) {
    return (
      <FullPageWidthWrapper>
        <Block>
          <AccordionItem label="Passwort ändern">
            <ChangePasswordForm />
          </AccordionItem>
        </Block>
      </FullPageWidthWrapper>
    );
  }

  return (
    <Block>
      <AccordionItem label="Passwort ändern">
        <ChangePasswordForm />
      </AccordionItem>
    </Block>
  );
};
