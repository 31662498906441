import { HeaderTastic } from './header';
import { NavigationTastic } from './navigation';
import { EmilioHeaderTastic } from './emilio/header';
import { EmilioNavigationTastic } from './emilio/navigation';
import { HeaderCheckoutTastic } from './header-checkout';
import { EngbersCategoryImageTextTastic } from './category-image-text';
import { EngbersToTheNewsletterTastic } from './to-the-newsletter';
import { MyAccountTastic } from './my-account';
import { EngbersOsBottomBarTastic } from './en-os-bottombar';
import { LoginPageTastic } from './login-page';
import { ActionBannerTastic } from './action-banner';
import { EngbersUsercentricsTastic } from './usercentrics';
import { MyAccountDataTastic } from './my-account/my-data';
import { MyAccountIconsTastic } from './my-account/my-account-icons';
import { MyAccountNewsletterTastic } from './my-account/my-newsletter';
import { MyAccountEngbersCardTastic } from './my-account/my-engbers-card';
import { MyAccountOverviewTastic } from './my-account/overview';
import { MyAccountOrderHistoryTastic } from './my-account/my-order-history';
import { EngbersTextTastic } from './text';
import { SpacerTastic } from './spacer';
import { EngbersButtonTastic } from './button';
import { RegisterPageTastic } from './register-page';
import { MigrationFormTastic } from './migration-form';
import { LoginFormTastic } from './login-form';
import { ChangePasswordFormTastic } from './change-password-form';
import { HeaderSecondLevelTastic } from './header-second-level';
import { ChangeEmailFormTastic } from './change-email-from';
import { MyDataFormTastic } from './my-data-form';
import { CheckoutTastic } from './checkout';
import { FlyoutCartTastic } from './flyout-cart';
import { ProductDetailsPageTastic } from './product-details-page';
import { ProductListAlgoliaTastic } from './product-list';
import { EcondaSliderTastic } from './econda-slider';
import { EngbersSearchAgainTastic } from './search-again';
import { SeparationLineTastic } from './separation-line';
import { MyWishlistTastic } from './my-account/my-wishlist';
import { ForgotPasswordFormTastic } from './forgot-password-form';
import { ResetPasswordTastic } from './reset-password';
import { OutfitDetailsPageTastic } from './outfit-details-page';
import { ConfirmationBoxTastic } from './content/confirmation-box';
import { NewsletterItemsTastic } from './newsletter-items';
import { NewsletterResubscriptionTastic } from './newsletter-resubscription';
import { ContactFormTastic } from './contact-form';
import { EnGermanyImageSliderTastic } from './enGermany-image-slider';
import { AlgoliaSliderTastic } from './algolia-slider';
import { BreadcrumbTastic } from './breadcrumb';
import { TabMenuTastic } from './tab-menu';
import { CouponsTastic } from './coupons';
import { CreditVouchersTastic } from './credit-vouchers';
import { VouchersTastic } from './vouchers';
import { MyVouchersTastic } from './my-account/my-vouchers';
import { EngbersCardBalanceTastic } from './engbers-card-balance';
import { VideoTastic } from './video';
import { MultiSectionContainerTastic } from './multi-section-container';
import { TableTastic } from './table';
import { ContentSidebarNavigationTastic } from './content-sidebar-navigation';
import { StorefinderTastic } from './storefinder';
import { HeaderNavigationTreeTastic } from './header-navigation-tree';

import Cart from './cart';
import ThankYou from './checkout/thank-you';
import CategoryTeasers from './content/category-teasers';
import Markdown from './content/markdown';
import Spacer from './content/spacer';
import Footer from './footer';
import NotFound from './not-found';
import StyleGuideTastic from './style-guide';
import EngbersContentNavigationTastic from './content-navigation';
import EngbersAccountBalanceTastic from './account-balance';
import EngbersCardTastic from './card';
import AccordionItemTastic from './accordion-item';
import EngbersImageTastic from './image';
import EngbersInfoBoxTastic from './infobox';
import ProductSliderTastic from './products/slider';
import CategoryFeedTastic from './category-feed';
import NewsletterTastic from './newsletter';
import { RaffleTastic } from './raffle';

export const tastics = {
  'commercetools/ui/cart': Cart,
  'commercetools/ui/footer': Footer,
  'commercetools/ui/content/spacer': Spacer,
  'commercetools/ui/content/markdown': Markdown,
  'commercetools/ui/content/category-teasers': CategoryTeasers,
  'engbers/category-image-text': EngbersCategoryImageTextTastic,
  'engbers/to-the-newsletter': EngbersToTheNewsletterTastic,
  'engbers/header': HeaderTastic,
  'emilio/header': EmilioHeaderTastic,
  'engbers/header-checkout': HeaderCheckoutTastic,
  'engbers/navigation': NavigationTastic,
  'emilio/navigation': EmilioNavigationTastic,
  'engbers/account/my-account': MyAccountTastic,
  'engbers/en-os-bottombar': EngbersOsBottomBarTastic,
  'engbers/content-navigation': EngbersContentNavigationTastic,
  'engbers/account/login-page': LoginPageTastic,
  'engbers/usercentricsbutton': EngbersUsercentricsTastic,
  'engbers/account-balance': EngbersAccountBalanceTastic,
  'engbers/card': EngbersCardTastic,
  'engbers/account/my-account-data': MyAccountDataTastic,
  'engbers/account/my-account-icons': MyAccountIconsTastic,
  'engbers/account/my-newsletter': MyAccountNewsletterTastic,
  'engbers/my-engbers-card': MyAccountEngbersCardTastic,
  'engbers/my-order-history': MyAccountOrderHistoryTastic,
  'engbers/account/overview': MyAccountOverviewTastic,
  'engbers/text': EngbersTextTastic,
  'engbers/action-banner': ActionBannerTastic,
  'engbers/accordion-item': AccordionItemTastic,
  'engbers/spacer': SpacerTastic,
  'engbers/button': EngbersButtonTastic,
  'engbers/image': EngbersImageTastic,
  'engbers/infobox': EngbersInfoBoxTastic,
  'engbers/account/register': RegisterPageTastic,
  'engbers/account/migration-form': MigrationFormTastic,
  'engbers/account/login-form': LoginFormTastic,
  'engbers/account/change-password': ChangePasswordFormTastic,
  'engbers/account/my-data': MyDataFormTastic,
  'engbers/header-second-level': HeaderSecondLevelTastic,
  'engbers/account/change-email': ChangeEmailFormTastic,
  'engbers/checkout': CheckoutTastic,
  'Engbers/checkout/thank-you': ThankYou,
  'engbers/flyout-cart': FlyoutCartTastic,
  'engbers/product-details-page': ProductDetailsPageTastic,
  'engbers/product-list-algolia': ProductListAlgoliaTastic,
  'engbers/econda-slider': EcondaSliderTastic,
  'engbers/search-again': EngbersSearchAgainTastic,
  'engbers/account/reset-password': ResetPasswordTastic,
  'engbers/account/forgot-password-form': ForgotPasswordFormTastic,
  'engbers/separation-line': SeparationLineTastic,
  'engbers/account/my-wishlist': MyWishlistTastic,
  'engbers/category-feed': CategoryFeedTastic,
  'engbers/product-slider': ProductSliderTastic,
  'engbers/outfit-details-page': OutfitDetailsPageTastic,
  'engbers/newsletter-items': NewsletterItemsTastic,
  'engbers/newsletter/resubscription': NewsletterResubscriptionTastic,
  'engbers/contact-form': ContactFormTastic,
  'engbers/newsletter': NewsletterTastic,
  'engbers/algolia-slider': AlgoliaSliderTastic,
  'engbers/breadcrumb': BreadcrumbTastic,
  'engbers/tab-menu': TabMenuTastic,
  'engbers/coupons': CouponsTastic,
  'engbers/credit-vouchers': CreditVouchersTastic,
  'engbers/vouchers': VouchersTastic,
  'engbers/my-vouchers': MyVouchersTastic,
  'engbers/engbers-card-balance': EngbersCardBalanceTastic,
  'engbers/video': VideoTastic,
  'engbers/multi-section-container': MultiSectionContainerTastic,
  'engbers/table': TableTastic,
  'engbers/content-sidebar-navigation': ContentSidebarNavigationTastic,
  'engbers/storefinder': StorefinderTastic,
  'engbers/header-navigation-tree': HeaderNavigationTreeTastic,
  'engbers/raffle/raffle-form': RaffleTastic,

  'engbers-germany/image-slider': EnGermanyImageSliderTastic,

  'engbers/content/confirmation-box': ConfirmationBoxTastic,

  'commercetools/ui/content/style-guide': StyleGuideTastic,

  default: NotFound,
};
