import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { getActiveShops, setActiveShops, useCurrentShop } from '@frontastic-engbers/lib';
import { HeaderMain } from '@engbers/components/header-main';
import { HeaderMinimized } from '@engbers/components/header-minimized';
import { FullPageWidthWrapper } from '@engbers/components/online-shops';

export const EmilioHeaderTastic = ({ data }) => {
  const activeShops = getActiveShops();
  const currentSection = useCurrentShop();
  const [isMinWidthMedium, setIsMinWidthMedium] = useState<boolean>(false);
  const isMobile = useMediaQuery({ maxWidth: 1024 });

  setActiveShops({
    ...activeShops,
    ['emilio-adani']: true,
  });

  useEffect(() => {
    if (isMobile !== isMinWidthMedium) {
      setIsMinWidthMedium(isMobile);
    }
  }, [isMobile]);

  return (
    <FullPageWidthWrapper>
      {isMinWidthMedium ? (
        <HeaderMinimized
          mobileCheckoutLogo={data.mobileCheckoutLogo}
          mobileCheckoutLogoLink={data.mobileCheckoutLogoLink}
          trustedShopIcon={data.trustedShopIcon}
          trustedShopText={data.trustedShopText}
          isCheckout={false}
          isEnGermany={currentSection==="engbers-germany"}
          isEmilio
        />
      ) : (
        <HeaderMain logos={data.logos} items={data.items} services={data.services} isEmilio />
      )}
    </FullPageWidthWrapper>
  );
};
