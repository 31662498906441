import classnames from 'classnames';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Transition } from '@headlessui/react';
import useI18n from '@frontastic-engbers/helpers/hooks/useI18n';
import { fetchApiHub } from '@frontastic-engbers/lib';
import { Product } from '@frontastic-engbers/types/product/Product';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { sortSizes } from '@frontastic-engbers/helpers/utils/sizeSorting';
import styles from './product-box-hover.module.scss';

export type Props = {
  product: Product;
  isHovering: boolean;
  isEnGermany: boolean;
};

type Size = {
  value: string;
  sku: string;
  isInStock: boolean;
};

type Color = {
  id: string;
  sku: string;
  slug: {
    [key: string]: string;
  };
  attributes: {
    baseColor: string;
  };
};

const hoverTransition = {
  enter: 'transition-opacity duration-200',
  enterFrom: 'opacity-0',
  enterTo: 'opacity-100',
  leave: 'transition-opacity duration-200',
  leaveFrom: 'opacity-100',
  leaveTo: 'opacity-0',
};

export const ProductBoxHover: React.FC<Props> = ({ product, isHovering, isEnGermany }) => {
  const { t } = useI18n(true);
  const { formatMessage } = useFormat({ name: 'product' });
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const [colorsFetched, setColorsFetched] = useState<boolean>(false);
  const [sizes, setSizes] = useState<Size[]>([]);
  const [colors, setColors] = useState<Color[]>([]);
  const colorsAvailable = colors.length > 0;

  useEffect(() => {
    const foundSizes: Size[] = [];

    product.variants.forEach((variant) => {
      const { attributes } = variant;

      if (attributes?.Sizing) {
        foundSizes.push({
          isInStock: variant.isInStock,
          value: attributes.Sizing,
        } as Size);
      }
    });

    foundSizes.sort(sortSizes);

    setSizes(foundSizes);
  }, []);

  useEffect(() => {
    const fetchColorVariants = async () => {
      const { ColorVariants } = product.attributes;

      if (ColorVariants && ColorVariants.length > 0) {
        const params = ColorVariants.map(({ id }) => `ids[]=${id}`).join('&');
        const colors = await fetchApiHub(`/action/product/getColorVariantsByIds?${params}`);

        setColorsFetched(true);
        setColors(colors.filter((c) => c.baseColor !== product.attributes.BaseColor));
      }
    };

    if (isHovering && !colorsFetched) {
      fetchColorVariants();
    }
  }, [isHovering]);

  if (isMobile) {
    return null;
  }

  return (
    <Transition show={isHovering || isEnGermany} {...hoverTransition}>
      <div className={classnames(styles.hovering, { [styles.enGermanyHovering]: isEnGermany })}>
        <div className={styles.title}>
          {formatMessage({
            id: isEnGermany ? 'hoverTitleEnGermany' : colorsAvailable ? 'hoverTitleColors' : 'hoverTitleSizes',
            defaultMessage: isEnGermany
              ? 'Erhältliche in:'
              : colorsAvailable
              ? 'Erhältliche Größen und Farben:'
              : 'Erhältliche Größen:',
          })}
        </div>
        <ul className={styles.sizes}>
          {sizes.map(({ isInStock, value }, index) => (
            <li className={classnames({ [styles.outOfStock]: !isInStock })} key={value + index}>
              {isInStock ? (
                <Link href={`${product.url}?size=${value}`}>
                  <a>{value}</a>
                </Link>
              ) : (
                value
              )}
            </li>
          ))}
        </ul>
        {colorsAvailable && (
          <ul className={styles.colors}>
            <li className={styles.active}>
              {product.attributes.BaseColor && (
                <Link href={product.url}>
                  <a title={product.attributes.BaseColor}>
                    <span className={product.attributes.BaseColor.toLowerCase()} />
                  </a>
                </Link>
              )}
            </li>
            {colors.map(({ id, sku, slug, attributes: { baseColor } }) => (
              <li key={id + sku}>
                <Link href={t(slug)}>
                  <a title={baseColor}>
                    <span className={baseColor.toLowerCase()} />
                  </a>
                </Link>
              </li>
            ))}
          </ul>
        )}
      </div>
    </Transition>
  );
};
