import { useMemo } from 'react';

export const dateFormat = Intl.DateTimeFormat('de-DE', {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
});

export const dateTimeFormat = Intl.DateTimeFormat('de-DE', {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
});

type FormattedDateProps = {
  dateStr: string;
  style?: 'date' | 'dateTime';
  render?: (str: string) => React.ReactNode;
};

export const FormattedDate = ({ dateStr, style = 'date', render }: FormattedDateProps) => {
  const parsed = useMemo(() => new Date(dateStr), [dateStr]);
  const f = style === 'date' ? dateFormat.format : dateTimeFormat.format;

  if (typeof render === 'function') {
    return <>{render(f(parsed))}</>;
  }

  return <>{f(parsed)}</>;
};
