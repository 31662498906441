import { useAccount } from '@frontastic-engbers/lib';
import { AccountMyNewsletter } from '@engbers/components/my-account/my-newsletter';
import { FullPageWidthWrapper } from '@engbers/components/online-shops';
import { Newsletter, NewsletterMap } from '@frontastic-engbers/types/newsletter/types';

export const MyAccountNewsletterTastic = ({ data }) => {
  const { loggedIn } = useAccount();

  if (!loggedIn) {
    return null;
  }

  const myNewsletter = (
    <AccountMyNewsletter
      headline={data.myNewsletterHeadline}
      textSize={data.textSize}
      subHeadline={data.myNewsletterSubheadline}
      icon={data.myNewsletterIcon}
      newsletters={
        data.myNewsletterMailingListMap?.map(
          (entry: NewsletterMap) =>
            ({
              id: entry.mailingListId,
              name: entry.newsletterName,
              description: entry.subscriptionText,
            } as Newsletter),
        ) ?? []
      }
      benefitsTitle={data.myNewsletterBenefitsTitle}
      benefitsColor={data.myNewsletterBenefitsTextColor}
      benefits={data.myNewsletterBenefits?.map((benefit) => benefit.benefit) ?? []}
      subscriptionInfoText={data.myNewsletterSubscriptionEmailInfo}
      subscriptionCtaText={data.myNewsletterSubscriptionCtaText}
      subscriptionSuccessMessage={data.myNewsletterSubscriptionSuccessMessage}
      subscriptionFailureMessage={data.myNewsletterSubscriptionFailureMessage}
    />
  );

  return data.isFullWidth ? <FullPageWidthWrapper>{myNewsletter}</FullPageWidthWrapper> : myNewsletter;
};
