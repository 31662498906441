import React from 'react';
import classNames from 'classnames';
import styles from './tab-menu-item.module.scss';

export interface ITabMenuItem {
  label: string;
  reference: any;
  isActive?: boolean;
  counter?: number;
  onClick?: any;
}

export const TabMenuItem: React.FC<ITabMenuItem> = ({ label, isActive, reference, onClick, counter }) => {
  const handleClick = async (e) => {
    e.preventDefault();
    onClick(reference._url);
  };

  return (
    <li
      className={classNames(styles.tabItem, {
        [styles[`tabItem--active`]]: isActive,
      })}
    >
      <button onClick={handleClick}>{label}</button>
      {counter ? <div className={styles.counter}>{counter}</div> : undefined}
    </li>
  );
};
