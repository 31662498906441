import { getProjectInfo, useAccount } from '@frontastic-engbers/lib';
import { AccountOverview } from '@engbers/components/my-account/overview';
import { FullPageWidthWrapper } from '@engbers/components/online-shops';

export const MyAccountOverviewTastic = ({ data }) => {
  const isEmilio = getProjectInfo()?.projectId === 'ea';
  const { loggedIn } = useAccount();

  if (!loggedIn) {
    return null;
  }

  if (data.isFullWidth) {
    return (
      <FullPageWidthWrapper>
        <AccountOverview
          isEmilio={isEmilio}
          overviewHeadline={data.overviewHeadline}
          textSize={data.textSize}
          overviewLinks={data.overviewLinks}
        />
      </FullPageWidthWrapper>
    );
  }

  return (
    <AccountOverview
      isEmilio={isEmilio}
      overviewHeadline={data.overviewHeadline}
      textSize={data.textSize}
      overviewLinks={data.overviewLinks}
    />
  );
};
