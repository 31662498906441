import React from 'react';
import classnames from 'classnames';
import { useImageSEO } from '@frontastic-engbers/helpers/hooks/useImageSEO';
import { MediaType } from '@frontastic-engbers/types/engbers-custom';
import {
  EngbersOsBottomBarExtraLinks,
  EngbersOsBottomBarShopLinks,
  EngbersOsBottomBarSocialMediaLinks,
} from '../index';
import { ReferenceLink } from '@frontastic-engbers/helpers/reference';
import { Image } from '@engbers/components';
import styles from './menu-extra-links.module.scss';

export interface Props {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  extraLinks?: EngbersOsBottomBarExtraLinks[];
  shopLinks?: EngbersOsBottomBarShopLinks[];
  socialMediaLinks?: EngbersOsBottomBarSocialMediaLinks[];
}

const Icon = ({ icon, width }: { icon: MediaType; width?: number }) => {
  const { getTitle } = useImageSEO();

  if (!icon) {
    return null;
  }

  return icon.media?.format === 'svg' ? (
    <Image src={icon.media.file} alt={getTitle(icon)} title={getTitle(icon)} width={width} />
  ) : (
    <span>?</span>
  );
};

export const MenuExtraLinks: React.FC<Props> = ({ setShowModal, extraLinks, shopLinks, socialMediaLinks }) => {
  return (
    <div>
      {extraLinks && !!extraLinks.length && (
        <div className={styles.sectionWrap}>
          {extraLinks.map((item, index) => (
            <ReferenceLink
              key={`extra-links-${index}`}
              target={item.extraLinksLink}
              className={styles.extraLink}
              onClick={() => setShowModal(false)}
            >
              <Icon icon={item.extraLinksIcon} width={25} />
              {item.extraLinksLabel}
            </ReferenceLink>
          ))}
        </div>
      )}
      {shopLinks && !!shopLinks.length && (
        <div className={styles.sectionWrap}>
          <div className={styles.shopLinksWrap}>
            {shopLinks.map((item, index) => (
              <ReferenceLink
                key={`extra-links-${index}`}
                target={item.shopLinksLink}
                className={styles.shopLink}
                onClick={() => setShowModal(false)}
              >
                <Icon icon={item.shopLinksIcon} width={150} />
              </ReferenceLink>
            ))}
          </div>
        </div>
      )}
      {socialMediaLinks && !!socialMediaLinks.length && (
        <div className={classnames(styles.sectionWrap, styles.socialMediaLinksWrap)}>
          {socialMediaLinks.map((item, index) => (
            <ReferenceLink
              key={`extra-links-${index}`}
              target={item.socialMediaLinksLink}
              onClick={() => setShowModal(false)}
            >
              <Icon icon={item.socialMediaLinksIcon} width={30} />
            </ReferenceLink>
          ))}
        </div>
      )}
    </div>
  );
};
