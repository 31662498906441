import React, { useCallback, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useRouter } from 'next/router';
import classnames from 'classnames';
import { useImageSEO } from '@frontastic-engbers/helpers/hooks/useImageSEO';
import useOnClickOutside from '@frontastic-engbers/helpers/hooks/useOnClickOutside';
import { useCart, useCurrentShop, useWishlist } from '@frontastic-engbers/lib';
import { ReferenceLink } from '@frontastic-engbers/helpers/reference';
import {
  EcondaWidgetCredentials,
  IFTLinkReference,
  IFTPageFolderReference,
  MediaType,
  PageFolderTree,
} from '@frontastic-engbers/types/engbers-custom';
import { NavigationList } from '@engbers/components/online-shops';
import styles from './en-os-bottombar.module.scss';
import { IconCustom } from '../../icon-custom';
import { disableScrollOnBody } from '@frontastic-engbers/helpers/utils/disableScrollOnBody';
import Spinner from '../commercetools-ui/spinner';

interface EngbersOsBottomBarItem {
  behaviour: 'modal' | 'link';
  itemId: 'homePage' | 'menu' | 'cart' | 'wishlist' | 'account';
  icon: MediaType;
  reference: IFTLinkReference | IFTPageFolderReference;
  tree: PageFolderTree | null;
}

export interface EngbersOsBottomBarSearch {
  searchPlaceholder: string;
  popularCategoriesLabel: string;
  popularCategories: {
    categoryLabel: string;
    categoryLink: IFTLinkReference | IFTPageFolderReference;
  }[];
  topSellerLabel: string;
  topSellerWidgetId: string;
  topSellerWidgetConfiguration: EcondaWidgetCredentials;
  suggestedProductsLabel: string;
  suggestedProductsLimit: number;
  suggestedCategoriesLabel: string;
  suggestedCategoriesLimit?: number;
  allResultsLabel: string;
}

export interface EngbersOsBottomBarExtraLinks {
  extraLinksIcon: MediaType;
  extraLinksLabel: string;
  extraLinksLink: IFTLinkReference | IFTPageFolderReference;
}

export interface EngbersOsBottomBarShopLinks {
  shopLinksIcon: MediaType;
  shopLinksLink: IFTLinkReference | IFTPageFolderReference;
}

export interface EngbersOsBottomBarSocialMediaLinks {
  socialMediaLinksIcon: MediaType;
  socialMediaLinksLink: IFTLinkReference | IFTPageFolderReference;
}

export interface EngbersOsBottomBarType {
  items?: EngbersOsBottomBarItem[];
  search: EngbersOsBottomBarSearch;
  extraLinks?: EngbersOsBottomBarExtraLinks[];
  shopLinks?: EngbersOsBottomBarShopLinks[];
  socialMediaLinks?: EngbersOsBottomBarSocialMediaLinks[];
  algoliaConfig: any;
  isLoadingContent?: boolean;
}

export const EngbersOsBottomBar: React.FC<EngbersOsBottomBarType> = ({
  items,
  search,
  extraLinks,
  shopLinks,
  socialMediaLinks,
  algoliaConfig,
  isLoadingContent,
}) => {
  const { getTitle } = useImageSEO();
  const currentSection = useCurrentShop();
  const ref = useRef<HTMLDivElement>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [activeModal, setActiveModal] = useState<number | null>(null);
  const router = useRouter();
  const { data: cart } = useCart();
  const { data: wishlist } = useWishlist();
  const getLineItemsCount = (): number =>
    cart.lineItems.reduce((accumulator, lineItem) => accumulator + lineItem.count, 0);

  const handleModal = useCallback(
    (id: number, isModalActive: boolean) => {
      setShowModal(id === activeModal ? !showModal : true);
      setActiveModal(id);
      disableScrollOnBody(!isModalActive && window?.innerWidth < 1024);
    },
    [activeModal, showModal],
  );

  useOnClickOutside(
    ref,
    () => {
      !!showModal && setShowModal(false);
    },
    (e) => {
      if (!(e.target as Element).matches('.toggle-modal') && !(e.target as Element).closest('.bottom-bar--modal')) {
        !!showModal && setShowModal(false);
      }
    },
  );

  const getModal = (item: EngbersOsBottomBarItem, isModalActive: boolean, index: number) => (
    <div key={`bottom-bar-modal-${index}`} style={{ width: `calc(100% / ${items.length})` }}>
      <div className={classnames('bottom-bar--modal', styles.bottomBarModal, { [styles.showModal]: isModalActive })}>
        {isLoadingContent && (
          <div className={styles.loadingWrap}>
            <Spinner size="x-small" color="#0f202f" />
          </div>
        )}
        {!isLoadingContent && item.tree ? (
          <NavigationList
            tree={item.tree}
            level={0}
            menuIcon={item.icon}
            search={search}
            algoliaConfig={algoliaConfig}
            setShowModal={setShowModal}
            extraLinks={extraLinks}
            shopLinks={shopLinks}
            socialMediaLinks={socialMediaLinks}
          />
        ) : (
          <div></div>
        )}
      </div>
      <button
        className={classnames('toggle-modal', styles.toggleModal, styles.iconWrapper, {
          [styles.isActive]: isModalActive,
          [styles.enGermanyIsActive]: isModalActive && currentSection === 'engbers-germany',
        })}
        onClick={() => handleModal(index, isModalActive)}
      >
        <IconCustom width={20} color={'custom'} icon="Close" className={styles.toggleClose} />
        <img
          className={classnames('toggle-modal', styles.icon)}
          src={item.icon.media.file}
          alt={getTitle(item.icon)}
          title={getTitle(item.icon)}
        />
      </button>
    </div>
  );

  const getLink = (item, isLinkActive, index) => (
    <div key={`bottom-bar-link-${index}`} style={{ width: `calc(100% / ${items.length})` }}>
      <ReferenceLink
        target={item.reference}
        className={classnames(styles.iconWrapper, {
          [styles.isActive]: isLinkActive,
          [styles.enGermanyIsActive]: isLinkActive && currentSection === 'engbers-germany',
        })}
      >
        {item.itemId === 'cart' && !!cart?.lineItems?.length && (
          <span
            className={classnames(styles.amountBadge, {
              [styles.enGermanyAmountBadge]: currentSection === 'engbers-germany',
            })}
            style={getLineItemsCount() >= 100 ? { fontSize: '10px' } : undefined}
          >
            {getLineItemsCount() >= 100 ? '+99' : getLineItemsCount()}
          </span>
        )}
        {item.itemId === 'wishlist' && !!wishlist?.lineItems?.length && (
          <span
            className={classnames(styles.amountBadge, {
              [styles.enGermanyAmountBadge]: currentSection === 'engbers-germany',
            })}
            style={wishlist.lineItems.length >= 100 ? { fontSize: '10px' } : undefined}
          >
            {wishlist.lineItems.length >= 100 ? '+99' : wishlist.lineItems.length}
          </span>
        )}
        <img className={styles.icon} src={item.icon.media.file} alt={getTitle(item.icon)} title={getTitle(item.icon)} />
      </ReferenceLink>
    </div>
  );

  return (
    <>
      {showModal && createPortal(<div className={styles.bottomBarModalBackdrop}></div>, document.body)}
      <div ref={ref} className={classnames(styles.wrap, { [styles.modalOpen]: showModal })}>
        {items.map((item, index) => {
          const linkUrl =
            item.reference?.type === 'link' ? item.reference.link : item.reference?.pageFolder?._url || null;
          const isLinkActive = router.asPath === linkUrl;

          if (item.behaviour === 'modal') {
            const isModalActive = showModal && activeModal === index;
            disableScrollOnBody(isModalActive && window?.innerWidth < 1024);

            return getModal(item, isModalActive, index);
          }

          return getLink(item, isLinkActive, index);
        })}
      </div>
    </>
  );
};
