import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import classnames from 'classnames';
import Link from 'next/link';
import { getSKUParts } from '@frontastic-engbers/helpers/utils/getSKUParts';
import { useAccount } from '@frontastic-engbers/lib';
import { Outfit } from '@frontastic-engbers/types/product/Outfit';
import { Product } from '@frontastic-engbers/types/product/Product';
import { Variant } from '@frontastic-engbers/types/product/Variant';
import { EcondaWidgetConfiguration } from '@frontastic-engbers/types/engbers-custom';
import { ProductMapper } from '@frontastic-engbers/lib/lib/mappers/productMapper';
import { ProductUtils } from '@frontastic-engbers/helpers/utils/productUtils';
import { Slider, SliderProps } from '@engbers/components/slider';
import { Button, IconCustom, Image } from '@engbers/components';
import Price from '../../price';
import lineItemStyles from '../cart-line-items/cart-line-items.module.scss';
import styles from './alternative-product-modal.module.scss';

export type EcondaConfigs = EcondaWidgetConfiguration & { sku?: string; categoryUrl?: string };

export interface IAlternativeProductModal {
  alternativeProductsConfig?: EcondaConfigs & Outfit;
  updateGlobalState: (isFlyoutCartOpen: boolean) => void;
  toCheckoutBtnLabel: string;
  toBasketBtnLabel: string;
  headlineAlternativeProduct: string;
  headlineAlternativeOutfit: string;
  continueShoppingLabel: string;
  toOutfitLabel: string;
}

export const AlternativeProductModal: React.FC<IAlternativeProductModal> = ({
  alternativeProductsConfig,
  updateGlobalState,
  toCheckoutBtnLabel,
  toBasketBtnLabel,
  headlineAlternativeProduct,
  headlineAlternativeOutfit,
  continueShoppingLabel,
  toOutfitLabel,
}) => {
  const [econda, setEconda] = useState(null);
  const [sliderProducts, setSliderProducts] = useState<Product[]>(null);
  const { loggedIn } = useAccount();
  const router = useRouter();

  const outfit: Outfit =
    alternativeProductsConfig?.productId && ProductUtils.isOutfit(alternativeProductsConfig)
      ? alternativeProductsConfig
      : undefined;
  const econdaConfigs: EcondaConfigs = !outfit ? alternativeProductsConfig : undefined;
  const isWishlist = window.location.href.includes('mein-konto/mein-merkzettel');

  const sliderConfiguration: SliderProps = {
    arrows: true,
    dots: false,
    direction: 'vertical',
    spaceBetween: 0,
  };

  const handleLastVisitedProduct = () => {
    const lastVisitedProduct = JSON.parse(sessionStorage.getItem('storage_last_visited_product'));

    if (!lastVisitedProduct) {
      return null;
    }

    lastVisitedProduct.visited = true;
    sessionStorage.setItem('storage_last_visited_product', JSON.stringify(lastVisitedProduct));
  };

  useEffect(() => {
    if (econdaConfigs) {
      window.econda.ready(() => {
        setEconda(window.econda);
      });
    }
  }, [typeof window !== 'undefined' && window.econda]);

  useEffect(() => {
    if (econdaConfigs && econda?.recengine) {
      const accountId = `${econdaConfigs.credentials?.clientKey}-${econdaConfigs.credentials?.accountId}`;
      const contextProductIds = [getSKUParts(econdaConfigs.sku).key];

      const request = new econda.recengine.Request({
        accountId,
        widgetId: econdaConfigs.alternativeSlider,
        autoContext: !contextProductIds,
        ...(contextProductIds && {
          context: {
            products: contextProductIds.map((id) => ({ id })),
          },
        }),
        success: (response) => {
          setSliderProducts(response.getProducts().map((product) => ProductMapper.econdaProductToProduct(product)));
        },
      });

      request.send();
    }
  }, [econda, econdaConfigs]);

  const getProductSlide = (index: number, product: Product, variant?: Variant) => {
    variant = variant ?? product.variants[0];
    return (
      <div key={`alternative-product-${index}`} className={classnames(lineItemStyles.lineItemWrap, styles.productWrap)}>
        <Link href={product.url}>
          <a onClick={() => updateGlobalState(false)}>
            <Image src={variant?.images[0]} width={90} alt={product.name} title={product.name} />
          </a>
        </Link>
        <div className={classnames(lineItemStyles.lineItemInfo, styles.productInfo)}>
          <Link href={product.url}>
            <a onClick={() => updateGlobalState(false)}>
              {product.attributes?.DesignerName && <div>{product.attributes?.DesignerName}</div>}
              <strong>{product.name}</strong>
            </a>
          </Link>

          <Price
            price={variant.price}
            discountedPrice={variant.discountedPrice}
            className={lineItemStyles.lineItemInfoPrice}
            isBold
            showCurrencySymbol
          />
        </div>
      </div>
    );
  };

  if (!alternativeProductsConfig || (!sliderProducts && !outfit?.products?.length)) {
    return null;
  }

  return (
    <div
      className={classnames(styles.alternativeProductWrap, {
        [styles.alternativeOutfitWrap]: outfit?.products?.length && outfit?.variants[0]?.images[0]?.length,
      })}
    >
      <div className={classnames(styles.blockWrap, styles.alternativeProductHeaderWrap)}>
        <strong>{outfit ? headlineAlternativeOutfit : headlineAlternativeProduct}</strong>
        <button onClick={() => updateGlobalState(false)}>
          <IconCustom width={18} color={'primary'} icon="Close" />
        </button>
      </div>
      <div
        className={classnames(styles.blockWrap, {
          'ml-auto w-96 pl-12': outfit,
        })}
      >
        <Button
          type="cta"
          label={toCheckoutBtnLabel}
          size="default"
          hasIcon={false}
          customStyle={{
            fontWeight: 700,
            fontSize: '16px',
          }}
          onClick={async () => {
            await router.push(loggedIn ? '/checkout' : '/checkout/login');
            updateGlobalState(false);
          }}
        />
        <Button
          type="primary"
          label={toBasketBtnLabel}
          size="default"
          hasIcon={false}
          customStyle={{ fontSize: '16px' }}
          onClick={async () => {
            await router.push('/cart');
            updateGlobalState(false);
          }}
        />
      </div>
      {sliderProducts && (
        <Slider {...sliderConfiguration}>
          {sliderProducts.map((product, index: number) => getProductSlide(index, product))}
        </Slider>
      )}

      {outfit?.variants[0] && (
        <div className={styles.alternativeOutfitImagesWrap}>
          {outfit?.variants[0]?.images[0] && (
            <Link href={outfit.url}>
              <a onClick={() => updateGlobalState(false)}>
                <Image src={outfit?.variants[0].images[0]} width={285} alt={outfit.name} title={outfit.name} />
              </a>
            </Link>
          )}
          <Slider {...sliderConfiguration}>
            {outfit.products?.map((product, index: number) => {
              return !product ? null : getProductSlide(index, product);
            })}
          </Slider>
        </div>
      )}
      <div className={classnames(styles.blockWrap, styles.continueShoppingWrap)}>
        {(econdaConfigs?.categoryUrl || (outfit?.url && !isWishlist)) && (
          <Button
            type="primary"
            label={continueShoppingLabel}
            size="default"
            hasIcon={false}
            customStyle={{ fontSize: '16px' }}
            onClick={async () => {
              await router.push(outfit ? '/herren-outfits' : econdaConfigs.categoryUrl);
              handleLastVisitedProduct();
              updateGlobalState(false);
            }}
          />
        )}
        {outfit && (
          <Button
            type="primary"
            label={toOutfitLabel}
            size="default"
            hasIcon={false}
            customStyle={{
              fontSize: '16px',
              marginLeft: isWishlist ? 'auto' : undefined,
            }}
            onClick={async () => {
              await router.push(outfit.url);
              updateGlobalState(false);
            }}
          />
        )}
      </div>
    </div>
  );
};
