import { AnimatePresence, motion } from 'framer-motion';
import styles from './input-tooltip.module.scss';

const modalVariants = {
  initial: () => ({
    opacity: 0,
    y: -10,
  }),
  enter: () => ({
    opacity: 1,
    y: 0,
  }),
  exit: {
    opacity: 0,
    y: -10,
  },
};

export const InputTooltip = ({ message, isActive, onClose }) => {
  return (
    <AnimatePresence>
      {isActive && (
        <motion.div
          variants={modalVariants}
          initial={'initial'}
          animate={'enter'}
          transition={{
            type: 'spring',
            bounce: 0,
          }}
          exit={'exit'}
          onClick={onClose}
          className={styles.tooltip}
        >
          <div className={styles.scrollContainer}>
            <div>{message}</div>
          </div>
          <div className={styles.closeButton}>
            <div className={styles.closeIcon}></div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
