import classNames from "classnames";
import styles from "./loading-indicator-inline.module.scss";

export interface ILoadingIndicatorInline {
    color?: "white" | "blue";
}

export const LoadingIndicatorInline: React.FC<ILoadingIndicatorInline> = ({
    color,
}) => {
    return (
        <div
            className={classNames(styles.wrap, {
                [styles[`color--${color}`]]: color,
            })}
        >
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
};
