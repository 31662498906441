import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { getStoreLocationLink } from '@frontastic-engbers/helpers/utils/getStoreLocationLink';
import { StoreAvailabilityResult as StoreAvailabilityResultType } from '@frontastic-engbers/types/product/StoreAvailability';
import { Button } from '@engbers/components';
import { IAvailabilityModalContent } from '../../../index';
import { StoreAvailabilitySize } from '../store-availability-size';
import styles from './store-availability-result.module.scss';

export interface IStoreAvailabilityResult {
  result: StoreAvailabilityResultType;
  onlineAvailability: Record<string, boolean>;
  availabilityModalContent: IAvailabilityModalContent;
}

export const StoreAvailabilityResult: React.FC<IStoreAvailabilityResult> = ({
  result,
  onlineAvailability,
  availabilityModalContent,
}) => {
  const { formatMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  const { store } = result;

  return (
    <div className={styles.result}>
      {result.stock && (
        <>
          <span>
            {formatProductMessage({
              id: 'sizes',
              defaultMessage: 'Größen',
            })}
            :{' '}
          </span>
          <div className={styles.sizes}>
            {result.stock?.map((stock) => (
              <StoreAvailabilitySize
                sizeStock={stock}
                onlineAvailability={onlineAvailability}
                storeIsClosed={store.isClosed}
                storeIsClosedHint={availabilityModalContent.storeClosedHint}
                key={stock.size}
              />
            ))}
          </div>
        </>
      )}
      {!result.stock && !store.isClosed && <div itemProp="availability">{availabilityModalContent.notListedHint}</div>}
      <div className={styles.storeInfo}>
        <div className={styles.mainStoreInfo}>
          <span>{store.name}</span>
          {store.addInfo?.length > 0 && <span>{store.addInfo}</span>}
          <span>
            {store.street} {store.streetNumber}
          </span>
          <strong>
            {store.zip} {store.city}
          </strong>
          <span className={styles.phone}>
            {formatMessage({
              id: 'phoneShort',
              defaultMessage: 'Telefon',
            })}
            : {store.phone}
          </span>
        </div>

        {store.isClosed && <p className={styles.isClosed}>{availabilityModalContent.storeClosedHint}</p>}

        {!store.isClosed && store.openingHours && (
          <div className={styles.openingHours}>
            <span>
              {formatMessage({
                id: 'openingHours',
                defaultMessage: 'Öffnungszeiten',
              })}
              :{' '}
            </span>
            {store.openingHours.split('<br>').map((text: string, i: number) => (
              <div key={`opening-hours-${i}`}>{text}</div>
            ))}
          </div>
        )}
      </div>

      {store.bookingLink?.length > 0 && (
        <Button
          href={store.bookingLink}
          target="_blank"
          label={availabilityModalContent.appointmentLabel}
          size="large"
          customStyle={{ marginTop: '16px' }}
          disabled={store.isClosed}
          hasIcon={false}
          className={styles.appointmentButton}
        />
      )}

      <div className={styles.showLocation}>
        <a href={getStoreLocationLink(store)} target="_blank" rel="noreferrer">
          {availabilityModalContent.locationLabel}
        </a>
      </div>
    </div>
  );
};
