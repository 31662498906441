import React, { useEffect, useRef, useState } from 'react';
import Toast from 'react-hot-toast';
import { useRouter } from 'next/router';
import ReCAPTCHA from 'react-google-recaptcha';
import { useMediaQuery } from 'react-responsive';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { getProjectInfo, useAccount } from '@frontastic-engbers/lib';
import { setPwaInstallStatus } from '@frontastic-engbers/lib/actions/account';
import { useModalActions } from '@frontastic-engbers/lib/state/modal/actions';
import { validateDateFormat } from '@frontastic-engbers/helpers/utils/validateDateFormat';
import { validatePostalCode } from '@frontastic-engbers/helpers/utils/validatePostalCode';
import { validateStreetName } from '@frontastic-engbers/helpers/utils/validateStreetName';
import {
  AccountRegisterFormData,
  AccountRegisterResponse,
  NewsletterIdentifier,
} from '@frontastic-engbers/types/account/Account';
import {
  Block,
  Button,
  CountrySelect,
  IconCustom,
  InfoBox,
  InputCheckbox,
  InputDate,
  InputRadioGroup,
  InputText,
  Link,
  Markdown,
  PasswordCheckList,
  SectionLayout,
  Spacer,
} from '@engbers/components';
import { AnimatePresence, motion } from 'framer-motion';
import { PasswordCheckListHelper } from '@frontastic-engbers/helpers/passwordCheckListHelper';
import { EmailValidatorHelper } from '@frontastic-engbers/helpers/emailValidatorHelper';
import { isStandaloneMode } from '@frontastic-engbers/helpers/utils/standaloneMode';
import { MigrationStepTwoModal } from '@engbers/components/migration-login-form/step-two-modal';
import { IFTLinkReference, IFTPageFolderReference } from '@frontastic-engbers/types/engbers-custom';
import { getReferenceTarget } from '@frontastic-engbers/helpers/reference';
import styles from './registration-form.module.scss';
import { StoreFinder } from './components/store-finder';
import isoAlpha2Data from '@frontastic-engbers/helpers/utils/iso-alpha2-data';

type ValidationErrors = {
  generalError?: string;

  salutation: boolean;
  firstName: boolean;
  lastName: boolean;

  addressStreetName: boolean;
  addressStreetNumber: boolean;
  addressPostalCode: boolean;
  addressCity: boolean;
  addressCountry: boolean;

  birthdayYear: boolean;
  birthdayMonth: boolean;
  birthdayDay: boolean;

  email: boolean;

  emailsMatch: boolean;

  password: boolean;
  passwordsMatch: boolean;

  acceptTos: boolean;
  acceptReCaptcha: boolean;
};

type StreetNamePostalCodeErrors = {
  streetName: boolean;
  postalCode: boolean;
};

const defaultState: AccountRegisterFormData = {
  cardId: '',
  salutation: null,
  firstName: '',
  lastName: '',
  addressStreetName: '',
  addressStreetNumber: '',
  addressPostalCode: '',
  addressCity: '',
  addressCountry: '',

  birthdayYear: null,
  birthdayMonth: null,
  birthdayDay: null,

  email: '',
  confirmEmail: '',
  password: '',
  confirmPassword: '',

  acceptEngbersNewsletter: false,
  acceptEmilioAdaniNewsletter: false,
  acceptEngbersXxlNewsletter: false,
  acceptPhysicalMail: false,
  acceptTos: false,

  isMigrationUser: false,

  // Shopping preference
  shoppingPreferenceOnline: false,
  shoppingPreferenceStore: false,
  shoppingPreferenceStoreId: '',
};

const PAGE_VARIANTS = {
  initial: {
    opacity: 0,
    x: -10,
  },
  animate: {
    opacity: 1,
    x: 0,
  },
  exit: {
    opacity: 0,
    x: 10,
  },
};

interface IRegistrationFormComponent {
  labels: any;
  initialState?: AccountRegisterFormData;
  isEngbersOS?: boolean;
  isFormCentered?: boolean;
  doiConfirmationLink?: IFTLinkReference | IFTPageFolderReference | undefined;
}

export const RegistrationForm: React.FC<IRegistrationFormComponent> = ({
  labels,
  doiConfirmationLink,
  initialState = defaultState,
  isEngbersOS = false,
  isFormCentered = false,
}) => {
  const projectInfo = getProjectInfo();
  const isPwa = projectInfo['projectId'] === 'Pwa';
  const defaultCountry = Object.keys(isoAlpha2Data).find((key) => isoAlpha2Data[key] === isoAlpha2Data.DE);
  initialState.addressCountry =
    initialState.addressCountry === '' ? (!isPwa ? defaultCountry : '') : initialState.addressCountry;
  const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { formatMessage } = useFormat({ name: 'common' });
  const { register, login } = useAccount();
  const { pushModal } = useModalActions();
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const router = useRouter();
  const recaptchaRef = useRef();
  const [data, setData] = useState<AccountRegisterFormData>(initialState);
  const [isMinWidthMedium, setIsMinWidthMedium] = useState<boolean>(false);
  const [isPassInputFocused, setIsPassInputFocused] = useState<boolean>(false);
  const [errors, setErrors] = useState<Partial<ValidationErrors>>({});
  const [emailErrors, setEmailErrors] = useState<string | null>(null);
  const [passwordErrors, setPasswordErrors] = useState<{ valid: boolean; message: string } | null>(null);
  const [streetNamePostalCodeErrors, setStreetNamePostalCodeErrors] = useState<Partial<StreetNamePostalCodeErrors>>({});
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const isMigrationRegistration = router.query.migrationslogin;

  const mapDay = (day: string) => {
    if (day.length === 1) {
      return `0${day}`;
    }

    return day;
  };

  initialState.salutation =
    initialState?.salutation?.toLowerCase() === 'frau'
      ? 'MRS'
      : initialState?.salutation?.toLowerCase() === 'herr'
        ? 'MR'
        : initialState.salutation;

  initialState.birthdayMonth = initialState?.birthdayMonth ? mapDay(initialState?.birthdayMonth?.toString()) : '';
  initialState.birthdayDay = initialState?.birthdayDay ? mapDay(initialState?.birthdayDay?.toString()) : '';

  useEffect(() => {
    if (isMobile !== isMinWidthMedium) {
      setIsMinWidthMedium(isMobile);
    }
  }, [isMobile]);

  useEffect(() => {
    setData(initialState);
  }, [initialState]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, trimEnd = false) => {
    setData((prev) => ({
      ...prev,
      [e.target.name]: trimEnd ? e.target.value.trimEnd() : e.target.value,
    }));
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }));
  };

  const parseDate = (date: number | string) => {
    if (typeof date === 'number') {
      return date;
    }

    return parseInt(date);
  };

  const validatePostalCodeField = (value: string, country: string = null) => {
    const isCountryGermany = country ? country.toLowerCase() === 'de' : data.addressCountry.toLowerCase() === 'de';

    if (isCountryGermany) {
      setStreetNamePostalCodeErrors((state) => ({
        ...state,
        postalCode: !validatePostalCode(value),
      }));
    }

    setStreetNamePostalCodeErrors((state) => ({
      ...state,
      postalCode: isCountryGermany ? !validatePostalCode(value) : false,
    }));
  };

  const validate = (country: string = null) => {
    const isCountryGermany = country ? country.toLowerCase() === 'de' : data.addressCountry?.toLowerCase() === 'de';
    const isCountryIreland = country ? country.toLowerCase() === 'ie' : data.addressCountry?.toLowerCase() === 'ie';

    const errors: ValidationErrors = {
      salutation: data.salutation !== null,
      firstName: data.firstName !== '',
      lastName: data.lastName !== '',

      addressStreetName: data.addressStreetName !== '',
      addressStreetNumber: data.addressStreetNumber !== '',
      addressPostalCode: isCountryIreland || data.addressPostalCode !== '',
      addressCity: data.addressCity !== '',
      addressCountry: data.addressCountry !== '' || !!country,

      birthdayYear:
        data.birthdayYear !== null &&
        validateDateFormat(data.birthdayDay?.toString(), data.birthdayMonth?.toString(), data.birthdayYear?.toString()),
      birthdayMonth:
        data.birthdayMonth !== null &&
        validateDateFormat(data.birthdayDay?.toString(), data.birthdayMonth?.toString(), data.birthdayYear?.toString()),
      birthdayDay:
        data.birthdayDay !== null &&
        validateDateFormat(data.birthdayDay?.toString(), data.birthdayMonth?.toString(), data.birthdayYear?.toString()),

      acceptTos: data.acceptTos,
      acceptReCaptcha: (recaptchaRef.current as any)?.getValue() !== '',

      email: true,
      emailsMatch: data.email === data.confirmEmail,

      password: true,
      passwordsMatch: data.password === data.confirmPassword,
    };

    validateEmail();
    validatePassword();
    setStreetNamePostalCodeErrors({
      streetName: !validateStreetName(data.addressStreetName),
      postalCode: isCountryGermany ? !validatePostalCode(data.addressPostalCode) : false,
    });
    setErrors(errors);

    // return a boolean representing the data validity
    // filter using includes, because if any value in the errors object is false then the form is invalid
    return !Object.values(errors).includes(false);
  };

  const validateEmail = async () => {
    const ruleDefinitions: string | null = await EmailValidatorHelper.ruleDefinitions(data.email, true);

    if (!ruleDefinitions) {
      setEmailErrors(null);
      return;
    }

    setEmailErrors(ruleDefinitions);
  };

  const validatePassword = (e?: React.ChangeEvent<HTMLInputElement>) => {
    const ruleDefinitions: any = PasswordCheckListHelper.ruleDefinitions(e ? e.target.value : data.password, 6);
    const rulesKeys: string[] = Object.keys(ruleDefinitions).filter((key) => !ruleDefinitions[key].valid);

    rulesKeys.forEach((key) => {
      if (!ruleDefinitions[key].valid) {
        setErrors({
          ...errors,
          password: ruleDefinitions[key].valid,
        });
        setPasswordErrors(ruleDefinitions[key]);
      }
    });

    if (!rulesKeys.length) {
      setErrors({
        ...errors,
        password: true,
      });
      setPasswordErrors({
        valid: true,
        message: '',
      });
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const isCountryGermany = data.addressCountry?.toLowerCase() === 'de';

    if (
      !validate() ||
      !passwordErrors?.valid ||
      emailErrors?.length ||
      !validateStreetName(data.addressStreetName) ||
      (isCountryGermany && !validatePostalCode(data.addressPostalCode))
    ) {
      return;
    }
    setLoading(true);

    try {
      const newsletterSubscriptions: NewsletterIdentifier[] = [
        data.acceptEngbersNewsletter ? 'Engbers_NL_Anmeldungen' : null,
        data.acceptEmilioAdaniNewsletter ? 'Emilio_NL_Anmeldungen' : null,
        data.acceptEngbersXxlNewsletter ? 'XXL_NL_Anmeldungen' : null,
      ].filter((x): x is NewsletterIdentifier => x !== null);

      const keptMigrationEmail = initialState.email === data.email;

      const response: AccountRegisterResponse = await register({
        cardId: data.cardId,
        email: data.email,
        password: data.password,
        salutation: data.salutation,
        firstName: data.firstName,
        lastName: data.lastName,
        ...(data.title && { title: data.title }),
        birthdayYear: parseDate(data.birthdayYear),
        birthdayMonth: parseDate(data.birthdayMonth),
        birthdayDay: parseDate(data.birthdayDay),
        streetName: data.addressStreetName,
        streetNumber: data.addressStreetNumber,
        postalCode: data.addressPostalCode,
        city: data.addressCity,
        country: data.addressCountry,
        additionalAddressInfo: '',
        additionalStreetInfo: '',
        state: '',
        acceptPhysicalMail: data.acceptPhysicalMail,
        newsletterSubscriptions: newsletterSubscriptions,
        isMigrationUser: data.isMigrationUser,
        isCheckout: false,
        shopId: projectInfo.projectId === 'ea' ? '3' : '2',
        shoppingPreferenceStore: data.shoppingPreferenceStore,
        shoppingPreferenceOnline: data.shoppingPreferenceOnline,
        preferredStoreId: data.shoppingPreferenceStoreId,
        ...(data.phoneNumber && { phoneNumber: data.phoneNumber }),
        ...(data.mobilePhoneNumber && { mobilePhoneNumber: data.mobilePhoneNumber }),
      });

      if (response.status === 'USER_ALREADY_EXISTS') {
        router
          .push({
            pathname: '/login',
            query: { cardId: response.cardId },
          })
          .then(() =>
            Toast.error(
              formatAccountMessage({
                id: 'account.haveAlready',
                defaultMessage: 'You already have an account!',
              }),
            ),
          );

        return;
      }

      if (response.status === 'MIGRATION_LOGIN') {
        pushModal({
          id: 'migration-login-step-2',
          title: formatErrorMessage({ id: 'securityQuery' }),
          content: (
            <MigrationStepTwoModal
              loading={loading}
              cardId={response.cardId ?? ''}
              migrationVerifyInformation={labels.migrationVerifyInformation}
              migrationVerifyBirthdate={labels.migrationVerifyBirthdate}
            />
          ),
        });

        setLoading(false);
        return;
      }

      if (response.status === 'CREATED') {
        const response = await login(data.email, data.password);

        if (response.loggedIn) {
          if (isStandaloneMode()) {
            setPwaInstallStatus();
          }

          router.push('/mein-konto');
        }

        return;
      }

      if (response.status === 'UNKNOWN_ERROR') {
        setErrors({
          generalError:
            response.errors && response.errors[0]
              ? response.errors[0] // TODO: check if we actually get multiple errors?
              : formatErrorMessage({
                id: 'wentWrong',
                defaultMessage: 'Sorry. Something went wrong..',
              }),
        });

        setLoading(false);
        return;
      }

      if (doiConfirmationLink) {
        router.push(getReferenceTarget(doiConfirmationLink));
        return;
      }

      setErrors({});

      if (data.isMigrationUser && keptMigrationEmail) {
        const innerResponse = await login(data.email, data.password);
        router.push('/');
        return;
      }
      setSuccess(true);
    } catch (err) {
      setErrors({
        generalError: formatErrorMessage({
          id: 'wentWrong',
          defaultMessage: 'Sorry. Something went wrong..',
        }),
      });
      setSuccess(false);
    }

    setLoading(false);
  };

  const getBlockStyle = (hasMarginRight: boolean) => {
    if (isEngbersOS && !isMinWidthMedium) {
      return {
        display: 'inline-flex',
        width: 'calc(50% - 8px)',
        flexDirection: 'column',
        marginRight: hasMarginRight ? '16px' : undefined,
      };
    }

    return undefined;
  };

  if (!loading && success && labels.DOIConfirmationHeading && labels.DOIConfirmationText) {
    return (
      <Block marginTop={7} marginBottom={7}>
        <Block marginBottom={7}>
          <h3>{labels.DOIConfirmationHeading}</h3>
        </Block>
        <Block
          paddingTop={4}
          customStyle={{
            borderTop: '1px dotted #ccc',
          }}
        >
          {labels.DOIConfirmationText}
        </Block>
      </Block>
    );
  }

  return (
    <SectionLayout maxWidth={540} isCentered={isFormCentered}>
      <form className="space-y-7" onSubmit={handleSubmit}>
        <Block marginTop={8}>
          <Markdown className={styles.headline} text={labels.headline} textSize={labels.textSize} />
        </Block>

        <AnimatePresence exitBeforeEnter>
          <motion.div
            key={`is-success-${success}`}
            variants={PAGE_VARIANTS}
            initial={'initial'}
            animate={'animate'}
            exit="exit"
          >
            {success ? (
              <InfoBox type="success" content={labels.messageSuccess} />
            ) : (
              <div>
                {errors.generalError && <p className="text-encom-400 text-sm">{errors.generalError}</p>}
                <Block marginBottom={4} marginTop={12}>
                  <InputRadioGroup
                    onChange={(id) => {
                      setData((prev) => ({
                        ...prev,
                        salutation: id as any,
                      }));

                      if (id) {
                        setErrors({
                          ...errors,
                          salutation: true,
                        });
                      }
                    }}
                    options={[
                      {
                        label: labels.radioMister,
                        id: 'MR',
                      },
                      {
                        label: labels.radioMs,
                        id: 'MRS',
                      },
                    ]}
                    activeId={data.salutation ? data.salutation : undefined}
                    errorMessage={
                      errors.salutation === false
                        ? formatMessage({ id: 'salutation' }) + formatErrorMessage({ id: 'mandatory.error' })
                        : undefined
                    }
                  />
                </Block>
                <Block customStyle={getBlockStyle(true)} marginBottom={4}>
                  <InputText
                    id="first_name"
                    name="firstName"
                    placeholder={labels.labelFirstName}
                    type="text"
                    autoComplete="given-name"
                    required
                    errorMessage={
                      errors.firstName === false
                        ? formatMessage({ id: 'firstName' }) + formatErrorMessage({ id: 'mandatory.error' })
                        : undefined
                    }
                    value={data.firstName}
                    onChange={handleInputChange}
                    onBlur={() =>
                      setErrors({
                        ...errors,
                        firstName: data.firstName !== '',
                      })
                    }
                    wrapperCustomStyle={{ width: '100%' }}
                  />
                </Block>
                <Block customStyle={getBlockStyle(false)} marginBottom={4}>
                  <InputText
                    id="last_name"
                    name="lastName"
                    type="text"
                    placeholder={labels.lastLastName}
                    autoComplete="family-name"
                    required
                    value={data.lastName}
                    errorMessage={
                      errors.lastName === false
                        ? formatMessage({ id: 'lastName' }) + formatErrorMessage({ id: 'mandatory.error' })
                        : undefined
                    }
                    onChange={handleInputChange}
                    onBlur={() =>
                      setErrors({
                        ...errors,
                        lastName: data.lastName !== '',
                      })
                    }
                    wrapperCustomStyle={{ width: '100%' }}
                  />
                </Block>
                <Block customStyle={getBlockStyle(true)} marginBottom={4}>
                  <InputText
                    id="address_street_name"
                    name="addressStreetName"
                    type="text"
                    autoComplete=""
                    required
                    value={data.addressStreetName}
                    placeholder={labels.labelStreet}
                    errorMessage={
                      errors.addressStreetName === false
                        ? formatMessage({ id: 'street.name' }) + formatErrorMessage({ id: 'mandatory.error' })
                        : streetNamePostalCodeErrors.streetName
                          ? formatErrorMessage({
                            id: 'streetNameCharacters',
                            defaultMessage: 'Straße darf keine Zahlen enthalten!',
                          })
                          : undefined
                    }
                    onChange={(e) => {
                      handleInputChange(e);
                      setStreetNamePostalCodeErrors((state) => ({
                        ...state,
                        streetName: !validateStreetName(e.target.value),
                      }));
                    }}
                    onBlur={(e) => {
                      handleInputChange(e, true);
                      setErrors({
                        ...errors,
                        addressStreetName: data.addressStreetName !== '',
                      });
                      setStreetNamePostalCodeErrors((state) => ({
                        ...state,
                        streetName: !validateStreetName(e.target.value),
                      }));
                    }}
                    wrapperCustomStyle={{ width: '100%' }}
                  />
                </Block>
                <Block customStyle={getBlockStyle(false)} marginBottom={4}>
                  <InputText
                    id="address_street_number"
                    name="addressStreetNumber"
                    type="text"
                    autoComplete=""
                    required
                    placeholder={labels.labelHouse}
                    value={data.addressStreetNumber}
                    errorMessage={
                      errors.addressStreetNumber === false
                        ? formatMessage({ id: 'street.number' }) + formatErrorMessage({ id: 'mandatory.error' })
                        : undefined
                    }
                    onChange={handleInputChange}
                    onBlur={() =>
                      setErrors({
                        ...errors,
                        addressStreetNumber: data.addressStreetNumber !== '',
                      })
                    }
                    wrapperCustomStyle={{ width: '100%' }}
                  />
                </Block>
                <Block customStyle={getBlockStyle(true)} marginBottom={4}>
                  <InputText
                    id="address_zip"
                    name="addressPostalCode"
                    type="text"
                    placeholder={labels.labelZip}
                    autoComplete=""
                    required={data.addressCountry?.toLowerCase() !== 'ie'}
                    value={data.addressPostalCode}
                    errorMessage={
                      errors.addressPostalCode === false
                        ? formatMessage({ id: 'zipCodeShort' }) + formatErrorMessage({ id: 'mandatory.error' })
                        : streetNamePostalCodeErrors.postalCode
                          ? formatErrorMessage({
                            id: 'postalCodeLength',
                            defaultMessage: 'PLZ muss in Deutschland 5 Zeigen lang sein',
                          })
                          : undefined
                    }
                    onChange={(e) => {
                      handleInputChange(e);
                      validatePostalCodeField(e.target.value);
                    }}
                    onBlur={(e) => {
                      const isCountryIreland = data.addressCountry?.toLowerCase() === 'ie';

                      setErrors({
                        ...errors,
                        addressPostalCode: isCountryIreland || data.addressPostalCode !== '',
                      });
                      validatePostalCodeField(e.target.value);
                    }}
                    wrapperCustomStyle={{ width: '100%' }}
                  />
                </Block>
                <Block customStyle={getBlockStyle(false)} marginBottom={4}>
                  <InputText
                    id="address_city"
                    name="addressCity"
                    type="text"
                    autoComplete=""
                    placeholder={labels.labelCity}
                    required
                    errorMessage={
                      errors.addressCity === false
                        ? formatMessage({ id: 'place' }) + formatErrorMessage({ id: 'mandatory.error' })
                        : undefined
                    }
                    value={data.addressCity}
                    onChange={handleInputChange}
                    onBlur={() =>
                      setErrors({
                        ...errors,
                        addressCity: data.addressCity !== '',
                      })
                    }
                    wrapperCustomStyle={{ width: '100%' }}
                    infoText={labels.cityInfo}
                    useInfoModal
                    infoModalContentClassName={styles.infoModalContent}
                    infoModalHasOkayButton={false}
                    infoModalIsLarge
                    infoModalCanCloseByBackdropClick
                    infoIconSize={18}
                    infoIconPosition="outer"
                  />
                </Block>
                <Block customStyle={getBlockStyle(true)} marginBottom={4}>
                  <CountrySelect
                    id="address_country"
                    placeholder={labels.labelCountry}
                    name="addressCountry"
                    errorMessage={
                      errors.addressCountry === false
                        ? formatMessage({ id: 'country' }) + formatErrorMessage({ id: 'mandatory.error' })
                        : undefined
                    }
                    autoComplete=""
                    required
                    value={data.addressCountry}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      if (isPwa && e.target.value === formatMessage({ id: 'chooseCountry' })) {
                        setErrors((prev) => ({
                          ...prev,
                          addressCountry: false,
                        }));
                        setData((prev) => ({
                          ...prev,
                          addressCountry: '',
                        }));
                      } else {
                        const isCountryIreland = e.target.value.toLowerCase() === 'ie';

                        setData((prev) => ({
                          ...prev,
                          addressCountry: e.target.value,
                        }));
                        validatePostalCodeField(data.addressPostalCode, e.target.value);
                        setErrors({
                          ...errors,
                          addressPostalCode: isCountryIreland || data.addressPostalCode !== '',
                          addressCountry: e.target.value !== '',
                        });
                      }
                    }}
                    wrapperCustomStyle={{ width: '100%' }}
                  />
                </Block>
                <Block customStyle={getBlockStyle(false)} marginBottom={4}>
                  <InputDate
                    label={labels.labelBirth}
                    required
                    disableValidatioOnBlur
                    onChange={(value) => {
                      setData((prev) => ({
                        ...prev,
                        ...value,
                      }));
                    }}
                    isFormatValid={(valid) => {
                      setErrors({
                        ...errors,
                        birthdayDay: valid,
                        birthdayMonth: valid,
                        birthdayYear: valid,
                      });
                    }}
                    stillHasError={
                      errors &&
                      (errors.birthdayDay === false || errors.birthdayMonth === false || errors.birthdayYear === false)
                    }
                    birthdayDay={data.birthdayDay}
                    birthdayMonth={data.birthdayMonth}
                    birthdayYear={data.birthdayYear}
                    info={labels.birthdateInfo}
                  />
                </Block>
                <Block customStyle={getBlockStyle(true)} marginBottom={4}>
                  <InputText
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    placeholder={labels.labelEmail}
                    required
                    errorMessage={
                      !emailErrors
                        ? undefined
                        : emailErrors === 'empty'
                          ? formatMessage({ id: 'email' }) + formatErrorMessage({ id: 'mandatory.error' })
                          : emailErrors === 'pattern'
                            ? labels?.invalidMailFormat || formatMessage({ id: 'emailWrongFormat' })
                            : formatErrorMessage({ id: 'emailInUse' })
                    }
                    onChange={handleInputChange}
                    onBlur={validateEmail}
                    value={data.email}
                    wrapperCustomStyle={{ width: '100%' }}
                  />
                </Block>
                <Block customStyle={getBlockStyle(false)} marginBottom={4}>
                  <InputText
                    id="email_repeat"
                    name="confirmEmail"
                    placeholder={labels.labelEmailConfirm}
                    type="email"
                    autoComplete="email"
                    required
                    errorMessage={
                      errors.emailsMatch === false ? formatErrorMessage({ id: 'emailMustMatch' }) : undefined
                    }
                    onChange={handleInputChange}
                    onBlur={() =>
                      setErrors({
                        ...errors,
                        emailsMatch: data.email === data.confirmEmail,
                      })
                    }
                    value={data.confirmEmail}
                    wrapperCustomStyle={{ width: '100%' }}
                  />
                </Block>

                <Block customStyle={getBlockStyle(true)} marginBottom={4}>
                  <Block>
                    <InputText
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="new-password"
                      placeholder={formatAccountMessage({ id: 'password' })}
                      required
                      onChange={(e) => {
                        handleInputChange(e);
                        validatePassword(e);
                      }}
                      onFocus={() => setIsPassInputFocused(true)}
                      onBlur={(e) => {
                        setIsPassInputFocused(false);
                        validatePassword(e);
                      }}
                      errorMessage={passwordErrors && !passwordErrors.valid ? passwordErrors.message : undefined}
                      value={data.password}
                      wrapperCustomStyle={{ width: '100%' }}
                    />
                  </Block>
                  {isPassInputFocused ? (
                    <Block marginTop={2}>
                      <PasswordCheckList
                        rules={['minLength', 'lowercase', 'capital', 'number', 'specialChar']}
                        minLength={6}
                        value={data.password}
                      />
                    </Block>
                  ) : null}
                </Block>
                <Block customStyle={getBlockStyle(false)} marginBottom={4}>
                  <InputText
                    id="password_repeat"
                    name="confirmPassword"
                    type="password"
                    autoComplete="new-password"
                    placeholder={formatAccountMessage({ id: 'password.repeat' })}
                    required
                    onChange={handleInputChange}
                    onBlur={() =>
                      setErrors({
                        ...errors,
                        passwordsMatch: data.password === data.confirmPassword,
                      })
                    }
                    errorMessage={
                      errors.passwordsMatch === false ? formatErrorMessage({ id: 'password.noMatch2' }) : undefined
                    }
                    value={data.confirmPassword}
                    wrapperCustomStyle={{ width: '100%' }}
                  />
                </Block>

                {!isEngbersOS && !isMigrationRegistration && (
                  <Block marginBottom={4} className={styles.shoppingPreferenceBlock}>
                    <div className={styles.shoppingPreferenceHeadline}>{labels.labelShoppingPreferenceHeadline}</div>
                    <div className={styles.shoppingPreferenceShopWrap}>
                      <InputCheckbox
                        style="white"
                        id="shopping_preference_shop"
                        name="shoppingPreferenceOnline"
                        label={'im Online-Shop'}
                        onChange={handleCheckboxChange}
                        checked={data.shoppingPreferenceOnline}
                      />
                    </div>
                    <InputCheckbox
                      style="white"
                      id="shopping_preference_store"
                      name="shoppingPreferenceStore"
                      label={'im Store'}
                      onChange={handleCheckboxChange}
                      checked={data.shoppingPreferenceStore}
                    />
                    {data.shoppingPreferenceStore && (
                      <div>
                        <StoreFinder
                          selectedStoreId={data.shoppingPreferenceStoreId}
                          currentZipCode={data.addressPostalCode}
                          labels={{
                            noStoreFound: labels.noStoreFound,
                            changeStore: labels.changeStore,
                          }}
                          setSelectedStoreId={(storeId) => {
                            setData((prev) => ({
                              ...prev,
                              shoppingPreferenceStoreId: storeId,
                            }));
                          }}
                        />
                      </div>
                    )}
                  </Block>
                )}

                <Block marginBottom={4}>
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={labels.reCaptchaSiteKey}
                    onChange={(v) =>
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        acceptReCaptcha: v !== '',
                      }))
                    }
                  />
                  <span className={styles.errorMsg}>
                    {errors.acceptReCaptcha === false
                      ? formatErrorMessage({
                        id: 'fillCaptcha',
                        defaultMessage: 'fillCaptcha',
                      })
                      : undefined}
                  </span>
                </Block>

                <Block marginBottom={2}>
                  <InputCheckbox
                    id="accept_engbers_newsletter"
                    name="acceptEngbersNewsletter"
                    label={labels.labelNewsletter}
                    onChange={handleCheckboxChange}
                    checked={data.acceptEngbersNewsletter}
                  />
                </Block>

                <Block marginBottom={2}>
                  <InputCheckbox
                    id="accept_emilio_adani_newsletter"
                    name="acceptEmilioAdaniNewsletter"
                    label={labels.labelAdaniNewsletter}
                    onChange={handleCheckboxChange}
                    checked={data.acceptEmilioAdaniNewsletter}
                  />
                </Block>

                <Block marginBottom={4}>
                  <InputCheckbox
                    id="accept_engbers_xxl_newsletter"
                    name="acceptEngbersXxlNewsletter"
                    label={labels.labelXXLNewsletter}
                    onChange={handleCheckboxChange}
                    checked={data.acceptEngbersXxlNewsletter}
                  />
                </Block>

                {!data.isMigrationUser && (
                  <Block marginBottom={4}>
                    <InputCheckbox
                      id="accept_mail"
                      name="acceptPhysicalMail"
                      label={labels.labelLetters}
                      onChange={handleCheckboxChange}
                      checked={data.acceptPhysicalMail}
                    />
                  </Block>
                )}

                <Block marginBottom={12}>
                  <InputCheckbox
                    id="accept_tos"
                    name="acceptTos"
                    label={
                      <span className={styles.acceptTosWrap}>
                        <span className={errors.acceptTos === false ? 'text-red-500' : ''}>
                          {labels.textGuidelinesMarkdown ? (
                            <Markdown
                              linkToNewTab
                              text={labels.textGuidelinesMarkdown}
                              className={styles.markdownGuidelines}
                            />
                          ) : (
                            <span>
                              Ich akzeptiere die{' '}
                              <a
                                rel="noreferrer"
                                href="https://www.engbers.com/unternehmen/engberscard/datenschutz/"
                                target="_blank"
                              >
                                Teilnahmebedingungen
                              </a>{' '}
                              des engbers Kundenkartenprogramms und habe die{' '}
                              <a
                                rel="noreferrer"
                                href="https://www.engbers.com/rechtliches/datenschutz/"
                                target="_blank"
                              >
                                Datenschutzbestimmungen
                              </a>{' '}
                              sowie die{' '}
                              <a rel="noreferrer" href="https://www.engbers.com/rechtliches/agb/">
                                AGB
                              </a>{' '}
                              zur Kenntnis genommen.
                            </span>
                          )}
                        </span>
                        <span className="flex w-min items-center">
                          {errors.acceptTos === false ? (
                            <IconCustom width={18} icon="Close" className={styles.errorCrossRegister} />
                          ) : (
                            ''
                          )}
                        </span>
                      </span>
                    }
                    onChange={handleCheckboxChange}
                    checked={data.acceptTos}
                  />
                </Block>

                <div>
                  <Button
                    buttonType="submit"
                    size="large"
                    isLoading={loading}
                    label={labels.labelButtonRegister}
                    onClick={handleSubmit}
                  />
                </div>
              </div>
            )}
          </motion.div>
        </AnimatePresence>

        <Block>
          <Link href={'/login'} className={styles.backToLoginLink}>
            {labels.labelBack}
          </Link>
        </Block>
      </form>
      <Spacer spacing={8} />
    </SectionLayout>
  );
};
