import { Button } from '@engbers/components';

export const EngbersButtonTastic = ({ data }) => {
  //data.algin = "left" | "center" | "right"
  //data.size = "default" | "large" | "small"
  const alignMapping = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end',
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: alignMapping[data.align] || 'flex-start',
      }}
    >
      <div
        style={{
          width: data.width && `${data.width}%`,
        }}
      >
        <Button
          customIconURL={data.buttonIcon?.media?.file}
          label={data.label}
          textSize={data.textSize}
          customIconWidth={data.iconWidth}
          type={data.type}
          size={data.size}
          color={data.color || undefined}
          textColor={data.textColor}
          link={data.link}
          className="w-full"
        />
      </div>
    </div>
  );
};
