import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useRouter } from 'next/router';
import { Button, Markdown } from '@engbers/components';
import { IFTLinkReference, IFTPageFolderReference } from '@frontastic-engbers/types/engbers-custom';
import { PasswordCheckListHelper } from '@frontastic-engbers/helpers/passwordCheckListHelper';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { useAccount } from '@frontastic-engbers/lib';
import { useToastNotificationsActions } from '@frontastic-engbers/lib/state/notification/actions';
import { getReferenceTarget } from '@frontastic-engbers/helpers/reference';
import SuccessIcon from '@heroicons/react/solid/CheckIcon';
import FailedIcon from '@heroicons/react/solid/XIcon';
import styles from './reset-password-form.module.scss';

interface IResetPasswordFormComponent {
  title?: string;
  textSize?: number;
  subHeading?: string;
  resetButtonLabel?: string;
  classNames?: Record<string, string>;
  token?: string;
  minPasswordLength?: number;
  redirectLink?: IFTLinkReference | IFTPageFolderReference;
}

interface IPasswordResetData {
  password: string;
  confirmPassword: string;
}

interface IValidationResult {
  valid: boolean;
  message?: string;
}

export const ResetPasswordForm: React.FC<IResetPasswordFormComponent> = ({
  title,
  textSize,
  subHeading,
  resetButtonLabel,
  classNames,
  token,
  redirectLink,
  minPasswordLength = 8,
}) => {
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });
  const { pushNotification } = useToastNotificationsActions();
  const { resetPassword } = useAccount();
  const router = useRouter();

  const [data, setData] = useState<IPasswordResetData>({
    password: '',
    confirmPassword: '',
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [fieldError, setFieldError] = useState<Record<string, string>>({});
  const [validationStatus, setValidationStatus] = useState<Record<string, IValidationResult>>({});
  const [ignoreConfirmPassword, setIgnoreConfirmPassword] = useState<boolean>(true);

  if (!redirectLink) {
    redirectLink = {
      type: 'link',
      link: '/login',
    } as IFTLinkReference;
  }

  const validate = () => {
    setValidationStatus({
      ...PasswordCheckListHelper.ruleDefinitions(data.password, minPasswordLength),
      noMatch: { valid: ignoreConfirmPassword || data.password === data.confirmPassword },
    });
  };

  const getPasswordMessage = (): string | undefined => {
    if (!validationStatus['notEmpty']?.valid) {
      return 'notEmpty';
    }

    return Object.keys(validationStatus).find(
      (status) => !(validationStatus[status]?.valid || ['noMatch', 'notEmpty'].includes(status)),
    );
  };

  const handleBlur = () => {
    setFieldError({
      password: getPasswordMessage() || undefined,
      confirmPassword: !validationStatus['noMatch']?.valid ? 'noMatch' : undefined,
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldError({
      ...fieldError,
      [e.target.name]: undefined,
    });
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const invalid = Object.keys(validationStatus).find((key) => !validationStatus[key].valid);

    if (invalid) {
      return;
    }

    try {
      setLoading(true);

      const response = await resetPassword(token, data.password);
      if ((response as any).error) {
        setLoading(false);
        pushNotification(
          formatErrorMessage({
            id: 'wentWrong',
            defaultMessage: 'Sorry. Something went wrong..',
          }),
          'error',
        );
        return;
      }

      router.push(getReferenceTarget(redirectLink));
    } catch (error) {
      setLoading(false);
      pushNotification(
        formatErrorMessage({
          id: 'wentWrong',
          defaultMessage: 'Sorry. Something went wrong..',
        }),
        'error',
      );
    }
  };

  const isInvalidInput = (field: string): boolean => {
    return fieldError?.[field]?.length > 0;
  };

  useEffect(() => {
    validate();
  }, [data, ignoreConfirmPassword]);

  return (
    <>
      <Markdown
        className={classnames(classNames?.headline, { [styles.headline]: !classNames?.headline })}
        text={
          title ??
          formatAccountMessage({
            id: 'password.forgot',
            defaultMessage: 'Forgot your password?',
          })
        }
      />
      {subHeading && (
        <Markdown
          className={classnames(classNames?.subHeading, { [styles.subHeading]: !classNames?.subHeading })}
          text={subHeading}
        />
      )}
      <form
        className={classnames(classNames?.resetForm, { [styles.resetForm]: !classNames?.resetForm })}
        onSubmit={handleSubmit}
      >
        <label
          htmlFor="password"
          className={classnames(classNames?.inputLabel, { [styles.inputLabel]: !classNames?.inputLabel })}
        >
          <span className={classnames({ [styles.inputInvalid]: isInvalidInput('password') })}>
            {formatAccountMessage({
              id: 'password.new',
              defaultMessage: 'New password',
            })}
          </span>
        </label>

        <input
          id="password"
          name="password"
          type="password"
          autoComplete="new-password"
          required
          className={classnames(classNames?.inputField, {
            [styles.inputField]: !classNames?.inputField,
            [styles.inputInvalidField]: isInvalidInput('password'),
          })}
          onChange={handleChange}
          onBlur={handleBlur}
        />

        {isInvalidInput('password') && (
          <div
            className={classnames(classNames?.inputInfoText, { [styles.inputInfoText]: !classNames?.inputInfoText })}
          >
            {fieldError.password && (
              <span className={styles.inputInvalid}>
                {formatErrorMessage({
                  id: `password.${fieldError.password}`,
                  defaultMessage: validationStatus[fieldError.password].message ?? fieldError.password,
                })}
              </span>
            )}
          </div>
        )}

        <label
          htmlFor="confirm-password"
          className={classnames(classNames?.inputLabel, { [styles.inputLabel]: !classNames?.inputLabel })}
        >
          <span className={classnames({ [styles['inputInvalid']]: isInvalidInput('confirmPassword') })}>
            {formatAccountMessage({
              id: 'password.confirm',
              defaultMessage: 'Confirm Password',
            })}
          </span>
        </label>

        <input
          id="confirm-password"
          name="confirmPassword"
          type="password"
          autoComplete="new-password"
          required
          className={classnames(classNames?.inputField, {
            [styles.inputField]: !classNames?.inputField,
            [styles['inputInvalidField']]: isInvalidInput('confirmPassword'),
          })}
          onFocus={() => setIgnoreConfirmPassword(false)}
          onChange={handleChange}
          onBlur={handleBlur}
        />

        {isInvalidInput('confirmPassword') && (
          <div
            className={classnames(classNames?.inputInfoText, { [styles.inputInfoText]: !classNames?.inputInfoText })}
          >
            {fieldError.confirmPassword && (
              <span className={styles.inputInvalid}>
                {formatErrorMessage({ id: `password.${fieldError.confirmPassword}` })}
              </span>
            )}
          </div>
        )}

        <ul
          className={classnames(classNames?.validationStatus, {
            [styles.validationStatus]: !classNames?.validationStatus,
          })}
        >
          {Object.keys(validationStatus).map((key: string) => {
            if (!['noMatch', 'notEmpty'].includes(key)) {
              return (
                <li key={key} className="flex items-center">
                  {validationStatus[key].valid ? (
                    <SuccessIcon
                      className={classnames('h-4 w-4', classNames?.icon, classNames?.successIcon, {
                        [styles.icon]: !classNames?.icon,
                        [styles.successIcon]: !classNames?.successIcon,
                      })}
                    />
                  ) : (
                    <FailedIcon
                      className={classnames('h-4 w-4', classNames?.icon, classNames?.failedIcon, {
                        [styles.icon]: !classNames?.icon,
                        [styles.failedIcon]: !classNames?.failedIcon,
                      })}
                    />
                  )}
                  {formatErrorMessage({
                    id: `password.${key}`,
                    defaultMessage: validationStatus[key].message ?? key,
                  })}
                </li>
              );
            }
          })}
        </ul>

        <div className={classnames(classNames?.ctaButton, { [styles.ctaButton]: !classNames?.ctaButton })}>
          <Button
            size="large"
            buttonType="submit"
            isLoading={loading}
            label={
              resetButtonLabel ??
              formatAccountMessage({
                id: 'password.reset',
                defaultMessage: 'Reset password',
              })
            }
            onClick={handleSubmit}
          />
        </div>
      </form>
    </>
  );
};
