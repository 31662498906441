import ThankYou from '@engbers/components/online-shops/thank-you';

const ThankYouTastic = ({ data }) => (<ThankYou
  deliveryTimeDE={data.deliveryTimeDE}
  deliveryTimeEU={data.deliveryTimeEU}
  shippingBy={data.shippingBy}
  orderConfirmationLabel={data.orderConfirmationLabel}
  orderConfirmationIcon={data.orderConfirmationIcon}
  orderModalImage={data.orderModalImage}
  orderModalEmailText={data.orderModalEmailText}
  orderModalLastText={data.orderModalLastText}
  orderModalPrepaymentHint={data.orderModalPrepaymentHint}
  orderModalPrepaymentAccountData={{
    payee: data.orderModalPrepaymentPayee,
    iban: data.orderModalPrepaymentIban,
    bic: data.orderModalPrepaymentBic,
    bank: data.orderModalPrepaymentBank,
  }}
  trustedShopIcon={data.trustedShopIcon}
  trustedShopText={data.trustedShopText}
  sslIcon={data.sslIcon}
  sslText={data.sslText}
/>);

export default ThankYouTastic;
