import React from 'react';
import NextLink from 'next/link';
import styles from './suggested-search.module.scss';

export interface ISuggestedSearch {
  value: string;
  isRecentSearch?: boolean;
  onRemove?: (id: string) => void;
}

export const SuggestedSearch: React.FC<ISuggestedSearch> = ({ value, isRecentSearch = false, onRemove }) => {
  return (
    <div className={styles.suggestedSearch}>
      <NextLink
        href={{
          pathname: '/search/[term]',
          query: { term: value } 
        }}>
        <a className={styles.suggestedSearchValue}>{value}</a>
      </NextLink>

      {isRecentSearch && (
        <button
          className={styles.removeSearchBtn}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onRemove(value);
          }}
        >
          X
        </button>
      )}
    </div>
  );
};
