import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import classNames from 'classnames';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import useI18n from '@frontastic-engbers/helpers/hooks/useI18n';
import { useImageSEO } from '@frontastic-engbers/helpers/hooks/useImageSEO';
import { MediaType, PageFolderTree } from '@frontastic-engbers/types/engbers-custom';
import { getProjectInfo } from '@frontastic-engbers/lib';
import {
  EngbersOsBottomBarExtraLinks,
  EngbersOsBottomBarSearch,
  EngbersOsBottomBarShopLinks,
  EngbersOsBottomBarSocialMediaLinks,
} from '../en-os-bottombar';
import { MenuExtraLinks } from '../en-os-bottombar/menu-extra-links';
import { IconCustom, Image } from '@engbers/components/index';
import { MobileSearch } from '@engbers/components/online-shops';
import styles from './mobile-navigation.module.scss';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

interface Props {
  tree: PageFolderTree;
  level: number;
  menuIcon?: MediaType;
  search: EngbersOsBottomBarSearch;
  algoliaConfig: any;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  extraLinks?: EngbersOsBottomBarExtraLinks[];
  shopLinks?: EngbersOsBottomBarShopLinks[];
  socialMediaLinks?: EngbersOsBottomBarSocialMediaLinks[];
}

interface INavigationListItem {
  name: string;
  url: string;
  level: number;
  index: number;
  hasChildren?: boolean;
  onClickEvent?: () => void;
  isShowAll?: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const NavigationList: React.FC<Props> = ({
  tree,
  level,
  menuIcon,
  search,
  algoliaConfig,
  setShowModal,
  extraLinks,
  shopLinks,
  socialMediaLinks,
}) => {
  const [firstLevelTree, setFirstLevelTree] = useState<PageFolderTree[]>(tree.children);
  const [secondLevelTree, setSecondLevelTree] = useState<PageFolderTree[] | null>(
    firstLevelTree ? firstLevelTree[1]?.children : null,
  );
  const [thirdLevelTree, setThirdLevelTree] = useState<PageFolderTree[] | null>(
    secondLevelTree ? secondLevelTree[0]?.children : null,
  );
  const [secondLevelLink, setSecondLevelLink] = useState<{ name: string; link: string }>(null);
  const [thirdLevelLink, setThirdLevelLink] = useState<{ name: string; link: string }>(null);
  const [showSecondLevel, setShowSecondLevel] = useState<boolean>(false);
  const [showThirdLevel, setShowThirdLevel] = useState<boolean>(false);
  const [showSearchModal, setShowSearchModal] = useState<boolean>(false);

  const { formatMessage } = useFormat({ name: 'common' });
  const { t } = useI18n();
  const { getTitle } = useImageSEO();

  const displayCategory = (category: PageFolderTree): boolean => category && !category?.configuration?.hideInNavigation;

  const [modalRef, setModalRef] = useState<Element | null>(null);

  useEffect(() => {
    if (!modalRef || !(showSecondLevel || showThirdLevel)) {
      return;
    }
    disableBodyScroll(modalRef, {
      allowTouchMove: (el: Element) => {
        return el.closest('.scrollable');
      },
    });
    return () => {
      enableBodyScroll(modalRef);
    };
  }, [modalRef]);

  useEffect(() => {
    setFirstLevelTree(tree.children);
  }, [tree]);

  return (
    <div className={styles.navigationWrapper} ref={setModalRef}>
      <div className={classNames({ [styles.isHidden]: showSecondLevel || showThirdLevel })}>
        <MobileSearch
          searchPlaceholder={
            search.searchPlaceholder ||
            formatMessage({
              id: 'navigation.search.placeholder',
              defaultMessage: 'Ihr Suchbegriff',
            })
          }
          popularCategoriesLabel={search.popularCategoriesLabel}
          popularCategories={search.popularCategories}
          topSellerLabel={search.topSellerLabel}
          topSellerWidgetId={search.topSellerWidgetId}
          topSellerWidgetConfiguration={search.topSellerWidgetConfiguration}
          suggestedProductsLabel={search.suggestedProductsLabel}
          suggestedProductsLimit={search.suggestedProductsLimit}
          suggestedCategoriesLabel={search.suggestedCategoriesLabel}
          suggestedCategoriesLimit={search.suggestedCategoriesLimit ?? search.suggestedProductsLimit}
          allResultsLabel={search.allResultsLabel}
          algoliaConfig={algoliaConfig}
          showSearchModal={showSearchModal}
          setShowSearchModal={setShowSearchModal}
          setShowModal={setShowModal}
        />
      </div>
      {firstLevelTree?.length ? (
        <>
          <ul
            className={classNames(styles.navigationFirstLevel, {
              [styles.isHidden]: showSecondLevel || showThirdLevel,
            })}
          >
            {firstLevelTree.map((item, index) => {
              if (!displayCategory(item)) {
                return null;
              }

              return (
                <NavigationListItem
                  key={`navigation-${level}-${index}`}
                  name={item.name}
                  url={t(item._urls)}
                  level={level + 2}
                  index={index}
                  hasChildren={!!item.children.length}
                  onClickEvent={() => {
                    setSecondLevelTree(item.children);
                    setSecondLevelLink({
                      name: item.name,
                      link: t(item._urls),
                    });
                    setShowSecondLevel(true);
                  }}
                  setShowModal={setShowModal}
                />
              );
            })}
          </ul>
          {!showSecondLevel && !showThirdLevel && !showSearchModal && (
            <MenuExtraLinks
              setShowModal={setShowModal}
              extraLinks={extraLinks}
              shopLinks={shopLinks}
              socialMediaLinks={socialMediaLinks}
            />
          )}
        </>
      ) : null}
      {secondLevelTree?.length ? (
        <ul
          className={classNames(styles.navigationSecondThirdLevel, {
            [styles.isActive]: showSecondLevel,
            [styles.isHidden]: showThirdLevel,
          })}
        >
          <div className={styles.navigationHeading}>
            <button
              className={classNames('toggle-modal', styles.navigationBackBtn)}
              onClick={() => setShowSecondLevel(false)}
            >
              <div className={styles.navigationBackIcon}>
                <IconCustom width={20} icon="ArrowThinLeft" />
              </div>
            </button>
            {secondLevelLink ? (
              <Link href={secondLevelLink.link} legacyBehavior={false} onClick={() => setShowModal(false)}>
                {secondLevelLink.name}
              </Link>
            ) : null}
          </div>
          {secondLevelLink ? (
            <NavigationListItem
              name={formatMessage({
                id: 'navigation.showAll',
                defaultMessage: 'Alles anzeigen',
              })}
              url={secondLevelLink.link}
              level={level + 2}
              index={1}
              isShowAll
              setShowModal={setShowModal}
            />
          ) : null}
          {secondLevelTree.map((item, index) => {
            if (!displayCategory(item)) {
              return null;
            }

            return (
              <NavigationListItem
                key={`navigation-${level}-${index}`}
                name={item.name}
                url={t(item._urls)}
                level={level + 2}
                index={index}
                hasChildren={!!item.children.length}
                onClickEvent={() => {
                  setThirdLevelTree(item.children);
                  setThirdLevelLink({
                    name: item.name,
                    link: t(item._urls),
                  });
                  setShowThirdLevel(true);
                }}
                setShowModal={setShowModal}
              />
            );
          })}
          <li>
            <button
              className={classNames('toggle-modal', styles.navigationBackBtn)}
              onClick={() => setShowSecondLevel(false)}
            >
              <div className={styles.navigationBackIcon}>
                <IconCustom width={20} icon="ArrowThinLeft" />
              </div>
              {formatMessage({
                id: 'navigation.back',
                defaultMessage: 'Zurück',
              })}
            </button>
          </li>
        </ul>
      ) : null}
      {thirdLevelTree?.length ? (
        <ul
          className={classNames(styles.navigationSecondThirdLevel, {
            [styles.isActive]: showThirdLevel,
          })}
        >
          <div className={styles.navigationHeading}>
            <button
              className={classNames('toggle-modal', styles.navigationBackBtn)}
              onClick={() => setShowThirdLevel(false)}
            >
              <div className={styles.navigationBackIcon}>
                <IconCustom width={20} icon="ArrowThinLeft" />
              </div>
            </button>
            {thirdLevelLink ? (
              <Link href={thirdLevelLink.link} legacyBehavior={false} onClick={() => setShowModal(false)}>
                {thirdLevelLink.name}
              </Link>
            ) : null}
          </div>
          {thirdLevelLink ? (
            <NavigationListItem
              name={formatMessage({
                id: 'navigation.showAll',
                defaultMessage: 'Alles anzeigen',
              })}
              url={thirdLevelLink.link}
              level={level + 2}
              index={2}
              isShowAll
              setShowModal={setShowModal}
            />
          ) : null}
          {thirdLevelTree.map((item, index) => {
            if (!displayCategory(item)) {
              return null;
            }

            return (
              <NavigationListItem
                key={`navigation-${level}-${index}`}
                name={item.name}
                url={t(item._urls)}
                level={level + 3}
                index={index}
                setShowModal={setShowModal}
              />
            );
          })}
          <li>
            <button
              className={classNames('toggle-modal', styles.navigationBackBtn)}
              onClick={() => setShowThirdLevel(false)}
            >
              <div className={styles.navigationBackIcon}>
                <IconCustom width={20} icon="ArrowThinLeft" />
              </div>
              {formatMessage({
                id: 'navigation.back',
                defaultMessage: 'Zurück',
              })}
            </button>

            <button
              className={classNames('toggle-modal', styles.navigationBackBtn, styles.hasBorderTop)}
              onClick={() => {
                setShowSecondLevel(false);
                setShowThirdLevel(false);
              }}
            >
              <div className={styles.navigationBackIcon}>
                {menuIcon?.media?.file ? (
                  <Image src={menuIcon.media.file} alt={getTitle(menuIcon)} title={getTitle(menuIcon)} width={20} />
                ) : (
                  <IconCustom width={20} icon="ArrowThinLeft" />
                )}
              </div>
              {formatMessage({
                id: 'navigation.backToMenu',
                defaultMessage: 'Zurück zum Hauptmenü',
              })}
            </button>
          </li>
        </ul>
      ) : null}
    </div>
  );
};

const NavigationListItem: React.FC<INavigationListItem> = ({
  name,
  url,
  level,
  index,
  hasChildren = false,
  onClickEvent = () => {},
  isShowAll = false,
  setShowModal,
}) => {
  const { projectId } = getProjectInfo();
  const isBlog = url === '/blog';

  if (isBlog) {
    url =
      projectId === 'en'
        ? 'https://www.engbers.com/blog/?utm_source=intern&utm_medium=verlinkung&utm_campaign=blog&utm_content=startseiten-menue'
        : 'https://www.engbers.com/blog/category/emilioadaniblog/?utm_source=intern&utm_medium=verlinkung&utm_campaign=blog&utm_content=startseiten-menue';
  }

  return (
    <li key={`navigation-${level}-${index}`} className={styles.navigationItem}>
      {hasChildren ? (
        <button onClick={onClickEvent}>
          {name}
          <IconCustom width={12} color={'primary'} icon="ChevronRightIcon" />
        </button>
      ) : (
        <Link
          href={url}
          legacyBehavior={false}
          onClick={() => setShowModal(false)}
          target={isBlog ? '_blank' : '_self'}
        >
          {name}
          {isShowAll ? <IconCustom width={12} color={'primary'} icon="ChevronRightIcon" /> : null}
        </Link>
      )}
    </li>
  );
};
