import React from 'react';
import { IconCustom, Markdown } from '@engbers/components';
import { getSKUParts } from '@frontastic-engbers/helpers/utils/getSKUParts';
import { LineItem } from '@frontastic-engbers/types/cart/LineItem';
import styles from './size-doubled-overview.module.scss';

export interface ISizeDoubledOverview {
  overviewDoubledSizeMessage: string;
  lineItems: LineItem[];
}

export const SizeDoubledOverview: React.FC<ISizeDoubledOverview> = ({ overviewDoubledSizeMessage, lineItems }) => {
  const skuArray = [];
  let doubled = false;

  if (lineItems) {
    for (const lineItem of lineItems) {
      const sku = getSKUParts(lineItem.variant.sku).key;
      const found = skuArray.find((x) => x.sku === sku);
      if (found) {
        doubled = true;
      }

      skuArray.push({
        sku,
      });
    }
  }

  if (!doubled) {
    return null;
  }

  return (
    <div className={styles.sizeInfoMessage}>
      <IconCustom width={20} icon="Info" color={'primary'} />
      <Markdown text={overviewDoubledSizeMessage} className={styles.markdown} linkToNewTab />
    </div>
  );
};
