import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { Listbox } from '@headlessui/react';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { useImageSEO } from '@frontastic-engbers/helpers/hooks/useImageSEO';
import { getProjectInfo } from '@frontastic-engbers/lib';
import { useAvailableCitiesAndAssortments, useGetStores } from '@frontastic-engbers/lib/actions/stores';
import { useToastNotificationsActions } from '@frontastic-engbers/lib/state/notification/actions';
import { MediaType } from '@frontastic-engbers/types/engbers-custom';
import { AvailableStoreCity } from '@frontastic-engbers/types/product/StoreAvailability';
import LocationResult from '@engbers/shop-backend/online-shop/models/LocationResult';
import { Button, IconCustom, InputText, Link } from '@engbers/components';
import Spinner from '@engbers/components/online-shops/commercetools-ui/spinner';
import { Map, useMap } from '@vis.gl/react-google-maps';
import { StoreMarker } from './store-marker';
import styles from './storefinder.module.scss';

interface IStoreFinder {
  mapId: string;
  labels: Record<string, string>;
  placeholder: Record<string, string>;
  assortments: Array<{
    assortmentId: string;
    icon: MediaType;
    name: string;
  }>;
  noResultsMessage: string;
  headline: string;
  fallbackImage: MediaType;
  appointmentLabel: string;
  appointmentBtnColor: string;
  locationLabel: string;
  storeClosedHint: string;
}

export const Storefinder: React.FC<IStoreFinder> = ({
  mapId,
  labels,
  placeholder,
  assortments,
  noResultsMessage,
  headline,
  fallbackImage,
  appointmentLabel,
  appointmentBtnColor,
  locationLabel,
  storeClosedHint,
}) => {
  const router = useRouter();
  const searchQuery = (router.query.search as string) ?? '';
  const cityQuery = (router.query.city as string) ?? '';
  const { formatMessage } = useFormat({ name: 'common' });
  const { getTitle } = useImageSEO();
  const map = useMap();
  const storeListRef = useRef(null);
  const { pushNotification, removeNotification } = useToastNotificationsActions();
  const projectInfo = getProjectInfo();
  const defaultAssortment = projectInfo?.projectId === 'en' ? ['engbers'] : ['emilio adani'];

  const [queryState, setQueryState] = useState({
    search: searchQuery,
    city: cityQuery,
    assortment: defaultAssortment,
  });
  const [searchValue, setSearchValue] = useState<string>(searchQuery);
  const [selectedAssortments, setSelectedAssortments] = useState<string[]>(defaultAssortment);
  const [activeMarker, setActiveMarker] = useState<string>('');
  const [notificationId, setNotificationId] = useState<string>();
  const [isOverflowing, setIsOverflowing] = useState<boolean>(false);
  const [multiSelectOpen, setMultiSelectOpen] = useState<boolean>(false);

  const { data: options, isLoading: areOptionsLoading } = useAvailableCitiesAndAssortments(
    'storeFinder',
    queryState.assortment,
  );

  const { data: results, isLoading } = useGetStores(queryState, 'storeFinder', queryState.assortment);

  const hideMobileKeyboardOnSearch = (element: HTMLFormElement) => {
    const input: HTMLInputElement = element?.querySelector('input[type="search"]');
    input?.blur();
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setActiveMarker('');

    if (e.target instanceof HTMLSelectElement) {
      setSearchValue('');
    }

    if (e.target instanceof HTMLFormElement) {
      hideMobileKeyboardOnSearch(e.target);
    }

    setQueryState(({ assortment }) => ({
      search: '',
      city: '',
      assortment,
      [e.target.name]: e.target.value,
    }));

    replaceUrl({ [e.target.name]: e.target.value });
  };

  const handleSearchSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    setActiveMarker('');
    hideMobileKeyboardOnSearch(e.target);

    setQueryState(({ assortment }) => ({
      search: searchValue,
      city: '',
      assortment,
    }));

    replaceUrl({ search: searchValue });
  };

  const replaceUrl = (query: { search?: string; city?: string }) => {
    if (query.search && !query.search.length) {
      router.push(`${router.asPath.split('?')[0]}`);
      return;
    }

    router.push(
      {
        pathname: router.asPath.split('?')[0],
        query,
      },
      undefined,
      { shallow: true },
    );
  };

  const handleAssortmentFilter = () => {
    setMultiSelectOpen(false);

    if (!assortmentsChanged) {
      return;
    }

    setActiveMarker('');

    setQueryState((prev) => ({
      ...prev,
      assortment: selectedAssortments,
    }));
  };

  const assortmentsChanged = useMemo(() => {
    return !(
      selectedAssortments.every((assortment) => queryState.assortment.includes(assortment)) &&
      queryState.assortment.every((assortment) => selectedAssortments.includes(assortment))
    );
  }, [queryState.assortment, selectedAssortments]);

  const setActive = (storeId: string, position?: { lat: number; lng: number }) => {
    setActiveMarker(storeId);

    if (position) {
      map.setCenter(position);
    }
  };

  useEffect(() => {
    if (storeListRef?.current) {
      const el = storeListRef.current;
      const isElementOverflowing = el.scrollHeight > el.clientHeight;
      setIsOverflowing(isElementOverflowing);
    }
  }, [results]);

  useEffect(() => {
    removeNotification(notificationId);
    setNotificationId('');

    if (!isLoading) {
      if (!results?.length) {
        const notificationId = pushNotification(noResultsMessage, 'error');
        setNotificationId(notificationId);
      }

      if (results?.length === 1) {
        setActiveMarker(results[0].id);
      }
    }
  }, [results]);

  useEffect(() => {
    if (!map || isLoading) {
      return;
    }

    // eslint-disable-next-line no-undef
    const bounds = new google.maps.LatLngBounds();

    if (bounds && results?.length) {
      results.forEach(({ latitude, longitude }) => {
        bounds.extend({
          lat: latitude,
          lng: longitude,
        });
      });

      map.fitBounds(bounds);
      map.panToBounds(bounds);
      map.setCenter(bounds.getCenter());
    }
  }, [map, results]);

  return (
    <>
      <div className={styles.formWrap}>
        <div className={styles.selectWrap}>
          <div className={styles.heading}>{labels.search}</div>
          <form className={styles.searchWrap} action="" onSubmit={handleSearchSubmit}>
            <InputText
              name="search"
              type="search"
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder={placeholder.search}
              value={searchValue}
              onEnter={handleSearch}
              onBlur={(event) => event.target.value === '' && handleSearch(event)}
              inputCustomStyle={{ padding: '20px 12px 6px' }}
              wrapperCustomStyle={{ width: '100%' }}
            />
            <button type="submit" className={styles.searchButton}>
              <IconCustom icon="Search" width={18} color="white" />
              <span className="sr-only">
                {formatMessage({
                  id: 'search',
                  defaultMessage: 'Suchen',
                })}
              </span>
            </button>
          </form>
        </div>
        {options?.cities?.length > 0 && (
          <>
            <div className={styles.separateSection}>
              {formatMessage({
                id: 'or',
                defaultMessage: 'oder',
              })}
            </div>
            <div className={styles.selectWrap}>
              <div className={styles.heading}>{labels.select}</div>
              <select name="city" value={queryState.city} onChange={handleSearch} className={styles.citySelect}>
                <option key="empty" value="">
                  {placeholder.select}
                </option>
                {options.cities.map((city: AvailableStoreCity) => (
                  <option key={city.zip + city.city} value={city.city}>
                    {city.city} ({city.country})
                  </option>
                ))}
              </select>
            </div>
          </>
        )}
        {projectInfo?.projectId === 'en' && options?.assortments?.length > 0 && (
          <div className={styles.selectWrap}>
            <div className={styles.heading}>{labels.assortment}</div>
            <Listbox value={selectedAssortments} onChange={setSelectedAssortments} multiple>
              <div className={styles.selectWrap}>
                <Listbox.Button className={styles.select} onClick={() => setMultiSelectOpen(true)}>
                  {placeholder.assortment}
                </Listbox.Button>
                <Listbox.Options
                  className={styles.selectOptions}
                  unmount={false}
                  style={{ display: multiSelectOpen ? 'block' : 'none' }}
                >
                  {assortments.map((assortment) => {
                    if (options.assortments.includes(assortment.name)) {
                      const isEngbers = assortment.assortmentId === 'engbers';

                      return (
                        <Listbox.Option
                          key={assortment.assortmentId}
                          value={assortment.assortmentId}
                          disabled={isEngbers}
                          className={styles.multiSelectOption}
                          defaultChecked={selectedAssortments.includes(assortment.assortmentId)}
                        >
                          <div
                            id={assortment.assortmentId}
                            className={classNames(styles.checkbox, { [styles.checkboxDisabled]: isEngbers })}
                          />
                          <div className={styles.label}>
                            {assortment.icon?.media?.file && (
                              <Image
                                src={assortment.icon?.media?.file}
                                title={assortment.name}
                                alt={getTitle(assortment.icon)}
                                width={40}
                                height={40}
                                loader={({ src }) => src}
                              />
                            )}
                            <span className={styles.assortment}>{assortment.name}</span>
                          </div>
                        </Listbox.Option>
                      );
                    }
                  })}
                  <Button
                    label={
                      assortmentsChanged
                        ? formatMessage({
                            id: 'apply',
                            defaultMessage: 'Übernehmen',
                          })
                        : formatMessage({
                            id: 'close',
                            defaultMessage: 'Schließen',
                          })
                    }
                    hasIcon={false}
                    size="large"
                    className={styles.applyAssortments}
                    onClick={handleAssortmentFilter}
                  />
                </Listbox.Options>
              </div>
            </Listbox>
          </div>
        )}
      </div>
      <div className={styles.storeFinder}>
        <div className={styles.mapWrapper}>
          {(isLoading || areOptionsLoading) && (
            <div className={styles.spinnerWrap}>
              <Spinner />
            </div>
          )}
          <Map
            mapId={mapId}
            defaultZoom={5}
            defaultCenter={{
              lat: 51.16,
              lng: 10.45,
            }}
            disableDefaultUI
            clickableIcons={false}
          >
            {results?.map((store: LocationResult) => (
              <StoreMarker
                key={store.id}
                store={store}
                active={activeMarker === store.id}
                setActive={setActive}
                assortments={assortments}
                fallbackImage={fallbackImage}
                appointmentLabel={appointmentLabel}
                appointmentBtnColor={appointmentBtnColor}
                locationLabel={locationLabel}
                storeClosedHint={storeClosedHint}
              />
            ))}
          </Map>
        </div>
        <div className={styles.results}>
          <h3 className="mb-4 text-base uppercase">{headline}</h3>
          <div className={styles.storeListWrap}>
            <ul className={styles.storeList} ref={storeListRef}>
              {results?.map((store: LocationResult) => (
                <li
                  key={store.id}
                  className={styles.storeInfo}
                  onClick={() =>
                    setActive(store.id, {
                      lat: store.latitude,
                      lng: store.longitude,
                    })
                  }
                >
                  <span>{store.name}</span>
                  {store.addInfo?.length > 0 && <span>{store.addInfo}</span>}
                  <span>
                    {store.street} {store.streetNumber}
                  </span>
                  <strong>
                    {store.zip} {store.city}
                  </strong>
                  {store.isClosed && <span className="text-danger">{storeClosedHint}</span>}
                  {store.bookingLink?.length > 0 && (
                    <span
                      style={
                        {
                          '--storeBookingLinkBgColo': appointmentBtnColor,
                        } as React.CSSProperties
                      }
                    >
                      {store.isClosed ? (
                        <Button
                          href={store.bookingLink}
                          target="_blank"
                          label={appointmentLabel}
                          color={appointmentBtnColor}
                          size="large"
                          customStyle={{ marginTop: '16px' }}
                          disabled={store.isClosed}
                          hasIcon={false}
                        />
                      ) : (
                        <Link
                          href={store.bookingLink}
                          openInNewWindow
                          className={classNames('btn', styles.storeBookingLink)}
                        >
                          {appointmentLabel}
                        </Link>
                      )}
                    </span>
                  )}
                </li>
              ))}
            </ul>
            {isOverflowing && <div className={styles.overflowShadow}></div>}
          </div>
        </div>
      </div>
    </>
  );
};
