import React, { useEffect, useState } from 'react';
import { useSwiper } from 'swiper/react';
import { MediaType } from '@frontastic-engbers/types/engbers-custom';
import { useImageSEO } from '@frontastic-engbers/helpers/hooks/useImageSEO';
import classNames from 'classnames';
import styles from './image-slider-nav-button.module.scss';

export interface IImageSliderNavButtons {
  arrowIcon: MediaType;
  numberOfSlides: number;
  autoPlayDelay: number;
}

export const ImageSliderNavButtons: React.FC<IImageSliderNavButtons> = ({
  arrowIcon,
  numberOfSlides,
  autoPlayDelay,
}) => {
  const { getTitle } = useImageSEO();
  const swiper = useSwiper();
  const [numberOfCurrentSlide, setNumberOfCurrentSlide] = useState(1);

  const sliderPrev = () => {
    setNumberOfCurrentSlide(numberOfCurrentSlide - 1);
    swiper.slidePrev();
  };

  const sliderNext = () => {
    setNumberOfCurrentSlide(numberOfCurrentSlide + 1);
    swiper.slideNext();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (numberOfCurrentSlide === numberOfSlides) {
        setNumberOfCurrentSlide(1);
        swiper.slideTo(0);
      } else {
        sliderNext();
      }
    }, autoPlayDelay * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [numberOfCurrentSlide, numberOfSlides, autoPlayDelay]);

  return (
    <div className={styles.sliderBtnWrap}>
      <div className={classNames(styles.sliderBtn, styles.sliderBtnPrev)}>
        {numberOfCurrentSlide !== 1 && (
          <button onClick={sliderPrev}>
            <img src={arrowIcon.media.file} alt={getTitle(arrowIcon)} title={getTitle(arrowIcon)} />
          </button>
        )}
      </div>
      <div className={classNames(styles.sliderBtn, styles.sliderBtnNext)}>
        {numberOfCurrentSlide !== numberOfSlides && (
          <button onClick={sliderNext}>
            <img src={arrowIcon.media.file} alt={getTitle(arrowIcon)} title={getTitle(arrowIcon)} />
          </button>
        )}
      </div>
    </div>
  );
};
