import React from 'react';
import classnames from 'classnames';
import Link from 'next/link';
import useI18n from '@frontastic-engbers/helpers/hooks/useI18n';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { Product } from '@frontastic-engbers/types/product/Product';
import { Image } from '@engbers/components';
import { MediaType } from '@frontastic-engbers/types/engbers-custom';
import styles from './pdp-colors.module.scss';

export interface IPdpColors {
  product: Product;
  colorVariants: {
    id: string;
    obj: any;
    type: string;
  }[];
  className: string;
  fallbackImage?: MediaType;
}

export interface IColorVariantElement {
  url: string;
  image: string;
  name: string;
  displayedOnMobile: boolean;
  isActive?: boolean;
}

export const PdpColors: React.FC<IPdpColors> = ({ product, colorVariants, className, fallbackImage }) => {
  const { t } = useI18n(true);
  const { formatMessage } = useFormat({ name: 'product' });
  const displayedOnMobile = className?.includes('md:hidden');
  const { DesignerName, ColorName } = product.attributes;

  if (!product.variants[0].images[0] && (!colorVariants?.length || colorVariants.some((variant) => !variant.obj))) {
    return null;
  }

  return (
    <div className={classnames(className, styles.productColors)}>
      <div className={styles.colorName}>
        <span>
          {formatMessage({
            id: 'color',
            defaultMessage: 'Farbe',
          })}
          :
        </span>
        <strong>{ColorName}</strong>
      </div>
      <ul className={styles.colorVariantsWrap}>
        <ColorVariantElement
          url={product.url}
          image={product.variants[0].images[0] || fallbackImage?.media?.file}
          name={`${DesignerName} ${product.name} ${ColorName}`}
          displayedOnMobile={displayedOnMobile}
          isActive
        />

        {colorVariants?.map((variant) => {
          const slug = variant.obj?.masterData?.current?.slug;
          const image = variant.obj?.masterData?.current?.masterVariant?.images[0]?.url || fallbackImage?.media?.file;
          const name = variant.obj?.masterData?.current?.name;
          const variantColorName = variant.obj?.masterData?.current?.masterVariant?.attributes.find(
            (obj) => obj.name === 'ColorName',
          )?.value;

          return (
            <ColorVariantElement
              key={t(slug)}
              url={t(slug)}
              image={image}
              name={`${DesignerName} ${t(name)} ${variantColorName}`}
              displayedOnMobile={displayedOnMobile}
            />
          );
        })}
      </ul>
    </div>
  );
};

const ColorVariantElement: React.FC<IColorVariantElement> = ({ url, image, name, displayedOnMobile, isActive }) => (
  <li className={classnames(styles.colorVariant, isActive ? styles.activeColorVariant : undefined)}>
    <Link href={url}>
      <a>
        <Image src={image} alt={name} title={name} width={displayedOnMobile ? 70 : 50} />
      </a>
    </Link>
  </li>
);
