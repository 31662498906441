import React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { ResetPasswordForm } from '@engbers/components/reset-password-form';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { FullPageWidthWrapper } from '@engbers/components/online-shops';
import { IFTLinkReference, IFTPageFolderReference } from '@frontastic-engbers/types/engbers-custom';

interface IResetPasswordData {
  resetPasswordTitle?: string;
  textSize?: number;
  resetPasswordSubheading?: string;
  resetButtonLabel?: string;
  isFullWidth?: boolean;
  confirmationRedirectLink?: IFTLinkReference | IFTPageFolderReference;
}

interface IResetPasswordTasticComponent {
  data: IResetPasswordData;
}

export const ResetPasswordTastic: React.FC<IResetPasswordTasticComponent> = ({ data }) => {
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const router = useRouter();
  const { token } = router.query;

  const resetPasswordForm = (
    <>
      <Head>
        <title>
          {data.resetPasswordTitle ?? formatAccountMessage({
            id: 'password.reset',
            defaultMessage: 'Reset password'
          })}
        </title>
      </Head>
      <ResetPasswordForm
        token={Array.isArray(token) ? token.pop() : token}
        title={data.resetPasswordTitle}
        textSize={data.textSize}
        subHeading={data.resetPasswordSubheading}
        resetButtonLabel={data.resetButtonLabel}
        redirectLink={data.confirmationRedirectLink}
      />
    </>
  );

  if (data.isFullWidth) {
    return <FullPageWidthWrapper>{resetPasswordForm}</FullPageWidthWrapper>;
  }

  return resetPasswordForm;
};
