import React, { useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import classnames from 'classnames';
import { Link, Video } from '@engbers/components';
import { useImageSEO } from '@frontastic-engbers/helpers/hooks/useImageSEO';
import { ProductListMedia } from '@frontastic-engbers/types/engbers-custom';
import styles from './media-tile.module.scss';

type Props = {
  mediaConfig: ProductListMedia;
  isFirstPage?: boolean;
  outfitSliderRowNumber?: number;
};

export const MediaTile: React.FC<Props> = ({ mediaConfig, isFirstPage, outfitSliderRowNumber }) => {
  const [isMinWidthMedium, setIsMinWidthMedium] = useState<boolean>(false);
  const [isMinWidthSmall, setIsMinWidthSmall] = useState<boolean>(false);
  const isMobile = useMediaQuery({ maxWidth: 1023 });
  const isSmallMobile = useMediaQuery({ maxWidth: 767 });
  const { getTitle } = useImageSEO();
  const { source, reference, sizeTwoTiles, videoAutoplay, videoLoop, videoMuted, videoControls } = mediaConfig;

  useEffect(() => {
    if (isMobile !== isMinWidthMedium) {
      setIsMinWidthMedium(isMobile);
    }
  }, [isMobile]);

  useEffect(() => {
    if (isSmallMobile !== isMinWidthSmall) {
      setIsMinWidthSmall(isSmallMobile);
    }
  }, [isSmallMobile]);

  const aspectRatio = useMemo(
    () => (source?.media ? +source.media.height / +source.media.width : undefined),
    [source.media],
  );

  const { rowStart, rowEnd } = useMemo((): { rowStart: number; rowEnd: number } => {
    let rowStartEnd = isFirstPage && !isMinWidthMedium ? 2 : 3;

    if (outfitSliderRowNumber === rowStartEnd) {
      rowStartEnd = rowStartEnd + 1;
    }

    return {
      rowStart: rowStartEnd,
      rowEnd: rowStartEnd + 1,
    };
  }, [isFirstPage, isMinWidthMedium, outfitSliderRowNumber]);

  if (!source.media) {
    return null;
  }

  const media =
    source.media.resourceType === 'video' ? (
      <Video
        media={source}
        autoPlay={videoAutoplay}
        loop={videoLoop}
        muted={videoMuted || videoAutoplay}
        controls={videoControls}
      />
    ) : (
      <img src={source.media.file} alt={getTitle(source)} title={getTitle(source)} />
    );

  return (
    <div
      className={classnames(styles.mediaTile)}
      style={
        {
          '--aspect-ratio':
            isMinWidthSmall && source.media.resourceType === 'video' && sizeTwoTiles ? aspectRatio : 'auto',
          '--tile-width': sizeTwoTiles ? 2 : 1,
          '--row-start': rowStart,
          '--row-end': rowEnd,
        } as React.CSSProperties
      }
    >
      {reference &&
      ((reference.type === 'link' && reference.link) || (reference.type === 'page-folder' && reference.pageFolder)) ? (
            <Link reference={reference}>{media}</Link>
          ) : (
            media
          )}
    </div>
  );
};
