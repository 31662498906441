import React from 'react';
import classNames from 'classnames';
import { PasswordCheckListHelper } from '@frontastic-engbers/helpers/passwordCheckListHelper';
import styles from './password-check-list.module.scss';

type PasswordCheckListType = {
  rules: ["minLength", "lowercase", "capital", "number", "specialChar"];
  minLength: number;
  value: string;
}

export const PasswordCheckList: React.FC<PasswordCheckListType> = ({ rules, minLength, value }) => {
  const ruleDefinitions = PasswordCheckListHelper.ruleDefinitions(value, minLength);

  return (
    <div>
      {rules.includes('minLength') && (
        <div className={styles.criteria}>
          <span className={classNames(styles.criteriaValid, !ruleDefinitions.minLength.valid ? styles.criteriaInvalid : undefined)}></span>
          {ruleDefinitions.minLength.message}
        </div>
      )}
      {rules.includes('lowercase') && (
        <div className={styles.criteria}>
          <span className={classNames(styles.criteriaValid, !ruleDefinitions.lowercase.valid ? styles.criteriaInvalid : undefined)}></span>
          {ruleDefinitions.lowercase.message}
        </div>
      )}
      {rules.includes('capital') && (
        <div className={styles.criteria}>
          <span className={classNames(styles.criteriaValid, !ruleDefinitions.capital.valid ? styles.criteriaInvalid : undefined)}></span>
          {ruleDefinitions.capital.message}
        </div>
      )}
      {rules.includes('number') && (
        <div className={styles.criteria}>
          <span className={classNames(styles.criteriaValid, !ruleDefinitions.number.valid ? styles.criteriaInvalid : undefined)}></span>
          {ruleDefinitions.number.message}
        </div>
      )}
      {rules.includes('specialChar') && (
        <div className={styles.criteria}>
          <span className={classNames(styles.criteriaValid, !ruleDefinitions.specialChar.valid ? styles.criteriaInvalid : undefined)}></span>
          {ruleDefinitions.specialChar.message}
        </div>
      )}
    </div>
  );
};
