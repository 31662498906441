import React from 'react';
import { useImageSEO } from '@frontastic-engbers/helpers/hooks/useImageSEO';
import { MediaType } from '@frontastic-engbers/types/engbers-custom';
import styles from '../my-account.module.scss';

interface IconsType {
  icons: IconType[];
}

interface IconType {
  icon: MediaType;
  iconText: string;
}

export const MyAccountIcons: React.FC<IconsType> = ({ icons }) => {
  const { getTitle } = useImageSEO();

  return icons && icons.length ? (
    <div className={styles.accountFooterIconsWrapper}>
      {icons.map((item, index) => (
        <div key={`my-account-icon-${index}`} className={styles.accountFooterIcon}>
          <img src={item.icon.media.file} alt={getTitle(item.icon)} title={getTitle(item.icon)} />
          <span>{item.iconText}</span>
        </div>
      ))}
    </div>
  ) : null;
};
