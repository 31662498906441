import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { MediaType } from '@frontastic-engbers/types/engbers-custom';
import { useImageSEO } from '@frontastic-engbers/helpers/hooks/useImageSEO';
import { IPdpUsps } from '../index';
import styles from './pdp-shop-usp.module.scss';

export interface IPdpShopUsps {
  usp: IPdpUsps;
  headline: string;
  text: string;
  fallbackIcon: MediaType;
}

export const PdpShopUsps: React.FC<IPdpShopUsps> = ({ usp, headline, text, fallbackIcon }) => {
  const { getTitle } = useImageSEO();
  const [isMinWidthMedium, setIsMinWidthMedium] = useState<boolean>(false);
  const isMobile = useMediaQuery({ maxWidth: 1024 });

  const imgUrl = usp.image?.media.file ?? fallbackIcon.media.file;
  const imgWidth = usp['icon-width'] ?? fallbackIcon['icon-width'];

  useEffect(() => {
    if (isMobile !== isMinWidthMedium) {
      setIsMinWidthMedium(isMobile);
    }
  }, [isMobile]);

  return !isMinWidthMedium ? (
    <li className={styles.shopUspContainer}>
      <img
        className={styles.shopUspImg}
        width={imgWidth}
        src={imgUrl}
        alt={getTitle(usp.image?.media ? usp.image : fallbackIcon)}
        title={getTitle(usp.image?.media ? usp.image : fallbackIcon)}
      />
      <strong className={styles.shopUspHeadline}>{headline}</strong>
      <span className={styles.shopUspText}>{text}</span>
    </li>
  ) : null;
};
