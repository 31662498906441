import { NewsletterSignupItems } from '@engbers/components/my-account/my-newsletter/mobileNewsletter/newsletter-signup-items';
import { SectionLayout } from '@engbers/components';
import { NewsletterConfig } from '@frontastic-engbers/types/newsletter/types';

export const NewsletterItemsTastic = ({ data }) => {
  return (
    <SectionLayout>
      <NewsletterSignupItems
        newsletters={(data.newsletter.dataSource as NewsletterConfig[]) ?? []}
        labels={{
          headline: data.headline,
          textSize: data.textSize,
          subscribeRadioLabel: data.subscribeRadioLabel,
          modalFrequencyDescription: data.modalFrequencyDescription,
          modalFrequencyHeadline: data.modalFrequencyHeadline,
          modalFrequencyButtonConfirmLabel: data.modalFrequencyButtonConfirmLabel,
          modalFrequencyButtonCancelLabel: data.modalFrequencyButtonCancelLabel,
          modalFrequencyButtonWeeklyLabel: data.modalFrequencyButtonWeeklyLabel,
          modalFrequencyButtonMonthlyLabel: data.modalFrequencyButtonMonthlyLabel,
          modalFrequencyButtonUnsubscribeLabel: data.modalFrequencyButtonUnsubscribeLabel,
          modalApproveHeadline: data.modalApproveHeadline,
          modalApproveDescription: data.modalApproveDescription,
          modalApproveButtonLabel: data.modalApproveButtonLabel,
          subscriptionSuccessMessage: data.myNewsletterSubscriptionSuccessMessage,
          subscriptionFailureMessage: data.myNewsletterSubscriptionFailureMessage,
        }}
      />
    </SectionLayout>
  );
};
