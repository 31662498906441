import React from 'react';
import { SectionLayout } from '@engbers/components';
import { CreditVoucher } from '@engbers/components/online-shops/credit-voucher';

interface ICreditVouchersTasticData {
  placeholderText?: string;
  labelYourCredit?: string;
}

interface ICreditVouchersTasticComponent {
  data: ICreditVouchersTasticData;
}

export const CreditVouchersTastic: React.FC<ICreditVouchersTasticComponent> = ({ data }) => {
  return (
    <SectionLayout>
      <CreditVoucher placeholderText={data.placeholderText} labelYourCredit={data.labelYourCredit} />
    </SectionLayout>
  );
};
