import classNames from "classnames";
import styles from "./infobox.module.scss";
import {
    Reference,
    ReferenceLink,
} from "@frontastic-engbers/helpers/reference";
import { ReactNode } from "react";

export interface IInfoBox {
  reference?: Reference;
  content: string;
  type?: 'default' | 'success' | 'os';
}

export const InfoBox: React.FC<IInfoBox> = ({ reference, content, type }) => {
  const children: ReactNode = (
    <div
      className={classNames(styles.infobox, {
        [styles[`infobox--${type}`]]: type,
      })}
    >
      {content}
    </div>
  );
  return <>{reference ? <ReferenceLink target={reference}>{children}</ReferenceLink> : children}</>;
};
