import { ReactNode, useEffect, useState } from 'react';
import classnames from 'classnames';
import { IconCustom } from '../icon-custom';
import Spinner from '../online-shops/commercetools-ui/spinner';
import styles from './custom-dropdown.module.scss';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';

export interface ICustomDropdown {
  className?: string;
  items: {
    label: string | ReactNode;
    value: string;
    disabled?: boolean;
  }[];
  placeholder?: string | ReactNode;
  onClick?: (selectedValue: string) => void;
  defaultValue?: string;
  optionClassName?: string;
  isFlyoutContent?: boolean;
  closeOnBackdrop?: boolean;
  isLoading?: boolean;
  isSizeDropdown?: boolean;
}

export const CustomDropdown: React.FC<ICustomDropdown> = ({
  className,
  items,
  placeholder,
  onClick,
  defaultValue,
  optionClassName,
  isFlyoutContent = false,
  closeOnBackdrop = false,
  isLoading = false,
  isSizeDropdown = false,
}) => {
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<string | ReactNode>(defaultValue);

  useEffect(() => {
    if (defaultValue) {
      setSelectedValue(defaultValue);
    }
  }, [defaultValue]);

  return (
    <>
      {isDropdownOpen && closeOnBackdrop && (
        <div className={styles.backDrop} onClick={() => setIsDropdownOpen(false)} />
      )}
      <div className={classnames(className, styles.customDropdown, isDropdownOpen ? styles.isDropdownOpen : undefined)}>
        <button className={styles.customDropdownToggleBtn} onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
          <div>{selectedValue ?? placeholder}</div>
          {!isLoading ? (
            <IconCustom
              icon="ChevronRightIcon"
              color="primary"
              width={12}
              className={classnames(
                styles.customDropdownIcon,
                isDropdownOpen ? styles.customDropdownIconRotate : undefined,
              )}
            />
          ) : (
            <Spinner size="x-small" />
          )}
        </button>
        <div
          className={classnames(
            styles.customDropdownContent,
            isDropdownOpen ? styles.isCustomDropdownOpen : undefined,
            isDropdownOpen && isFlyoutContent ? styles.customDropdownContentFlyout : undefined,
          )}
        >
          {items.map(({ label, value, disabled }, index) => (
            <button
              key={`${value}-${index}`}
              type="button"
              value={value}
              className={classnames(
                styles.customDropdownOptionBtn,
                optionClassName,
                disabled ? styles.customDropdownOptionBtnDisabled : undefined,
                defaultValue === label ? styles.customDropdownOptionBtnSelected : undefined,
              )}
              disabled={disabled}
              onClick={() => {
                setSelectedValue(label);
                setIsDropdownOpen(false);
                onClick(value);
              }}
            >
              {disabled && isSizeDropdown ? (
                <div className="inline-flex">
                  {label}&nbsp;
                  {formatProductMessage({
                    id: 'soldOut',
                    defaultMessage: 'Sold Out ',
                  })}
                </div>
              ) : (
                label
              )}
            </button>
          ))}
        </div>
      </div>
    </>
  );
};
