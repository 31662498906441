import React from 'react';
import useI18n from '@frontastic-engbers/helpers/hooks/useI18n';
import { useImageSEO } from '@frontastic-engbers/helpers/hooks/useImageSEO';
import { MediaType } from '@frontastic-engbers/types/engbers-custom';
import { useAccountBalance } from '@engbers/components/account-balance';
import { Block, CustomMarkdown, Image, LoadingIndicatorInline, Markdown, MyAccountHeadline } from '@engbers/components';
import styles from './my-engbers-card.module.scss';

interface ICard {
  cardId: string;
  data: CardData;
}

interface CardData {
  cardHeadline: string;
  textSize?: number;
  cardHeadlineIcon: MediaType;
  cardAccountBalanceHeadline: string;
  accountBalanceTextSize?: number;
  cardAccountBalanceImage: MediaType;
  cardAccountBalanceText: string;
}

export const MyAccountEngbersCard: React.FC<ICard> = ({ cardId, data }) => {
  const { t } = useI18n();
  const { getTitle } = useImageSEO();
  const { loaded, balance } = useAccountBalance();
  const {
    cardHeadline,
    textSize,
    cardHeadlineIcon,
    cardAccountBalanceHeadline,
    accountBalanceTextSize,
    cardAccountBalanceImage,
    cardAccountBalanceText,
  } = data;

  return (
    <div>
      <Block marginBottom={8}>
        <MyAccountHeadline
          headlineText={`${cardHeadline} ${cardId}`}
          textSize={textSize}
          iconCustom="CardsOpen"
          iconMedia={cardHeadlineIcon}
        />
      </Block>
      <div className={styles.accountBalanceWrapper}>
        <div className={styles.accountBalanceHeadline}>
          <Markdown text={cardAccountBalanceHeadline} textSize={accountBalanceTextSize} disableMargin />
          {!loaded ? <LoadingIndicatorInline color="blue" /> : <span className={styles.balance}>{balance} Euro</span>}
        </div>
        <div className={styles.accountBalanceImage}>
          <Image
            src={cardAccountBalanceImage.media.file}
            alt={getTitle(cardAccountBalanceImage)}
            title={getTitle(cardAccountBalanceImage)}
          />
        </div>
      </div>
      <Block marginBottom={8}>
        <CustomMarkdown text={cardAccountBalanceText} />
      </Block>
    </div>
  );
};
