import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { IFTLinkReference, IFTPageFolderReference } from '@frontastic-engbers/types/engbers-custom';
import { getReferenceTarget } from '@frontastic-engbers/helpers/reference';
import { IconCustom, InputText, Markdown } from '@engbers/components';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import styles from './to-the-newsletter.module.scss';

interface DataType {
  data: {
    headline: string;
    textSize?: number;
    voucherValue: number;
    bgColor: string;
    textColor: string;
    placeholder: string;
    reference: IFTLinkReference | IFTPageFolderReference;
  };
}

export const ToTheNewsletter: React.FC<DataType> = ({ data }) => {
  const { formatMessage } = useFormat({ name: 'cart' });
  const router = useRouter();
  const [email, setEmail] = useState<string>('');

  const handleRedirect = async () => {
    await router.push({
      pathname: getReferenceTarget(data.reference),
      query: {
        ...(email && { email }),
      },
    });
    setEmail('');
  };

  return (
    <div className={styles.wrap}>
      <div
        className={styles.newsletterHeadline}
        style={{
          color: data.textColor,
          backgroundColor: data.bgColor,
        }}
      >
        <Markdown text={data.headline} textSize={data.textSize} disableMargin />
        {data.voucherValue > 0 && (
          <div className={styles.newsletterHeadlineVoucher}>
            <h1>{data.voucherValue}€</h1>
            <span>{formatMessage({ id: 'voucher' })}</span>
          </div>
        )}
      </div>

      <div className={styles.newsletterEmailWrapper}>
        <div className={styles.newsletterEmailInput}>
          <InputText
            name="email"
            type="email"
            value={email}
            placeholder={data.placeholder}
            onChange={(e) => setEmail(e.target.value)}
            onEnter={handleRedirect}
          />
        </div>
        <button className={styles.newsletterEmailButton} onClick={handleRedirect}>
          <IconCustom width={20} color="white" icon="ChevronRightIcon" />
        </button>
      </div>
    </div>
  );
};
