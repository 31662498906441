import { HeaderSecondLevel } from '@engbers/components/header-second-level';
import { FullPageWidthWrapper } from '@engbers/components/online-shops';

export const HeaderSecondLevelTastic = ({ data }) => {
  if (data.isFullWidth) {
    return (
      <FullPageWidthWrapper>
        <HeaderSecondLevel
          link={data.backLink}
          bgColor={data.bgColor}
          textColor={data.textColor}
          icon={data.icon}
          title={data.title}
          textSize={data.textSize}
          isWishlist={data.isWishlist}
        />
      </FullPageWidthWrapper>
    );
  }

  return (
    <div>
      <HeaderSecondLevel
        link={data.backLink}
        bgColor={data.bgColor}
        textColor={data.textColor}
        icon={data.icon}
        title={data.title}
        textSize={data.textSize}
        isWishlist={data.isWishlist}
      />
    </div>
  );
};
