import { useAccount } from '@frontastic-engbers/lib';
import { MediaType } from '@frontastic-engbers/types/engbers-custom';
import { Newsletter } from '@frontastic-engbers/types/newsletter/types';
import { Block, MyAccountHeadline } from '@engbers/components';
import { AccountMyNewsletterBenefits } from './components/my-newsletter-benefits';
import { AccountMyNewsletterSubscription } from './components/my-newsletter-subscription';
import styles from './my-newsletter.module.scss';

type AccountMyNewsletterProps = {
  headline?: string;
  textSize?: number;
  subHeadline?: string;
  icon?: MediaType;
  benefitsTitle?: string;
  benefitsColor?: string;
  benefits?: string[];
  subscriptionInfoText?: string;
  subscriptionCtaText?: string;
  subscriptionSuccessMessage?: string;
  subscriptionFailureMessage?: string;
  newsletters?: Newsletter[];
};

export const AccountMyNewsletter: React.FC<AccountMyNewsletterProps> = ({
  headline,
  textSize,
  subHeadline,
  icon,
  benefitsTitle,
  benefitsColor,
  benefits,
  subscriptionInfoText,
  subscriptionCtaText,
  subscriptionSuccessMessage,
  subscriptionFailureMessage,
  newsletters,
}) => {
  const { account } = useAccount();

  return (
    <div>
      {headline && (
        <Block marginBottom={8}>
          <MyAccountHeadline
            headlineText={headline}
            textSize={textSize}
            iconCustom="Inbox"
            iconMedia={icon}
            uppercase
            subHeadline={subHeadline}
          />
        </Block>
      )}

      {benefits?.length > 0 && (
        <Block marginBottom={7}>
          <div className={styles.blockWrap}>
            <AccountMyNewsletterBenefits
              benefits={benefits}
              benefitsTitle={benefitsTitle}
              benefitsColor={benefitsColor}
            />
          </div>
        </Block>
      )}

      <AccountMyNewsletterSubscription
        emailAddress={account.email}
        newsletters={newsletters}
        subscriptionSuccessMessage={subscriptionSuccessMessage}
        subscriptionFailureMessage={subscriptionFailureMessage}
        subscriptionInfoText={subscriptionInfoText}
        subscriptionCtaText={subscriptionCtaText}
      />
    </div>
  );
};
