import React, { useCallback } from 'react';
import classNames from 'classnames';
import styles from './textarea.module.scss';

export interface ITextarea {
  rows?: number;
  placeholder?: string;
  style?: 'default' | 'grey' | 'white';
  id?: string;
  name?: string;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  required?: boolean;
  value?: string;
  errorMessage?: string;
  textareaCustomStyle?: object;
}

export const Textarea: React.FC<ITextarea> = ({
  rows,
  placeholder,
  style,
  id,
  name,
  onChange,
  onBlur,
  required,
  value = '',
  errorMessage,
  textareaCustomStyle,
}) => {
  const changeValue = useCallback(
    (event) => {
      onChange && onChange(event);
    },
    [onChange],
  );

  return (
    <div>
      <div
        className={classNames(styles.textareaWrap, {
          [styles[`style--${style}`]]: style,
          [styles[`textarea-has-content`]]: value?.length,
        })}
      >
        <textarea
          onChange={changeValue}
          onBlur={onBlur}
          rows={rows}
          value={value}
          placeholder={placeholder}
          className={styles.textarea}
          id={id}
          name={name}
          required={required}
          style={textareaCustomStyle ? { ...textareaCustomStyle } : { padding: '24px 12px 4px' }}
        />
        {placeholder && <div className={styles.placeholder}>{placeholder}</div>}
      </div>

      {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
    </div>
  );
};
