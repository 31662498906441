import { useCallback, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import classnames from 'classnames';
import { IconCustom, InputTooltip } from '@engbers/components';
import { useModalActions } from '@frontastic-engbers/lib/state/modal/actions';
import styles from './input-text.module.scss';

export interface IInputText {
  placeholder?: string;
  infoText?: string;
  infoTextHeadline?: string;
  useInfoModal?: boolean;
  infoModalContentClassName?: string;
  infoModalIsLarge?: boolean;
  infoModalHasOkayButton?: boolean;
  infoModalCanCloseByBackdropClick?: boolean;
  infoIconSize?: number;
  infoIconPosition?: 'inner' | 'outer';
  style?: 'default' | 'grey' | 'white';
  id?: string;
  name?: string;
  type?: 'email' | 'text' | 'password' | 'number' | 'search';
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onEnter?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onPaste?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  autoComplete?: string;
  required?: boolean;
  value?: string;
  hasError?: boolean;
  disableErrorIcon?: boolean;
  errorMessage?: string;
  isDisabled?: boolean;
  wrapperCustomStyle?: object;
  placeholderCustomStyle?: object;
  inputCustomStyle?: object;
  successValidation?: boolean;
  styleNumberAsTextInput?: boolean;
  errorClassName?: string;
}

export const InputText: React.FC<IInputText> = ({
  placeholder,
  infoText,
  infoTextHeadline,
  useInfoModal,
  infoModalContentClassName,
  infoModalIsLarge = false,
  infoModalHasOkayButton = true,
  infoModalCanCloseByBackdropClick = false,
  infoIconSize = 22,
  infoIconPosition = 'inner',
  style,
  id,
  name,
  type = 'text',
  onChange,
  onFocus,
  onBlur,
  onEnter,
  onPaste,
  required,
  autoComplete,
  isDisabled,
  value = '',
  hasError,
  disableErrorIcon,
  errorMessage,
  wrapperCustomStyle,
  placeholderCustomStyle,
  inputCustomStyle,
  successValidation,
  styleNumberAsTextInput = false,
  errorClassName,
}) => {
  const [openTooltip, setOpenTooltip] = useState<boolean>(false);
  const { pushModal, removeModal } = useModalActions();
  const changeValue = useCallback(
    (event) => {
      onChange?.(event);
    },
    [onChange],
  );

  const focusValue = useCallback(
    (event) => {
      onFocus?.(event);
    },
    [onFocus],
  );

  const blurValue = useCallback(
    (event) => {
      onBlur?.(event);
    },
    [onBlur],
  );

  const enterValue = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        onEnter?.(event);
      }
    },
    [onEnter],
  );

  const pasteValue = useCallback(
    (event) => {
      onPaste?.(event);
    },
    [onPaste],
  );

  return (
    <div
      style={{
        ...wrapperCustomStyle,
      }}
    >
      <div
        className={classnames(styles.inputWrap, {
          [styles[`style--${style}`]]: style,
          [styles[`input-has-content`]]: value?.length,
          [styles[`is-disabled`]]: isDisabled,
          [styles[`has-error`]]: !!errorMessage,
          [styles[`numberAsTextInput`]]: styleNumberAsTextInput && type === 'number',
        })}
      >
        <input
          onChange={changeValue}
          onFocus={focusValue}
          onBlur={blurValue}
          onKeyDown={enterValue}
          onPaste={pasteValue}
          value={value}
          placeholder={placeholder}
          className={styles.input}
          autoComplete={autoComplete}
          id={id}
          disabled={isDisabled}
          name={name}
          type={type}
          required={required}
          style={inputCustomStyle ? { ...inputCustomStyle } : { padding: '24px 40px 4px 12px' }}
        />
        {placeholder && (
          <div style={{ ...placeholderCustomStyle }} className={styles.placeholder}>
            {placeholder} {required && '*'}
          </div>
        )}
        {infoText && !hasError && (
          <div
            onClick={() => {
              if (useInfoModal) {
                pushModal({
                  title: infoTextHeadline,
                  hasCloseButton: true,
                  content: (
                    <div className={classnames(styles.modalContent, infoModalContentClassName)}>
                      <ReactMarkdown>{infoText}</ReactMarkdown>
                    </div>
                  ),
                  isLarge: infoModalIsLarge,
                  hasOkayButton: infoModalHasOkayButton,
                  canCloseByBackdropClick: infoModalCanCloseByBackdropClick,
                  id: 'input-modal',
                  onClose: () => {
                    removeModal();
                  },
                });
              } else {
                setOpenTooltip(!openTooltip);
              }
            }}
            className={classnames(styles.infoIconWrap, {
              [styles.infoIconOuterWrap]: infoIconPosition === 'outer',
            })}
          >
            <IconCustom width={infoIconSize} icon="Info" />
            <InputTooltip
              onClose={() => {
                setOpenTooltip(false);
              }}
              message={
                <div className={styles.modalContent}>
                  <ReactMarkdown>{infoText}</ReactMarkdown>
                </div>
              }
              isActive={openTooltip}
            />
          </div>
        )}
        {((!disableErrorIcon && !infoText && errorMessage) || (hasError && !errorMessage)) && (
          <div className={styles.statusWrap}>
            <div
              className={classnames(styles.errorIcon, {
                [styles.errorIconCustomStyle]: inputCustomStyle,
              })}
            />
          </div>
        )}
        {successValidation && hasError === false && (
          <div className={styles.statusWrap}>
            <div className={styles.successIcon} />
          </div>
        )}
      </div>

      {errorMessage && <div className={classnames(errorClassName, styles.errorMessage)}>{errorMessage}</div>}
    </div>
  );
};
