import { Block, InputCheckbox, InputText } from '@engbers/components';

const StyleGuideInputs: React.FC = () => {
  return (
    <div className="dark:bg-encom-900 mb-10 border border-solid border-slate-300 dark:text-white">
      <h2 className="mx-6 my-4 dark:text-white">Inputs</h2>

      <div className="my-3 flex items-center justify-start p-4 align-middle">
        <div className="text-base-bold mx-3 w-[20%] text-center md:w-[10%]">Default</div>
        <div className="mx-3 w-full md:w-[40%]">
          <InputText type="text" placeholder="Label" required />
        </div>
      </div>

      <div className="my-3 flex items-center justify-start p-4 align-middle">
        <div className="text-base-bold mx-3 w-[20%] text-center md:w-[10%]">Info icon</div>
        <div className="mx-3 w-full md:w-[40%]">
          <InputText
            type="text"
            placeholder="Label"
            required
            infoText="Here goes any text"
            useInfoModal
            infoModalHasOkayButton={false}
            infoModalCanCloseByBackdropClick
          />
        </div>
      </div>

      <div className="my-3 flex items-center justify-start p-4 align-middle">
        <div className="text-base-bold mx-3 w-[20%] text-center md:w-[10%]">Error</div>
        <div className="mx-3 w-full md:w-[40%]">
          <InputText type="text" placeholder="Label" required errorMessage="Error message here" />
        </div>
      </div>

      <div className="my-3 flex items-center justify-start p-4 align-middle">
        <div className="text-base-bold mx-3 w-[20%] text-center md:w-[10%]">Disabled</div>
        <div className="mx-3 w-full md:w-[40%]">
          <InputText type="text" placeholder="Label" required isDisabled />
        </div>
      </div>

      <div className="my-3 flex items-center justify-start p-4 align-middle">
        <div className="text-base-bold mx-3 w-[20%] text-center md:w-[10%]">Outer Label</div>
        <div className="mx-3 w-full md:w-[40%]">
          <Block className="flex items-center">
            <label className="w-1/6 text-sm" htmlFor="outer-label">
              Label
            </label>
            <InputText
              id="outer-label"
              name="outer-label"
              type="text"
              style="white"
              required
              wrapperCustomStyle={{ width: '75%' }}
              inputCustomStyle={{ height: '30px', padding: '0 8px' }}
            />
          </Block>
        </div>
      </div>

      <div className="my-3 flex items-center justify-start p-4 align-middle">
        <div className="text-base-bold mx-3 w-[20%] text-center md:w-[10%]">Outer Label Error</div>
        <div className="mx-3 w-full md:w-[40%]">
          <Block className="flex items-center">
            <label className="w-1/6 text-sm" htmlFor="outer-label-error">
              Label
            </label>
            <InputText
              id="outer-label-error"
              name="outer-label-error"
              type="text"
              style="white"
              required
              errorMessage="Error message here"
              wrapperCustomStyle={{ width: '75%' }}
              inputCustomStyle={{ height: '30px', padding: '0 8px' }}
            />
          </Block>
        </div>
      </div>

      <div className="my-3 flex items-center justify-start p-4 align-middle">
        <div className="text-base-bold mx-3 w-[20%] text-center md:w-[10%]">Default</div>
        <div className="mx-3 w-full md:w-[40%]">
          <div className="input-radio-group">
            <input
              id="input-6"
              name="radio"
              type="radio"
              required
              className="border-encom-200 bg-encom-50 hover:border-encom-900 focus:ring-transparent"
            />
            <label htmlFor="input-6" className="text-encom-500 dark:text-white">
              Radio Button
            </label>
          </div>
        </div>
      </div>

      <div className="my-3 flex items-center justify-start p-4 align-middle">
        <div className="text-base-bold mx-3 w-[20%] text-center md:w-[10%]">Disabled</div>
        <div className="mx-3 w-full md:w-[40%]">
          <div className="input-radio-group">
            <input
              id="input-7"
              name="radio"
              type="radio"
              required
              className="border-encom-200 bg-encom-50 hover:border-encom-900 focus:ring-transparent"
              disabled
            />
            <label htmlFor="input-7" className="text-encom-500 dark:text-white">
              Radio Button
            </label>
          </div>
        </div>
      </div>

      <div className="my-3 flex items-center justify-start p-4 align-middle">
        <div className="text-base-bold mx-3 w-[20%] text-center md:w-[10%]">Error</div>
        <div className="mx-3 w-full md:w-[40%]">
          <div className="input-radio-group">
            <input
              id="input-8"
              name="radio"
              type="radio"
              required
              className="border-danger bg-encom-50 hover:border-encom-900 focus:ring-transparent"
            />
            <label htmlFor="input-8" className="text-encom-500 dark:text-white">
              Radio Button
            </label>
          </div>
        </div>
      </div>

      <div className="my-3 flex items-center justify-start p-4 align-middle">
        <div className="text-base-bold mx-3 w-[20%] text-center md:w-[10%]">Default</div>
        <div className="mx-3">
          <InputCheckbox
            id="checkbox-default"
            name="checkbox-default"
            label="Checkbox White"
            style="white"
            hasHoverEffect
            onChange={() => console.log('changed')}
          />
        </div>
        <div className="mx-3">
          <InputCheckbox
            id="checkbox-default-gray"
            name="checkbox-default-gray"
            label="Checkbox Gray"
            hasHoverEffect
            onChange={() => console.log('changed')}
          />
        </div>
      </div>

      <div className="my-3 flex items-center justify-start p-4 align-middle">
        <div className="text-base-bold mx-3 w-[20%] text-center md:w-[10%]">Disabled</div>
        <div className="mx-3">
          <InputCheckbox
            id="checkbox-disabled"
            name="checkbox-disabled"
            label="Checkbox"
            style="white"
            hasHoverEffect
            disabled
            onChange={() => console.log('changed')}
          />
        </div>
      </div>

      <div className="my-3 flex items-center justify-start p-4 align-middle">
        <div className="text-base-bold mx-3 w-[20%] text-center md:w-[10%]">Error</div>
        <div className="mx-3">
          <InputCheckbox
            id="checkbox-error"
            name="checkbox-error"
            label="Checkbox"
            style="white"
            hasHoverEffect
            errorMessage="Error message here"
            onChange={() => console.log('changed')}
          />
        </div>
        <div className="mx-3">
          <InputCheckbox
            id="checkbox-error-gray"
            name="checkbox-error-gray"
            label="Checkbox Gray"
            hasHoverEffect
            errorMessage="Error message here"
            onChange={() => console.log('changed')}
          />
        </div>
      </div>
    </div>
  );
};

export default StyleGuideInputs;
